import InputComponent from '@bugbug/core/components/Input';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

import { Label as LabelComponent } from '~/components/FormField/FormField.styled';

import { Row, Select as SelectComponent } from '../ProjectSettings.styled';

export const Container = styled.div``;

export const Table = styled.div`
  width: 800px;
  border-top: 1px solid ${COLOR.GRAY_18};
`;

export const TableRow = styled(Row)`
  width: 100%;
  border-bottom: 1px solid ${COLOR.GRAY_18};
  height: 54px;
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled(LabelComponent)`
  padding: 0 8px 0 0;
  width: 170px;
`;

export const Select = styled(SelectComponent)`
  width: 292px;
  margin-right: 8px;
`;

export const Input = styled(InputComponent)`
  width: 292px;
  margin-right: 12px;
`;

export const Placeholder = styled.span`
  width: 292px;
  margin-right: 12px;
`;
