import { animated, useSpring, to } from '@react-spring/web';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import {
  AddIcon,
  AddIconsWrapperContainer,
  AddIconWrapper,
  ActiveBorder,
} from '../StepsBaseList/StepsBaseList.styled';

const AnimatedAddIconsWrapperContainer = animated(AddIconsWrapperContainer);
const AnimatedAddIconWrapper = animated(AddIconWrapper);

export const RowActiveBorderContext = createContext({
  onItemEnter: () => {
    throw new Error('Unimplemented');
  },
  onItemLeave: () => {
    throw new Error('Unimplemented');
  },
});

const getTop = (el) => (el ? el.offsetTop + (el.offsetParent ? getTop(el.offsetParent) : 0) : 0);

export const RowActiveBorderContextProvider = ({ children, containerRef, hidden }) => {
  const [positionSpring, apiPositionSpring] = useSpring(() => ({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    topBorderVisibility: 'hidden',
    bottomBorderVisibility: 'hidden',
    topBorderOpacity: 0,
    bottomBorderOpacity: 0,
    config: { duration: 0 },
    topBorderPosition: 'absolute',
  }));

  const borderProps = useRef({});
  const bordersContainerRef = useRef();
  const itemRef = useRef(null);
  const [activeBorderProps, setActiveBorderProps] = useState();
  const isFixed = useRef(false);
  const modifiersCache = useRef({});
  const [scrollSpring, setScrollSpring] = useSpring(() => ({ y: 0 }));

  const getPositionValues = useCallback(
    (isFixedPosition) => ({
      x: modifiersCache.current.x || 0,
      y:
        (isFixedPosition ? getTop(itemRef.current) : itemRef.current.offsetTop) +
        (modifiersCache.current.y || 0),
      height: itemRef.current.offsetHeight + (modifiersCache.current.height || 0),
      width: itemRef.current.offsetWidth + (modifiersCache.current.width || 0),
      topBorderPosition: isFixedPosition ? 'fixed' : 'absolute',
    }),
    [],
  );

  const enableFixedMode = useCallback(() => {
    isFixed.current = true;

    setScrollSpring({
      y: containerRef.current.scrollTop,
      config: { duration: 0 },
    });

    apiPositionSpring.start(getPositionValues(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableFixedMode = useCallback(() => {
    isFixed.current = false;

    setScrollSpring({
      y: 0,
      config: { duration: 0 },
    });

    apiPositionSpring.start(getPositionValues(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const shouldBeDisabled =
        bordersContainerRef.current.getBoundingClientRect().top <
        e.currentTarget.getBoundingClientRect().top - modifiersCache.current.x;

      if (isFixed.current) {
        if (shouldBeDisabled) {
          disableFixedMode();
        } else {
          setScrollSpring({
            y: containerRef.current.scrollTop,
            config: { duration: 0 },
          });
        }
      } else if (!shouldBeDisabled) {
        enableFixedMode();
      }
    },
    [disableFixedMode, setScrollSpring, containerRef, enableFixedMode],
  );

  const onItemEnter = useCallback(
    (target, modifiers, props) => {
      itemRef.current = target;
      modifiersCache.current = modifiers;
      borderProps.current = props;

      containerRef?.current?.removeEventListener('scroll', handleScroll, { passive: true });

      if (modifiers.fixed && containerRef?.current) {
        containerRef.current.addEventListener('scroll', handleScroll, { passive: true });

        isFixed.current =
          target.getBoundingClientRect().top >
          (containerRef?.current.getBoundingClientRect().top ?? 0) - modifiersCache.current.x;

        if (isFixed.current) {
          enableFixedMode();
        }
      }

      apiPositionSpring.start({
        ...getPositionValues(isFixed.current),
        topBorderOpacity: Number(!!props?.top),
        bottomBorderOpacity: Number(!!props?.bottom),
        topBorderVisibility: props?.top ? 'visible' : 'hidden',
        bottomBorderVisibility: props?.bottom ? 'visible' : 'hidden',
      });
    },
    [apiPositionSpring, containerRef, handleScroll, enableFixedMode, getPositionValues],
  );

  const onItemLeave = useCallback(() => {
    if (isFixed.current) {
      setScrollSpring({
        y: 0,
        config: { duration: 0 },
      });
    }

    apiPositionSpring.start({
      topBorderOpacity: 0,
      bottomBorderOpacity: 0,
    });

    isFixed.current = false;
    modifiersCache.current = {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBorderMouseLeave = useCallback(() => {
    apiPositionSpring.start({
      topBorderPosition: 'absolute',
      topBorderVisibility: 'hidden',
      bottomBorderVisibility: 'hidden',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({
      onItemEnter,
      onItemLeave,
    }),
    [onItemEnter, onItemLeave],
  );

  const handleActiveBorderClose = useCallback(() => {
    setActiveBorderProps(undefined);
  }, []);

  const handleTopBorderClick = useCallback((e) => {
    e.stopPropagation();
    if (!borderProps.current?.top) {
      return;
    }

    setActiveBorderProps({
      position: 'top',
      ...borderProps.current.top,
    });
  }, []);

  const handleBottomBorderClick = useCallback((e) => {
    e.stopPropagation();
    if (!borderProps.current?.bottom) {
      return;
    }

    setActiveBorderProps({
      position: 'bottom',
      ...borderProps.current.bottom,
    });
  }, []);

  const isTopBorderOpened = activeBorderProps?.position === 'top';
  const isBottomBorderOpened = activeBorderProps?.position === 'bottom';

  return (
    <RowActiveBorderContext.Provider value={contextValue}>
      {children}
      {!hidden && (
        <AnimatedAddIconsWrapperContainer
          ref={bordersContainerRef}
          data-testid="RowActiveBorderContext.IconsWrapper"
          style={{
            position: positionSpring.topBorderPosition,
            width: positionSpring.width,
            height: positionSpring.height,
            transform: to(
              [scrollSpring.y, positionSpring.x, positionSpring.y],
              (scrollY, x, y) => `translate3d(${x}px, ${y - scrollY}px, 0)`,
            ),
          }}
        >
          <>
            <animated.div
              style={{
                visibility: positionSpring.topBorderVisibility,
              }}
            >
              <ActiveBorder
                onMouseLeave={handleBorderMouseLeave}
                opened={isTopBorderOpened}
                onClick={handleTopBorderClick}
                onClose={handleActiveBorderClose}
                {...(isTopBorderOpened && activeBorderProps)}
              />
            </animated.div>
            <AnimatedAddIconWrapper
              data-testid="RowActiveBorderContext.AddIconWrapper"
              style={{
                opacity: positionSpring.topBorderOpacity,
              }}
            >
              <AddIcon />
            </AnimatedAddIconWrapper>
          </>
          <>
            <AnimatedAddIconWrapper
              style={{
                opacity: positionSpring.bottomBorderOpacity,
              }}
              data-testid="RowActiveBorderContext.AddIconWrapper"
              bottom
            >
              <AddIcon />
            </AnimatedAddIconWrapper>
            <animated.div
              style={{
                visibility: positionSpring.bottomBorderVisibility,
              }}
            >
              <ActiveBorder
                onMouseLeave={handleBorderMouseLeave}
                opened={isBottomBorderOpened}
                bottom
                onClick={handleBottomBorderClick}
                onClose={handleActiveBorderClose}
                {...(isBottomBorderOpened && activeBorderProps)}
              />
            </animated.div>
          </>
        </AnimatedAddIconsWrapperContainer>
      )}
    </RowActiveBorderContext.Provider>
  );
};

RowActiveBorderContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export const useRowActiveBorder = ({ modifiers = {}, props }, getTopOffset) => {
  const { onItemEnter, onItemLeave } = useContext(RowActiveBorderContext);

  const handleItemEnter = useCallback(
    (e) => {
      onItemEnter(
        e.currentTarget,
        {
          ...modifiers,
          y: (getTopOffset?.() || 0) + (modifiers.y || 0),
          fixed: modifiers.fixed || false,
        },
        props,
      );
    },
    [getTopOffset, modifiers, onItemEnter, props],
  );

  return useMemo(
    () => ({
      onItemEnter: handleItemEnter,
      onItemLeave,
    }),
    [handleItemEnter, onItemLeave],
  );
};
