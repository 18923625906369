import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Timezone } from '@bugbug/core/types/geography';
import type { Schedule } from '@bugbug/core/types/schedules';
import { DAYS_OF_WEEK, HOURS } from '~/constants/schedules';
import { convertHourValueBetweenTimezones } from '~/utils/dates';
import { getTimeUnit } from '~/utils/schedules';

import * as S from './ScheduleItem.styled';

interface ScheduleItemProps {
  data: Schedule;
  timezone: Timezone;
}

const ScheduleItemDescription = ({ data, timezone }: ScheduleItemProps) => {
  const { t } = useTranslation();

  const timePeriodDescription = useMemo(() => {
    const timeUnit = getTimeUnit(data);
    const hour = HOURS.find(
      ({ id }) => id === convertHourValueBetweenTimezones(data.hour, 'Utc', timezone),
    );

    const selectedDaysOfWeek = data.dayOfWeek.split(',');
    const daysOfWeek = DAYS_OF_WEEK.filter(({ id }) => selectedDaysOfWeek.includes(id)).map((day) =>
      day.name.slice(0, 3),
    );

    const daysOfMonth = data.dayOfMonth.split(',').join(', ');

    switch (timeUnit) {
      case 'hourly':
        return t('scheduleItem.description.interval.everyHour', 'Runs every hour');
      case 'daily':
        return t('scheduleItem.description.interval.everyDay', 'Daily at {{ hour }}', {
          hour: hour?.name,
        });
      case 'weekly':
        return t(
          'scheduleItem.description.interval.everyWeek',
          'Weekly on {{ days }} at {{ hour }}',
          {
            days: daysOfWeek.join(', '),
            hour: hour?.name,
          },
        );
      case 'monthly':
        return t(
          'scheduleItem.description.interval.everyMonth',
          'On {{ day }} day of month at {{ hour }}',
          {
            day: daysOfMonth,
            hour: hour?.name,
          },
        );
      case 'everyMinute':
        return t('scheduleItem.description.interval.everyMinute', 'Runs every minute');
      case 'everyFiveMinutes':
        return t('scheduleItem.description.interval.everyFiveMinutes', 'Runs every 5 minutes');

      default:
        return '';
    }
  }, [data, timezone, t]);

  return (
    <>
      <S.Time>{timePeriodDescription}</S.Time>
      <S.Suites>
        {data.suites.length
          ? t('scheduleItem.description.suites', {
              count: data.suites.length,
              defaultValue_one: '1 suite scheduled',
              defaultValue_other: '{{ count }} suites scheduled',
            })
          : t('scheduleItem.description.noSuites', 'No suites selected')}
      </S.Suites>
    </>
  );
};

export default ScheduleItemDescription;
