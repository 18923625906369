import SearchInput from '@bugbug/core/components/SearchInput';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { InputChangeHandler } from '@bugbug/core/components/Input/Input.types';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';

import { PaidFeature } from '../PaidFeatureGuard';

import { Container, CreateButton } from './SchedulesActions.styled';

const SchedulesActions = () => {
  const { t } = useTranslation();
  const updateQueryString = useUpdateQueryString();
  const queryString = useQueryString();
  const appRoutes = useAppRoutes('schedule');

  const handleCreate = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    appRoutes.push('newSchedule');
  }, [appRoutes]);

  const handleQueryChange = useCallback<InputChangeHandler>(
    (event) => {
      updateQueryString({ query: event.target.value });
    },
    [updateQueryString],
  );

  return (
    <Container>
      <SearchInput value={queryString.query?.toString()} onChange={handleQueryChange} />
      <PaidFeature>
        {(isDisabled) => (
          <CreateButton
            data-testid="schedulesList.actions.createNewSchedule"
            disabled={isDisabled}
            onClick={handleCreate}
          >
            {t('schedulesList.header.createButton', 'New schedule')}
          </CreateButton>
        )}
      </PaidFeature>
    </Container>
  );
};

SchedulesActions.displayName = 'SchedulesActions';

export default SchedulesActions;
