import * as R from 'ramda';

import type { Column } from 'react-table';
import type { Data } from '~/components/Table';

import i18n from '~/translations';

import ImageCell from '../ImageCell';
import StepNameWithParams from '../StepNameWithParams';

export const STEPS_COLUMNS: Column<Data>[] = [
  {
    Header: i18n.t('componentDetails.table.columns.action', 'Action') as string,
    accessor: R.identity,
    id: 'action',
    Cell: StepNameWithParams,
    minWidth: 240,
    width: '43%',
    disableSortBy: true,
  },
  {
    Header: i18n.t('componentDetails.table.columns.screenshot', 'Screenshot') as string,
    accessor: R.identity,
    Cell: ImageCell,
    id: 'screenshot',
    disableSortBy: true,
  },
];
