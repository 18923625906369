import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const ContentSlice = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20%;
`;

export const ChartContainer = styled.div`
  width: 140px;
  height: 105px;
  flex: none;
`;

export const DetailsMetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  width: max-content;

  &:first-child {
    margin-right: 5%;
  }

  span:first-child {
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 34px;
  }

  span:last-child {
    font-weight: ${FONT_WEIGHT.NORMAL};
    font-size: 13px;
  }
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 12px;
  flex: 200px;

  @media print {
    display: none;
  }
`;

export const StatsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;

  span {
    margin-top: -5px;
  }
`;

export const Container = styled.div`
  border: 1px solid ${COLOR.GRAY_35};
  border-radius: 15px;
  padding: 25px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15%;
  align-items: center;
  justify-content: space-between;
`;
