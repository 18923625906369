import ButtonBase from '@bugbug/core/components/Button';
import DropdownBase, { DROPDOWN_VARIANT, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import InputContainer from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import SelectBase from '@bugbug/core/components/Select';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  p {
    color: ${COLOR.GRAY_20};
  }
`;

export const Url = styled(Link)`
  color: ${COLOR.PRIMARY_LIGHT} !important;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  text-decoration: none !important;
`;

export const ActionButton = styled(ButtonBase)`
  border-top: 1px solid ${COLOR.GRAY_9};
  border-radius: 0;
  color: inherit;
  padding-left: 37px;
  font-weight: ${FONT_WEIGHT.NORMAL};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.neutrals[96]};
  }

  svg {
    width: 24px;
  }
`;

export const Dropdown = styled(DropdownBase).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
  anchor: DROPDOWN_ANCHOR.BOTTOM_START,
  rounded: true,
  hideExpander: true,
}))`
  margin-top: 5px;
  width: fit-content;
`;

export const Select = styled(SelectBase)`
  grid-area: state;
  min-width: 200px;
`;

export const CustomSelectOption = styled.div`
  display: flex;

  span {
    margin-left: 3px;
    color: ${COLOR.GRAY_12};
    text-transform: lowercase;
  }
`;

export const Input = styled(InputContainer)`
  grid-area: value;
`;

export const ConditionState = styled.div`
  font-size: 11px;
  font-weight: 700;
  position: relative;
  border-radius: 4px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  margin-top: 2px;
  width: 24px;
  height: 15px;
  padding-bottom: 1px;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.GRAY_31};

  &:after {
    content: 'off';
  }

  ${styleWhenTrue(
    prop('enabled'),
    css`
      &:after {
        content: 'on';
      }
      background-color: ${COLOR.PRIMARY_BORDER};
    `,
  )}
`;
