import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '~/components/FormField';
import { INTERACTION_POSITIONS, INTERACTION_POSITION_TYPE } from '~/constants/step';

import { CoordsValues } from './InteractionPositionField.styled';

const InteractionPositionField = ({
  label,
  helperText,
  customCoordsLabel,
  customDisabled,
  disabled,
  name,
  nameX,
  nameY,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getFieldProps = useCallback(
    (fieldName) => {
      const fieldProps = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name: fieldProps.name, value: fieldProps.value, error: touched && error };
    },
    [formik],
  );

  const renderPositionField = () => (
    <CoordsValues>
      <Input
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(nameX)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={formik.handleFocus}
        readOnly={disabled}
        startAdornment={t('stepDetails.interaction.dndCoords.x.label', 'X')}
        endAdornment={t('stepDetails.interaction.dndCoords.x.unit', 'px')}
        aria-label={t('stepDetails.interaction.dndCoords.x.ariaLabel', 'Position X')}
      />
      <Input
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(nameY)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={formik.handleFocus}
        readOnly={disabled}
        startAdornment={t('stepDetails.interaction.dndCoords.y.label', 'Y')}
        endAdornment={t('stepDetails.interaction.dndCoords.y.unit', 'px')}
        aria-label={t('stepDetails.interaction.dndCoords.y.ariaLabel', 'Position Y')}
      />
    </CoordsValues>
  );

  return (
    <>
      <FormField
        label={label || t('stepDetails.interaction.interactionPosition', 'Position')}
        labelId={name}
        helperText={helperText}
      >
        <Select
          aria-labelledby={name}
          fullWidth
          {...formik.getFieldProps(name)}
          disabled={disabled}
        >
          {INTERACTION_POSITIONS.filter(
            ({ value }) => !customDisabled || value !== INTERACTION_POSITION_TYPE.CUSTOM,
          ).map((position) => (
            <SelectOption key={position.value} value={position.value}>
              {position.label}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      {formik.values[name] === INTERACTION_POSITION_TYPE.CUSTOM && (
        <FormField
          label={
            customCoordsLabel ||
            t('stepDetails.interaction.dndCoords.drop.label', 'Custom position')
          }
          helperText={t(
            'stepDetails.interaction.dndCoords.drop.helperText',
            'Distance in pixels from the top-left corner of the element.',
          )}
        >
          {renderPositionField()}
        </FormField>
      )}
    </>
  );
};

InteractionPositionField.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  customCoordsLabel: PropTypes.string,
  customDisabled: PropTypes.bool,
};

export default InteractionPositionField;
