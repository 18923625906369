import { TextWithExtraSpaces } from '@bugbug/core/theme/typography';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TextDiff from '~/components/TextDiff';
import { ASSERTION_PROPERTY, ASSERTION_PROPERTY_TYPE, ASSERTION_TYPE } from '~/constants/step';

import { Description, Title } from '../ErrorDetails.styled';

import { ERROR_DESCRIPTION } from './AssertFailedDetails.constants';
import { ComparisonContainer, ComparisonRow } from './AssertFailedDetails.styled';

const AssertFailedDetails = ({ stepRun }) => {
  const { t } = useTranslation();
  const emptyValue = t('assertFailedDetails.column.emptyValue', '(empty)');

  const shouldShowTable =
    !ASSERTION_PROPERTY[stepRun.assertionProperty]?.isTrueFalse &&
    ![
      ASSERTION_PROPERTY_TYPE.COUNT,
      ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT,
      ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT,
      ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED,
    ].includes(stepRun.assertionProperty) &&
    stepRun.assertionType !== ASSERTION_TYPE.NOT_EQUAL;

  const shouldShowDiff = ![ASSERTION_TYPE.MATCH, ASSERTION_TYPE.NOT_MATCH].includes(
    stepRun.assertionType,
  );

  const title = stepRun.warning
    ? t('assertFailedDetails.title.warning', 'Assertion failed but the issue was ignored')
    : t('assertFailedDetails.title.failed', 'Assertion failed!');

  const getDescription = pathOr(
    Function.prototype,
    [stepRun.assertionProperty, stepRun.assertionType],
    ERROR_DESCRIPTION,
  );

  const expected = stepRun.computedAssertionExpectedValue || stepRun.assertionExpectedValue || '';
  const observed = stepRun.assertionCurrentValue || '';

  return (
    <>
      <Title>{title}</Title>
      <Description>
        {getDescription({
          expected,
          current: observed,
        })}
      </Description>

      {shouldShowTable && (
        <ComparisonContainer warning={stepRun.warning} role="table">
          <ComparisonRow>
            <span>{t('assertFailedDetails.column.expected', 'Expected')}</span>
            <TextWithExtraSpaces dangerouslySetInnerHTML={{ __html: expected || emptyValue }} />
          </ComparisonRow>
          <ComparisonRow>
            <span>{t('assertFailedDetails.column.current', 'Observed')}</span>
            <TextWithExtraSpaces dangerouslySetInnerHTML={{ __html: observed || emptyValue }} />
          </ComparisonRow>
          {shouldShowDiff && (
            <ComparisonRow>
              <span>{t('assertFailedDetails.column.diff', 'Diff')}</span>
              <TextDiff value={observed} oldValue={expected} />
            </ComparisonRow>
          )}
        </ComparisonContainer>
      )}
    </>
  );
};

AssertFailedDetails.propTypes = {
  stepRun: PropTypes.shape({
    warning: PropTypes.bool,
    assertionType: PropTypes.string,
    assertionProperty: PropTypes.oneOf(Object.values(ASSERTION_PROPERTY_TYPE)),
    assertionExpectedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]),
    assertionCurrentValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]),
  }).isRequired,
};

AssertFailedDetails.defaultProps = {};

export default AssertFailedDetails;
