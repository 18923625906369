import { ActionButton as ActionButtonBase, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import mixins from '@bugbug/core/theme/mixins';
import { H1 } from '@bugbug/core/theme/typography';
import styled, { css } from 'styled-components';

import CollapsableSection from '~/components/CollapsableSection';
import * as CollapsableSectionPartials from '~/components/CollapsableSection/CollapsableSection.styled';
import * as FormFieldPartials from '~/components/FormField/FormField.styled';
import { PaidFeatureBanner as PaidFeatureBannerBase } from '~/components/PaidFeatureGuard';

import TestStepMoreActions from '../TestStepMoreActions';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 27px;
  height: 120px;
  position: relative;
  z-index: 7;
  background-color: ${({ theme }) => theme.colors.background.surface};
`;

export const Title = styled(H1).attrs(() => ({ as: 'h2' }))`
  ${mixins.textEllipsis};
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 20px;
`;

export const RunMetaData = styled.div``;

export const FieldRow = styled.div`
  display: grid;
  align-items: center;
  min-height: 52px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
  grid-template-columns: 120px min(250px);
  grid-column-gap: 8px;

  ${({ theme }) => theme.media.desktopWide`
    grid-template-columns: 170px min(300px);
  `}
`;

export const FieldLabel = styled.label`
  margin: 0;
  width: 100%;
  flex: none;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  min-height: 38px;
`;

export const FieldValue = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  align-items: center;
`;

const commonButtonStyles = css`
  margin-left: 9px;
  justify-content: center;
  width: 74px;

  & > div {
    flex: none;
  }
`;

export const ActionButton = styled(ActionButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  ${commonButtonStyles}
`;

export const SaveButton = styled(ActionButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  ${commonButtonStyles};
  width: 124px;
`;

export const Section = styled(CollapsableSection)`
  ${CollapsableSectionPartials.Header} {
    padding: 10px 8px 9px 27px;
    background: ${COLOR.GRAY_21};
    border-top: 2px solid ${COLOR.GRAY_18};
    box-shadow: 0 1px 2px 0 ${COLOR.DARK_GRAY_7};
    font-size: 13px;
    letter-spacing: 0.02em;

    > div {
      padding-bottom: 1px;
    }

    &:hover {
      background: ${COLOR.GRAY_28};
    }
  }

  ${CollapsableSectionPartials.Content} {
    padding: 20px 27px 27px;
    align-items: stretch;
  }

  ${CollapsableSectionPartials.Expander} {
    transform: translateY(-1px) scale(0.9);
  }

  ${FormFieldPartials.Label} {
    color: ${COLOR.GRAY_19};
    font-size: 12px;
  }
`;

export const MoreActions = styled(TestStepMoreActions)`
  margin-right: -6px;
  margin-left: 2px;
`;

export const PaidFeatureBanner = styled(PaidFeatureBannerBase)`
  margin: 5px 24px 20px 24px;
`;
