import { COLOR, hexToRgba } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { always, ifElse, prop } from 'ramda';
import styled from 'styled-components';

const ifWarningElse = (warningStyle, style) =>
  ifElse(prop('warning'), always(warningStyle), always(style));

export const ComparisonRow = styled.div`
  padding: 2px 8px 6px;
  line-height: 19px;
  border-top: 1px solid ${COLOR.BORDER_GRAY};

  &:first-child {
    border: none;
  }

  span {
    padding-top: 2px;
    padding-bottom: 2px;
    color: ${COLOR.GRAY_13};
    font-size: 12px;
    line-height: 14px;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    text-transform: uppercase;
  }

  pre {
    color: ${COLOR.DARK_GRAY};
    line-height: 17px;
    font-weight: ${FONT_WEIGHT.NORMAL};
  }
`;

export const ComparisonContainer = styled.div`
  border-collapse: separate;
  margin: 8px 24px 18px 0;
  border-radius: 5px;
  overflow: hidden;
  background: ${COLOR.WHITE};
  border-spacing: 0;
  flex: none;
  background: ${hexToRgba(COLOR.WHITE, 0.75)};

  max-width: 100%;
  width: 100%;
  border: 1px solid ${ifWarningElse(COLOR.GRAY_11, COLOR.ERROR)};
  display: flex;
  flex-direction: column;
`;
