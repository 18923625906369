import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';

import type { MouseEvent } from 'react';

import type { Alert } from '@bugbug/core/types/alerts';
import type { SideEffect } from '@bugbug/core/types/utils';

import AlertItemActions from '../AlertItemActions';
import AlertItemActionSummary from '../AlertItemActionSummary';
import AlertItemActivate from '../AlertItemActivate';
import AlertItemWhen from '../AlertItemWhen';

import * as S from './AlertItem.styled';

interface AlertItemProps {
  alert: Alert;
  onClick: SideEffect<MouseEvent<HTMLDivElement>, Alert['id']>;
}

const AlertItem = ({ alert, onClick }: AlertItemProps) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      onClick(event, alert.id);
    },
    [alert.id, onClick],
  );

  return (
    <S.Container data-testid="AlertItem" onClick={handleClick}>
      <AlertItemWhen data={alert} />
      <S.AlertArrow>
        <Icon name="alertsArrow" />
      </S.AlertArrow>
      <AlertItemActionSummary data={alert.notificationAction} />
      <S.ActionWrapper>
        <AlertItemActivate data={alert} />
        <AlertItemActions data={alert} />
      </S.ActionWrapper>
    </S.Container>
  );
};

export default AlertItem;
