import Input, { ErrorMessage } from '@bugbug/core/components/Input';
import styled from 'styled-components';

import { Row as RowBase } from '~/views/ProjectSettings/ProjectSettings.styled';

export const Container = styled.div``;

export const Row = styled(RowBase)`
  align-items: center;
  padding: 2px 0;

  label > svg {
    margin-right: 8px;
  }
`;

export const ResolutionInput = styled(Input)`
  max-width: 169px;

  & ~ ${ErrorMessage} {
    position: absolute;
  }
`;

export const ResolutionRow = styled(Row)`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;

  > span {
    opacity: 0.5;
    padding-bottom: 2px;
  }
`;
