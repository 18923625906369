import CopyButton from '@bugbug/core/components/CopyButton';
import { SelectOption } from '@bugbug/core/components/Select';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { prop, path, clone } from 'ramda';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '~/components/FormField';
import { InputWithVariables } from '~/components/InputWithVariables/InputWithVariables';

import { ComputedField, ComputedValue } from '../Interaction/Interaction.styled';

import { FIELD_NAMES } from './ElementSelectorField.constants';
import { Fields, Select } from './ElementSelectorField.styled';

const ElementSelectorField = ({
  context,
  label,
  name = FIELD_NAMES.SELECTORS,
  disabled = false,
  computedValue,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const selectors = clone(formik.values[name]);

  const getActiveSelectorIndex = useCallback(
    () => Math.max(selectors.findIndex(prop('isActive')), 0),
    [selectors],
  );

  const activeSelectorIndex = getActiveSelectorIndex();
  const customSelectorName = t('stepDetails.interaction.element.custom', 'Use custom selector');
  const activeSelector = selectors[activeSelectorIndex];
  const isCustomSelectorActive = activeSelector && activeSelector.isCustom;

  const setCustomSelectorValue = useCallback(
    (value) => {
      selectors[activeSelectorIndex].selector = value;
      selectors[activeSelectorIndex].isActive = true;
      formik.handleChange({ target: { name, value: selectors } });
    },
    [activeSelectorIndex, formik, selectors, name],
  );

  const handleCustomSelectorChange = useCallback(
    (event) => setCustomSelectorValue(event.target.value),
    [setCustomSelectorValue],
  );

  const handleSelectorChange = useCallback(
    (event) => {
      const selectedSelectorId = event.target.value;

      // eslint-disable-next-line no-param-reassign
      event.target.value = selectors.map((selector, index, selectorsList) => {
        const isActive = selector.id === selectedSelectorId;
        const updatedSelector = { ...selector, isActive };
        if (updatedSelector.isCustom) {
          updatedSelector.selector =
            updatedSelector.selector || selectorsList[activeSelectorIndex].selector;
        }
        return updatedSelector;
      });
      formik.handleChange(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getActiveSelectorIndex],
  );

  const { touched, error } = formik.getFieldMeta(name);

  const renderCustomSelector = renderWhenTrue(() => {
    const customSelectorError = path(['errors', name, activeSelectorIndex, 'selector'])(formik);

    return (
      <InputWithVariables
        as="textarea"
        value={selectors?.[activeSelectorIndex]?.selector ?? ''}
        onChange={handleCustomSelectorChange}
        error={customSelectorError || (touched && error)}
        readOnly={disabled}
        initialHeight={55}
        maxInitialHeight={120}
        lineBreakDisabled
        fullWidth
        autoSize
      />
    );
  });

  return (
    <>
      <FormField
        label={label || t('stepDetails.interaction.element.label', 'Element')}
        labelId={name}
        helperText={t(
          'stepDetails.interaction.element.helperText',
          'XPath locator or CSS selector',
        )}
      >
        <Fields>
          <Select
            fullWidth
            aria-labelledby={name}
            name={name}
            onChange={handleSelectorChange}
            value={activeSelector ? activeSelector.id : null}
            disabled={disabled}
          >
            {selectors.map(({ id, selector, isCustom }, index) => (
              <SelectOption key={id || index} value={id}>
                {isCustom ? customSelectorName : selector}
              </SelectOption>
            ))}
          </Select>
          {renderCustomSelector(isCustomSelectorActive)}
        </Fields>
        <CopyButton value={activeSelector ? activeSelector.selector : null} />
      </FormField>
      {computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedSelector', 'This test run selector')
              : t('stepDetails.interaction.lastComputedSelector', 'Last test run selector')
          }
        >
          <ComputedValue>{computedValue}</ComputedValue>
          <CopyButton value={computedValue} />
        </ComputedField>
      )}
    </>
  );
};

ElementSelectorField.defaultProps = {
  context: 'test',
};

ElementSelectorField.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ElementSelectorField;
