import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Dropdown, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  gap: 9px;
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))``;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
}))``;

export const DetailsWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
