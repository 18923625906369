import Tooltip from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useState, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';

import localStorage from '~/services/localStorage';

import { TRANSITION_TIME } from './CollapsableSection.constants';
import { Container, Header, Content, Expander, HelperIcon } from './CollapsableSection.styled';

const CollapsableSection = forwardRef((props, ref) => {
  const {
    className,
    label,
    children,
    'data-testid': dataTestId,
    defaultExpanded,
    helperText,
    onEntered,
    expandedCacheName,
  } = props;
  const cacheKey = `section.${expandedCacheName.toLowerCase()}.expanded`;

  const cachedExpanded = useMemo(
    () => localStorage.getUserItem(cacheKey) ?? defaultExpanded,
    [defaultExpanded, cacheKey],
  );
  const [expanded, setExpanded] = useState(cachedExpanded);

  const handleChange = useCallback(
    (event, newExpanded) => {
      setExpanded(newExpanded);
      localStorage.setUserItem(cacheKey, newExpanded);
    },
    [cacheKey],
  );

  useImperativeHandle(
    ref,
    () => ({
      open: () => handleChange(null, true),
    }),
    [handleChange],
  );

  const Helper = useMemo(() => {
    if (!helperText) {
      return null;
    }
    return (
      <Tooltip content={helperText}>
        <HelperIcon />
      </Tooltip>
    );
  }, [helperText]);

  const transitionProps = useMemo(
    () => ({
      onEntered,
      timeout: TRANSITION_TIME,
    }),
    [onEntered],
  );

  return (
    <Container
      className={className}
      expanded={expanded}
      data-testid={dataTestId}
      onChange={handleChange}
      TransitionProps={transitionProps}
    >
      <Header>
        <Expander active={expanded} />
        {label}
        {Helper}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
});

CollapsableSection.displayName = 'CollapsableSection';

CollapsableSection.defaultProps = {
  className: null,
  label: null,
  children: null,
  defaultExpanded: false,
  helperText: '',
  expandedCacheName: '',
  'data-testid': 'CollapsableSection',
  onEntered: null,
};

CollapsableSection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  'data-testid': PropTypes.string,
  expandedCacheName: PropTypes.string,
  onEntered: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
};

export default CollapsableSection;
