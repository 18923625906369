import Loader from '@bugbug/core/components/Loader';
import { H1 } from '@bugbug/core/theme/typography';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import memoize from 'lodash.memoize';
import { prop } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { useMount } from 'react-use';

import type { SimpleTableRef } from '~/components/Table';

import type { Maybe } from '@bugbug/core/types/utils';
import Badge, { BADGE_VARIANT } from '~/components/Badge';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import { SimpleTable, useRecentSort } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectHasUsedVariousScreenSizes, selectTestsList } from '~/modules/test/test.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import SelectedTestsActions from './components/SelectedTestsActions';
import TestsActions from './components/TestsActions';
import { TestsModalRoute } from './components/TestsModalRoute';
import { TEST_LIST_COLUMNS, TEST_LIST_COLUMNS_WITHOUT_SCREEN } from './TestsList.constants';
import * as S from './TestsList.styled';

const defaultSort = {
  sortBy: 'created',
  desc: true,
};

const TestsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef<Maybe<SimpleTableRef>>(null);
  const containerRef = useRef<Maybe<HTMLDivElement>>(null);
  const testsActionsRef = useRef();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const queryString = useQueryString();
  const location = useLocation();

  const testListState = useActionState(TestActions.getListRequest, { reset: true });
  const tests = useAppSelector(selectTestsList);

  const hasUsedVariousScreenSizes = useAppSelector(selectHasUsedVariousScreenSizes);

  const appRoutes = useAppRoutes('testsList');
  const { projectId, projectSlug, organizationId } = appRoutes.params;

  const tableColumns = hasUsedVariousScreenSizes
    ? TEST_LIST_COLUMNS
    : TEST_LIST_COLUMNS_WITHOUT_SCREEN;

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'testsList',
    defaultConfig: defaultSort,
    columns: tableColumns,
  });

  const handleDataUpdate = useCallback(
    (tableData = tableRef.current?.state) => {
      dispatch(
        TestActions.getListRequest(
          queryString.query,
          tableData.sortConfig.sortBy,
          tableData.sortConfig.desc,
        ),
      );
      updateRecentSort({ ...tableData.sortConfig, query: queryString.query });
    },
    [dispatch, updateRecentSort, queryString.query],
  );

  const handleSortChange = useCallback(
    (sortConfig) => {
      handleDataUpdate({ sortConfig });
    },
    [handleDataUpdate],
  );

  const fetchInitialData = useCallback(() => {
    handleDataUpdate({ sortConfig: recentSort, ...queryString });
  }, [handleDataUpdate, recentSort, queryString]);

  const handleDeselectAll = useCallback(() => {
    tableRef.current?.toggleAllRowsSelected(false);
  }, []);

  useActionState(TestActions.cloneRequest, { onSuccess: fetchInitialData });
  useActionState(TestActions.removeRequest, { onSuccess: fetchInitialData });

  useMount(() => {
    fetchInitialData();
  });

  useEffect(() => {
    if (testListState.isSuccess && !tests.length && !queryString.query) {
      appRoutes.push('newTest');
    }
    // appRoutes.replace is not a stable reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tests, testListState.isSuccess]);

  const renderLoader = renderWhenTrue(() => (
    <S.LoaderContainer>
      <Loader size="large" />
    </S.LoaderContainer>
  ));

  const EmptyState = useMemo(() => {
    if (testListState.hasInternalServerError || testListState.hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible={testListState.hasInternalServerError || testListState.hasBadRequestError}
          onRetry={handleDataUpdate}
          hideRetryButton={testListState.hasBadRequestError}
        />
      );
    }
    return <S.TableEmptyState>{renderLoader(true)}</S.TableEmptyState>;
  }, [
    handleDataUpdate,
    renderLoader,
    testListState.hasInternalServerError,
    testListState.hasBadRequestError,
  ]);

  const renderEmptyState = renderWhenTrue(() => () => EmptyState);

  const Title = useMemo(
    () => (
      <S.TitleContainer data-testid="TestsList.Header">
        <Badge value={tests.length} variant={BADGE_VARIANT.COUNTER}>
          <H1>{t('testsList.title', 'Tests')}</H1>
        </Badge>
        <S.Actions>
          {selectedRowIds.length ? (
            <SelectedTestsActions testsIds={selectedRowIds} onDeselect={handleDeselectAll} />
          ) : (
            <TestsActions
              ref={testsActionsRef}
              // @ts-expect-error TestActions has missing typings
              onDelete={handleDeselectAll}
            />
          )}
        </S.Actions>
      </S.TitleContainer>
    ),
    [t, selectedRowIds, handleDeselectAll, tests.length],
  );

  const emptyStateText = queryString.query
    ? t('testsList.noSearchResults.label', 'No tests were found that match your search criteria.')
    : t(
        'testsList.emptyState.label',
        'Welcome to empty project! To begin you need to create a new test',
      );

  const handleTrackOpen = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_TEST);
  }, []);

  const getExtraRowProp = memoize((row) => {
    const pathname = reverse(urls.test, {
      projectId,
      projectSlug,
      organizationId,
      testId: row.id,
    });

    return {
      to: {
        pathname,
        state: { backUrl: `${location.pathname}${location.search}` },
      },
      onClick: handleTrackOpen,
    };
  }, prop('id'));

  return (
    <S.Container ref={containerRef}>
      <Helmet title={t('testsList.pageTitle', 'Tests')} />
      <S.ListContainer title={Title} data-testid="TestsList" wideContent>
        <SimpleTable
          ref={tableRef}
          columns={tableColumns}
          data={tests}
          onSortChange={handleSortChange}
          onSelectionChange={setSelectedRowIds}
          defaultSortBy={defaultSort.sortBy}
          defaultDescOrder={defaultSort.desc}
          initialSortBy={recentSort.sortBy}
          initialDescOrder={recentSort.desc}
          getRowProps={getExtraRowProp}
          emptyStateText={emptyStateText}
          renderEmptyState={renderEmptyState(
            testListState.isLoading || testListState.hasInternalServerError,
          )}
        />
      </S.ListContainer>
      <Switch>
        <Route path={urls.testsListEdit} component={TestsModalRoute} />
      </Switch>
    </S.Container>
  );
};

export default TestsList;
