import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';

import { Container, Menu } from './ProjectSettingsActions.styled';

const ProjectSettingsActions = ({ className, project }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const showDeleteProjectModal = useCallback(
    () => modal.show(MODAL_TYPE.DELETE_PROJECT, { project }),
    [modal, project],
  );

  return (
    <Container className={className} data-testid="ProjectSettingsActions">
      <Menu
        data-testid="ProjectSettingsActions.Menu"
        iconName="more"
        hideExpander
        rounded
        condensed
      >
        <DropdownItem onClick={showDeleteProjectModal}>
          <Icon name="delete" />
          {t('projectSettings.projectSettingsActions.deleteProject.label', 'Delete project')}
        </DropdownItem>
      </Menu>
    </Container>
  );
};

ProjectSettingsActions.defaultProps = {
  className: null,
};

ProjectSettingsActions.propTypes = {
  className: PropTypes.string,
  project: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectSettingsActions;
