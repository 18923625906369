import Button from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import styled, { css } from 'styled-components';

import type { Position } from './ScrollToStep.types';

export const Arrow = styled(Icon).attrs(() => ({
  name: 'arrowBackRounded',
}))`
  transform: rotate(-90deg) scale(0.8);
  margin-right: -4px;
`;

export const Container = styled(Button)<{ visibility: Position }>`
  background: ${(props) => props.theme.colors.common.white};
  box-shadow: 0 1px 20px -11px ${(props) => props.theme.colors.primary};
  position: sticky;
  flex: none;
  z-index: 5;
  left: 50%;
  transform: translate(calc(-50% + 7px), 0);
  padding-right: 16px;
  padding-left: 14px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  margin-bottom: -38px;

  ${({ visibility }) =>
    visibility &&
    css`
      opacity: 1;
      pointer-events: all;
      transform: translate(calc(-50% + 7px), calc(-100% - 15px));
      top: 100%;
    `}

  ${({ visibility }) =>
    visibility === 'top' &&
    css`
      top: 0;
      bottom: auto;
      transform: translate(calc(-50% + 7px), 60px);

      svg {
        transform: rotate(90deg);
      }
    `}
`;
