import PropTypes from 'prop-types';
import { equals, without } from 'ramda';
import { memo, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';

import { DRAG_DROP_ITEM_TYPE, TableBody } from '~/components/Table';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectGroup, selectGroupStepsList } from '~/modules/test/test.selectors';
import { selectIsGroupCollapsed } from '~/modules/uiState';
import { UIStateActions } from '~/modules/uiState/uiState.redux';

import GroupEmptyState from '../GroupEmptyState';
import { useRowActiveBorder } from '../RowActiveBorder/RowActiveBorderContext';
import Step from '../Step';
import StepsGroupHeader from '../StepsGroupHeader';
import UnconfirmedGroups from '../UnconfirmedGroups';

import { GROUP_HEADER_HEIGHT, STEPS_COLUMNS, STEPS_RUNS_COLUMNS } from './StepsGroup.constants';
import { GroupContainer, StepsContainer, Table, EmptyState } from './StepsGroup.styled';

const StepsGroup = memo(
  forwardRef(({ testId, groupId, onSelectionChange, readOnly, index, runResultEnabled }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableRef = useRef();
    const headerRef = useRef();
    const containerRef = useRef();
    const cachedSelectedSteps = useRef([]);
    const group = useAppSelector(selectGroup(runResultEnabled, groupId));
    const steps = useAppSelector(selectGroupStepsList(groupId, runResultEnabled));
    const isCollapsed = useAppSelector(selectIsGroupCollapsed(groupId, testId));
    const isExpanded = !isCollapsed;

    const getContainerTop = useCallback(() => containerRef.current?.offsetTop || 0, []);

    const getGroupOffset = useCallback(
      () => getContainerTop() + GROUP_HEADER_HEIGHT,
      [getContainerTop],
    );

    const bottomOffset = useMemo(() => {
      if (isExpanded && steps && steps.length > 0) {
        return {
          row: { id: steps[0].id, groupId: group.id },
          testsCount: group.testsCount,
          isComponent: group.isComponent,
          index: 0,
          disabledGroupOptions: true,
        };
      }
      if (!isExpanded) {
        return {
          row: group,
          isComponent: group.isComponent,
          disabledSplitting: true,
          disabledStepOptions: true,
          index,
        };
      }

      return null;
    }, [group, index, isExpanded, steps]);

    const activeBorderProps = useMemo(
      () => ({
        modifiers: {
          x: 2,
          y: 2,
          height: !isExpanded ? 2 : 0,
          fixed: !index,
        },
        props: {
          top: {
            row: group,
            isComponent: group?.isComponent,
            disabledSplitting: true,
            disabledRunAndStop: true,
            disabledStepOptions: true,
            index: index - 1,
          },
          bottom: bottomOffset,
        },
      }),
      [bottomOffset, group, index, isExpanded],
    );

    const { onItemEnter, onItemLeave } = useRowActiveBorder(activeBorderProps, getContainerTop);

    const handleToggleExpanded = useCallback(() => {
      dispatch(UIStateActions.toggleCollapsedGroup({ testId, collapsedGroupId: groupId }));
    }, [dispatch, groupId, testId]);

    const handleRowClick = useCallback((stepId) => {
      dispatch(TestActions.toggleCurrentStepId(stepId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStepsSelectionChange = useCallback(
      (selectedSteps, shouldDeselectGroup = false) => {
        onSelectionChange(group, selectedSteps, shouldDeselectGroup);
        headerRef.current?.setSelectionState({
          all: selectedSteps.length === group.steps.length && group.steps.length > 0,
          partial: !!selectedSteps.length && selectedSteps.length < group.steps.length,
        });
        cachedSelectedSteps.current = selectedSteps;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSelectionChange, group.steps],
    );

    const handleRowDelete = useCallback(
      (stepId) => {
        tableRef.current.toggleAllRowsSelected(false);
        handleStepsSelectionChange(without([stepId], cachedSelectedSteps.current));
      },
      [handleStepsSelectionChange],
    );

    const handleGroupSelectionChange = useCallback(
      (isSelected) => {
        // eslint-disable-next-line no-unused-expressions
        tableRef.current?.toggleAllRowsSelected(isSelected);
        handleStepsSelectionChange(isSelected ? group.steps : [], !isSelected);
      },
      [handleStepsSelectionChange, group.steps],
    );

    useUpdateEffect(() => {
      const { isSelected } = headerRef.current || {};

      if (isExpanded) {
        if (isSelected) {
          // eslint-disable-next-line no-unused-expressions
          tableRef.current?.toggleAllRowsSelected(true);
        } else {
          for (let idx = 0; idx < cachedSelectedSteps.current.length; idx += 1) {
            const stepId = cachedSelectedSteps.current[idx];
            const stepIndex = group.steps.findIndex(equals(stepId));
            // eslint-disable-next-line no-unused-expressions
            tableRef.current?.toggleRowSelected(stepIndex, true);
          }
        }
      }
    }, [isExpanded]);

    useImperativeHandle(
      ref,
      () => ({
        groupId,
        goToStep: (stepId, useAsIndex = false, position, expandGroup = true) => {
          if (expandGroup) {
            dispatch(UIStateActions.forgetCollapsedGroup({ testId, collapsedGroupId: groupId }));
          }
          setTimeout(() => {
            if (!tableRef.current && !expandGroup) {
              // Group is collapsed
              headerRef.current?.element?.scrollIntoView?.({
                behavior: 'smooth',
                block: position || 'end',
                inline: 'nearest',
              });
            } else if (!group.steps.length) {
              // Group does not have steps
              tableRef.current?.element?.scrollIntoView({
                behavior: 'smooth',
                block: position || 'end',
                inline: 'nearest',
              });
            } else {
              const stepIndex = useAsIndex ? stepId : group.steps.findIndex(equals(stepId));
              tableRef.current?.scrollToRow(stepIndex, position);
            }
          }, 0);
        },
        getStepElement: (stepId) => {
          const stepIndex = group.steps.findIndex(equals(stepId));
          return tableRef.current?.getRowElement(stepIndex);
        },
        deselectAllRows: () => {
          tableRef.current?.toggleAllRowsSelected(false);
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [groupId, group.steps],
    );

    const onTableSelectionChange = useDebouncedCallback((rows) => {
      handleStepsSelectionChange(rows);
    }, 50);

    const EmptyGroupState = useMemo(
      () => (
        <GroupEmptyState
          testId={testId}
          group={group}
          getGroupOffset={getGroupOffset}
          index={index}
          readOnly={readOnly}
        />
      ),
      [testId, group, getGroupOffset, index, readOnly],
    );

    const DropState = useMemo(
      () => (
        <EmptyState data-testid="StepsGroup.DropState">
          {t('stepsGroup.dropHereMessage', 'Drop step here.')}
        </EmptyState>
      ),
      [t],
    );

    const getExtraRowProp = useCallback(
      (step, stepIndex) => ({
        onClick: () => handleRowClick(step.frontId || step.id),
        onDelete: () => handleRowDelete(step.frontId || step.id, stepIndex),
        stepId: step.frontId || step.id,
        readOnly: readOnly || runResultEnabled,
        runResultEnabled,
        index: stepIndex,
        groupIndex: index,
        getGroupOffset,
      }),
      [getGroupOffset, handleRowClick, index, readOnly, runResultEnabled, handleRowDelete],
    );

    const renderBody = useCallback(
      ({ children, ...bodyProps }) => (
        <Droppable droppableId={groupId} type={DRAG_DROP_ITEM_TYPE.STEP}>
          {(providedSteps, state) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TableBody {...bodyProps} ref={providedSteps.innerRef}>
              {children}
              {!!steps.length && providedSteps.placeholder}
              {!state.isDraggingOver && !steps.length && EmptyGroupState}
              {state.isDraggingOver && !steps.length && DropState}
            </TableBody>
          )}
        </Droppable>
      ),
      [DropState, EmptyGroupState, groupId, steps],
    );

    return (
      <Draggable
        disableInteractiveElementBlocking
        draggableId={groupId}
        key={groupId}
        index={index}
        isDragDisabled={readOnly}
      >
        {({ dragHandleProps, innerRef, draggableProps }, snapshot) => (
          <GroupContainer
            ref={(groupRef) => {
              innerRef(groupRef);
              containerRef.current = groupRef;
            }}
            {...draggableProps}
            dragged={snapshot.isDragging}
          >
            <StepsGroupHeader
              ref={headerRef}
              testId={testId}
              group={group}
              dragHandleProps={dragHandleProps}
              onSelectionChange={handleGroupSelectionChange}
              onExpandClick={handleToggleExpanded}
              readOnly={readOnly}
              runResultEnabled={runResultEnabled}
              expanded={isExpanded}
              onEnter={onItemEnter}
              onLeave={onItemLeave}
            />
            {isExpanded && (
              <StepsContainer>
                <Table
                  ref={tableRef}
                  data={steps}
                  columns={runResultEnabled ? STEPS_RUNS_COLUMNS : STEPS_COLUMNS}
                  onSelectionChange={onTableSelectionChange}
                  getRowProps={getExtraRowProp}
                  RowComponent={Step}
                  BodyComponent={renderBody}
                  hiddenHeaders
                  disabledWindowing
                  readOnly={readOnly}
                  renderEmptyState={() => null}
                />
                {!runResultEnabled && (
                  <UnconfirmedGroups
                    relatedGroupId={groupId}
                    index={group.steps.length}
                    includeOutOfRange
                  />
                )}
              </StepsContainer>
            )}
          </GroupContainer>
        )}
      </Draggable>
    );
  }),
);

StepsGroup.displayName = 'StepsGroup';

StepsGroup.defaultProps = {
  readOnly: false,
  runResultEnabled: false,
  onSelectionChange: Function.prototype,
};

StepsGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onSelectionChange: PropTypes.func,
  readOnly: PropTypes.bool,
  runResultEnabled: PropTypes.bool,
};

export default StepsGroup;
