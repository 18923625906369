import DropdownBase from '@bugbug/core/components/Dropdown';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Dropdown = styled(DropdownBase)`
  margin-left: 8px;
`;
