import Tooltip, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import { TestActions } from '~/modules/test/test.redux';
import {
  selectStep,
  selectOrderedGroupPartialsIndices,
  selectGroup,
  selectCurrentStepId,
} from '~/modules/test/test.selectors';
import { StepContext } from '~/views/TestDetails/TestDetails.context';

import { useRowActiveBorder } from '../RowActiveBorder/RowActiveBorderContext';
import UnconfirmedGroups from '../UnconfirmedGroups';

import {
  Container,
  Breakpoint,
  BreakpointContainer,
  Wrapper,
  DragHandler,
  DragPlaceholder,
} from './Step.styled';

const Step = ({
  stepId,
  readOnly,
  runResultEnabled,
  index,
  children,
  groupIndex,
  getGroupOffset,
  ...rowProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStepId = useSelector(selectCurrentStepId);
  const step = useSelector(selectStep(stepId, runResultEnabled));
  const partialsIndices = useSelector(
    selectOrderedGroupPartialsIndices(step.groupId, runResultEnabled),
  );
  const group = useSelector(selectGroup(runResultEnabled, step.groupId));
  const isLastStep = group?.steps?.indexOf?.(step.id) === (group?.steps?.length ?? 0) - 1;
  const dragIndex = partialsIndices[`step.${stepId}`];

  const activeBorderProps = useMemo(() => {
    if (!group) {
      return {};
    }

    const commonProps = {
      row: step,
      testsCount: group.testsCount,
      isComponent: group.isComponent,
      disabledGroupOptions: true,
    };

    return {
      modifiers: { x: 2 },
      props: {
        top: {
          ...commonProps,
          index,
          disabledRunAndStop: index === 0,
        },
        bottom: !isLastStep
          ? {
              ...commonProps,
              index: index + 1,
            }
          : {
              row: group,
              index: groupIndex,
              disabledSplitting: true,
            },
      },
    };
  }, [group, index, isLastStep, step, groupIndex]);

  const { onItemEnter, onItemLeave } = useRowActiveBorder(activeBorderProps, getGroupOffset);

  const handleToggleBreakpoint = useCallback(() => {
    dispatch(TestActions.toggleStepBreakpointRequest(stepId));
  }, [dispatch, stepId]);

  const isExpanded = currentStepId === stepId;

  useUpdateEffect(() => {
    if (step.isTemporary && !isExpanded) {
      dispatch(TestActions.cancelTemporaryStep(stepId, step.groupId, currentStepId));
    }
  }, [currentStepId]);

  const MemoizedUnconfirmedGroups = useMemo(
    () => <UnconfirmedGroups relatedGroupId={step.groupId} index={index} />,
    [step.groupId, index],
  );

  const BreakpointMarker = useMemo(() => {
    const description = step.isBreakpoint
      ? t(
          'testStepActions.breakpoint.active.tooltip',
          'Breakpoint is enabled for this step. Click to remove the breakpoint.',
        )
      : '';

    return (
      <Tooltip content={description} anchor={TOOLTIP_ANCHOR.RIGHT_CENTER} offset={10}>
        <BreakpointContainer
          data-testid="Step.Breakpoint"
          active={step.isBreakpoint}
          onClick={handleToggleBreakpoint}
        >
          <Breakpoint />
        </BreakpointContainer>
      </Tooltip>
    );
  }, [handleToggleBreakpoint, step.isBreakpoint, t]);

  return (
    <Draggable
      disableInteractiveElementBlocking
      draggableId={stepId}
      key={stepId}
      index={runResultEnabled ? 0 : dragIndex}
      isDragDisabled={readOnly || runResultEnabled}
    >
      {({ dragHandleProps, innerRef, draggableProps }) => (
        /* eslint-disable-next-line react/jsx-no-constructed-context-values */
        <StepContext.Provider value={{ runResultEnabled, dragHandleProps }}>
          <Wrapper
            data-testid="Step.Wrapper"
            onMouseEnter={group ? onItemEnter : null}
            onMouseLeave={group ? onItemLeave : null}
          >
            {MemoizedUnconfirmedGroups}
            <Container
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rowProps}
              active={step.isActive}
              aria-selected={currentStepId === stepId || step.isTemporary}
              ref={innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...draggableProps}
            >
              {!readOnly && !runResultEnabled && BreakpointMarker}
              {!readOnly && !runResultEnabled ? (
                <DragHandler dragHandleProps={dragHandleProps} />
              ) : (
                <DragPlaceholder />
              )}
              {children}
            </Container>
          </Wrapper>
        </StepContext.Provider>
      )}
    </Draggable>
  );
};

Step.defaultProps = {
  className: null,
  readOnly: false,
  runResultEnabled: false,
};

Step.propTypes = {
  stepId: PropTypes.string.isRequired,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  runResultEnabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  groupIndex: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Step;
