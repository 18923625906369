import { useTranslation } from 'react-i18next';

import type { StepRun } from '@bugbug/core/types/tests';

import { Description, Title } from '../ErrorDetails.styled';

import { DEFAULT_ERROR_DESCRIPTION, ERROR_DESCRIPTION } from './PageLoadingFailedDetails.constants';

interface PageLoadingFailedDetailsProps {
  error: StepRun['error'];
}

export const PageLoadingFailedDetails = ({ error }: PageLoadingFailedDetailsProps) => {
  const { t } = useTranslation();
  const message = ERROR_DESCRIPTION[error?.replace('net::', '') ?? ''] || DEFAULT_ERROR_DESCRIPTION;

  return (
    <>
      <Title>{t('pageLoadingFailedDetails.title', 'Page loading failed!')}</Title>
      <Description>{message}</Description>
    </>
  );
};
