import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import SearchInput from '@bugbug/core/components/SearchInput';
import PropTypes from 'prop-types';
import React, { useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { TestActions } from '~/modules/test/test.redux';

import { Container, CreateButton, Menu } from './TestsActions.styled';

const TestsActions = ({ className, onDelete, forwardedRef }) => {
  const { t } = useTranslation();
  const updateQueryString = useUpdateQueryString();
  const queryString = useQueryString();
  const dispatch = useDispatch();
  const modal = useModal();
  const { push } = useAppRoutes('alert');

  const handleQueryChange = useCallback(
    (event) => {
      updateQueryString({ query: event.target.value });
    },
    [updateQueryString],
  );

  const handleCreate = useCallback(() => {
    push('newTest');
  }, [push]);

  const handleDeleteAll = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_TESTS, {
        onDelete,
      }),
    [modal, onDelete],
  );

  const handleStopAll = useCallback(() => {
    dispatch(TestActions.stopRequest());
  }, [dispatch]);

  useImperativeHandle(
    forwardedRef,
    () => ({
      showCreateModal: handleCreate,
    }),
    [handleCreate],
  );

  return (
    <Container className={className} data-testid="TestsActions">
      <SearchInput value={queryString.query?.toString()} onChange={handleQueryChange} />
      <CreateButton
        iconName="addCircle"
        data-testid="TestsActions.CreateNewTest"
        onClick={handleCreate}
      >
        {t('testsList.header.createButton', 'New test')}
      </CreateButton>
      <Menu data-testid="TestsActions.Menu" iconName="more" hideExpander rounded condensed>
        <DropdownItem onClick={handleStopAll}>
          <Icon name="stop" /> {t('testsList.testActions.stopAll', 'Stop all tests')}
        </DropdownItem>
        <DropdownItem onClick={handleDeleteAll} danger>
          <Icon name="delete" /> {t('testsList.testActions.deleteAll', 'Delete all tests')}
        </DropdownItem>
      </Menu>
    </Container>
  );
};

TestsActions.defaultProps = {
  className: null,
  forwardedRef: React.createRef(),
};

TestsActions.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  forwardedRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <TestsActions {...props} forwardedRef={ref} />);
