import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Route } from '@bugbug/core/types/routes';
import type { Variable } from '@bugbug/core/types/variables';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useAppSelector } from '~/modules/store';
import { selectVariable } from '~/modules/variable';

interface VariablesModalRouteProps {
  triggerRoute: Extract<Route, 'customVariables' | 'builtInVariables'>;
  modalRoute: Extract<Route, 'customVariable' | 'builtInVariable'>;
}

const VariablesModalRoute = ({ triggerRoute, modalRoute }: VariablesModalRouteProps) => {
  const { t } = useTranslation();

  const modal = useModal();
  const { params, push } = useAppRoutes(modalRoute);

  const { variableId } = params;

  const variable = useAppSelector(selectVariable(variableId)) as unknown as Variable | undefined;

  const handleHide = useCallback(
    (route: Route = triggerRoute) => {
      push(route);
    },
    [push, triggerRoute],
  );

  useEffect(() => {
    if (!variableId) return;

    if (variableId === 'new') {
      modal.show('edit_variable', {}, { onHide: handleHide });
      return;
    }

    if (!variable) return;
    modal.show('edit_variable', { variable }, { onHide: handleHide });
  }, [handleHide, modal, t, variable, variableId]);

  return null;
};

export { VariablesModalRoute };
