import ButtonBase from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import IconButton from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme/colors';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled(animated.div)`
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: ${COLOR.SECONDARY_LIGHT};
  box-shadow: 0 1px 2px 0 rgba(30, 32, 59, 0.32), 0 7px 50px -3px rgba(35, 34, 41, 0.33);
  padding: 8px 50px 8px 8px;
  pointer-events: auto;
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    min-height: 12px;
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
`;

export const InfoIcon = styled(Icon).attrs(() => ({
  name: 'info',
}))`
  opacity: 0.42;
  margin: 0 8px;
`;

export const Button = styled(ButtonBase)`
  height: 32px;
  margin-left: 16px;
`;

export const Text = styled.span`
  position: relative;
  top: -1px;
  line-height: 19px;
`;
