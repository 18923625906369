import { H1 } from '@bugbug/core/theme/typography';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useSelector } from 'react-redux';

import type { Test } from '@bugbug/core/types/tests';
import RunDetailsSummary from '~/components/RunDetailsSummary';
import RunMetadataSection from '~/components/RunMetadataSection';
import {
  selectSingleSuiteRunId,
  selectSuiteRunWithStats,
} from '~/modules/suiteRun/suiteRun.selectors';

import HeaderActions from '../HeaderActions';
import HeaderMetadata from '../HeaderMetadata';
import HeaderTestRunsActions from '../HeaderTestRunsActions';

import * as S from './Header.styled';

interface HeaderProps {
  selectedTests?: Test['id'][];
  onDeselect?: () => void;
}

const Header = ({ selectedTests = [], onDeselect }: HeaderProps) => {
  const suiteRunId = useSelector(selectSingleSuiteRunId);
  const suiteRunWithStats = useSelector(selectSuiteRunWithStats(suiteRunId));

  const hasSelectedTests = !!selectedTests.length;

  if (!suiteRunWithStats.id) {
    return null;
  }

  const summaryData = {
    stats: {
      passed: suiteRunWithStats.passedCount,
      failed: suiteRunWithStats.failedCount,
      error: suiteRunWithStats.errorCount,
      total: suiteRunWithStats.totalCount,
    },
    duration: suiteRunWithStats.rawDuration,
  };

  const RunDetailsElement =
    !isRunningStatus(suiteRunWithStats.status) && summaryData.stats.total ? (
      <RunDetailsSummary {...summaryData} passedLabel="Tests passed" />
    ) : null;

  return (
    <>
      <S.PrintableContent>
        {RunDetailsElement}
        <RunMetadataSection data={{ ...suiteRunWithStats, hiddenViaSuite: true }} />
      </S.PrintableContent>
      <S.Container data-testid="SuiteRunDetailsHeader">
        <S.MainContainer>
          <S.TitleContainer>
            <H1>{suiteRunWithStats.suiteName}</H1>
          </S.TitleContainer>
          {!hasSelectedTests && (
            <HeaderActions suiteId={suiteRunWithStats.suiteSourceId} suiteRun={suiteRunWithStats} />
          )}
          {hasSelectedTests && (
            <HeaderTestRunsActions selectedTests={selectedTests} onDeselect={onDeselect} />
          )}
        </S.MainContainer>
        <S.DetailsContainer>
          <HeaderMetadata suiteRun={suiteRunWithStats} />
          {RunDetailsElement}
        </S.DetailsContainer>
      </S.Container>
    </>
  );
};

export default Header;
