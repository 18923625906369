import { always } from 'ramda';

import {
  ASSERTION_TYPE,
  ASSERTION_PROPERTY_TYPE,
  ASSERTION_TYPES_BY_PROPERTY,
} from '~/constants/step';
import i18n from '~/translations';

i18n.t(
  'assertFailedDetails.errorDescription.generic.equal',
  '{{type}} {{property, regularCase}} does not equal expected value.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.notEqual',
  '{{type}} {{property, regularCase}} does’t match specified criteria.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.contain',
  '{{type}} {{property, regularCase}} does’t match specified criteria.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.notContain',
  '{{type}} {{property, regularCase}} contains expected value.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.match',
  '{{type}} {{property, regularCase}} does not match expected value.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.notMatch',
  '{{type}} {{property, regularCase}} matches expected value.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.greaterThan',
  '{{type}} {{property, regularCase}} is not greater than expected value.',
);
i18n.t(
  'assertFailedDetails.errorDescription.generic.lessThan',
  '{{type}} {{property, regularCase}} is not less than expected value.',
);

i18n.t(
  'assertFailedDetails.errorDescription.count.equal',
  'The document does not contain an expected number of element occurrences. It should equal {{expected}}, but found {{current}}.',
);

i18n.t(
  'assertFailedDetails.errorDescription.count.greaterThan',
  'The document does not contain an expected number of element occurrences. It should be greather than {{expected}}, but found {{current}}.',
);

i18n.t(
  'assertFailedDetails.errorDescription.count.lessThan',
  'The document does not contain an expected number of element occurrences. It should be less than {{expected}}, but found {{current}}.',
);

i18n.t('assertFailedDetails.errorDescription.pageTitle.equal', 'Page title is not "{{expected}}".');
i18n.t('assertFailedDetails.errorDescription.pageTitle.notEqual', 'Page title is "{{expected}}".');
i18n.t(
  'assertFailedDetails.errorDescription.pageTitle.contain',
  'Page title does not contain "{{expected}}".',
);
i18n.t(
  'assertFailedDetails.errorDescription.pageTitle.notContain',
  'Page title contains "{{expected}}".',
);

i18n.t(
  'assertFailedDetails.errorDescription.pageTitle.match',
  'Page title does not match "{{expected}}".',
);

i18n.t(
  'assertFailedDetails.errorDescription.downloadStarted.any',
  'The download has not started, but it should.',
);
i18n.t(
  'assertFailedDetails.errorDescription.downloadStarted.contain',
  'The download for file "{{expected}}" has not started, but it should.',
);

export const getDescriptions = (
  propertyType,
  namespace = propertyType,
  defaultValue,
  type = i18n.t('assertFailedDetails.type.element', 'Element'),
) =>
  ASSERTION_TYPES_BY_PROPERTY[propertyType].reduce(
    (descriptions, assertionType) => ({
      ...descriptions,
      [assertionType.value]: (props = {}) => {
        const textKey = `assertFailedDetails.errorDescription.${namespace}.${assertionType.value}`;
        return i18n.t(textKey, {
          ...props,
          type,
          defaultValue,
          property: propertyType,
        });
      },
    }),
    {},
  );

export const ERROR_DESCRIPTION = {
  [ASSERTION_PROPERTY_TYPE.VALUE]: getDescriptions(ASSERTION_PROPERTY_TYPE.VALUE, 'generic'),
  [ASSERTION_PROPERTY_TYPE.TEXT_CONTENT]: getDescriptions(
    ASSERTION_PROPERTY_TYPE.TEXT_CONTENT,
    'generic',
  ),
  [ASSERTION_PROPERTY_TYPE.COUNT]: getDescriptions(ASSERTION_PROPERTY_TYPE.COUNT),
  [ASSERTION_PROPERTY_TYPE.VISIBLE]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t('assertFailedDetails.errorDescription.boolean.visible', 'Element is not visible.'),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.NOT_VISIBLE]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.notVisible',
        "Element is visible, but it shouldn't.",
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.EXIST]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.exist',
        'Element does not exist, but it should.',
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.NOT_EXIST]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.notExists',
        "Element exists, but it shouldn't.",
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.CHECKED]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.checked',
        'Element is not checked, but it should.',
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.NOT_CHECKED]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.notChecked',
        "Element is checked, but it shouldn't.",
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT]: {
    [ASSERTION_TYPE.EQUAL]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.customJavascript',
        'The result of code execution is not equal true.',
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.PAGE_SHOWS_TEXT]: {
    [ASSERTION_TYPE.CONTAIN]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.pageShowsText',
        "Page doesn't display required text, but it should.",
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.PAGE_DOES_NOT_SHOW_TEXT]: {
    [ASSERTION_TYPE.NOT_CONTAIN]: always(
      i18n.t(
        'assertFailedDetails.errorDescription.boolean.pageDoesNotShowText',
        "Page displays required text, but it shouldn't.",
      ),
    ),
  },
  [ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED]: getDescriptions(
    ASSERTION_PROPERTY_TYPE.DOWNLOAD_STARTED,
    undefined,
    i18n.t(
      'assertFailedDetails.errorDescription.downloadStarted.default',
      'File download not started.',
    ),
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE]: getDescriptions(
    ASSERTION_PROPERTY_TYPE.PAGE_HAS_TITLE,
    undefined,
    i18n.t('assertFailedDetails.errorDescription.pageTitle.equal', 'Page title is invalid'),
  ),
  [ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE]: getDescriptions(
    'value',
    'generic',
    i18n.t(
      'assertFailedDetails.errorDescription.variableValue.default',
      "The value of the variable doesn't fulfill the condition.",
    ),
    i18n.t('assertFailedDetails.type.variable', 'Variable'),
  ),
  [ASSERTION_PROPERTY_TYPE.PAGE_URL_IS]: getDescriptions(
    ASSERTION_PROPERTY_TYPE.PAGE_URL_IS,
    undefined,
    i18n.t('assertFailedDetails.errorDescription.pageUrlIs.equal', 'Page URL is invalid'),
  ),
};
