import type { RunStatus } from '@bugbug/core/types/base';
import type { RunStatusFlags } from '@bugbug/core/types/tests';

import { RUN_STATUS_ICON, RUN_STATUS_LABEL } from '../../constants/status';
import { isRunningStatus } from '../../types/base';
import { getExtendedRunStatus } from '../../utils/status';
import Icon from '../Icon';
import Loader from '../Loader';

import { STATUS_TOOLTIP } from './StatusBadge.constants';
import { Container, IconContainer, RelativeTime, Label, Tooltip } from './StatusBadge.styled';

interface StatusBadgeProps {
  className?: string;
  date?: string;
  status?: RunStatus;
  extended?: boolean;
  warning?: boolean;
  hiddenStatusLabel?: boolean;
  ignoredFailed?: boolean;
  autoRetried?: boolean;
  errorCode?: string;
}

export const StatusBadge = ({
  className,
  status,
  date,
  extended,
  warning,
  ignoredFailed,
  autoRetried,
  hiddenStatusLabel,
  errorCode,
}: StatusBadgeProps) => {
  if (!status) {
    return null;
  }

  const isRunning = isRunningStatus(status);
  const isQueued = status === 'queued';
  const statusFlags: Omit<RunStatusFlags, 'forceFailed'> = {
    status,
    warning: !!warning,
    ignoredFailed: !!ignoredFailed,
  };
  const finalStatus = getExtendedRunStatus(statusFlags);
  const label = autoRetried ? RUN_STATUS_LABEL['failed-retried'] : RUN_STATUS_LABEL[finalStatus];

  const tooltipContent = STATUS_TOOLTIP[finalStatus] ?? errorCode;

  return (
    <Tooltip content={tooltipContent}>
      <Container data-testid="StatusBadge" className={className} status={finalStatus}>
        <IconContainer title={!errorCode ? finalStatus : undefined}>
          {!(extended && isRunning) ? (
            <Icon name={RUN_STATUS_ICON[finalStatus] || 'smallDot'} />
          ) : (
            <Loader size="small" stopped={isQueued} />
          )}
        </IconContainer>
        <Label visible={extended && !hiddenStatusLabel && !!label}>
          {label}
          {isRunning ? '...' : ''}
        </Label>
        {!((isRunning || autoRetried) && extended) && !!date && (
          <RelativeTime date={date as string} noTooltip={!!errorCode} />
        )}
      </Container>
    </Tooltip>
  );
};
