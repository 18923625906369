import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-left: 9px;
`;
