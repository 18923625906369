import { pick, mergeDeepRight, pipe, omit } from 'ramda';

import type { Column } from 'react-table';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';
import IndexedNameCell from '~/views/RunsHistory/components/IndexedNameCell';
import TestRunsActions from '~/views/SuiteRunDetails/components/TestRunsActions';

import { getDuration } from './TestRuns.helpers';

export const TEST_RUNS_COLUMNS_ID = {
  ACTIONS: 'actions',
  DURATION: 'duration',
  NAME: 'name',
  STATUS: 'status',
};

export const TESTS_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('suiteRunDetails.tests.columns.name', 'Test name'),
    accessor: 'test.name',
    id: TEST_RUNS_COLUMNS_ID.NAME,
    Cell: IndexedNameCell,
    disableSortBy: true,
    width: 300,
  },
  {
    Header: i18n.t('suiteRunDetails.tests.columns.duration', 'Duration'),
    accessor: getDuration,
    id: TEST_RUNS_COLUMNS_ID.DURATION,
    Cell: TablePartials.TextCell,
    disableSortBy: true,
  },
  {
    ...omit(['maxWidth'], TablePartials.PREDEFINED_COLUMN.SCREEN),
    disableSortBy: true,
  },
  {
    Header: i18n.t('suiteRunDetails.tests.columns.status', 'Status'),
    accessor: pipe(
      pick(['started', 'status', 'isAutoRetried']),
      mergeDeepRight({ extended: true }),
    ),
    Cell: TablePartials.StatusCell,
    id: TEST_RUNS_COLUMNS_ID.STATUS,
    disableSortBy: true,
  },
  {
    Cell: TestRunsActions,
    id: TEST_RUNS_COLUMNS_ID.ACTIONS,
    maxWidth: 85,
  },
] as Column<TablePartials.Data>[];
