import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import {
  selectSuiteRunsList,
  selectCurrentPageParams,
} from '~/modules/suiteRun/suiteRun.selectors';

const useRefreshSuiteRunsHistory = () => {
  const dispatch = useDispatch();
  const suiteRunsList = useSelector(selectSuiteRunsList);
  const params = useSelector(selectCurrentPageParams);

  const page = !suiteRunsList.length
    ? Math.max(params.pagination.currentPage - 1, 1)
    : params.pagination.currentPage;

  return useCallback(() => {
    dispatch(SuiteRunActions.getListRequest(page, params.query, params.sortBy, params.descOrder));
  }, [dispatch, page, params]);
};

export default useRefreshSuiteRunsHistory;
