import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { Test } from '@bugbug/core/types/tests';
import { selectSingleTest } from '~/modules/test/test.selectors';

import * as S from './TestSidebarNotes.styled';
import TestSidebarNotesForm from './TestSidebarNotesForm';

const TestSidebarNotes = () => {
  const { notes, id: testId } = useSelector(selectSingleTest) as Test;
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Title>{t('testSidebar.notes.title', 'Test notes')}</S.Title>
      <TestSidebarNotesForm testId={testId} notes={notes} />
    </S.Container>
  );
};

export default TestSidebarNotes;
