import { useCallback } from 'react';

import type { SidebarViewProps } from '~/components/SidebarView';

import type { Step } from '@bugbug/core/types/steps';
import SidebarView from '~/components/SidebarView';
import { useAppSelector } from '~/modules/store';
import { selectCurrentStepId } from '~/modules/test/test.selectors';
import Sidebar from '~/views/TestDetails/components/TestSidebar';

import Content from './components/Content';

const TestRunDetails = () => {
  const currentStepId = useAppSelector(selectCurrentStepId) as Step['id'];

  const renderSidebar = useCallback<SidebarViewProps['Sidebar']>(
    ({ onToggle, collapsed }) => (
      <Sidebar onToggle={onToggle} context="testRun" readOnly collapsed={collapsed} />
    ),
    [],
  );

  return (
    <SidebarView
      prefix="testRunDetails"
      sidebarAlwaysVisible={!!currentStepId}
      Content={Content}
      Sidebar={renderSidebar}
    />
  );
};

export default TestRunDetails;
