import Switch from '@bugbug/core/components/Switch';
import { useTranslation } from 'react-i18next';

import type { Schedule } from '@bugbug/core/types/schedules';
import { PaidFeature } from '~/components/PaidFeatureGuard';

import * as S from './ScheduleItem.styled';

interface ScheduleItemTitleProps {
  data: Schedule;
  onToggle: React.ChangeEventHandler<HTMLInputElement>;
}

const ScheduleItemTitle = ({ data, onToggle }: ScheduleItemTitleProps) => {
  const { t } = useTranslation();

  const disabledTooltipMessage = !data.suites.length
    ? t(
        'scheduleDetails.scheduleDetailsActions.switchDisabledTooltip',
        'This schedule is empty. You need to select suites to enable it',
      )
    : undefined;

  return (
    <>
      <S.Headline>{data.name}</S.Headline>
      <PaidFeature>
        {(isDisabled) => (
          <Switch
            onChange={onToggle}
            value={!!data.suites.length && data.enabled}
            disabled={!data.suites.length || isDisabled}
            tooltip={!data.suites.length ? disabledTooltipMessage : undefined}
            tooltipAnchor="bottom-center"
          />
        )}
      </PaidFeature>
    </>
  );
};

export default ScheduleItemTitle;
