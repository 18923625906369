import * as T from '@bugbug/core/utils/toolbox';
import { Formik } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';

import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import ScrollShadow from '~/components/ScrollShadow';
import { STEP_TYPE } from '~/constants/step';
import useFormikContextWithTabsErrors from '~/hooks/useFormikContextWithTabsErrors';
import Execution, {
  FIELD_NAMES_VALUES as EXECUTION_SECTION_FIELDS,
  // eslint-disable-next-line object-curly-newline
} from '~/views/TestDetails/components/Execution';
import Interaction, {
  FIELD_NAMES_VALUES as INTERACTION_SECTION_FIELDS,
  // eslint-disable-next-line object-curly-newline
} from '~/views/TestDetails/components/Interaction';
import {
  SECTION,
  SECTION_LABEL,
} from '~/views/TestDetails/components/StepDetails/StepDetails.constants';
import StepRunExecutionUrl from '~/views/TestDetails/components/StepRunExecutionUrl';
import WaitingConditions, {
  FIELD_NAMES_VALUES as WAITING_CONDITIONS_SECTION_FIELDS,
  // eslint-disable-next-line object-curly-newline
} from '~/views/TestDetails/components/WaitingConditions';

import StepRunScreenshot from '../StepRunScreenshot';

import * as S from './StepDetails.styled';

const SECTION_FIELDS = {
  [SECTION.INTERACTION]: INTERACTION_SECTION_FIELDS,
  [SECTION.EXECUTION]: EXECUTION_SECTION_FIELDS,
  [SECTION.WAITING_CONDITIONS]: WAITING_CONDITIONS_SECTION_FIELDS,
};

interface StepDetailsProps {
  onClose: SideEffect;
  step: Step;
}

// TODO refactor this component and similar one in TestDetails to make it one to serve all situations
const StepDetails = ({ onClose, step }: StepDetailsProps) => (
  <Formik
    initialValues={step}
    onSubmit={T.noop}
    validateOnMount={false}
    validateOnBlur={false}
    enableReinitialize
  >
    <StepDetailsContent step={step} onClose={onClose} />
  </Formik>
);

const StepDetailsContent = ({ step, onClose }: StepDetailsProps) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLFormElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const interactionRef = useRef<HTMLDivElement>(null);
  const executionRef = useRef<HTMLDivElement>(null);
  const conditionsRef = useRef<HTMLDivElement>(null);

  const { values } = useFormikContextWithTabsErrors({ fieldGroups: SECTION_FIELDS });

  useUpdateEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [step.id]);

  const hasWaitingConditions = ![STEP_TYPE.GOTO, STEP_TYPE.NEW_TAB, STEP_TYPE.CLOSE_TAB].includes(
    values.type,
  );

  return (
    <S.Container data-testid="StepDetails" ref={containerRef}>
      <S.Header>
        <S.TitleContainer>
          <S.Title as="h2">{t('stepDetails.header.title', 'Step details')}</S.Title>
          <S.ActionButton onClick={onClose} bordered>
            {t('stepDetails.readOnly.button.close', 'Close')}
          </S.ActionButton>
        </S.TitleContainer>
      </S.Header>

      <S.Content ref={contentRef}>
        <ScrollShadow />

        {/* @ts-expect-error This is JSX component. Migrate to TS to solve it */}
        <S.Section
          defaultExpanded
          label={SECTION_LABEL[SECTION.SCREENSHOTS]}
          expandedCacheName={SECTION.SCREENSHOTS}
        >
          <StepRunScreenshot step={step} />
          <StepRunExecutionUrl stepId={step.id} />
        </S.Section>

        {/* @ts-expect-error This is JSX component. Migrate to TS to solve it */}
        <S.Section
          ref={interactionRef}
          defaultExpanded
          label={SECTION_LABEL[SECTION.INTERACTION]}
          expandedCacheName={SECTION.INTERACTION}
        >
          <Interaction step={step} readOnly />
        </S.Section>

        {/* @ts-expect-error This is JSX component. Migrate to TS to solve it */}
        <S.Section
          ref={executionRef}
          defaultExpanded
          label={SECTION_LABEL[SECTION.EXECUTION]}
          expandedCacheName={SECTION.EXECUTION}
        >
          <Execution readOnly />
        </S.Section>

        {hasWaitingConditions && (
          // @ts-expect-error This is JSX component. Migrate to TS to solve it
          <S.Section
            ref={conditionsRef}
            defaultExpanded
            label={SECTION_LABEL[SECTION.WAITING_CONDITIONS]}
            expandedCacheName={SECTION.WAITING_CONDITIONS}
          >
            <WaitingConditions readOnly />
          </S.Section>
        )}
      </S.Content>
    </S.Container>
  );
};

export default StepDetails;
