import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RunButton from '~/components/RunButton';
import useSuiteRunner from '~/hooks/useSuiteRunner';

const RunSuiteButton = (props) => {
  const { t } = useTranslation();
  const { className, disabled, suite, suiteRunId, running, queued } = props;

  const suiteRunner = useSuiteRunner(suite, { suiteRunId });

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (running) {
        suiteRunner.stop();
        return;
      }

      suiteRunner.startLocal({ withProfileSelection: true });
    },
    [suiteRunner, running],
  );

  const label = useMemo(() => {
    if (queued) {
      return t('runSuiteButton.queued.label', 'Suite is queued');
    }
    if (running) {
      return t('runSuiteButton.running.label', 'Suite is running');
    }

    return t('runSuiteButton.idle.local.label', 'Run suite locally');
  }, [t, queued, running]);

  return (
    <RunButton
      className={className}
      onClick={handleClick}
      disabled={disabled}
      running={running}
      queued={queued}
      label={label}
    />
  );
};

RunSuiteButton.defaultProps = {
  className: null,
  disabled: false,
  queued: false,
  running: false,
  suiteRunId: '',
};

RunSuiteButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  queued: PropTypes.bool,
  running: PropTypes.bool,
  suiteRunId: PropTypes.string,
  suite: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RunSuiteButton;
