import { ButtonsRow } from '@bugbug/core/components/Button/Button.styled';
import SearchInput from '@bugbug/core/components/SearchInput';
import { useTranslation } from 'react-i18next';

import { PaidFeature } from '~/components/PaidFeatureGuard';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';

import * as S from './VariablesActions.styled';

const VariablesActions = () => {
  const { t } = useTranslation();
  const updateQuerystring = useUpdateQueryString();
  const queryString = useQueryString();
  const { push } = useAppRoutes('variables');

  const handleQueryChange = (event) => {
    updateQuerystring({ query: event.target.value });
  };

  const handleNewProfileClick = () => {
    push('newProfile');
  };

  const handleNewVariableClick = () => {
    push('newCustomVariable');
  };

  return (
    <S.Container data-testid="Variables.Header">
      <SearchInput value={queryString.query} onChange={handleQueryChange} />
      <ButtonsRow>
        <PaidFeature>
          {(isDisabled) => (
            <S.CreateButton
              iconName="addCircle"
              onClick={handleNewVariableClick}
              disabled={isDisabled}
            >
              {t('variables.header.createButton', 'New variable')}
            </S.CreateButton>
          )}
        </PaidFeature>
        <PaidFeature>
          {(isDisabled) => (
            <S.CreateButton
              iconName="addCircle"
              onClick={handleNewProfileClick}
              disabled={isDisabled}
            >
              {t('profiles.header.createButton', 'New profile')}
            </S.CreateButton>
          )}
        </PaidFeature>
      </ButtonsRow>
    </S.Container>
  );
};

export default VariablesActions;
