import Loader from '@bugbug/core/components/Loader';
import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';

import type { Component } from '@bugbug/core/types/components';
import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import useBackUrl from '~/hooks/useBackUrl';
import { ComponentsActions, useGetComponentQuery } from '~/modules/components';
import urls from '~/views/urls';

import ComponentHeader from '../ComponentHeader';
import { StepRow } from '../StepRow';

import { STEPS_COLUMNS } from './Content.constants';
import * as S from './Content.styled';

const ComponentContent = () => {
  const {
    params: { componentId },
  } = useAppRoutes('component');
  const { data: component, isError, isLoading, refetch } = useGetComponentQuery(componentId);

  return (
    <>
      <ServerErrorInfo isVisible={isError} onRetry={refetch} />

      {isLoading && (
        <S.LoaderContainer>
          <Loader size="large" />
        </S.LoaderContainer>
      )}

      {!isLoading && !isError && <ComponentDetails component={component as Component} />}
    </>
  );
};

interface ComponentDetailsProps {
  component: Component;
}

const ComponentDetails = ({ component }: ComponentDetailsProps) => {
  const { t } = useTranslation();
  const backUrl = useBackUrl(urls.componentsList);

  const dispatch = useDispatch();

  const handleRowClick = useCallback<SideEffect<Step>>(
    (step) => {
      dispatch(ComponentsActions.setCurrentStep(step));
    },
    [dispatch],
  );

  const getExtraRowProp = useCallback<SideEffect<Step, number>>(
    (step, stepIndex) => ({
      onClick: () => handleRowClick(step),
      stepId: step.id,
      readOnly: true,
      index: stepIndex,
      runResultEnabled: true,
    }),
    [handleRowClick],
  );

  useUnmount(() => {
    dispatch(ComponentsActions.resetCurrentStep());
  });

  const Header = useMemo(() => <ComponentHeader component={component} />, [component]);

  return (
    <S.Container title={Header} data-testid="ComponentContent" withBackButton backUrl={backUrl}>
      <Helmet
        title={t('componentDetails.pageTitle', 'Components / {{- name }}', {
          name: component.name,
        })}
      />

      <S.StepsContainer>
        {/* @ts-expect-error SimpleTable refactoring is on the way and it fill fix TS issues */}
        <S.Table
          data={component.steps}
          columns={STEPS_COLUMNS}
          getRowProps={getExtraRowProp}
          RowComponent={StepRow}
          hiddenHeaders
          readOnly
          emptyStateText={t(
            'componentDetails.emptyState.message',
            'This components contains no steps.',
          )}
        />
      </S.StepsContainer>
    </S.Container>
  );
};

export default ComponentContent;
