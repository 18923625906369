import { RUN_STATUS } from '@bugbug/core/constants/status';
import { groupBy, pick } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { selectTestStep } from '~/modules/test/test.selectors';
import {
  selectStepRun,
  selectTestRun,
  selectTestRunStep,
} from '~/modules/testRun/testRun.selectors';
import { getRawDurationFromDate } from '~/utils/dates';

import { RunMetaData } from './StepRunMetadata.styled';

const StepRunMetadata = ({ stepId }) => {
  const modal = useModal();
  const step = useSelector(selectTestStep(stepId));
  const testRunStep = useSelector(selectTestRunStep(stepId));
  const stepRun = useSelector(selectStepRun(stepId));
  const isRunning = stepRun?.status === RUN_STATUS.RUNNING;
  const { t } = useTranslation();

  const testRun = useSelector(selectTestRun(stepRun?.stepRunId));

  const waitingConditions = useMemo(() => {
    const grouped = groupBy(({ isSoftSuccess, isSuccess }) => {
      if (isSuccess) {
        return 'passed';
      }
      if (stepRun.elementExists && isSoftSuccess) {
        return 'skipped';
      }
      return 'failed';
    }, stepRun?.waitingConditions || []);
    grouped.totalNumber = stepRun?.waitingConditions?.length ?? 0;
    return grouped;
  }, [stepRun]);

  const handleShowDetails = useCallback(
    () =>
      modal.show(MODAL_TYPE.RUN_METADATA, {
        runData: {
          ...testRun,
          ...pick(['sequence', 'started', 'ended', 'status', 'testRunId'], stepRun || {}),
          stepRunId: {
            label: t('stepRunMetadata.stepRunId', 'Step Run ID'),
            value: stepRun?.id,
          },
          testRunId: {
            label: t('stepRunMetadata.testRunId', 'Test Run ID'),
            value: stepRun?.testRunId,
          },
          rawDuration: stepRun?.rawDuration || stepRun?.duration,
          waitingConditions,
          hiddenViaSuite: true,
          running: isRunning,
        },
        generalData: {
          ...{ ...step, ...testRunStep },
          id: {
            label: t('stepRunMetadata.stepId', 'Step ID'),
            value: { ...step, ...testRunStep }?.id,
          },
        },
      }),
    [modal, isRunning, stepRun, testRunStep, waitingConditions, t, testRun, step],
  );

  return (
    <RunMetaData
      duration={stepRun?.rawDuration ?? getRawDurationFromDate(new Date(stepRun?.started))}
      status={stepRun?.status}
      warning={stepRun?.warning}
      ignoredFailed={stepRun?.ignoredFailed}
      hideMessage
      showMilliseconds
      showOnlySeconds
      onDetailsClick={handleShowDetails}
    />
  );
};

export default StepRunMetadata;
