import { useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import React from 'react';
import { useMountedState } from 'react-use';

import { getActiveState, STATE_COMPONENT_MAP } from './EmptyState.constants';
import { Container, StateWrapper } from './EmptyState.styled';

const EmptyState = ({
  className,
  isVisible,
  isRecording,
  isFirstRecording,
  onRecordClick,
  onNewStepClick,
  onInsertComponentClick,
  onPasteStepsClick,
  disabledPasting,
  initialRecordingUrl,
}) => {
  const activeState = getActiveState({ isVisible, isRecording });
  const isMounted = useMountedState();

  const transition = useTransition(activeState, {
    from: { position: 'absolute', opacity: Number(!isMounted()) },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <Container className={className} data-testid="TestDetailsEmptyState">
      {transition((styles, item, t, i) => {
        const Component = STATE_COMPONENT_MAP[item];
        const isComponentVisible = activeState === item;

        return (
          <StateWrapper key={i} style={styles} data-testid="TestDetailsEmptyState.StateWrapper">
            <Component
              isFirstRecording={isFirstRecording}
              isVisible={isComponentVisible}
              onRecordClick={onRecordClick}
              onNewStepClick={onNewStepClick}
              onInsertComponentClick={onInsertComponentClick}
              onPasteStepsClick={onPasteStepsClick}
              initialRecordingUrl={initialRecordingUrl}
              disabledPasting={disabledPasting}
            />
          </StateWrapper>
        );
      })}
    </Container>
  );
};

EmptyState.defaultProps = {
  className: null,
  isVisible: false,
  isRecording: false,
  onRecordClick: Function.prototype,
  onNewStepClick: Function.prototype,
  onInsertComponentClick: Function.prototype,
};

EmptyState.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  isRecording: PropTypes.bool,
  onRecordClick: PropTypes.func,
  onNewStepClick: PropTypes.func,
  onInsertComponentClick: PropTypes.func,
};

export default EmptyState;
