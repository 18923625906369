import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop, complement, path } from 'ramda';
import styled, { css } from 'styled-components';

import DragHandlerBase from '~/components/DragHandler';
import { TableRow } from '~/components/Table';

const isNotActive = complement(prop('active'));

export const DragHandler = styled(DragHandlerBase)`
  opacity: 0;
  flex: none;
`;

export const DragPlaceholder = styled.div`
  height: 100%;
  width: 16px;
  flex: none;
`;

export const Container = styled(TableRow)`
  cursor: pointer;
  position: relative;
  border-top-color: ${COLOR.GRAY_18};
  border-bottom-color: ${COLOR.TRANSPARENT};

  ${styleWhenTrue(
    isNotActive,
    css`
      background-image: repeating-linear-gradient(
        135deg,
        #f2f2f2 9%,
        #f2f2f2 11%,
        ${COLOR.WHITE} 0,
        ${COLOR.WHITE} 13%
      );
    `,
  )}

  &[aria-selected='true'] {
    border-radius: 5px !important;
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px ${COLOR.PRIMARY_BORDER};
    background: ${COLOR.MUTED};

    ${styleWhenTrue(
      isNotActive,
      css`
        background-image: repeating-linear-gradient(
          135deg,
          ${COLOR.MUTED} 9%,
          ${COLOR.MUTED} 11%,
          ${COLOR.WHITE} 0,
          ${COLOR.WHITE} 13%
        );
      `,
    )}
  }

  &:hover ${DragHandler} {
    opacity: 1;
  }
`;

export const AddIconWrapper = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.DARK_GRAY};
  top: -12px;
  right: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition-delay: 50ms;

  ${styleWhenTrue(
    path(['bottom']),
    css`
      bottom: -12px;
      top: unset;
    `,
  )}

  ${Container}:hover & {
    opacity: 1;
    transition-delay: 0ms;
  }
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  font-size: 18px !important;
  fill: ${COLOR.DARK_GRAY} !important;
`;

export const BreakpointContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transform: translateX(-3px);
  display: none;
  cursor: pointer;
  width: 13px;
  height: 52px;

  ${styleWhenTrue(
    prop('active'),
    css`
      display: block;
    `,
  )}
`;

export const Breakpoint = styled(Icon).attrs(() => ({
  name: 'breakpoint',
}))`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;

  &:last-child ${Container} {
    border-radius: 0 0 5px 5px;
  }
`;
