import { ActionButton as ActionButtonBase, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { COLOR } from '@bugbug/core/theme/colors';
import mixins from '@bugbug/core/theme/mixins';
import { H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import CollapsableSection from '~/components/CollapsableSection';
import * as CollapsableSectionPartials from '~/components/CollapsableSection/CollapsableSection.styled';
import * as FormFieldPartials from '~/components/FormField/FormField.styled';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 27px;
`;

export const Title = styled(H1).attrs(() => ({ as: 'h2' }))`
  ${mixins.textEllipsis};
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 20px;
`;

export const ActionButton = styled(ActionButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-left: 8px;
  justify-content: center;
  width: 74px;

  & > div {
    flex: none;
  }
`;

export const Section = styled(CollapsableSection)`
  ${CollapsableSectionPartials.Header} {
    padding: 10px 8px 9px 27px;
    background: ${COLOR.GRAY_21};
    border-top: 2px solid ${COLOR.GRAY_18};
    box-shadow: 0 1px 2px 0 ${COLOR.DARK_GRAY_7};
    font-size: 13px;
    letter-spacing: 0.02em;

    > div {
      padding-bottom: 1px;
    }

    &:hover {
      background: ${COLOR.GRAY_28};
    }
  }

  ${CollapsableSectionPartials.Content} {
    padding: 20px 27px 27px;
    align-items: stretch;
  }

  ${CollapsableSectionPartials.Expander} {
    transform: translateY(-1px) scale(0.9);
  }

  ${FormFieldPartials.Label} {
    color: ${COLOR.GRAY_19};
    font-size: 12px;
  }
`;
