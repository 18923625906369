import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 7px 4px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  a {
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 11px;
    color: ${COLOR.PRIMARY_LIGHT};
    &:hover {
      text-decoration: none;
    }
  }
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  width: 15px;
  stroke: ${COLOR.PRIMARY_LIGHT};
  margin-right: 3px;
`;
