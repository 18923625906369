import Checkbox from '@bugbug/core/components/Checkbox';
import CopyButton from '@bugbug/core/components/CopyButton';
import Input from '@bugbug/core/components/Input';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { cond, equals } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FileUpload from '~/components/FileUpload';
import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import SecretToggleField from '~/components/SecretToggleField';
import StepTypePickerInput from '~/components/StepTypePickerInput';
import { STEP_TYPE, STEPS_TYPES_WITHOUT_SELECTOR } from '~/constants/step';
import { selectProjectHomepageUrl } from '~/modules/project/project.selectors';
import { selectDefaultStepsParams } from '~/modules/test/test.selectors';
import { pickDefaultStepParams } from '~/modules/test/test.utils';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import AssertActionFields from '../AssertActionFields';
import DragAndDropActionFields from '../DragAndDropActionFields';
import ElementSelectorField from '../ElementSelectorField';
import ExecuteActionFields from '../ExecuteActionFields';
import InteractionPositionField from '../InteractionPositionField';
import ScrollActionFields from '../ScrollActionFields';
import SelectActionFields from '../SelectActionFields';
import SetLocalVariableFields from '../SetLocalVariableFields';
import { getInitialValues } from '../StepDetails/StepDetails.helpers';

import { FIELD_NAMES } from './Interaction.constants';
import { isTrueFalseAssertion, isBasicAuthVisible } from './Interaction.helpers';
import { ComputedValue, ComputedField } from './Interaction.styled';

const Interaction = ({ context, readOnly, step }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const projectHomepageUrl = useSelector(selectProjectHomepageUrl);
  const defaultStepsParams = useSelector(selectDefaultStepsParams);
  const [isAuthVisible, setBasicAuthVisible] = useState(isBasicAuthVisible(formik.values));
  const stepRun = useSelector(selectStepRun(step.id));
  const [isSecretToggleDisabled, setIsSecretToggleDisabled] = useState(readOnly);

  useEffect(() => {
    setBasicAuthVisible(isBasicAuthVisible(formik.values));

    if (!readOnly && formik.values[FIELD_NAMES.VALUE]) {
      const containsVariable = /\{\{.*\}\}/.test(formik.values[FIELD_NAMES.VALUE]);
      if (containsVariable) {
        formik.setFieldValue(FIELD_NAMES.HAS_SECRET_CONTENT, false);
      }
      setIsSecretToggleDisabled(containsVariable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, readOnly]);

  const handleTypeChange = useCallback(
    (event) => {
      const { value: type, assertionProperty } = event.target;
      if (
        formik.values.type === type &&
        formik.values[FIELD_NAMES.ASSERTION_PROPERTY] === assertionProperty
      ) {
        return;
      }

      const defaultParams = pickDefaultStepParams(
        type,
        defaultStepsParams,
        type === STEP_TYPE.ASSERT ? { assertionProperty } : undefined,
      );

      const updatedStep = {
        ...step,
        type,
        ...defaultParams,
        assertionType: defaultParams?.assertionTypes?.at(0),
        assertionExpectedValue: null,
        selectors: formik.values.selectors,
      };
      formik.setValues(getInitialValues(updatedStep));

      if (type === STEP_TYPE.GOTO && projectHomepageUrl) {
        formik.setFieldValue(FIELD_NAMES.URL, projectHomepageUrl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step, projectHomepageUrl, formik.values],
  );

  const handleBasicAuthChange = useCallback((event) => {
    formik.setFieldValue(FIELD_NAMES.USERNAME, '');
    formik.setFieldValue(FIELD_NAMES.PASSWORD, '');
    setBasicAuthVisible(event.target.checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHasSecretValueChange = useCallback((event) => {
    if (event.target.value === 'false') {
      formik.setFieldValue(FIELD_NAMES.VALUE, '');
    }
    formik.handleChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error, 'aria-labelledby': name };
    },
    [formik],
  );

  useEffect(() => {
    if (isTrueFalseAssertion(formik.values[FIELD_NAMES.ASSERTION_PROPERTY])) {
      formik.setFieldValue(FIELD_NAMES.ASSERTION_EXPECTED_VALUE, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTypeField = () => (
    <FormField label={t('stepDetails.interaction.type', 'Action')} labelId={FIELD_NAMES.TYPE}>
      <StepTypePickerInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.TYPE)}
        onChange={handleTypeChange}
        readOnly={readOnly}
        assertionPropertyValue={formik.values[FIELD_NAMES.ASSERTION_PROPERTY]}
        assertionVariantValue={formik.values[FIELD_NAMES.ASSERTION_TYPE]}
      />
    </FormField>
  );

  const renderElementField = renderWhenTrue(() => (
    <ElementSelectorField
      context={context}
      computedValue={stepRun.computedSelector}
      disabled={readOnly}
    />
  ));

  const renderGoToBasicAuth = renderWhenTrue(() => (
    <>
      <FormField
        label={t('stepDetails.interaction.username', 'Username')}
        labelId={FIELD_NAMES.USERNAME}
      >
        <FormikInputWithVariables fullWidth name={FIELD_NAMES.USERNAME} readOnly={readOnly} />
      </FormField>
      {stepRun.computedUsername && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedUsername', 'This test run username')
              : t('stepDetails.interaction.lastComputedUsername', 'Last test run username')
          }
        >
          <ComputedValue>{stepRun.computedUsername}</ComputedValue>
          <CopyButton value={stepRun.computedUsername} />
        </ComputedField>
      )}
      <FormField
        label={t('stepDetails.interaction.password', 'Password')}
        labelId={FIELD_NAMES.PASSWORD}
      >
        <FormikInputWithVariables
          fullWidth
          type={FIELD_NAMES.PASSWORD}
          name={FIELD_NAMES.PASSWORD}
          readOnly={readOnly}
        />
      </FormField>
      {stepRun.computedPassword && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedPassword', 'This test run password')
              : t('stepDetails.interaction.lastComputedPassword', 'Last test run password')
          }
        >
          <ComputedValue>{stepRun.computedPassword}</ComputedValue>
          <CopyButton value={stepRun.computedPassword} />
        </ComputedField>
      )}
    </>
  ));

  const renderGoToFields = () => (
    <>
      <FormField label={t('stepDetails.interaction.url', 'URL')} labelId={FIELD_NAMES.URL}>
        <FormikInputWithVariables fullWidth name={FIELD_NAMES.URL} readOnly={readOnly} />
      </FormField>
      {stepRun.computedUrl && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedUrl', 'This test run url')
              : t('stepDetails.interaction.lastComputedUrl', 'Last test run url')
          }
        >
          <ComputedValue>{stepRun.computedUrl}</ComputedValue>
          <CopyButton value={stepRun.computedUrl} />
        </ComputedField>
      )}
      <FormField>
        <Checkbox checked={isAuthVisible} onChange={handleBasicAuthChange} disabled={readOnly}>
          {t('stepDetails.interaction.basicAuthCheckbox', 'Password protected')}
        </Checkbox>
      </FormField>
      {renderGoToBasicAuth(isAuthVisible)}
    </>
  );

  const renderScrollFields = () => <ScrollActionFields step={step} readOnly={readOnly} />;

  const renderExecuteFields = () => <ExecuteActionFields readOnly={readOnly} />;

  const renderTypeFields = () => (
    <>
      <FormField
        label={t('stepDetails.interaction.value.label', 'Value')}
        labelId={FIELD_NAMES.VALUE}
      >
        <FormikInputWithVariables
          as="textarea"
          name={FIELD_NAMES.VALUE}
          fullWidth
          clearOnFocus={!readOnly && `${formik.values[FIELD_NAMES.HAS_SECRET_CONTENT]}` === 'true'}
          readOnly={readOnly}
          autoSize
        />
        <CopyButton value={formik.values[FIELD_NAMES.VALUE]} />
      </FormField>
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedValue', 'This test run value')
              : t('stepDetails.interaction.lastComputedValue', 'Last test run value')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
      <SecretToggleField
        name={FIELD_NAMES.HAS_SECRET_CONTENT}
        onChange={handleHasSecretValueChange}
        value={formik.values[FIELD_NAMES.HAS_SECRET_CONTENT]}
        tooltipDisabled={isSecretToggleDisabled}
        disabled={isSecretToggleDisabled}
      />
    </>
  );

  const renderPromptFields = () => (
    <>
      <FormField
        label={t('stepDetails.interaction.answe.label', 'Answer')}
        labelId={FIELD_NAMES.VALUE}
      >
        <FormikInputWithVariables
          as="textarea"
          fullWidth
          name={FIELD_NAMES.VALUE}
          readOnly={readOnly}
          autoSize
        />
        <CopyButton value={formik.values[FIELD_NAMES.VALUE]} />
      </FormField>
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedAnswer', 'This test run answer')
              : t('stepDetails.interaction.lastComputedAnswer', 'Last test run answer')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
    </>
  );

  const renderChangeFields = renderTypeFields;

  const renderSelectOptionFields = () => (
    <SelectActionFields context={context} step={step} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderUploadFileFields = () => (
    <FormField label={t('stepDetails.interaction.file', 'File')} labelId={FIELD_NAMES.VALUE}>
      <FileUpload
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.VALUE)}
        key={formik.values[FIELD_NAMES.VALUE]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        readOnly={readOnly}
      />
    </FormField>
  );

  const renderDragAndDropFields = () => (
    <DragAndDropActionFields context={context} step={step} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderSwitchContextFields = () => (
    <>
      <FormField
        label={t('stepDetails.windowAndTabs.tabNumber', 'Tab number')}
        labelId={FIELD_NAMES.TAB_NO}
      >
        <Input
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.TAB_NO)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          readOnly={readOnly}
        />
      </FormField>
      <FormField
        label={t('stepDetails.windowAndTabs.frameLocation', 'Frame path')}
        labelId={FIELD_NAMES.FRAME_LOCATION}
      >
        <FormikInputWithVariables
          as="textarea"
          name={FIELD_NAMES.FRAME_LOCATION}
          readOnly={readOnly}
          fullWidth
          autoSize
          maxInitialHeight={55}
        />
        <CopyButton value={step.frameLocation} />
        {stepRun.computedFrameLocation && (
          <ComputedField
            label={
              context === 'testRun'
                ? t('stepDetails.interaction.thisComputedFrameLocation', 'This test run frame path')
                : t('stepDetails.interaction.lastComputedFrameLocation', 'Last test run frame path')
            }
          >
            <ComputedValue>{stepRun.computedFrameLocation}</ComputedValue>
            <CopyButton value={stepRun.computedFrameLocation} />
          </ComputedField>
        )}
      </FormField>
    </>
  );

  const renderMouseActionFields = () => (
    <InteractionPositionField
      {...formik.getFieldProps(FIELD_NAMES.INTERACTION_POSITION)}
      disabled={readOnly}
      onChange={formik.handleChange}
      name={FIELD_NAMES.INTERACTION_POSITION}
      nameX={FIELD_NAMES.MOUSE_X}
      nameY={FIELD_NAMES.MOUSE_Y}
      customDisabled
    />
  );

  const renderAssertFields = () => (
    <AssertActionFields context={context} step={step} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderSetLocalVariableFields = () => (
    <SetLocalVariableFields context={context} step={step} stepRun={stepRun} readOnly={readOnly} />
  );

  const renderFields = cond([
    [equals(STEP_TYPE.GOTO), renderGoToFields],
    [equals(STEP_TYPE.NEW_TAB), renderGoToFields],
    [equals(STEP_TYPE.SCROLL), renderScrollFields],
    [equals(STEP_TYPE.EXECUTE), renderExecuteFields],
    [equals(STEP_TYPE.TYPE), renderTypeFields],
    [equals(STEP_TYPE.SWITCH_CONTEXT), renderSwitchContextFields],
    [equals(STEP_TYPE.SELECT), renderSelectOptionFields],
    [equals(STEP_TYPE.CHANGE), renderChangeFields],
    [equals(STEP_TYPE.ASSERT), renderAssertFields],
    [equals(STEP_TYPE.CLICK), renderMouseActionFields],
    [equals(STEP_TYPE.DOUBLE_CLICK), renderMouseActionFields],
    [equals(STEP_TYPE.MOUSE_DOWN), renderMouseActionFields],
    [equals(STEP_TYPE.MOUSE_UP), renderMouseActionFields],
    [equals(STEP_TYPE.HOVER), renderMouseActionFields],
    [equals(STEP_TYPE.UPLOAD_FILE), renderUploadFileFields],
    [equals(STEP_TYPE.DRAG_AND_DROP), renderDragAndDropFields],
    [equals(STEP_TYPE.ANSWER_PROMPT), renderPromptFields],
    [equals(STEP_TYPE.SET_LOCAL_VARIABLE), renderSetLocalVariableFields],
  ]);

  const hasElementField =
    !STEPS_TYPES_WITHOUT_SELECTOR.includes(formik.values[FIELD_NAMES.TYPE]) &&
    ![STEP_TYPE.DRAG_AND_DROP, STEP_TYPE.ASSERT, STEP_TYPE.SET_LOCAL_VARIABLE].includes(
      formik.values[FIELD_NAMES.TYPE],
    );

  return (
    <>
      {renderTypeField()}
      {renderElementField(hasElementField)}
      {renderFields(formik.values[FIELD_NAMES.TYPE])}
    </>
  );
};

Interaction.defaultProps = {
  context: 'test',
  readOnly: false,
};

Interaction.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  step: PropTypes.shape({
    id: PropTypes.string,
    frontId: PropTypes.string,
    computedValue: PropTypes.string,
  }).isRequired,
};

export default Interaction;
