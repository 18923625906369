import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Page from '~/components/Page';

export const Container = styled(Page)``;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  flex: 1;
`;

export const ListContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
`;
