import IconButton from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme/colors';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import styled, { css } from 'styled-components';

import { MIN_SIDEBAR_SIZE } from '~/components/SidebarView';

export const Container = styled.aside`
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 500px;
  min-width: ${MIN_SIDEBAR_SIZE}px;
  background: ${COLOR.WHITE};
`;

export const CollapseButton = styled(IconButton).attrs(() => ({
  iconName: 'chevronsUp',
}))`
  background: ${({ theme }) => theme.colors.common.white};
  width: 32px;
  height: 32px;

  svg {
    transform: rotate(90deg);
    stroke: ${({ theme }) => theme.colors.border.normal};
    fill: none;
    width: 20px;
    height: 20px;
  }
`;

export const ExpandButton = styled(CollapseButton)`
  border: 2px solid ${({ theme }) => theme.colors.border.semi};
  transition: all 250ms ease-in-out, opacity 350ms ease-in-out;
  border-right: 0;
  height: 38px;
  transform: translate(0, 18px);
  position: fixed;
  border-radius: 100px 0 0 100px;
  right: -32px;
  z-index: 1;
  opacity: 0;

  svg {
    transform: rotate(-90deg);
    margin-left: 1px;
  }
`;

export const CollapsibleContainer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${Container} {
    transition: all 250ms ease-in-out;
    position: relative;

    ${CollapseButton} {
      position: absolute;
      right: 12px;
      top: 16px;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  // Collapse/Expand animation
  &,
  ${Container} {
    transition: width 250ms ease-in-out, opacity 200ms ease-in-out;
  }

  ${styleWhenTrue(
    T.prop('collapsed'),
    css`
      width: 0;

      ${Container} {
        opacity: 0;
      }

      ${ExpandButton} {
        right: 0;
        opacity: 1;
      }
    `,
  )}
`;
