import type { UUID } from './aliases';
import type { Organization } from './organizations';
import type { Project } from './projects';
import type { LocalVariableSource } from './steps';
import type { Maybe } from './utils';

export interface Variable {
  description: Maybe<string>;
  hasSecretValue: boolean;
  id: UUID;
  isSystem: boolean;
  key: string;
  name: string;
  organizationId: Organization['id'];
  projectId: Project['id'];
  type: VariableType;
  isDefault: boolean;
  value: string;
}

type VariableType = 'evaluate' | 'value';

export const VARIABLES_TAB = ['builtInVariables', 'customVariables', 'profiles'] as const;
export type VariablesTab = (typeof VARIABLES_TAB)[number];

export type VariableListItem = Pick<
  Variable,
  'key' | 'value' | 'id' | 'description' | 'hasSecretValue'
> & {
  uninitialized?: boolean;
  type: LocalVariableSource;
};
