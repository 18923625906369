import { DropdownItem, DROPDOWN_VARIANT, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { propOr } from 'ramda';
import { useCallback, useMemo, useRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest } from '~/modules/test/test.selectors';

import { Container, Dropdown, VisibilityWrapper } from './GroupActions.styled';

const GroupActions = memo(({ className, group, readOnly, onDelete, onRename }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const containerRef = useRef();
  const test = useSelector(selectSingleTest);
  const [areActionsLocked, setAreActionsLocked] = useState(false);

  const openRemoveModal = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_GROUP, {
        test,
        group,
        onSubmit: onDelete,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.show, test, group, onDelete],
  );

  const openDeletePermanentlyModal = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_GROUP, {
        test,
        group,
        permanent: true,
        onSubmit: onDelete,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.show, group, test, onDelete],
  );

  const openCloneModal = useCallback(
    () =>
      modal.show(MODAL_TYPE.CLONE_GROUP, {
        id: group.id,
        testId: test.id,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.show, group.id, test.id],
  );

  const handleUnlink = useCallback(() => {
    dispatch(TestActions.unlinkComponentRequest(test.id, group.id, { reqId: group.id }));
  }, [dispatch, test.id, group.id]);

  const hiddenActions = useMemo(
    () => [
      {
        name: t('groupActions.clone.label', 'Duplicate'),
        onClick: openCloneModal,
        iconName: 'clone',
        dataTestId: 'GroupActions.CloneButton',
        visible: !group.isComponent,
      },
      {
        name: t('groupActions.rename.label', 'Rename'),
        onClick: onRename,
        iconName: 'edit',
        dataTestId: 'GroupActions.RenameButton',
      },
      {
        name: t('groupActions.unlink.label', 'Unlink component'),
        onClick: handleUnlink,
        iconName: 'linkOff',
        dataTestId: 'GroupActions.UnlinkButton',
        visible: group.isComponent,
      },
      {
        name: t('groupActions.remove.label', 'Remove from this test'),
        onClick: openRemoveModal,
        iconName: 'delete',
        visible: group.isComponent,
      },
      {
        name: t('groupActions.delete.label', 'Delete'),
        onClick: openDeletePermanentlyModal,
        iconName: 'delete',
        visible: !group.isComponent,
      },
    ],
    [
      t,
      openRemoveModal,
      onRename,
      handleUnlink,
      openDeletePermanentlyModal,
      openCloneModal,
      group.isComponent,
    ],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  const ExtraActions = useMemo(
    () => (
      <Dropdown
        variant={DROPDOWN_VARIANT.ICON}
        anchor={DROPDOWN_ANCHOR.BOTTOM_END}
        iconName="more"
        onOpen={lockActionButtons}
        onClose={unlockActionButtons}
        disabled={readOnly}
      >
        {hiddenActions
          .filter(propOr(true, 'visible'))
          .map(({ name, onClick, iconName, disabled, dataTestId, danger }) => (
            <DropdownItem
              data-testid={dataTestId}
              key={name}
              disabled={disabled}
              onClick={onClick}
              danger={danger}
            >
              <Icon name={iconName} /> {name}
            </DropdownItem>
          ))}
      </Dropdown>
    ),
    [hiddenActions, lockActionButtons, unlockActionButtons, readOnly],
  );

  return (
    <VisibilityWrapper className={className} force={areActionsLocked}>
      <Container ref={containerRef} data-testid="GroupActions">
        {!readOnly && ExtraActions}
      </Container>
    </VisibilityWrapper>
  );
});

GroupActions.displayName = 'GroupActions';

export default GroupActions;
