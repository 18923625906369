import { COLOR } from '@bugbug/core/theme/colors';
import styled, { css } from 'styled-components';

const resizerStyles = css`
  .Resizer {
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
    position: relative;
    z-index: 3;
    transition: border 150ms ease-in-out;
  }

  .Resizer.vertical {
    width: 8px;
    cursor: col-resize;
    position: relative;
    border-left: 1px solid ${COLOR.GRAY_9};
    margin-right: -8px;
  }

  .Resizer.vertical:hover {
    border-left-color: ${COLOR.PRIMARY_LIGHT};
  }

  .Resizer.disabled {
    cursor: not-allowed;
  }

  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .Pane {
    overflow: hidden;
  }

  .Pane2 {
    z-index: 2;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${resizerStyles}
`;
