import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Dropdown, { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useModal from '~/hooks/useModal';

import { DeselectButton } from './SelectedComponentsActions.styled';

const SelectedTestsActions = ({ components, onDeselect }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleDeleteAll = useCallback(
    () => modal.show('delete_component', { data: components, onDeleteSuccess: onDeselect }),
    [components, modal, onDeselect],
  );

  return (
    <>
      <Dropdown
        fullWidth
        label={t('componentsList.selectedComponentsActions.bulkActionsButton', {
          defaultValue_one: '{{count}} component selected',
          defaultValue_other: '{{count}} components selected',
          count: components.length,
        })}
        rounded
      >
        <DropdownItem onClick={handleDeleteAll} danger>
          <Icon name="delete" />
          {t('componentsList.selectedComponentsActions.deleteButton', 'Delete')}
        </DropdownItem>
      </Dropdown>

      <DeselectButton
        aria-label={t('componentsList.selectedComponentsActions.deselectButton')}
        bordered
        iconName="close"
        onClick={onDeselect}
        variant={BUTTON_VARIANT.PRIMARY}
      >
        {t('componentsList.selectedComponentsActions.deselectButton', 'Deselect all')}
      </DeselectButton>
    </>
  );
};

export default SelectedTestsActions;
