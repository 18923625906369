import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Page from '~/components/Page';
import { TableBody } from '~/components/Table';

export const ListContainer = styled(Page)`
  ${TableBody} {
    overflow-y: hidden;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const TableEmptyState = styled.div`
  background-color: ${COLOR.WHITE};
  position: relative;
  height: 100%;

  ${LoaderContainer} {
    top: 25px;
  }
`;
