import { DropdownItem, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { MODAL_TYPE } from '~/components/modals';
import useCopyHandler from '~/hooks/useCopyHandler';
import useCopyPasteSteps from '~/hooks/useCopyPasteSteps';
import useModal from '~/hooks/useModal';
import config from '~/modules/config';
import { TestActions } from '~/modules/test/test.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Dropdown, Content } from './TestStepMoreActions.styled';

const TestStepMoreActionsComponent = ({
  className,
  step,
  onOpen,
  onClose,
  bordered = false,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const params = useParams();
  const { t } = useTranslation();
  const { copySteps } = useCopyPasteSteps();

  const location = useLocation();
  const handleCopyLinkToStep = useCopyHandler(
    `${config.WEBAPP_URL}${location.pathname}?${new URLSearchParams({
      groupId: step.groupId,
      stepId: step.id,
    })}`,
    {
      success: t('testStepActions.copyLinkToStep.copyLinkToStepMsg.success', 'Link to step copied'),
      failure: t(
        'testStepActions.copyLinkToStep.copyLinkToStepMsg.failure',
        'Error when copying link to step',
      ),
    },
  );

  const openDeleteModal = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_STEPS, {
        testId: params.testId,
        stepsIds: [step.id],
        onSuccess: onDelete,
      }),
    [modal, params.testId, step.id, onDelete],
  );

  const handleClone = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.DUPLICATE_STEP);
    dispatch(TestActions.cloneStepRequest(step.id));
  }, [dispatch, step.id]);

  const handleCopy = useCallback(() => {
    copySteps([step.id]);
  }, [step.id, copySteps]);

  const handleToggleActive = useCallback(
    () => dispatch(TestActions.toggleStepActiveRequest(step.id)),
    [dispatch, step.id],
  );

  const handleToggleBreakpoint = useCallback(() => {
    dispatch(TestActions.toggleStepBreakpointRequest(step.id));
  }, [dispatch, step.id]);

  const actions = useMemo(
    () => [
      {
        name: t('testStepActions.clone.label', 'Duplicate'),
        onClick: handleClone,
        iconName: 'clone',
      },
      {
        name: t('testStepActions.copy.label', 'Copy step'),
        onClick: handleCopy,
        iconName: 'copy',
      },
      {
        name: t('testStepActions.copyLinkToStep.label', 'Copy link to step'),
        onClick: handleCopyLinkToStep,
        iconName: 'link2',
      },
      {
        name: step.isActive
          ? t('testStepActions.setInactive.label', 'Deactivate step')
          : t('testStepActions.setActive.label', 'Activate step'),
        onClick: handleToggleActive,
        iconName: step.isActive ? 'layersClear' : 'layers',
      },
      {
        name: step.isBreakpoint
          ? t('testStepActions.breakpoint.active.label', 'Remove breakpoint')
          : t('testStepActions.breakpoint.inactive.label', 'Set breakpoint'),
        onClick: handleToggleBreakpoint,
        iconName: step.isBreakpoint ? 'labelOff' : 'label',
      },
      {
        name: t('testStepActions.remove.label', 'Delete'),
        onClick: openDeleteModal,
        iconName: 'delete',
      },
    ],
    [
      t,
      openDeleteModal,
      handleClone,
      handleCopy,
      handleCopyLinkToStep,
      handleToggleBreakpoint,
      handleToggleActive,
      step.isActive,
      step.isBreakpoint,
    ],
  );

  return (
    <Tooltip
      content={
        step.isTemporary
          ? t(
              'testStepActions.moreActions.temporaryStepTooltip',
              'You need to create this step until actions are available',
            )
          : undefined
      }
    >
      <Content className={className}>
        <Dropdown
          anchor={DROPDOWN_ANCHOR.BOTTOM_END}
          iconName="more"
          onOpen={onOpen}
          onClose={onClose}
          disabled={step.isTemporary}
          hideExpander
          rounded
          bordered={bordered}
          condensed
        >
          {actions.map(({ name, onClick, iconName, disabled, dataTestId }) => (
            <DropdownItem data-testid={dataTestId} key={name} disabled={disabled} onClick={onClick}>
              <Icon name={iconName} /> {name}
            </DropdownItem>
          ))}
        </Dropdown>
      </Content>
    </Tooltip>
  );
};

TestStepMoreActionsComponent.propTypes = {
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  step: PropTypes.shape({
    id: PropTypes.string,
    frontId: PropTypes.string,
    isActive: PropTypes.bool,
    isBreakpoint: PropTypes.bool,
    isTemporary: PropTypes.bool,
  }).isRequired,
  bordered: PropTypes.bool,
};

export default TestStepMoreActionsComponent;
