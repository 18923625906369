import type React from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';

import * as S from './CopyText.styled';

interface CopyTextProps {
  text: string;
  icon: React.ReactNode;
  onCopy?: SideEffect;
}

const CopyText = ({ text, icon, onCopy }: CopyTextProps) => (
  <S.Container data-testid="CopyText">
    <S.Command>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      <span>{text}</span>
    </S.Command>
    <S.CopyButton value={text} onCopy={onCopy} />
  </S.Container>
);

export default CopyText;
