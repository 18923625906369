import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import { SimpleTable } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectUnconfirmedGroupStepsList,
  selectOrderedGroupPartialsIndices,
  selectSingleTestIsRecording,
} from '~/modules/test/test.selectors';
import { StepContext } from '~/views/TestDetails/TestDetails.context';

import { STEPS_COLUMNS } from '../StepsGroup/StepsGroup.constants';

import {
  Container,
  Header,
  Content,
  Actions,
  Steps,
  ConfirmButton,
  DeleteButton,
  EmptyState,
} from './UnconfirmedGroup.styled';

const emptyStepContext = {};

const UnconfirmedGroup = ({ className, groupId, relatedGroup, relatedIndex }) => {
  const { t } = useTranslation();
  const isRecording = useSelector(selectSingleTestIsRecording);

  const modal = useModal();
  const dispatch = useDispatch();
  const partialsIndieces = useSelector(selectOrderedGroupPartialsIndices(relatedGroup));
  const steps = useSelector(selectUnconfirmedGroupStepsList(groupId));
  const dragIndex = partialsIndieces[`group.${groupId}`];

  const stateParams = useMemo(
    () => ({
      reqId: groupId,
    }),
    [groupId],
  );

  const confirmRequestState = useActionState(
    TestActions.confirmUnconfirmedGroupRequest,
    stateParams,
  );

  const handleConfirm = useCallback(() => {
    dispatch(
      TestActions.confirmUnconfirmedGroupRequest(groupId, relatedGroup, relatedIndex, stateParams),
    );
  }, [dispatch, groupId, relatedGroup, relatedIndex, stateParams]);

  const handleDeleteConfirmation = useCallback(() => {
    modal.show(MODAL_TYPE.DELETE_UNCONFIRMED_STEPS, { groupId });
  }, [modal, groupId]);

  return (
    <Draggable
      disableInteractiveElementBlocking
      draggableId={groupId}
      key={groupId}
      index={dragIndex}
      isDragDisabled
    >
      {({ draggableProps, innerRef }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Container className={className} {...draggableProps} ref={innerRef}>
          {!isRecording && (
            <Header>
              <Content>
                {t('unconfirmedGroup.title', "Please confirm the steps you've recorded")}
              </Content>
              <Actions>
                <DeleteButton onClick={handleDeleteConfirmation}>
                  {t('default.button.delete')}
                </DeleteButton>
                <ConfirmButton pending={confirmRequestState.isLoading} onClick={handleConfirm}>
                  {t('default.button.confirm')}
                </ConfirmButton>
              </Actions>
            </Header>
          )}
          {!!steps.length && (
            <Steps>
              <StepContext.Provider value={emptyStepContext}>
                <SimpleTable data={steps} columns={STEPS_COLUMNS} hiddenHeaders disabledWindowing />
              </StepContext.Provider>
            </Steps>
          )}
          {isRecording && !steps.length && (
            <EmptyState>
              <Content>
                {t('unconfirmedGroup.emptyState', 'There are no steps recorded yet.')}
              </Content>
            </EmptyState>
          )}
        </Container>
      )}
    </Draggable>
  );
};

UnconfirmedGroup.displayName = 'UnconfirmedGroup';

UnconfirmedGroup.defaultProps = {
  className: null,
};

UnconfirmedGroup.propTypes = {
  className: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  relatedGroup: PropTypes.string.isRequired,
  relatedIndex: PropTypes.number.isRequired,
};

export default UnconfirmedGroup;
