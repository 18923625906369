import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Page, { Content, Header, ContentWrapper } from '~/components/Page';
import RouteNotification from '~/components/RouteNotification';

export const Container = styled(Page)`
  flex: 1;
  position: relative;
  z-index: 2;
  background-color: ${COLOR.WHITE};
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  height: 100%;

  ${Header} {
    margin-bottom: 0;
  }

  ${Content} {
    padding-right: 0;
    padding-bottom: 0;
  }

  ${ContentWrapper} {
    min-width: 735px;
    overflow: hidden;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};
  z-index: 1;

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const Notification = styled(RouteNotification)`
  top: 135px;
  left: 34px;
  right: 0;
`;
