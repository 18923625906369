export const FIELD_NAMES = {
  DRAG_ON: 'dndDragOn',
  DRAG_SELECTORS: 'selectors',
  DRAG_X: 'dndDragX',
  DRAG_Y: 'dndDragY',
  DRAG_INTERACTION_POSITION: 'interactionPosition',
  DROP_ON: 'dndDropOn',
  DROP_SELECTORS: 'dndDropSelectors',
  DROP_X: 'dndDropX',
  DROP_Y: 'dndDropY',
  DROP_INTERACTION_POSITION: 'dndDropInteractionPosition',
};
