import { useCallback, useMemo, useRef, useState } from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';
import localStorage from '~/services/localStorage';

interface UseCollapsiblePaneReturn {
  isCollapsed: boolean;
  paneStyle: React.CSSProperties;
  resizerStyle: React.CSSProperties;
  onToggle: SideEffect<boolean | undefined>;
  fromCache: boolean;
  initialCollapsed: boolean;
}

const useCollapsiblePane = (
  storageName: string,
  defaultValue = false,
): UseCollapsiblePaneReturn => {
  const cachedValueName = `${storageName}.expanded`;
  const cachedValue = localStorage.getUserItem(cachedValueName);
  const initialCollapsed = useRef(cachedValue === null ? defaultValue : cachedValue);
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed.current);

  const onToggle = useCallback(
    (tempState) => {
      setIsCollapsed((prev) => tempState ?? !prev);

      if (tempState === undefined) {
        initialCollapsed.current = !isCollapsed;
        localStorage.setUserItem(cachedValueName, !isCollapsed);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cachedValueName],
  );

  const paneStyle = useMemo(() => {
    const commonStyle = {
      transition: 'width 250ms ease-in-out',
    };

    if (isCollapsed) {
      return {
        ...commonStyle,
        width: 0,
      };
    }

    return commonStyle;
  }, [isCollapsed]);

  const resizerStyle = isCollapsed
    ? {
        display: 'none',
      }
    : {};

  return {
    isCollapsed,
    fromCache: cachedValue !== null,
    paneStyle,
    resizerStyle,
    onToggle,
    initialCollapsed: initialCollapsed.current,
  };
};

export default useCollapsiblePane;
