import { RUN_STATUS } from '@bugbug/core/constants/status';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { SuiteRun } from '@bugbug/core/types/suites';
import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { selectSingleSuiteRunEnvData } from '~/modules/suiteRun/suiteRun.selectors';

import { RunMetaData } from './HeaderMetadata.styled';

interface HeaderMetadataProps {
  suiteRun: SuiteRun;
}

const HeaderMetadata = ({ suiteRun }: HeaderMetadataProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const isRunning = suiteRun.status === RUN_STATUS.RUNNING;

  const suiteRunEnvData = useSelector(selectSingleSuiteRunEnvData);

  const handleShowMore = useCallback(
    () =>
      modal.show(MODAL_TYPE.RUN_METADATA, {
        generalData: {
          id: {
            label: t('suiteRun.details.suiteIdLabel', 'Suite ID'),
            value: suiteRun.suite.id,
          },
          created: suiteRun.suite.created,
          createdBy: suiteRun.suite.createdBy,
        },
        runData: {
          ...suiteRun,
          ...suiteRunEnvData,
          hiddenViaSuite: true,
          running: isRunning,
          suiteRunId: {
            label: t('suiteRun.details.suiteRunIdLabel', 'Suite Run ID'),
            value: suiteRun.id,
          },
        },
      }),
    [isRunning, modal, suiteRun, t, suiteRunEnvData],
  );

  return (
    <RunMetaData
      status={suiteRun.status}
      started={suiteRun.started}
      created={suiteRun.created}
      passedCurrent={suiteRun.passedCount}
      passedExpected={suiteRun.totalCount}
      runMode={suiteRun.runMode}
      profileName={suiteRun.profileName}
      id={suiteRun.sequence}
      duration={suiteRun.rawDuration}
      onDetailsClick={handleShowMore}
    />
  );
};

HeaderMetadata.propTypes = {
  suiteRun: PropTypes.shape({
    duration: PropTypes.string,
    runMode: PropTypes.string.isRequired,
    profileName: PropTypes.string,
    sequence: PropTypes.number.isRequired,
    started: PropTypes.string,
    created: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default HeaderMetadata;
