import i18n from '~/translations';

import { AddComponentIcon, AddGroupIcon, DotIcon, PasteIcon } from './EndTestActions.styled';

export const ACTION = {
  RECORD: 'record',
  NEW_GROUP: 'newStep',
  EXISTING_COMPONENT: 'existingComponent',
  PASTE_STEPS: 'pasteSteps',
};

export const ACTIONS = [
  {
    key: ACTION.RECORD,
    title: i18n.t('testDetailsEmptyState.endTestActions.actionTitle.record', 'Record from here'),
    Icon: DotIcon,
  },
  {
    key: ACTION.NEW_GROUP,
    title: i18n.t('testDetailsEmptyState.endTestActions.actionTitle.newStep', 'New group'),
    Icon: AddGroupIcon,
  },
  {
    key: ACTION.PASTE_STEPS,
    title: i18n.t('testDetailsEmptyState.endTestActions.actionTitle.pasteSteps', 'Paste step(s)'),
    Icon: PasteIcon,
  },
  {
    key: ACTION.EXISTING_COMPONENT,
    title: i18n.t(
      'testDetailsEmptyState.endTestActions.actionTitle.existingComponent',
      'Insert an existing component',
    ),
    Icon: AddComponentIcon,
  },
];

export const ANIMATED_ELEMENTS_LENGTH = ['title', ...ACTIONS, 'tip'].length;
