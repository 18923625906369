import DropdownBase from '@bugbug/core/components/Dropdown';
import IconButtonBase from '@bugbug/core/components/IconButton';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: flex-end;
`;

export const Dropdown = styled(DropdownBase)`
  margin-left: 14px;
`;

export const IconButton = styled(IconButtonBase)`
  position: absolute;
  left: 0;

  svg {
    min-width: 10px;
  }
`;
