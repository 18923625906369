import Loader from '@bugbug/core/components/Loader';
import { useCallback, useEffect, useRef, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useBackUrl from '~/hooks/useBackUrl';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { TestActions } from '~/modules/test/test.redux';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import {
  selectSingleTestRun,
  selectSingleTestRunTestArchive,
  selectFailedStepRunWithStep,
  selectTestRunSteps,
} from '~/modules/testRun/testRun.selectors';
import { Container, LoaderContainer } from '~/views/TestDetails/components/Content/Content.styled';
import StepsBaseList from '~/views/TestDetails/components/StepsBaseList';
import urls from '~/views/urls';

import TestRunHeader from '../TestRunHeader';

const TestRunDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { groupId: urlGroupId, stepId: urlStepId } = useQueryString();
  const {
    params: { testRunId },
  } = useAppRoutes('testRun');
  const backUrl = useBackUrl(urls.testRunsList);

  const stepsListRef = useRef();
  const modal = useModal();
  const testRun = useSelector(selectSingleTestRun);
  const test = useSelector(selectSingleTestRunTestArchive);
  const allTestRunSteps = useSelector(selectTestRunSteps);
  const { failedStepRun = {} } = useSelector(selectFailedStepRunWithStep);

  const getSingleRequestState = useActionState(TestRunActions.getSingleRequest, { reset: false });
  const isLoading = getSingleRequestState.isLoading || !test || !testRun;
  const updateQueryString = useUpdateQueryString();

  useMount(() => {
    dispatch(TestRunActions.getSingleRequest(testRunId));
  }, [dispatch, testRunId]);

  useUnmount(() => {
    dispatch(TestActions.setCurrentStepId(null));
    dispatch(TestRunActions.clearSingle());
  });

  const openUnavailableStepModal = useCallback(
    () => {
      modal.show('unavailable_step');
      updateQueryString({ stepId: undefined, groupId: undefined });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectAndScrollToStep = useCallback(
    (groupId, stepId, currentSteps) => {
      if (stepsListRef.current && groupId && stepId && currentSteps) {
        const stepAvailable = Object.keys(currentSteps).includes(stepId);
        if (stepAvailable) {
          stepsListRef.current.goToStep(groupId, stepId, false, 'center');
          dispatch(TestActions.setCurrentStepId(stepId));
        } else {
          openUnavailableStepModal();
        }
      }
    },
    [dispatch, openUnavailableStepModal],
  );

  useEffect(() => {
    if (!isLoading) {
      selectAndScrollToStep(urlGroupId, urlStepId, allTestRunSteps);
    }
  }, [urlGroupId, urlStepId, allTestRunSteps, isLoading, selectAndScrollToStep]);

  const handleGoToError = useCallback(() => {
    selectAndScrollToStep(failedStepRun.groupId, failedStepRun.stepId, allTestRunSteps);
  }, [failedStepRun, selectAndScrollToStep, allTestRunSteps]);

  const refresh = useCallback(() => {
    getSingleRequestState.reset();
    dispatch(TestRunActions.getSingleRequest(testRunId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testRunId]);

  const Header = useMemo(
    () =>
      test && (
        <TestRunHeader
          test={test}
          testRun={testRun}
          onErrorClick={failedStepRun.stepId ? handleGoToError : null}
        />
      ),
    [failedStepRun.stepId, handleGoToError, test, testRun],
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" />
      </LoaderContainer>
    );
  }

  if (getSingleRequestState.hasInternalServerError) {
    return <ServerErrorInfo isVisible onRetry={refresh} />;
  }

  return (
    <Container title={Header} data-testid="TestRunDetails" withBackButton backUrl={backUrl}>
      <Helmet
        title={t('testRunDetails.pageTitle', 'Test runs / {{- name }}', { name: test?.name })}
      />
      <StepsBaseList
        ref={stepsListRef}
        test={test}
        testRunId={testRun.id}
        readOnly
        runResultEnabled
      />
    </Container>
  );
};

export default TestRunDetails;
