import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RUN_ENV } from '~/modules/constans';

import { Container, MetaValue, Tooltip } from './MethodCell.styled';

const MethodCell = ({ className, value }) => {
  const { t } = useTranslation();

  const isLocalRun = value && value === RUN_ENV.LOCAL;

  const iconName = isLocalRun ? 'computer' : 'cloud';

  const tooltip = isLocalRun
    ? t('runMetadata.meta.env.local.tooltip', 'Test has been run locally on your computer')
    : t('runMetadata.meta.env.cloud.tooltip', 'Test has been run in cloud server');

  const label = isLocalRun
    ? t('runMetadata.meta.env.local.label', 'Local')
    : t('runMetadata.meta.env.cloud.label', 'Cloud');

  return (
    <Container className={className} data-testid="MethodCell">
      <Tooltip content={tooltip}>
        <MetaValue>
          <Icon name={iconName} />
          {label}
        </MetaValue>
      </Tooltip>
    </Container>
  );
};

MethodCell.defaultProps = {
  className: null,
};

MethodCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default MethodCell;
