import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import RunMetadata from '~/components/RunMetadata';

export const RunMetaData = styled(RunMetadata)`
  margin-top: 15px;
  border-top: 1px solid ${COLOR.GRAY_29};
  height: 47px;
  padding-bottom: 1px;
`;
