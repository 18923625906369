import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ElementSelectorField from '../ElementSelectorField';
import InteractionPositionField from '../InteractionPositionField';

import { FIELD_NAMES } from './DragAndDropActionFields.constants';

const DragAndDropActionFields = ({ context, readOnly = false }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <>
      <ElementSelectorField
        context={context}
        label={t('stepDetails.interaction.dndDragOn', 'Drag element')}
        name={FIELD_NAMES.DRAG_SELECTORS}
        disabled={readOnly}
      />
      <InteractionPositionField
        {...formik.getFieldProps(FIELD_NAMES.DRAG_INTERACTION_POSITION)}
        disabled={readOnly}
        onChange={formik.handleChange}
        name={FIELD_NAMES.DRAG_INTERACTION_POSITION}
        nameX={FIELD_NAMES.DRAG_X}
        nameY={FIELD_NAMES.DRAG_Y}
        label={t(
          'stepDetails.interaction.dndDragInteractionPosition.regular',
          'Drag element: click position',
        )}
        helperText={t(
          'stepDetails.interaction.dndDragInteractionPosition.helperText',
          'Which area of the element should be interacted with. For example you can decide if you want to click the center of the button or the bottom right.',
        )}
        customCoordsLabel={t(
          'stepDetails.interaction.dndDragInteractionPosition.coords',
          'Drag coordinates',
        )}
      />
      <ElementSelectorField
        context={context}
        label={t('stepDetails.interaction.dndDropOn', 'Drop on element')}
        name={FIELD_NAMES.DROP_SELECTORS}
        disabled={readOnly}
      />
      <InteractionPositionField
        {...formik.getFieldProps(FIELD_NAMES.DROP_INTERACTION_POSITION)}
        disabled={readOnly}
        onChange={formik.handleChange}
        name={FIELD_NAMES.DROP_INTERACTION_POSITION}
        nameX={FIELD_NAMES.DROP_X}
        nameY={FIELD_NAMES.DROP_Y}
        label={t(
          'stepDetails.interaction.dndDropInteractionPosition.regular',
          'Drop position in element',
        )}
        customCoordsLabel={t(
          'stepDetails.interaction.dndDropInteractionPosition.coords',
          'Drop coordinates',
        )}
      />
    </>
  );
};

DragAndDropActionFields.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  readOnly: PropTypes.bool,
};

export default DragAndDropActionFields;
