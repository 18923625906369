import styled from 'styled-components';

import { VisibleOnRowHover as VisibleOnRowHoverBase } from '~/components/Table';

export const Container = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px;
`;

export const VisibleOnRowHover = styled(VisibleOnRowHoverBase)`
  width: 100%;
`;
