/* eslint-disable prettier/prettier */
import Link from '@bugbug/core/components/Link';
import { propOr } from 'ramda';
import { Trans } from 'react-i18next';

import { WAITING_CONDITION_TYPE } from '~/modules/constans';
import i18n from '~/translations';
import { getScreenshotUrl } from '~/utils/misc';


export const getDescription = (condition, stepRun) => {
  const conditionMessage = {
    [WAITING_CONDITION_TYPE.DOCUMENT_COMPLETE]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.documentComplete',
        'Page loading has not finished: document "ready" didn\'t happen. If the page is loading slowly, you can increase the step running time in the step settings below.',
      ),
    [WAITING_CONDITION_TYPE.PAGE_NAVIGATION_AFTER_EXECUTION]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.pageNavigationAfterExecution',
        'Page was supposed to navigate to new URL after step execution but it did not happen.',
      ),
    [WAITING_CONDITION_TYPE.NETWORK_IDLE]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.networkIdle',
        'Network is not idle. Page didn\'t have expected number of pending requests ({{ expected }}).',
        { expected: condition.expected }
      ),
    [WAITING_CONDITION_TYPE.ELEMENT_HAS_ATTRIBUTE]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.elementHasAttribute',
        'Element didn\'t have the expected attribute "{{attribute}}".',
        { attribute: condition.expected },
      ),
    [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_ANIMATING]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.elementIsNotAnimating',
        'Element was animating. It should not be moving during execution.',
      ),
    [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_DISABLED]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.elementIsNotDisabled',
        'Element was disabled. It should not be disabled during execution.',
      ),
    [WAITING_CONDITION_TYPE.ELEMENT_IS_VISIBLE]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.elementIsVisible',
        'Element was not visible.',
      ),
    // eslint-disable-next-line react/display-name
    [WAITING_CONDITION_TYPE.ELEMENT_IS_NOT_COVERED]: () => {
      const screenshot = getScreenshotUrl(stepRun.coveringElementScreenshot);
      return (
        <Trans i18nKey="waitingConditionsFailedDetails.errorMessage.condition.elementIsNotCovered">
          Element was covered by a different element (<Link to={screenshot} openNewTab>screen</Link>)
        </Trans>
      );
    },
    [WAITING_CONDITION_TYPE.ELEMENT_HAS_FOCUS]: () => i18n.t(
        'waitingConditionsFailedDetails.errorMessage.condition.elementHasFocus',
        "Element didn't have focus.",
      ),
  };

  return propOr(() => {}, condition.type, conditionMessage)();
};
