import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import EditableText from '~/components/EditableText';

export const Container = styled.div`
  position: relative;
  top: -4px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-right: 32px;
`;

export const Title = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 15px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
`;

export const NameContainer = styled.div`
  flex: 1;
  max-width: 50%;
`;

export const NameInput = styled(EditableText)`
  padding: 0 8px 4px;
  color: ${COLOR.BLACK};
  border-color: ${COLOR.TRANSPARENT};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${FONT_WEIGHT.MEDIUM};

  &:hover {
    border-color: ${COLOR.GRAY_3};
  }

  &:focus {
    border-color: ${COLOR.GRAY_10};
  }
`;
