import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import SearchInput from '@bugbug/core/components/SearchInput';
import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type React from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { SuiteActions } from '~/modules/suite/suite.redux';

import * as S from './SuitesActions.styled';

export interface SuitesActionsRef {
  showCreateModal: SideEffect;
}

interface SuitesActionsProps {
  className?: string;
  onDelete: SideEffect;
}

export const SuitesActions = forwardRef<SuitesActionsRef, SuitesActionsProps>(
  ({ className, onDelete }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const modal = useModal();
    const appRoutes = useAppRoutes('suitesList');
    const queryString = useQueryString();
    const updateQueryString = useUpdateQueryString();

    const handleCreate = useCallback(() => {
      appRoutes.push('newSuite');
    }, [appRoutes]);

    const handleDeleteAll = useCallback(
      () =>
        modal.show('delete_suites', {
          onDelete,
        }),
      [modal, onDelete],
    );

    const handleQueryChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        updateQueryString({ query: event.target.value });
      },
      [updateQueryString],
    );

    const handleStopAll = useCallback(() => {
      dispatch(SuiteActions.stopRequest());
    }, [dispatch]);

    useImperativeHandle(
      ref,
      () => ({
        showCreateModal: handleCreate,
      }),
      [handleCreate],
    );

    return (
      <S.Container className={className} data-testid="SuitesActions">
        <SearchInput value={queryString.query?.toString()} onChange={handleQueryChange} />
        <S.CreateButton
          iconName="addCircle"
          data-testid="SuitesActions.CreateNewSuite"
          onClick={handleCreate}
        >
          {t('suitesList.header.createButton', 'New suite')}
        </S.CreateButton>
        <S.Menu data-testid="SuitesActions.Menu" iconName="more" hideExpander rounded condensed>
          <DropdownItem onClick={handleStopAll}>
            <Icon name="stop" /> {t('suitesList.suiteActions.stopAll', 'Stop all suites')}
          </DropdownItem>
          <DropdownItem onClick={handleDeleteAll} danger>
            <Icon name="delete" /> {t('suitesList.suiteActions.deleteAll', 'Delete all suites')}
          </DropdownItem>
        </S.Menu>
      </S.Container>
    );
  },
);

SuitesActions.displayName = 'SuitesActions';
