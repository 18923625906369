import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  CloseButton,
  Container,
  InfoIcon,
  Text,
  Content,
} from './RouteNotification.styled';

const RouteNotification = (props) => {
  const {
    className,
    text = '',
    buttonLabel = '',
    onClick = Function.prototype,
    onClose = Function.prototype,
  } = props;
  const transition = useTransition(text, {
    from: { opacity: 0, transform: 'translateY(-50px) translateZ(0)' },
    enter: { opacity: 1, transform: 'translateY(0px) translateZ(0)' },
    leave: { opacity: 0, transform: 'translateY(50px) translateZ(0)' },
  });

  return transition((styles, item, t, i) =>
    item ? (
      <Container style={styles} key={i} className={className} data-testid="RouteNotification">
        <Content>
          <InfoIcon />
          <Text>{item}</Text>
          <Button variant={BUTTON_VARIANT.PRIMARY} onClick={onClick}>
            {buttonLabel}
          </Button>
          <CloseButton data-testid="RouteNotification.CloseButton" onClick={onClose}>
            <Icon name="close" />
          </CloseButton>
        </Content>
      </Container>
    ) : null,
  );
};

RouteNotification.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

export default RouteNotification;
