import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useActionState from '~/hooks/useActionState';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import { RUN_ENV } from '~/modules/constans';
import { selectHasMoreThanOneProfile } from '~/modules/profile/profile.selectors';
import { TestActions } from '~/modules/test/test.redux';

import { DeselectButton } from './SelectedTestsActions.styled';

const SelectedTestsActions = ({ testsIds, onDeselect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const extensionState = useExtensionState();
  const hasMoreThanOneProfile = useSelector(selectHasMoreThanOneProfile);

  useActionState(TestActions.stopRequest, { onSuccess: onDeselect });

  const handleStop = useCallback(() => {
    dispatch(TestActions.stopRequest(testsIds));
  }, [dispatch, testsIds]);

  const handleDelete = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_TESTS, {
        testsIds,
        onDelete: onDeselect,
      }),
    [modal, onDeselect, testsIds],
  );

  const openExtensionModal = useCallback(
    () => modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION),
    [modal],
  );

  const run = useCallback(
    (options) => {
      const continueRun = (profileId) => {
        dispatch(
          TestActions.startRunningSelectedTestsRequest(testsIds, {
            runMode: RUN_ENV.SERVER,
            ...options,
            runProfileId: profileId,
          }),
        );
        onDeselect();
      };

      if (hasMoreThanOneProfile) {
        modal.show(MODAL_TYPE.RUN_WITH_PROFILE, {
          onSuccess: continueRun,
        });
        return;
      }

      continueRun();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, testsIds, onDeselect, hasMoreThanOneProfile, modal.show],
  );

  const handleRun = useCallback(() => run({ runMode: RUN_ENV.SERVER }), [run]);

  const handleRunLocal = useCallback(() => {
    if (!extensionState.isActive) {
      openExtensionModal();
      return;
    }
    run({ runMode: RUN_ENV.LOCAL });
  }, [extensionState.isActive, openExtensionModal, run]);

  return (
    <>
      <Dropdown
        rounded
        variant={DROPDOWN_VARIANT.PRIMARY}
        label={t('testsList.selectedTestsActions.bulkActionsButton', {
          defaultValue_one: '{{count}} test selected',
          defaultValue_other: '{{count}} tests selected',
          count: testsIds.length,
        })}
      >
        <DropdownItem onClick={handleRunLocal}>
          <Icon name="play" />
          {t('testsList.selectedTestsActions.runButton', 'Run')}
        </DropdownItem>
        <DropdownItem onClick={handleRun}>
          <Icon name="cloudRun" />
          {t('testsList.selectedTestsActions.runInCloudButton', 'Run in cloud')}
        </DropdownItem>
        <DropdownItem onClick={handleStop}>
          <Icon name="stop" />
          {t('testsList.selectedTestsActions.stopButton', 'Stop')}
        </DropdownItem>
        <DropdownItem onClick={handleDelete} danger>
          <Icon name="delete" />
          {t('testsList.selectedTestsActions.deleteButton', 'Delete')}
        </DropdownItem>
      </Dropdown>
      <DeselectButton
        aria-label={t('testsList.selectedTestsActions.deselectButton')}
        iconName="close"
        variant={BUTTON_VARIANT.PRIMARY}
        onClick={onDeselect}
        bordered
      >
        {t('testsList.selectedTestsActions.deselectButton', 'Deselect all')}
      </DeselectButton>
    </>
  );
};

SelectedTestsActions.defaultProps = {};

SelectedTestsActions.propTypes = {
  testsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDeselect: PropTypes.func.isRequired,
};

export default SelectedTestsActions;
