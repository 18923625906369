import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import Tooltip from '@bugbug/core/components/Tooltip';
import mixins from '@bugbug/core/theme/mixins';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import styled, { css } from 'styled-components';

export const tooltipWrapperClassName = 'NavItem__tooltipWrapper';

/*
  Project
*/
export const Context = styled.div`
  padding: 25px 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  > div {
    width: inherit;
  }
`;

export const ProjectName = styled.div`
  ${mixins.textEllipsis};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.brand};
  border: 2px solid ${({ theme }) => theme.colors.common.transparent};
  height: 36px;
  font-size: 20px;
  line-height: 29px;
  max-width: 100%;
  width: auto;
`;

/*
  Navigation
*/
export const NavItemIcon = styled(Icon)`
  padding: 0 1px;
  width: 20px;
  flex: none;
`;

export const NavItemTooltip = styled(Tooltip).attrs({
  wrapperClassName: tooltipWrapperClassName,
})``;

export const NavItem = styled.li`
  ${mixins.layout.flexRowCenter};
  height: 38px;
  border-radius: 0 100px 100px 0;
  overflow: hidden;

  & + & {
    margin-top: 4px;
  }

  [class*='${tooltipWrapperClassName}'] {
    width: 100%;
    height: 100%;
  }
`;

export const NavItemLabel = styled.span`
  line-height: 13px;
  height: 15px;
  white-space: nowrap;
  margin-left: 11px;
`;

export const NavLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.normal};
  height: 100%;
  width: 100%;
  padding: 0 0 0 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
  border-left: 3px solid transparent;

  &[aria-current='page'],
  &:hover {
    text-decoration: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.neutral};
    color: initial;
  }

  &[aria-current='page'] {
    border-left-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.background.highlight};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Sizer = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const Container = styled.aside<{ collapsed: boolean }>`
  background-color: ${({ theme }) => theme.colors.common.white};
  border-right: 1px solid ${({ theme }) => theme.colors.border.light};
  min-height: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: none;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;

  // Collapse/Expand transition
  &,
  ${Sizer}, ${NavItem}, ${NavLink}, ${NavItemLabel}, ${NavItemIcon}, ${Context}, ${ProjectName} {
    transition-property: opacity, width, height, padding, margin, background;
    transition-duration: 200ms, 250ms, 250ms, 250ms, 250ms, 250ms;
    transition-timing-function: ease-in-out;
  }

  ${styleWhenTrue(
    T.prop('collapsed'),
    css`
      ${Sizer} {
        width: 48px;
      }

      ${Context} {
        padding: 0 11px;
        opacity: 0;
        pointer-events: none;
      }

      ${ProjectName} {
        height: 16px;
      }

      ${NavItem} {
        border-radius: 0;
      }

      ${NavLink} {
        padding-left: 11px;
      }

      ${NavItemLabel} {
        opacity: 0;
        width: 0;
        margin-left: 0;
      }
    `,
  )}
`;

export const Navigation = styled.nav<{ collapsed: boolean }>`
  transition: all 250ms ease-in-out;
  list-style: none;
  width: calc(100% - 20px);
  flex: 1;

  ${NavItemIcon} {
    fill: currentColor;
  }

  // Collapse/Expand transition
  ${({ collapsed }) =>
    collapsed &&
    css`
      width: 100%;
    `}
`;

export const Footer = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  position: sticky;
  flex: none;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.common.white};

  ${NavItem} {
    width: calc(100% - 20px);
  }

  ${NavLink} {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 14px;
  }

  ${NavItemIcon} {
    transform: rotate(-90deg);
    stroke: currentColor;
  }

  // Collapse/Expand transition
  ${({ collapsed }) =>
    collapsed &&
    css`
      ${NavItem} {
        width: 100%;
      }

      ${NavItemIcon} {
        transform: rotate(90deg);
      }
    `}
`;
