import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import localeData from 'dayjs/locale.json';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import TextArea from '~/components/TextArea';
import useActionState from '~/hooks/useActionState';
import { SELECT_STATE } from '~/modules/constans';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectSingleProject } from '~/modules/project/project.selectors';
import * as validators from '~/utils/validators';
import {
  SettingsDescriptionWrapper,
  SettingsDescription,
  Row,
  Column,
  HelpIcon,
  Label,
  Description,
  SubmitButton,
  Select,
  Form,
  ButtonsWrapper,
  ErrorInfo,
} from '~/views/ProjectSettings/ProjectSettings.styled';

import { Container } from './BrowserSettings.styled';

const browserSettingsSchema = Yup.object().shape({
  browserLanguage: validators.browserLanguageValidator,
  browserCustomHeaders: Yup.string()
    .optional()
    .max(256, validators.VALIDATION_MESSAGE.MAX_LENGTH)
    .ensure(),
  userAgent: Yup.string().optional().max(256, validators.VALIDATION_MESSAGE.MAX_LENGTH).ensure(),
});

const BrowserSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(selectSingleProject);

  const handleOnSubmit = (values) => {
    dispatch(ProjectActions.updateWindowAndBrowserSettingsRequest(values));
  };

  const [lang, region] = project.settings.browserLanguage.toLowerCase().split('-');

  const language = lang === region ? lang : project.settings.browserLanguage;

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    submitForm,
    setSubmitting,
    setErrors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      incognitoMode: project.settings.incognitoMode,
      browserCustomHeaders: project.settings.browserCustomHeaders
        .map(({ key, value }) => `${key}: ${value}`)
        .join('\n'),
      browserLanguage: language.toLowerCase(),
      userAgent: project.settings.userAgent,
    },
    validationSchema: browserSettingsSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isSuccess, isLoading, hasInternalServerError } = useActionState(
    ProjectActions.updateWindowAndBrowserSettingsRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  const incognitoModeTooltipContent = t(
    'projectSettings.browserSettings.form.incognitoMode.tooltipContent',
    'Disable incognito mode to test session, local storage persistance, cookies etc.',
  );

  return (
    <Container data-testid="BrowserSettings">
      <SettingsDescriptionWrapper>
        <SettingsDescription data-testid="SettingsDescription">
          {t(
            'projectSettings.browserSettings.description',
            'Affects both recording and running tests',
          )}
        </SettingsDescription>
      </SettingsDescriptionWrapper>
      <Form noValidate data-testid="browserSettings.Form" onSubmit={handleSubmit}>
        <Row>
          <Column>
            <Tooltip content={incognitoModeTooltipContent}>
              <Label>
                {t('projectSettings.browserSettings.form.incognitoMode.label', 'Incognito mode')}
                <HelpIcon data-testid="HelpIcon" />
              </Label>
            </Tooltip>
          </Column>
          <Column>
            <Select
              name="incognitoMode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.incognitoMode.toString()}
              fullWidth
            >
              <SelectOption value={SELECT_STATE.ENABLED}>
                {t('default.select.enabled')}
              </SelectOption>
              <SelectOption value={SELECT_STATE.DISABLED}>
                {t('default.select.disabled')}
              </SelectOption>
            </Select>
          </Column>
        </Row>
        <Row>
          <Column>
            <Label>
              {t('projectSettings.browserSettings.form.browserLanguage.label', 'Browser language')}
            </Label>
            <Description>
              {t(
                'projectSettings.browserSettings.form.browserLanguage.description',
                'Force browser language code',
              )}
            </Description>
          </Column>
          <Column>
            <Select
              name="browserLanguage"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.browserLanguage}
              fullWidth
              showSearch
              error={touched.browserLanguage && errors.browserLanguage}
            >
              {localeData.map(({ key, name }) => (
                <SelectOption key={key} value={key}>
                  {name}
                </SelectOption>
              ))}
            </Select>
          </Column>
        </Row>

        <Row>
          <Column>
            <Label>{t('projectSettings.browserSettings.form.userAgent.label', 'User agent')}</Label>
            <Description>
              {t(
                'projectSettings.browserSettings.form.userAgent.description',
                'Force browser user agent',
              )}
            </Description>
          </Column>
          <Column>
            <Input
              type="text"
              name="userAgent"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userAgent}
              error={touched.userAgent && errors.userAgent}
              fullWidth
            />
          </Column>
        </Row>

        <Row>
          <Column>
            <Label>
              {t(
                'projectSettings.browserSettings.form.customHeaders.label',
                'HTTP request headers',
              )}
            </Label>
            <Description>
              <Trans i18nKey="projectSettings.browserSettings.form.customHeaders.description">
                Add custom{' '}
                <Link
                  to={t(
                    'default.docs.customHeaders',
                    'https://en.wikipedia.org/wiki/List_of_HTTP_header_fields',
                  )}
                >
                  headers
                </Link>{' '}
                to each request
              </Trans>
            </Description>
          </Column>
          <Column>
            <TextArea
              name="browserCustomHeaders"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.browserCustomHeaders}
              error={touched.browserCustomHeaders && errors.browserCustomHeaders}
              fullWidth
              autoSize
              placeholder={t(
                'projectSettings.browserSettings.form.customHeaders.placeholder',
                'my-header-1: my-example-value\nmy-header-2: my-example-value',
              )}
            />
          </Column>
        </Row>

        <ButtonsWrapper>
          <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
            {t('default.button.save')}
          </SubmitButton>
          <ErrorInfo isVisible={hasInternalServerError} onRetry={submitForm} />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
};

export default BrowserSettings;
