import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Dropdown, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { PointerEventsCatcher } from '@bugbug/core/components/Tooltip';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  ${PointerEventsCatcher} + ${PointerEventsCatcher} {
    margin-left: 9px;
  }
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))``;

export const RunButton = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: false,
}))`
  margin-right: 9px;
`;

export const RecordButton = styled(Button)`
  svg {
    font-size: 16px;
  }
`;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
}))`
  margin-left: 9px;
`;

export const CombinedButtons = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    min-width: 113px;
    justify-content: center;
    margin: 0;

    > * {
      flex: none;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-right-width: 2px;

      &:not(:nth-child(2)) {
        border-left-width: 0;
      }
    }
  }
`;

export const CloudRunIcon = styled(Icon).attrs(() => ({
  name: 'cloudRun',
}))`
  width: 100%;
  height: 100%;
`;
