import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';

import * as S from './TestActions.styled';

interface RunningButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

const RunningButtons = ({ testId, testRunId }: RunningButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });

  return (
    <S.CombinedButtons>
      <S.RecordButton onClick={testRunner.stop} iconName="stop">
        {t('testDetails.testActions.stopButton', 'Stop')}
      </S.RecordButton>
      <S.RecordButton disabled={testRunner.isCloudRun} onClick={testRunner.pause} iconName="pause">
        {t('testDetails.testActions.pauseButton', 'Pause')}
      </S.RecordButton>
      <S.Button disabled={!testRunner.isDebugging} onClick={testRunner.runNextStep} iconName="next">
        {t('testDetails.testActions.nextStepButton', 'Next step')}
      </S.Button>
    </S.CombinedButtons>
  );
};

export default RunningButtons;
