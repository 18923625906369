import { useEffect } from 'react';
import { usePrevious } from 'react-use';

import type { Step } from '@bugbug/core/types/steps';
import SidebarView from '~/components/SidebarView';
import useModal from '~/hooks/useModal';
import { useAppSelector } from '~/modules/store';
import { selectCurrentStepId } from '~/modules/test/test.selectors';
import { selectFirstTestRunPassed } from '~/modules/user/user.selectors';

import Content from './components/Content';
import Sidebar from './components/TestSidebar';

const TestDetails = () => {
  const currentStepId = useAppSelector(selectCurrentStepId) as Step['id'];
  const firstTestRunPassed = useAppSelector(selectFirstTestRunPassed) as boolean;
  const prevTestRunPassed = usePrevious(firstTestRunPassed);

  const modal = useModal();
  useEffect(() => {
    if (prevTestRunPassed === undefined || prevTestRunPassed || !firstTestRunPassed) return;

    modal.show('first_successful_run');
  }, [firstTestRunPassed, modal, prevTestRunPassed]);

  return (
    <SidebarView
      prefix="testDetails"
      sidebarAlwaysVisible={!!currentStepId}
      Content={Content}
      Sidebar={Sidebar}
    />
  );
};

export default TestDetails;
