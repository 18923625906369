import CopyButton from '@bugbug/core/components/CopyButton';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import { SELECT_TYPE, SELECT_TYPE_LABEL } from '~/constants/step';

import { ComputedField, ComputedValue } from '../Interaction/Interaction.styled';

import { FIELD_NAMES } from './SelectActionFields.constants';
import { Checkbox } from './SelectActionFields.styled';

const SelectActionFields = ({ context, stepRun, readOnly = false }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error };
    },
    [formik],
  );

  return (
    <>
      <FormField label={t('stepDetails.interaction.selectType', 'Select option by')}>
        <Select
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.TYPE)}
          onChange={formik.handleChange}
          disabled={readOnly}
        >
          {Object.values(SELECT_TYPE).map((type) => (
            <SelectOption key={type} value={type}>
              {SELECT_TYPE_LABEL[type]}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      <FormField>
        <Checkbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.IS_MULTIPLE)}
          onChange={formik.handleChange}
          checked={!!formik.values[FIELD_NAMES.IS_MULTIPLE]}
          disabled={readOnly}
        >
          {t('stepDetails.interaction.isMultiple', 'Select multiple options')}
        </Checkbox>
      </FormField>
      <FormField
        label={
          formik.values[FIELD_NAMES.IS_MULTIPLE]
            ? t('stepDetails.interaction.values.label', 'Multiple values - each in a new line')
            : t('stepDetails.interaction.value.label', 'Value')
        }
        labelId={FIELD_NAMES.VALUE}
      >
        <FormikInputWithVariables
          as="textarea"
          name={FIELD_NAMES.VALUE}
          fullWidth
          readOnly={readOnly}
          placeholder={
            formik.values[FIELD_NAMES.IS_MULTIPLE]
              ? t(
                  'stepDetails.interaction.values.placeholder',
                  'Example value 1\nExample value 2\nExample value 3',
                )
              : t('stepDetails.interaction.value.placeholder', 'Enter value')
          }
          autoSize
        />
        <CopyButton value={formik.values[FIELD_NAMES.VALUE]} />
      </FormField>
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.interaction.thisComputedValue')
              : t('stepDetails.interaction.lastComputedValue')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
    </>
  );
};

SelectActionFields.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  stepRun: PropTypes.object.isRequired,
};

export default SelectActionFields;
