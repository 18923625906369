import { DropdownItem, DROPDOWN_VARIANT, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { VisibleOnRowHover } from '~/components/Table';
import useModal from '~/hooks/useModal';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { QUERY_ACTION } from '~/modules/constans';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import useRefreshSuiteRunsHistory from '~/views/RunsHistory/hooks/useRefreshSuiteRunsHistory';

import { Container, Dropdown, IconButton } from './SuiteRunRowActions.styled';

const SuiteRunRowActions = ({ className, row }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const suiteRun = row.original;
  const containerRef = useRef();
  const isRunning = isRunningStatus(suiteRun.status);
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const onDelete = useRefreshSuiteRunsHistory();
  const updateQueryString = useUpdateQueryString();

  const handleStopRunning = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(SuiteRunActions.stopRequest([suiteRun.id]));
    },
    [dispatch, suiteRun.id],
  );

  const handleDeleteClick = useCallback(() => {
    modal.show('delete_suite_runs', {
      onDelete,
      suiteRunsIds: [suiteRun.id],
    });
  }, [modal, onDelete, suiteRun.id]);

  const handleGoToSuite = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_SUITE);
    updateQueryString({ action: QUERY_ACTION.SHOW_SUITE_DETAILS, suiteId: suiteRun.suiteSourceId });
  }, [suiteRun.suiteSourceId, updateQueryString]);

  const hiddenActions = useMemo(
    () => [
      {
        name: t('suiteRunRowActions.goToSuite.label', 'Go to suite'),
        onClick: handleGoToSuite,
        iconName: 'cornerDownRight',
      },
      {
        name: t('suiteRunRowActions.remove.label', 'Clear from history'),
        onClick: handleDeleteClick,
        iconName: 'delete',
        danger: true,
      },
    ],
    [t, handleGoToSuite, handleDeleteClick],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <Container ref={containerRef} className={className} data-testid="SuiteRunRowActions">
      {isRunning && (
        <VisibleOnRowHover force={isRunning || areActionsLocked}>
          <IconButton data-testid="SuiteRunRowActions.StopButton" onClick={handleStopRunning}>
            <Icon name="stop" />
          </IconButton>
        </VisibleOnRowHover>
      )}
      <VisibleOnRowHover force={areActionsLocked}>
        <Dropdown
          variant={DROPDOWN_VARIANT.ICON}
          anchor={DROPDOWN_ANCHOR.BOTTOM_END}
          iconName="more"
          onOpen={lockActionButtons}
          onClose={unlockActionButtons}
        >
          {hiddenActions.map(
            ({
              name,
              onClick,
              iconName,
              disabled,
              dataTestId,
              to,
              Component = DropdownItem,
              danger,
            }) => (
              <Component
                data-testid={dataTestId}
                key={name}
                disabled={disabled}
                onClick={onClick}
                to={to}
                danger={danger}
              >
                <Icon name={iconName} /> {name}
              </Component>
            ),
          )}
        </Dropdown>
      </VisibleOnRowHover>
    </Container>
  );
};

export default SuiteRunRowActions;
