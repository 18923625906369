import Icon from '@bugbug/core/components/Icon';
import Loader from '@bugbug/core/components/Loader';
import React from 'react';

import { IconButton } from './RunButton.styled';

const RunTestButton = (props) => {
  const {
    disabled = false,
    label = '',
    className = '',
    running = false,
    queued = false,
    onClick,
  } = props;

  const iconName = running ? 'stop' : 'play';

  return (
    <IconButton
      className={className}
      data-testid="RunButton"
      onClick={onClick}
      disabled={disabled}
      pending={running}
      tooltip={label}
      aria-label={label}
    >
      {running && <Loader stopped={queued} />}
      <Icon name={iconName} />
    </IconButton>
  );
};

export default RunTestButton;
