import { isRunningStatus } from '@bugbug/core/types/base';
import { path } from 'ramda';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import { RUN_ENV } from '~/modules/constans';
import { selectHasMoreThanOneProfile } from '~/modules/profile/profile.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { SuiteActions } from '~/modules/suite/suite.redux';
import { selectSuiteRun } from '~/modules/suiteRun/suiteRun.selectors';
import { selectUserId } from '~/modules/user/user.selectors';
import { isTriggeredByCurrentUser } from '~/utils/runs';

const useSuiteRunner = (suite = {}, config = {}) => {
  const dispatch = useDispatch();
  const extension = useExtensionState();
  const modal = useModal();
  const lastUnfinishedRunId = path(['lastUnfinishedUserRun', 'id'], suite);
  const suiteRunId = config.suiteRunId || lastUnfinishedRunId;
  const project = useSelector(selectSingleProject);
  const suiteRunResult = useSelector(selectSuiteRun(suiteRunId));
  const hasMoreThanOneProfile = useSelector(selectHasMoreThanOneProfile);
  const currentUserId = useSelector(selectUserId);
  const status = suiteRunResult.status || path(['lastUnfinishedUserRun', 'status'], suite);

  const isRunning =
    isRunningStatus(status) &&
    isTriggeredByCurrentUser(suiteRunResult, currentUserId, !!lastUnfinishedRunId);
  const isIdle = !isRunning;

  const openExtensionModal = useCallback(
    () => modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION),
    [modal],
  );

  const openIncognitoTabsModal = useCallback(
    (onConfirm) => modal.show(MODAL_TYPE.INCOGNITO_TABS_CLOSE, { onConfirm }),
    [modal],
  );

  const start = useCallback(
    async ({ withProfileSelection, ...options } = {}) => {
      const continueRun = (profileId = options.runProfileId) =>
        dispatch(
          SuiteActions.runRequest(suite.id, {
            runMode: RUN_ENV.SERVER,
            ...options,
            runProfileId: profileId,
          }),
        );

      const showIncognitoWindowsModalIfNeeded = async (profileId) => {
        if (options.runMode === RUN_ENV.LOCAL) {
          const hasActiveIncognitoWindows = await extension.hasActiveIncognitoWindows();
          if (hasActiveIncognitoWindows) {
            openIncognitoTabsModal(continueRun);
            return;
          }
        }
        continueRun(profileId);
      };

      if (hasMoreThanOneProfile) {
        modal.show(MODAL_TYPE.RUN_WITH_PROFILE, {
          initialProfileId: suite.runProfileId,
          onSuccess: showIncognitoWindowsModalIfNeeded,
        });
        return;
      }

      await showIncognitoWindowsModalIfNeeded();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suite.id, dispatch, modal.show, hasMoreThanOneProfile, suite.runProfileId],
  );

  const startLocal = useCallback(
    (options = {}) => {
      if (!extension.isActive) {
        openExtensionModal();
        return null;
      }

      return start({ ...options, runMode: RUN_ENV.LOCAL });
    },
    [start, openExtensionModal, extension.isActive],
  );

  const stop = useCallback(() => {
    dispatch(SuiteActions.stopRequest([suite.id]));
  }, [suite, dispatch]);

  return {
    start,
    startLocal,
    stop,
    incognitoMode: project.settings.incognitoMode,
    isExtensionConnected: extension.isConnected,
    isExtensionActive: extension.isActive,
    isExtensionAllowedIncognitoAccess: extension.hasIncognitoAccess,
    isIncognitoModeRequired: !!project.settings.incognitoMode,
    isIdle,
    isRunning,
  };
};

export default useSuiteRunner;
