import Link from '@bugbug/core/components/Link';
import Tooltip from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectStepRunExecutionUrl } from '~/modules/testRun/testRun.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Content, Text, InfoIcon } from './StepRunExecutionUrl.styled';

const StepRunExecutionUrl = ({ stepId }) => {
  const { t } = useTranslation();
  const executionUrl = useSelector(selectStepRunExecutionUrl(stepId));

  const trackScreenshotClick = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.EXECUTION_URL_CLICKED);
  }, []);

  if (!executionUrl) {
    return null;
  }

  return (
    <Content>
      <Link to={executionUrl} openNewTab onClick={trackScreenshotClick}>
        <Tooltip content={t('stepExecutionUrl.infoTooltip.label', 'Captured at this URL')}>
          <InfoIcon />
        </Tooltip>
        <Text>{executionUrl}</Text>
      </Link>
    </Content>
  );
};

StepRunExecutionUrl.defaultProps = {};

StepRunExecutionUrl.propTypes = {
  stepId: PropTypes.string.isRequired,
};

export default StepRunExecutionUrl;
