import * as TablePartials from '~/components/Table';
import i18n from '~/translations';

import ComponentRowActions from '../ComponentRowActions';

export const COMPONENTS_LIST_COLUMN_ID = {
  NAME: 'name',
  TESTS_COUNT: 'testsCount',
  ACTIONS: 'actions',
};

export const COMPONENTS_LIST_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('componentsList.table.columns.name', 'Name'),
    accessor: 'name',
    id: COMPONENTS_LIST_COLUMN_ID.NAME,
    Cell: TablePartials.TextCell,
  },
  {
    Header: i18n.t('componentsList.table.columns.relatedTestsNumber', 'Related tests'),
    accessor: 'testsCount',
    id: COMPONENTS_LIST_COLUMN_ID.TESTS_COUNT,
    Cell: TablePartials.TextCell,
  },
  {
    Header: i18n.t('componentsList.table.columns.actions', 'Actions'),
    Cell: ComponentRowActions,
    id: COMPONENTS_LIST_COLUMN_ID.ACTIONS,
    disableSortBy: true,
    maxWidth: 85,
  },
];
