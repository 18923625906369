import Tooltip from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { TextCell } from '~/components/Table';
import urls, { reverse } from '~/views/urls';

import { Container } from './SuiteRunCell.styled';

const SuiteRunCell = ({ className, value }) => {
  const { t } = useTranslation();
  const { organizationId, projectId, projectSlug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const suiteRunId = path(['id'], value);
  const sequence = path(['sequence'], value);
  const name = path(['suite', 'name'], value);
  const label = sequence ? t('suiteRunCell.value', '#{{ sequence }}', { sequence }) : sequence;

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();

      const pathname = reverse(urls.suiteRun, {
        organizationId,
        projectId,
        projectSlug,
        suiteRunId,
      });
      history.push({ pathname, state: { backUrl: location.pathname } });
    },
    [history, location.pathname, organizationId, projectId, projectSlug, suiteRunId],
  );

  return (
    <Tooltip content={name}>
      <Container
        className={className}
        aria-label={t('suiteRunCell.label', 'Suite run #{{ sequence }}', { sequence })}
        tabindex="0"
        role="link"
        onClickCapture={suiteRunId ? handleClick : null}
      >
        <TextCell value={label} />
      </Container>
    </Tooltip>
  );
};

SuiteRunCell.defaultProps = {
  className: null,
  value: null,
};

SuiteRunCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    sequence: PropTypes.number,
    suite: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default SuiteRunCell;
