import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { Tag } from '@bugbug/core/theme/typography';

import type { GridItemAction } from './GridItem.types';
import type { ReactNode } from 'react';

import { Container, Header, Headline, Description, Actions } from './GridItem.styled';

interface GridItemProps {
  className?: string;
  title: ReactNode;
  description?: ReactNode;
  tag?: string;
  actions?: GridItemAction[];
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const GridItem = ({ className, title, description, actions = [], tag, onClick }: GridItemProps) => (
  <Container className={className} onClick={onClick} role="gridcell">
    <Header>
      <Headline>{title}</Headline>
      <Actions>
        {tag && <Tag>{tag}</Tag>}
        {actions.length && (
          <Dropdown variant={DROPDOWN_VARIANT.ICON} iconName="more">
            {actions.map(({ iconName, onClick: onItemClick, label, disabled, danger }) => (
              <DropdownItem onClick={onItemClick} disabled={disabled} key={label} danger={danger}>
                <Icon name={iconName} /> {label}
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </Actions>
    </Header>
    {description && <Description>{description}</Description>}
  </Container>
);

export default GridItem;
