import PropTypes from 'prop-types';
import React from 'react';

import { Container, Index, Name } from './IndexedNameCell.styled';

const IndexedNameCell = ({ className, value, row }) => {
  const { sequence } = row.original;

  return (
    <Container className={className} data-testid="IndexedNameCell">
      <Index>#{sequence}</Index>
      <Name>{value}</Name>
    </Container>
  );
};

IndexedNameCell.defaultProps = {
  className: null,
};

IndexedNameCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      sequence: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default IndexedNameCell;
