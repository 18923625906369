import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectSingleTestRun } from '~/modules/testRun/testRun.selectors';

import * as S from './LogsInfo.styled';

const LogsInfo = ({ className }) => {
  const { t } = useTranslation();
  const { logsArtifactUrl } = useSelector(selectSingleTestRun);

  return logsArtifactUrl ? (
    <S.Container className={className}>
      <a target="_blank" href={logsArtifactUrl} rel="noreferrer">
        <S.LinkIcon /> {t('logsInfo.label', 'Show test run logs')}
      </a>
    </S.Container>
  ) : null;
};

LogsInfo.defaultProps = {
  className: null,
};

LogsInfo.propTypes = {
  className: PropTypes.string,
};
export default LogsInfo;
