import i18n from '~/translations';

import { AddComponentIcon, AddIcon, PasteIcon } from './EmptyStateNoRecording.styled';

export const ACTION = {
  RECORD: 'record',
  NEW_STEP: 'newStep',
  PASTE_STEPS: 'pasteSteps',
  EXISTING_COMPONENT: 'existingComponent',
};

export const ACTIONS = [
  {
    key: ACTION.NEW_STEP,
    label: i18n.t(
      'testDetailsEmptyState.noRecording.actionTitle.newStep',
      'Add a new step manually',
    ),
    Icon: AddIcon,
  },
  {
    key: ACTION.EXISTING_COMPONENT,
    label: i18n.t(
      'testDetailsEmptyState.noRecording.actionTitle.existingComponent',
      'Insert an existing component',
    ),
    Icon: AddComponentIcon,
  },
  {
    key: ACTION.PASTE_STEPS,
    label: i18n.t('testDetailsEmptyState.noRecording.actionTitle.pasteSteps', 'Paste step(s)'),
    Icon: PasteIcon,
  },
];

export const ANIMATED_ELEMENTS_LENGTH = ['title', 'record', ...ACTIONS].length;

export const ANIMATED_ELEMENTS_FOR_FIRST_RECORDING_LENGTH = ['title', 'description', 'record']
  .length;
