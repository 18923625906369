import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { lineClamp } from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const MetaValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  opacity: 0.5;
  ${lineClamp({ lines: 2 })}

  &:hover {
    opacity: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    flex: none;
  }
`;
