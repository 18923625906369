import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { MODAL_TYPE } from '~/components/modals';
import StepName from '~/components/StepName';
import { ASSERTION_PROPERTY_TYPE, STEP_TYPE } from '~/constants/step';
import useModal from '~/hooks/useModal';

import { Container, ExpandIcon } from './StepTypePickerInput.styled';

const StepTypePickerInput = (props) => {
  const {
    'aria-labelledby': ariaLabelledBy,
    className,
    name,
    value,
    assertionPropertyValue,
    assertionVariantValue,
    onChange,
    readOnly,
  } = props;
  const modal = useModal();

  const handleChange = useCallback(
    (stepType, assertionProperty) => {
      onChange({
        target: { name, value: stepType, assertionProperty },
      });
    },
    [name, onChange],
  );

  const handleClick = useCallback(() => {
    modal.showWide(MODAL_TYPE.EDIT_STEP, {
      stepType: value,
      assertionProperty: assertionPropertyValue,
      assertionVariant: assertionVariantValue,
      onStepChange: handleChange,
    });
  }, [modal, value, assertionPropertyValue, handleChange, assertionVariantValue]);

  return (
    <Container
      className={className}
      data-testid="StepTypePickerInput"
      readOnly={readOnly}
      disabled={readOnly}
      aria-labelledby={ariaLabelledBy}
      onFocus={handleClick}
      onClick={handleClick}
    >
      <StepName type={value} assertType={assertionPropertyValue} />
      <ExpandIcon />
    </Container>
  );
};

StepTypePickerInput.defaultProps = {
  name: null,
  'aria-labelledby': null,
  className: null,
  value: null,
  readOnly: null,
  assertionPropertyValue: undefined,
  onChange: Function.prototype,
};

StepTypePickerInput.propTypes = {
  name: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  className: PropTypes.string,
  assertionPropertyValue: PropTypes.oneOf(Object.values(ASSERTION_PROPERTY_TYPE)),
  value: PropTypes.oneOf(Object.values(STEP_TYPE)),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default StepTypePickerInput;
