import * as Ariakit from '@ariakit/react';
import { DropdownHeader } from '@bugbug/core/components/Dropdown/Dropdown.styled';
import Input from '@bugbug/core/components/Input/Input';
import * as T from '@bugbug/core/utils/toolbox';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import type { HTMLInputLikeElement, InputLikeBaseProps } from './InputWithVariables.types';
import type React from 'react';

import type { Test } from '@bugbug/core/types/tests';
import type { VariableListItem as VariableListItemType } from '@bugbug/core/types/variables';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useVariables } from '~/hooks/useVariables';
import { useAppSelector } from '~/modules/store';
import { selectSingleTest } from '~/modules/test/test.selectors';

import TextArea from '../TextArea';

import {
  calculateAnchorRect,
  getSearchValue,
  getTrigger,
  getTriggerStartIndex,
  getVariablesNamesInValue,
  hasEnteredFirstCharOfEndToken,
  isBeforeVariableEnd,
  isRevertCommand,
  isVariableEnd,
  variableEndToken,
  variableStartToken,
} from './InputWithVariables.helpers';
import * as S from './InputWithVariables.styled';
import { VariableListItem } from './VariableListItem';
import { VariablesAdornment } from './VariablesAdornment';

export interface InputWithVariableProps extends InputLikeBaseProps {
  name?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputLikeElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputLikeElement>) => void;
}

export const InputWithVariables = ({
  as = 'input',
  value,
  onChange,
  ...props
}: InputWithVariableProps) => {
  const ref = useRef<HTMLInputLikeElement | null>(null);
  const inputScrollRef = useRef<{
    offset: number;
    width: number;
  }>({
    offset: 0,
    width: 0,
  });
  const customCaretPosition = useRef<number | null>(null);

  const [lastCaretPosition, setLastCaretPosition] = useState(0);
  const [anchorRect, setAnchorRect] = useState<{
    x: number;
    y: number;
    height: number;
  } | null>(null);
  const [history, setHistory] = useState<{
    value: string;
    editedVariable: string;
  } | null>(null);

  const setValue = (newValue: string) => {
    if (!ref.current) return;
    ref.current.value = newValue;
    // @ts-expect-error custom onChange event
    onChange({ target: ref.current });
  };

  const combobox = Ariakit.useComboboxStore({
    focusLoop: true,
    includesBaseElement: false,
    placement: 'bottom-start',

    // Clear the search value if the combobox is closed
    setOpen: (open) => {
      if (open || !ref.current) return;

      if (hasEnteredFirstCharOfEndToken(ref.current) || isBeforeVariableEnd(ref.current)) return;

      // If a custom caret position is set, it means that the variable was just autofilled
      if (customCaretPosition.current !== null) return;

      const variableStartIndex = getTriggerStartIndex(ref.current);
      if (variableStartIndex < 0) return;

      customCaretPosition.current = variableStartIndex;
      const textBeforeVar = value.slice(0, variableStartIndex);
      const textAfterVar = value.slice(
        variableStartIndex + variableStartToken.length + searchValue.length,
      );
      setValue(textBeforeVar + textAfterVar);
    },
  });
  const searchValue = combobox.useState('value');
  const isComboboxOpen = combobox.useState('open');

  const setCaretPosition = (position: number) => {
    if (!ref.current) return;
    ref.current.focus();
    ref.current.setSelectionRange(position, position);
    const inputPaddingPx = 10;
    const scrollDiff = ref.current.scrollWidth - inputScrollRef.current.width;
    ref.current.scrollLeft = inputScrollRef.current.offset + scrollDiff + inputPaddingPx;
  };

  // Check if variables list should be triggered
  useEffect(() => {
    const input = ref.current;
    if (!input) return;

    // Browsers update input.selectionStart on keydown events, so we need to override it manually
    if (customCaretPosition.current) {
      setCaretPosition(customCaretPosition.current);
      customCaretPosition.current = null;
    }

    const trigger = getTrigger(input);
    const targetSearchValue = getSearchValue(input);

    if (hasEnteredFirstCharOfEndToken(input)) {
      combobox.hide();
    } else if (trigger) {
      inputScrollRef.current = {
        offset: input.scrollLeft,
        width: input.scrollWidth,
      };
      setAnchorRect(calculateAnchorRect(input));
      combobox.show();
    } else if (!targetSearchValue) {
      combobox.hide();
    }

    combobox.setValue(targetSearchValue);
  }, [combobox, value]);

  // Re-calculates the position of the combobox popover in case the changes on
  // the textarea value have shifted the trigger character.
  useEffect(() => {
    if (!combobox.getState().open) return;
    combobox.render();
  }, [combobox, value]);

  const onSelect = (selectedValue: string) => () => {
    const input = ref.current;
    if (!input) return;

    const startIndex = getTriggerStartIndex(input);

    customCaretPosition.current =
      startIndex + variableStartToken.length + selectedValue.length + variableEndToken.length;

    const variableDefinition = `${variableStartToken}${selectedValue}${
      isBeforeVariableEnd(input) ? '' : variableEndToken
    }`;

    const updatedValue = `${value.slice(0, startIndex)}${variableDefinition}${value.slice(
      startIndex + searchValue.length + variableStartToken.length,
    )}`;
    setValue(updatedValue);
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputLikeElement>) => {
    if (isComboboxOpen && (event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
      event.preventDefault();
      event.stopPropagation();
      combobox.hide();
      return;
    }

    const metaOrCtrl = event.metaKey || event.ctrlKey;

    if (!metaOrCtrl) {
      setHistory(null);
    }

    if (history && metaOrCtrl && event.key === 'z' && ref.current?.selectionEnd) {
      event.preventDefault();
      event.stopPropagation();

      const { selectionEnd } = ref.current;
      customCaretPosition.current = selectionEnd + history.editedVariable.length;
      setValue(history.value);

      setHistory(null);
      return;
    }

    // Remove the whole variable entry if backspace or mod+z is pressed at the end of the token
    if (
      ref.current?.selectionEnd &&
      isVariableEnd(ref.current) &&
      (event.key === 'Backspace' || isRevertCommand(event.nativeEvent))
    ) {
      event.preventDefault();
      event.stopPropagation();

      const variableStartIndex = getTriggerStartIndex(ref.current);
      const variableEndIndex = ref.current.selectionEnd - variableEndToken.length;
      const variable = value.slice(variableStartIndex, variableEndIndex + variableEndToken.length);

      setHistory({
        value,
        editedVariable: variable,
      });

      const valueWithoutVariable =
        value.slice(0, variableStartIndex) +
        value.slice(variableEndIndex + variableEndToken.length);

      customCaretPosition.current = variableStartIndex;
      setValue(valueWithoutVariable);
    }
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputLikeElement> = (event) => {
    props.onBlur?.(event);
    setLastCaretPosition(ref.current?.selectionEnd ?? 0);
  };

  const onAdornmentClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const element = ref.current;
    if (!element) return;

    element.focus();
    customCaretPosition.current = lastCaretPosition + variableStartToken.length;
    setValue(
      `${value.slice(0, lastCaretPosition)}${variableStartToken}${value.slice(lastCaretPosition)}`,
    );
  };

  const filteredVariables = useVariables(searchValue);
  const allVariables = useVariables();
  const [variablesErrors, setVariablesErrors] = useState<
    {
      name: string;
      reason: 'uninitialized' | 'notFound';
    }[]
  >([]);
  const { buildIn, custom, local } = filteredVariables;
  const variablesAmount = local.length + custom.length + buildIn.length;

  const [debouncedValue] = useDebounce(value, 300, { leading: true });
  const insertedVariables = useMemo(
    () => new Set(getVariablesNamesInValue(debouncedValue)),
    [debouncedValue],
  );
  const prevInsertedVariables = usePrevious(insertedVariables);
  const currentTest = useAppSelector(selectSingleTest) as Test | null;
  const disableVariableParser = T.isEmpty(currentTest);

  // Validate inserted variables
  useEffect(() => {
    if (disableVariableParser || T.equals(insertedVariables, prevInsertedVariables)) return;
    const errors: typeof variablesErrors = [];
    const variablesArray = Object.values(allVariables).flat();

    insertedVariables.forEach((variable) => {
      const foundVariable = variablesArray.find((item) => item.key === variable);
      if (!foundVariable) {
        errors.push({
          name: variable,
          reason: 'notFound',
        });
        return;
      }

      if (foundVariable.uninitialized) {
        errors.push({
          name: variable,
          reason: 'uninitialized',
        });
      }
    });

    setVariablesErrors(errors);
  }, [allVariables, disableVariableParser, insertedVariables, prevInsertedVariables]);

  const isListVisible = combobox.useState('open');
  const InputElement = as === 'input' ? Input : TextArea;
  const { t } = useTranslation(undefined, { keyPrefix: 'variablesCombobox' });
  const { getRouteUrl } = useAppRoutes('test');

  const adornment = (
    <VariablesAdornment
      onClick={props.readOnly ? undefined : onAdornmentClick}
      hideTooltip={isListVisible}
      container={as}
      aria-label={t('openVariablesList', 'Open variables list')}
    />
  );

  const getCustomVariableLabel = (variable: VariableListItemType) => {
    switch (true) {
      case variable.type === 'evaluate':
        return t('jsValueLabel', 'Evaluated while running');
      case variable.type === 'element':
        return t('elementValueLabel', 'From element');
      case variable.hasSecretValue:
        return '*******';
      default:
        return variable.value;
    }
  };

  const updateAnchorRect = useDebouncedCallback(() => {
    if (!ref.current) return;
    setAnchorRect(calculateAnchorRect(ref.current));
  });

  useEffect(() => {
    window.addEventListener('scroll', updateAnchorRect, { capture: true });
    return () => {
      window.removeEventListener('scroll', updateAnchorRect, { capture: true });
    };
  }, [updateAnchorRect]);

  return (
    <>
      <Ariakit.Combobox
        autoSelect
        store={combobox}
        value={value}
        showOnClick={false}
        showOnChange={false}
        showOnKeyPress={false}
        setValueOnChange={false}
        render={
          <InputElement
            {...props}
            value={value}
            onScroll={combobox.render}
            onChange={onChange}
            onKeyDown={onKeyDownHandler}
            onBlur={onBlurHandler}
            endAdornment={adornment}
            rightAdornment={adornment}
            // @ts-expect-error incompatible types, but still HTMLInputLikeElement
            ref={ref}
          />
        }
      />
      <Ariakit.ComboboxPopover
        fixed
        store={combobox}
        unmountOnHide
        fitViewport
        getAnchorRect={() => anchorRect}
        render={<S.VariablesDropdownContainer />}
        gutter={4}
      >
        <S.InsertVariableHeader>{t('insertVariable', 'Insert variable')}</S.InsertVariableHeader>
        {local.length > 0 && (
          <>
            <Ariakit.ComboboxLabel store={combobox} render={<DropdownHeader />}>
              {t('localVariables', 'Local variables')}
            </Ariakit.ComboboxLabel>
            <Ariakit.ComboboxGroup>
              {local.map((variable) => (
                <VariableListItem
                  key={variable.id}
                  value={variable.key}
                  description={getCustomVariableLabel(variable)}
                  onClick={onSelect(variable.key)}
                />
              ))}
            </Ariakit.ComboboxGroup>
          </>
        )}
        {custom.length > 0 && (
          <>
            <Ariakit.ComboboxLabel store={combobox} render={<DropdownHeader />}>
              {t('customVariables', 'Custom variables')}
            </Ariakit.ComboboxLabel>
            <Ariakit.ComboboxGroup>
              {custom.map((variable) => (
                <VariableListItem
                  key={variable.id}
                  value={variable.key}
                  description={getCustomVariableLabel(variable)}
                  onClick={onSelect(variable.key)}
                />
              ))}
            </Ariakit.ComboboxGroup>
          </>
        )}
        {buildIn.length > 0 && (
          <>
            <Ariakit.ComboboxLabel store={combobox} render={<DropdownHeader />}>
              {t('builtInVariables', 'Built-in variables')}
            </Ariakit.ComboboxLabel>
            <Ariakit.ComboboxGroup>
              {buildIn.map((variable) => (
                <VariableListItem
                  key={variable.id}
                  value={variable.key}
                  description={variable.description}
                  onClick={onSelect(variable.key)}
                />
              ))}
            </Ariakit.ComboboxGroup>
          </>
        )}
        {variablesAmount === 0 && (
          <S.EmptyState>{t('noVariablesFound', 'No variables found')}</S.EmptyState>
        )}
      </Ariakit.ComboboxPopover>
      {variablesErrors.map((error) => (
        <S.VariableError key={error.name}>
          <S.VariableErrorTitle>
            <Trans i18nKey="variableDoesNotExist">Variable "{error.name}" does not exist</Trans>
          </S.VariableErrorTitle>
          <p>
            <Trans i18nKey="setVariableFirst">
              Set the value of the variable in the previous test steps or define it in{' '}
              <Link to={getRouteUrl('customVariables')}>project variables</Link>.
            </Trans>
          </p>
        </S.VariableError>
      ))}
    </>
  );
};
