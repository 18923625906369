import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import memoize from 'lodash.memoize';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAppRoutes from '~/hooks/useAppRoutes';
import { selectProfilesList } from '~/modules/profile';
import { useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';

import * as S from './ProfilePicker.styled';

const trackProfilePicker = memoize((profileName) => () => {
  analytics.trackEvent(TRACK_EVENT_TYPE.CHANGE_PROFILE, {
    [TRACK_EVENT_ARG_TYPE.NEXT_PROFILE_NAME]: profileName,
  });
});

const ProfilePicker = ({ className, name, value, onChange, fullWidth, container }) => {
  const { t } = useTranslation();

  const profiles = useAppSelector(selectProfilesList);
  const { push } = useAppRoutes('profiles');

  const handleCreateProfile = useCallback(() => {
    push('newProfile');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push]);

  const handleProfileClick = useCallback(
    (id) => () => {
      onChange({ target: { name, value: id } });
      trackProfilePicker();
    },
    [onChange, name],
  );

  const handleProfileEditClick = useCallback(
    (index) => (event) => {
      event.stopPropagation();
      const profile = profiles[index] || {};
      push('profile', { profileId: profile.id });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [push, profiles],
  );

  const items = useMemo(
    () =>
      profiles.map((profile, index) => ({
        ...profile,
        name: (
          <>
            {profile.name}
            {profile.isDefault ? <S.Tag>default</S.Tag> : null}
          </>
        ),
        onClick: handleProfileClick(profile.id),
        onEdit: handleProfileEditClick(index),
      })),
    [profiles, handleProfileClick, handleProfileEditClick],
  );

  const header = (
    <S.Label>
      <S.Name>{t('profilePicker.name', 'Profiles')}</S.Name>
      <Tooltip
        hideOnScroll
        content={t(
          'profilePicker.tooltip',
          'Profiles enable you to efficiently work with variables among multiple testing environments.',
        )}
      >
        <S.IconWrapper>
          <Icon name="help" />
        </S.IconWrapper>
      </Tooltip>

      <S.LearnMore to={t('default.docs.profiles')} target="_blank" rel="noreferrer">
        {t('profilePicker.learnMore', 'Learn more')}
      </S.LearnMore>
    </S.Label>
  );

  return (
    <S.DropdownPicker
      header={header}
      className={className}
      fullWidth={fullWidth}
      value={value}
      items={items}
      onCreateClick={handleCreateProfile}
      onCreateLabel={t('profilePicker.create', 'Create new profile')}
      container={container}
    />
  );
};

ProfilePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  container: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
};

export default ProfilePicker;
