import SwitchBase from '@bugbug/core/components/Switch';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Headline = styled.div`
  flex: 1;
  padding-right: 12px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Time = styled.span``;

export const Suites = styled.span`
  color: ${COLOR.DARK_GRAY};
`;

export const Switch = styled(SwitchBase)`
  margin-right: -6px;
`;
