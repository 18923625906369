import styled from 'styled-components';

export const Handler = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 20px;
  margin-left: -3px;

  &:hover {
    background-color: rgba(29, 12, 36, 0.04);
  }
`;
