import Link from '@bugbug/core/components/Link';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectStepRunScreenshot } from '~/modules/testRun/testRun.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Image, EmptyStateLabel } from './StepRunScreenshot.styled';

const StepRunScreenshot = ({ stepId }) => {
  const { t } = useTranslation();
  const screenshot = useSelector(selectStepRunScreenshot(stepId));

  const trackScreenshotClick = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.SCREENSHOT_FULLPAGE_CLICKED);
  }, []);

  if (!screenshot) {
    return (
      <EmptyStateLabel>
        {t('stepRunScreenshot.emptyState.label', 'Screenshot is not available for this step.')}
      </EmptyStateLabel>
    );
  }

  return (
    <Link to={screenshot} openNewTab onClick={trackScreenshotClick}>
      <Image src={screenshot} loading="lazy" />
    </Link>
  );
};

StepRunScreenshot.defaultProps = {};

StepRunScreenshot.propTypes = {
  stepId: PropTypes.string.isRequired,
};

export default StepRunScreenshot;
