import SelectBase from '@bugbug/core/components/Select';
import styled from 'styled-components';

export const Select = styled(SelectBase)`
  button {
    height: auto;
    min-height: 38px;

    span {
      padding: 5px 0;
      word-break: break-all;
      white-space: initial;
    }
  }
`;

export const Fields = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;
  flex: 1;
`;
