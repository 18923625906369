import Link from '@bugbug/core/components/Link';
import { H1 } from '@bugbug/core/theme/typography';
import { RUNS_HISTORY_TAB } from '@bugbug/core/types/tests';
import { useState, useCallback, useRef, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { RunsHistoryTab } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import Tabs, { Tab } from '~/components/Tabs';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { selectRunsHistoryTab, UIStateActions } from '~/modules/uiState';

import RunsHistoryHeader from './components/RunsHistoryActions';
import SelectedRunsActions from './components/SelectedRunsActions';
import SuitesHistory from './components/SuitesHistory';
import TestsHistory from './components/TestsHistory';
import { TAB_LABEL } from './RunsHistory.constants';
import { Container, TitleContainer, Actions } from './RunsHistory.styled';

const RunsHistory = () => {
  const { t } = useTranslation();
  const queryString = useQueryString();
  const runsHistoryRef = useRef<{ refresh: SideEffect; deselectAll: SideEffect }>();
  const updateQueryString = useUpdateQueryString();

  const [selectedRuns, setSelectedRuns] = useState([]);

  const routes = useAppRoutes('runsHistory');

  const lastTab = useAppSelector(selectRunsHistoryTab);
  const selectedTab = RUNS_HISTORY_TAB.find((path) => routes.isRoute(path)) ?? lastTab;

  const handleTabChange = useCallback<SideEffect<unknown, RunsHistoryTab>>(
    (_, value) => {
      if (selectedTab === value) return;

      setSelectedRuns([]);
      updateQueryString({ query: '' });
      routes.push(value);
    },
    [selectedTab, routes, updateQueryString],
  );

  const handleQueryChange = useCallback(
    (event) => {
      updateQueryString({ query: event.target.value });
    },
    [updateQueryString],
  );

  const handleDeselectAll = useCallback(() => {
    runsHistoryRef.current?.deselectAll();
  }, []);

  const handleRefresh = useCallback(() => {
    runsHistoryRef.current?.refresh();
  }, []);

  const handleSelectionChange = useCallback(setSelectedRuns, [setSelectedRuns]);

  const onDelete = useCallback(() => {
    handleDeselectAll();
    handleRefresh();
  }, [handleDeselectAll, handleRefresh]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(UIStateActions.setRunsHistoryTab(selectedTab));
  }, [dispatch, selectedTab]);

  return (
    <Container
      wideContent
      data-testid="RunsHistory"
      title={
        <TitleContainer data-testid="TestDetails.Header">
          <H1>{t('runsHistory.title', 'Runs history')}</H1>
          <Actions>
            {selectedRuns.length ? (
              <SelectedRunsActions
                type={selectedTab}
                runs={selectedRuns}
                onDeselect={handleDeselectAll}
                onDelete={onDelete}
              />
            ) : (
              <RunsHistoryHeader
                type={selectedTab}
                query={queryString.query}
                onRefresh={handleRefresh}
                onQueryChange={handleQueryChange}
                onDelete={onDelete}
              />
            )}
          </Actions>
        </TitleContainer>
      }
      HeaderContent={
        <>
          <InlineTutorialMessage
            title={t('inlineTutorial.title')}
            message={
              <Trans key="runsHistory.inlineTutorial.message">
                Runs history shows all your executed test and suite runs. Here you can download a
                suite run report, navigate to a specific test issue or stop all running tests.{' '}
                <Link to={t('default.docs.runsHistory')}>Learn more</Link>
              </Trans>
            }
            storageName="runsHistoryGettingStarted"
          />
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {RUNS_HISTORY_TAB.map((tab) => (
              <Tab key={tab} label={TAB_LABEL[tab]} value={tab} />
            ))}
          </Tabs>
        </>
      }
    >
      {selectedTab === 'testRunsList' && (
        <TestsHistory
          ref={runsHistoryRef}
          // @ts-expect-error Missing types
          query={queryString.query}
          onSelectionChange={handleSelectionChange}
        />
      )}
      {selectedTab === 'suiteRunsList' && (
        <SuitesHistory
          ref={runsHistoryRef}
          // @ts-expect-error Missing types
          query={queryString.query}
          onSelectionChange={handleSelectionChange}
        />
      )}
    </Container>
  );
};

export default RunsHistory;
