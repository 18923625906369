import { useSelector } from 'react-redux';

import type { Alert } from '@bugbug/core/types/alerts';
import type { Project } from '@bugbug/core/types/projects';
import { ACTIONS_DATA } from '~/constants/alerts';
import { selectSingleProject } from '~/modules/project/project.selectors';

import { getActionSummary } from './AlertItemActionSummary.helpers';
import * as S from './AlertItemActionSummary.styled';

interface AlertItemActionSummaryProps {
  data: Alert['notificationAction'];
}
const AlertItemActionSummary = ({ data: action }: AlertItemActionSummaryProps) => {
  const project = useSelector(selectSingleProject) as Project;

  const { icon, label } = ACTIONS_DATA[action.type];

  return (
    <S.Container>
      <S.IconWrapper>
        <S.Icon name={icon} />
      </S.IconWrapper>
      <S.Wrapper data-testid="AlertItemActionSummary">
        <S.Title>{label}</S.Title>
        <S.Content>{getActionSummary(action, project)}</S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

export default AlertItemActionSummary;
