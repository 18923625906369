import { DATA_RESTRICTIONS } from '@bugbug/core/constants/dataRestrictions';
import { isEmpty, propOr } from 'ramda';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TestRunMetadata from '~/components/TestRunMetadata';
import useActionState from '~/hooks/useActionState';
import useCopyPasteSteps from '~/hooks/useCopyPasteSteps';
import useQueryString from '~/hooks/useQueryString';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest, selectTestStepsActivation } from '~/modules/test/test.selectors';

import SelectedRowsActionsHeader from '../SelectedRowsActions';
import TestActionsHeader from '../TestActions';

import { Container, Title, Actions, NameContainer, NameInput } from './TestHeader.styled';

const TestHeader = ({
  allNoneGroupsExpanded,
  onCollapseExpandAll,
  onDeselect,
  onErrorClick,
  readOnly,
  selectedRows,
}) => {
  const nameFieldRef = useRef();
  const dispatch = useDispatch();
  const test = useSelector(selectSingleTest);
  const stepsActivation = useSelector(selectTestStepsActivation);
  const query = useQueryString();
  const { copySteps } = useCopyPasteSteps();

  const testRun = propOr({}, 'testRun', test);
  const hasGroups = !!test?.groups?.length;

  const handleNameChange = useCallback(
    (value) => {
      dispatch(TestActions.updateRequest(test.id, { name: value }, { reqId: test.id }));
    },
    [dispatch, test.id],
  );

  const handleNameChangeFailure = useCallback((errors) => {
    nameFieldRef.current.setError(errors.name);
  }, []);

  useActionState(TestActions.updateRequest, {
    onFailure: handleNameChangeFailure,
    reqId: test.id,
  });

  const handleStepsActivationChange = useCallback(
    (steps, value) => {
      dispatch(TestActions.updateStepsActivationRequest(test.id, steps, value));
      onDeselect();
    },
    [dispatch, test.id, onDeselect],
  );

  const handleStepsDelete = useCallback(() => {
    onDeselect();
  }, [onDeselect]);

  const handleCopySteps = useCallback(
    (stepIds) => {
      copySteps(stepIds);
      onDeselect();
    },
    [onDeselect, copySteps],
  );

  return (
    <Container data-testid="TestHeader">
      <Title>
        <NameContainer>
          {test?.name && (
            <NameInput
              data-hj-allow
              ref={nameFieldRef}
              value={test?.name}
              onChange={handleNameChange}
              readOnly={readOnly}
              max={DATA_RESTRICTIONS.TEST_NAME_MAX_LENGTH}
            />
          )}
        </NameContainer>
        <Actions>
          {!isEmpty(selectedRows.groups) || !isEmpty(selectedRows.steps) ? (
            <SelectedRowsActionsHeader
              testId={test.id}
              rows={selectedRows}
              onDeselect={onDeselect}
              stepsActivation={stepsActivation}
              onDelete={handleStepsDelete}
              onActivationChange={handleStepsActivationChange}
              onCopySteps={handleCopySteps}
            />
          ) : (
            <TestActionsHeader
              onCollapseExpandAll={onCollapseExpandAll}
              allNoneGroupsExpanded={allNoneGroupsExpanded}
              testId={test.id}
              testRunId={query.testRunId || testRun.id}
            />
          )}
        </Actions>
      </Title>
      {hasGroups && <TestRunMetadata testRunId={testRun.id} onErrorClick={onErrorClick} />}
    </Container>
  );
};

export default TestHeader;
