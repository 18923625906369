import Loader from '@bugbug/core/components/Loader';
import { isEmpty, without } from 'ramda';
import { useCallback, useRef, useState, useMemo, useEffect, memo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';

import { MODAL_TYPE } from '~/components/modals';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useBackUrl from '~/hooks/useBackUrl';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import useQueryString from '~/hooks/useQueryString';
import useTestFailedStateDetector from '~/hooks/useTestFailedStateDetector';
import useTestRecordingFinished from '~/hooks/useTestRecordingFinished';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import { TestActions } from '~/modules/test/test.redux';
import {
  selectIsSingleTestRecording,
  selectIsSingleTestLoading,
  selectSingleTest,
  selectChangedNotificationByTestId,
  selectTestSteps,
  selectCurrentStepId,
} from '~/modules/test/test.selectors';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';
import { selectUserFlags } from '~/modules/user/user.selectors';
import urls from '~/views/urls';

import StepsList from '../StepsList';
import TestHeader from '../TestHeader';

import { INITIAL_SELECTION_STATE } from './Content.constants';
import { Container, LoaderContainer, Notification } from './Content.styled';

const TestContent = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepsListRef = useRef();
  const initialErrorSkipped = useRef(false);
  const { testId } = useParams();
  const { groupId: urlGroupId, stepId: urlStepId, testRunId } = useQueryString();
  const allTestSteps = useSelector(selectTestSteps);
  const modal = useModal();
  const backUrl = useBackUrl(urls.testsList);

  const test = useSelector(selectSingleTest);
  const isSingleTestLoading = useSelector(selectIsSingleTestLoading);
  const isReadOnly = useSelector(selectIsSingleTestRecording);
  const changedNotification = useSelector(selectChangedNotificationByTestId(testId));
  const userFlags = useSelector(selectUserFlags);
  const extension = useExtensionState();
  const currentStepId = useSelector(selectCurrentStepId);

  const getSingleRequestState = useActionState(TestActions.getSingleRequest, { reset: false });
  const stopRecordingRequestState = useActionState(TestActions.stopRecordingRequest, {
    reset: false,
  });
  const [selectedRows, setSelectedRows] = useState(INITIAL_SELECTION_STATE);

  const failedStepRunWithStep = useSelector(selectFailedStepRunWithStep);
  const failedStepId = failedStepRunWithStep?.failedStepRun?.stepId;
  const failedStepRun = useMemo(() => {
    if (!failedStepId) return {};

    // Filter out steps that were just removed
    const failedStep = allTestSteps[failedStepId];

    if (!failedStep) {
      return {};
    }

    return failedStepRunWithStep.failedStepRun;
  }, [allTestSteps, failedStepRunWithStep.failedStepRun, failedStepId]);

  const updateQueryString = useUpdateQueryString();

  const isLoading =
    getSingleRequestState.isLoading ||
    stopRecordingRequestState.isLoading ||
    !test.groups ||
    isSingleTestLoading;

  const handleDeselectAll = useCallback(() => {
    stepsListRef.current.deselectAllRows();
    setSelectedRows(INITIAL_SELECTION_STATE);
  }, []);

  const handleSelectionChange = useCallback((group, selectedSteps, shouldDeselectGroup = false) => {
    setSelectedRows((currentRows) => {
      const isGroupEmptyAndOnTheList = !group.steps.length && currentRows.groups.includes(group.id);
      const groups = without([group.id], currentRows.groups);
      const steps = without(group.steps, currentRows.steps);

      if (!shouldDeselectGroup && (selectedSteps.length || !isGroupEmptyAndOnTheList)) {
        if (selectedSteps.length === group.steps.length) {
          groups.push(group.id);
        } else {
          steps.push(...selectedSteps);
        }
      }

      return {
        groups,
        steps,
        groupSteps: { ...currentRows.groupSteps, [group.id]: selectedSteps },
      };
    });
  }, []);

  const openUnavailableStepModal = useCallback(
    () => {
      modal.show('unavailable_step');
      updateQueryString({ stepId: undefined, groupId: undefined });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectAndScrollToStep = useCallback(
    (groupId, stepId, currentSteps) => {
      if (stepsListRef.current && groupId && stepId && currentSteps) {
        const stepAvailable = Object.keys(currentSteps).includes(stepId);
        if (stepAvailable) {
          stepsListRef.current.goToStep(groupId, stepId, false, 'center');
          dispatch(TestActions.setCurrentStepId(stepId));
        } else {
          openUnavailableStepModal();
        }
      }
    },
    [openUnavailableStepModal, dispatch],
  );

  const recentlyFocusedFailedStepRunId = useRef(null);
  const handleGoToError = useCallback(() => {
    if (recentlyFocusedFailedStepRunId.current === failedStepRun.id) return;
    recentlyFocusedFailedStepRunId.current = failedStepRun.id;
    selectAndScrollToStep(failedStepRun.groupId, failedStepRun.stepId, allTestSteps);
  }, [allTestSteps, failedStepRun, selectAndScrollToStep]);

  const goToFailedStep = () => {
    recentlyFocusedFailedStepRunId.current = null;
    handleGoToError();
  };

  const handleGoToRecorderSteps = useCallback((recordedGroup) => {
    if (stepsListRef.current && recordedGroup) {
      const { unconfirmedRelatedGroup: unconfirmedGroupId, unconfirmedIndex: index } =
        recordedGroup;
      if (index) {
        stepsListRef.current.goToStep(unconfirmedGroupId, index - 1, true);
      } else {
        stepsListRef.current.goToStep(unconfirmedGroupId);
      }
    }
  }, []);

  useTestFailedStateDetector(handleGoToError);

  useTestRecordingFinished(handleGoToRecorderSteps);

  useMount(() => {
    if (!userFlags.testRecorded && !extension.isActive) {
      modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION);
    }
  });

  useEffect(() => {
    if (!isLoading && !currentStepId) {
      selectAndScrollToStep(urlGroupId, urlStepId, allTestSteps);
    }
  }, [isLoading, urlGroupId, urlStepId, currentStepId, allTestSteps, selectAndScrollToStep]);

  useEffect(() => {
    dispatch(TestActions.getSingleRequest(testId, testRunId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testId]);

  useUnmount(() => {
    dispatch(TestActions.clearSingleRequest());
    dispatch(TestActions.clearChangedNotification(testId));
    dispatch(TestRunActions.clearSingle());
    dispatch(TestActions.setCurrentStepId(null));
    getSingleRequestState.reset();
    stopRecordingRequestState.reset();
  });

  useEffect(() => {
    if (initialErrorSkipped.current && !isEmpty(failedStepRun)) {
      handleGoToError();
    } else {
      initialErrorSkipped.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedStepRun]);

  const refresh = useCallback(() => {
    getSingleRequestState.reset();
    dispatch(TestActions.clearChangedNotification(testId));
    dispatch(TestActions.getSingleRequest(testId, testRunId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testId, testRunId]);

  const handleNotificationClose = useCallback(() => {
    dispatch(TestActions.clearChangedNotification(testId));
  }, [dispatch, testId]);

  if (getSingleRequestState.hasInternalServerError) {
    return <ServerErrorInfo isVisible onRetry={refresh} />;
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" />
      </LoaderContainer>
    );
  }

  return (
    <Container
      title={
        <TestHeader
          selectedRows={selectedRows}
          readOnly={isReadOnly}
          onErrorClick={isEmpty(failedStepRun) ? openUnavailableStepModal : goToFailedStep}
          onDeselect={handleDeselectAll}
        />
      }
      data-testid="TestContent"
      withBackButton
      backUrl={backUrl}
    >
      <Helmet
        title={t('testDetails.pageTitle', 'Tests / {{ name }}', {
          name: test.name,
          interpolation: { escapeValue: false },
        })}
      />
      <Notification
        text={
          changedNotification
            ? t(
                'testDetails.changedNotification.text',
                'This test has just been updated by another user ({{email}}).',
                { email: changedNotification.email },
              )
            : null
        }
        buttonLabel={t(
          'testDetails.changedNotification.refreshButtonLabel',
          'Refresh to see changes',
        )}
        onClick={refresh}
        onClose={handleNotificationClose}
      />
      <StepsList
        ref={stepsListRef}
        testId={testId}
        testRunId={testRunId}
        onSelectionChange={handleSelectionChange}
      />
    </Container>
  );
});

TestContent.displayName = 'TestContent';

export default TestContent;
