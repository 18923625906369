import { useEffect } from 'react';

import type React from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';
import SplitView, { useCollapsiblePane } from '~/components/SplitView';
import useBreakpoint from '~/hooks/useBreakpoint';

import { MIN_SIDEBAR_SIZE } from './SidebarView.constants';

export interface SidebarViewProps {
  prefix: string;
  Content: React.FC;
  Sidebar: (props: {
    onToggle: SideEffect<boolean | undefined>;
    collapsed: boolean;
  }) => JSX.Element;
  sidebarAlwaysVisible?: boolean;
}

const SidebarView = ({ prefix, Sidebar, Content, sidebarAlwaysVisible }: SidebarViewProps) => {
  const rightPane = useCollapsiblePane(`${prefix}Sidebar`, false);
  const isMinWideDesktop = useBreakpoint('desktopWide');

  useEffect(() => {
    if (!sidebarAlwaysVisible) {
      rightPane.onToggle(isMinWideDesktop ? rightPane.initialCollapsed : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinWideDesktop, sidebarAlwaysVisible]);

  return (
    <SplitView
      minSize={rightPane.isCollapsed ? 0 : MIN_SIDEBAR_SIZE}
      LeftPane={<Content />}
      RightPane={<Sidebar onToggle={rightPane.onToggle} collapsed={rightPane.isCollapsed} />}
      rightPaneStyle={rightPane.paneStyle}
      resizerStyle={rightPane.resizerStyle}
      sizeStoreName={`${prefix}SplitView`}
    />
  );
};

export default SidebarView;
