import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { HiddenSuiteRowAction } from './SuiteRowActions.types';
import type { MouseEventHandler } from 'react';

import type { Suite } from '@bugbug/core/types/suites';
import RunSuiteButton from '~/components/RunSuiteButton';
import { VisibleOnRowHover } from '~/components/Table';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import useSuiteRunner from '~/hooks/useSuiteRunner';

import * as S from './SuiteRowActions.styled';

export interface SuiteRowActionsProps {
  className?: string;
  row: {
    original: Suite;
  };
}

export const SuiteRowActions = ({ className, row }: SuiteRowActionsProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const { push } = useAppRoutes('suitesList');
  const suite = row.original;
  const containerRef = useRef<HTMLDivElement>(null);
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const suiteRunner = useSuiteRunner(suite);

  const handleShowHistory = useCallback(() => {
    push('suiteRunsList', undefined, { query: suite.name });
  }, [push, suite]);

  const handleClone = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () =>
      modal.show('clone_suite', {
        id: suite.id,
        shouldNavigate: true,
      }),
    [modal, suite.id],
  );

  const handleEdit = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    push('suite', { suiteId: suite.id });
  }, [push, suite.id]);

  const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => modal.show('delete_suites', { suitesIds: [suite.id] }),
    [modal, suite.id],
  );

  const handleCliCommands = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    modal.show('suite_cli_commands', { suiteId: suite.id });
  }, [modal, suite.id]);

  const handleRunInCloud = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    suiteRunner.start();
  }, [suiteRunner]);

  const hiddenActions = useMemo<HiddenSuiteRowAction[]>(
    () => [
      {
        name: t('suiteRowActions.runInCloud.label', 'Run in cloud'),
        onClick: handleRunInCloud,
        iconName: 'cloudRun',
        disabled: suiteRunner.isRunning,
        dataTestId: 'SuiteRowActions.RunInCloudButton',
      },
      {
        name: t('suiteRowActions.duplicate.label', 'Duplicate'),
        onClick: handleClone,
        iconName: 'clone',
      },
      {
        name: t('suiteRowActions.edit.label', 'Edit'),
        onClick: handleEdit,
        iconName: 'edit',
      },
      {
        name: t('suiteRowActions.runHistory.label', 'Runs history'),
        onClick: handleShowHistory,
        iconName: 'history',
      },
      {
        name: t('suiteRowActions.cliCommands.label', 'Run via CLI'),
        onClick: handleCliCommands,
        iconName: 'terminal',
      },
      {
        name: t('suiteRowActions.remove.label', 'Delete'),
        onClick: handleDelete,
        iconName: 'delete',
        dataTestId: 'SuiteRowActions.DeleteButton',
        danger: true,
      },
    ],
    [
      t,
      handleRunInCloud,
      suiteRunner.isRunning,
      handleClone,
      handleEdit,
      handleShowHistory,
      handleCliCommands,
      handleDelete,
    ],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);
  const isCurrentlyQueued = suite?.lastUnfinishedUserRun?.status === 'queued';

  const renderMoreActions = () => (
    <S.Dropdown
      variant="icon"
      anchor="bottom-end"
      iconName="more"
      onOpen={lockActionButtons}
      onClose={unlockActionButtons}
    >
      {hiddenActions.map(({ name, onClick, iconName, disabled, dataTestId, danger }) => (
        <DropdownItem
          data-testid={dataTestId}
          key={name}
          disabled={disabled}
          onClick={onClick}
          danger={danger}
        >
          <Icon name={iconName} /> {name}
        </DropdownItem>
      ))}
    </S.Dropdown>
  );

  return (
    <S.Container ref={containerRef} className={className} data-testid="SuiteRowActions">
      <VisibleOnRowHover force={suiteRunner.isRunning || areActionsLocked}>
        <RunSuiteButton
          suite={suite}
          running={suiteRunner.isRunning}
          disabled={null}
          queued={isCurrentlyQueued}
        />
      </VisibleOnRowHover>
      <VisibleOnRowHover force={areActionsLocked}>{renderMoreActions()}</VisibleOnRowHover>
    </S.Container>
  );
};
