import { useTrail } from '@react-spring/web';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Tip from '~/components/Tip';
import { handleYToTranslateInterpolation } from '~/utils/spring';

import { Title } from '../EmptyState/EmptyState.styled';

import { Container, TipWrapper } from './EmptyStateRecording.styled';

const EmptyStateRecording = ({ className, isVisible }) => {
  const { t } = useTranslation();

  const trail = useTrail(2, {
    from: { y: isVisible ? 50 : 0, opacity: Number(!isVisible) },
    y: isVisible ? 0 : 50,
    opacity: Number(isVisible),
  });

  const getTrailStyle = useCallback(
    (index) => ({
      ...trail[index],
      transform: trail[index].y.to(handleYToTranslateInterpolation),
    }),
    [trail],
  );

  return (
    <Container className={className} data-testid="TestDetailsEmptyStateRecording">
      <Title style={getTrailStyle(0)}>
        <span>{t('testDetailsEmptyState.recording.titleLine1', 'Recording in progress...')}</span>
        <span>
          {t(
            'testDetailsEmptyState.recording.titleLine2',
            'Your recording has started in a new window.',
          )}
        </span>
      </Title>

      <TipWrapper style={getTrailStyle(1)}>
        <Tip
          text={t(
            'testDetailsEmptyState.recording.tip',
            'Tip: to quickly create tests you can also click on the component in another test and use "New test from here',
          )}
        />
      </TipWrapper>
    </Container>
  );
};

EmptyStateRecording.defaultProps = {
  className: null,
  isVisible: false,
};

EmptyStateRecording.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default EmptyStateRecording;
