import Paginate from '~/components/Paginate';

import { PaginationContainer, PaginationWrapper } from './Table.styled';

const Pagination = ({ onChange, total = 0, current = 1, pageSize = 0, children }) => (
  <PaginationWrapper>
    {children}
    {total ? (
      <PaginationContainer>
        <Paginate current={current} total={total} pageSize={pageSize} onChange={onChange} />
      </PaginationContainer>
    ) : null}
  </PaginationWrapper>
);

export default Pagination;
