import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import { STEP_TYPE_ICON_NAME } from '~/constants/step';

import ParamsCell from '../ParamsCell';

import * as S from './StepNameWithParams.styled';

interface StepNameWithParamsProps {
  value: Step;
}

// TODO a similar ocmponent is present in TestDetails but is couples with Tests logic
//      this should be generalised as part of refactoring
const StepNameWithParams = ({ value: step }: StepNameWithParamsProps) => {
  const { t } = useTranslation();
  const iconName = STEP_TYPE_ICON_NAME[step.type === 'assert' ? step.assertionProperty : step.type];

  return (
    <S.Container>
      <S.IconWrapper>
        <Icon name={iconName} />
      </S.IconWrapper>

      <S.StepName type={step.type} />

      <ParamsCell step={step} />

      {step.sleep && (
        <S.Tooltip
          content={t(
            'stepName.sleep.tooltip',
            'This step has Delay / Sleep set for {{seconds}} seconds',
            {
              seconds: step.sleep,
            },
          )}
        >
          <S.SleepIcon />
        </S.Tooltip>
      )}
    </S.Container>
  );
};

export default StepNameWithParams;
