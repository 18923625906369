import Loader from '@bugbug/core/components/Loader';

import { Container, IconWrapper, TextWrapper, Title, Description } from './ActionBlock.styled';

const ActionBlock = ({
  className,
  title = '',
  description = '',
  Icon = () => null,
  onClick = Function.prototype,
  disabled = false,
  pending = false,
  pulseAnimationEnabled = false,
}) => (
  <Container
    className={className}
    onClick={onClick}
    data-testid="ActionBlock"
    disabled={disabled || pending}
    pulseAnimationEnabled={pulseAnimationEnabled}
  >
    <IconWrapper>
      {pending ? <Loader size="small" /> : <Icon data-testid="ActionBlock.Icon" />}
    </IconWrapper>
    <TextWrapper>
      <Title data-testid="ActionBlock.Title">{title}</Title>
      <Description data-testid="ActionBlock.Description">{description}</Description>
    </TextWrapper>
  </Container>
);

export default ActionBlock;
