import { DropdownItem, DROPDOWN_VARIANT, DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import RunTestButton from '~/components/RunTestButton';
import { VisibleOnRowHover } from '~/components/Table';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import useModal from '~/hooks/useModal';
import useTestRunner from '~/hooks/useTestRunner';
import config from '~/modules/config';
import urls, { reverse } from '~/views/urls';

import { Container, Dropdown } from './TestRowActions.styled';

const TestRowActions = ({ className, row }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const test = row.original;
  const containerRef = useRef();
  const history = useHistory();
  const { projectSlug, projectId, organizationId } = useParams();
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const testRunner = useTestRunner(test);
  const { push } = useAppRoutes('testsList');

  const testPath = reverse(urls.test, {
    projectId,
    projectSlug,
    organizationId,
    testId: test.id,
  });
  const handleCopyLinkToTest = useCopyHandler(`${config.WEBAPP_URL}${testPath}`, {
    success: t('testRowActions.copyLinkToTest.copyLinkToTestMsg.success', 'Link to test copied'),
    failure: t(
      'testRowActions.copyLinkToTest.copyLinkToTestMsg.failure',
      'Error when copying link to test',
    ),
  });

  const handleRunInCloud = useCallback(
    () => testRunner.start({ withProfileSelection: true }),
    [testRunner],
  );

  const handleEdit = useCallback(() => {
    push('testsListEdit', {
      testId: test.id,
    });
  }, [push, test.id]);

  const handleClone = useCallback(
    () => modal.show('clone_test', { id: test.id }),
    [modal, test.id],
  );

  const handleDelete = useCallback(
    () =>
      modal.show('delete_tests', {
        testsIds: [test.id],
      }),
    [modal, test.id],
  );

  const handleShowHistory = useCallback(() => {
    history.push(
      reverse(urls.testRunsList, { projectSlug, projectId, organizationId }, { query: test.id }),
    );
  }, [history, projectSlug, projectId, organizationId, test]);

  const handleCliCommands = useCallback(() => {
    modal.show('test_cli_commands', { data: { testId: test.id } });
  }, [modal, test.id]);

  const hiddenActions = useMemo(
    () => [
      {
        name: t('testRowActions.runInCloud.label', 'Run in cloud'),
        onClick: handleRunInCloud,
        iconName: 'cloudRun',
        disabled: testRunner.isRunning,
        dataTestId: 'TestRowActions.RunInCloudButton',
      },
      {
        name: t('testRowActions.clone.label', 'Duplicate'),
        onClick: handleClone,
        iconName: 'clone',
        dataTestId: 'TestRowActions.CloneButton',
      },
      {
        name: t('testRowActions.edit.label', 'Rename'),
        onClick: handleEdit,
        iconName: 'edit',
        dataTestId: 'TestRowActions.EditButton',
      },
      {
        name: t('testRowActions.runsHistory.label', 'Runs history'),
        onClick: handleShowHistory,
        iconName: 'history',
        dataTestId: 'TestRowActions.HistoryButton',
      },
      {
        name: t('testRowActions.copyLinkToTest.label', 'Copy link to test'),
        onClick: handleCopyLinkToTest,
        iconName: 'link2',
        dataTestId: 'TestRowActions.CopyLinkToTest',
      },
      {
        name: t('testRowActions.cliCommands.label', 'Run via CLI'),
        onClick: handleCliCommands,
        iconName: 'terminal',
        dataTestId: 'TestRowActions.CliCommands',
      },
      {
        name: t('testRowActions.remove.label', 'Delete'),
        onClick: handleDelete,
        iconName: 'delete',
        dataTestId: 'TestRowActions.DeleteButton',
        danger: true,
      },
    ],
    [
      t,
      handleRunInCloud,
      handleShowHistory,
      testRunner.isRunning,
      handleClone,
      handleEdit,
      handleCopyLinkToTest,
      handleCliCommands,
      handleDelete,
    ],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  const renderMoreActions = () => (
    <Dropdown
      variant={DROPDOWN_VARIANT.ICON}
      anchor={DROPDOWN_ANCHOR.BOTTOM_END}
      iconName="more"
      onOpen={lockActionButtons}
      onClose={unlockActionButtons}
    >
      {hiddenActions.map(({ name, onClick, iconName, disabled, dataTestId, danger }) => (
        <DropdownItem
          data-testid={dataTestId}
          key={name}
          disabled={disabled}
          onClick={onClick}
          danger={danger}
        >
          <Icon name={iconName} /> {name}
        </DropdownItem>
      ))}
    </Dropdown>
  );

  return (
    <Container ref={containerRef} className={className} data-testid="TestRowActions">
      <VisibleOnRowHover force={testRunner.isRunning || areActionsLocked}>
        <RunTestButton
          test={test}
          running={testRunner.isRunning}
          disabled={null}
          queued={testRunner.isQueued}
        />
      </VisibleOnRowHover>
      <VisibleOnRowHover force={areActionsLocked}>{renderMoreActions()}</VisibleOnRowHover>
    </Container>
  );
};

TestRowActions.defaultProps = {
  className: null,
};

TestRowActions.propTypes = {
  className: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isRecording: PropTypes.bool,
      hasSteps: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default TestRowActions;
