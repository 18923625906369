import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${COLOR.WHITE};
  pointer-events: all;
  padding-bottom: 57px;
  z-index: 1;
`;

export const ActionWrapper = styled.div`
  max-width: 260px;
  width: 100%;
  display: flex;

  > * {
    flex: 1;
  }
`;

export const Title = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  padding-bottom: 2px;
  color: ${COLOR.GRAY_23};
  margin-top: 40px;
  margin-bottom: 20px;

  &:before,
  &:after {
    flex: 1;
    height: 2px;
    background: ${COLOR.GRAY_18};
    content: '.';
    overflow: hidden;
    margin-top: 10px;
  }

  &:before {
    margin-right: 11px;
  }

  &:after {
    margin-left: 11px;
  }
`;

export const DotIcon = styled(Icon).attrs(() => ({
  name: 'dot',
}))`
  && {
    color: ${COLOR.PRIMARY_LIGHT};
    width: 12px;
    height: 12px;
  }
`;

export const AddGroupIcon = styled(Icon).attrs(() => ({
  name: 'addGroup',
}))`
  width: 17px;
  height: 17px;
`;

export const PasteIcon = styled(Icon).attrs(() => ({
  name: 'paste',
}))`
  width: 17px;
  height: 17px;
`;

export const AddComponentIcon = styled(Icon).attrs(() => ({
  name: 'addComponent',
}))`
  width: 24px;
  height: 24px;
  left: 4px;
  position: relative;
`;
