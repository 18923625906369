import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';

import * as S from './TestActions.styled';

interface DebugButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

const DebugButtons = ({ testId, testRunId }: DebugButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });

  return (
    <S.CombinedButtons>
      <S.RecordButton onClick={testRunner.stop} iconName="stop">
        {t('testDetails.testActions.stopButton', 'Stop')}
      </S.RecordButton>
      <S.Button onClick={testRunner.resume} iconName="play">
        {t('testDetails.testActions.resumeButton', 'Resume')}
      </S.Button>
      <S.Button disabled={!testRunner.isDebugging} onClick={testRunner.runNextStep} iconName="next">
        {t('testDetails.testActions.nextStepButton', 'Next step')}
      </S.Button>
    </S.CombinedButtons>
  );
};

export default DebugButtons;
