import CopyButton from '@bugbug/core/components/CopyButton';
import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CodeField from '~/components/CodeField';
import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import { SET_LOCAL_VARIABLE_SOURCE_LABEL } from '~/constants/step';
import { VARIABLE_TYPE } from '~/constants/variables';

import ElementSelectorField from '../ElementSelectorField';
import { ComputedField, ComputedValue } from '../Interaction/Interaction.styled';

import { FIELD_NAMES } from './SetLocalVariableFields.constants';

const SetLocalVariableFields = ({ context, stepRun, readOnly }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error };
    },
    [formik],
  );

  const renderElementField = renderWhenTrue(() => (
    <ElementSelectorField context={context} disabled={readOnly} />
  ));

  const renderStaticValue = renderWhenTrue(() => (
    <FormField label={t('stepDetails.localVariable.value', 'Set value')}>
      <FormikInputWithVariables name={FIELD_NAMES.VALUE} readOnly={readOnly} />
    </FormField>
  ));

  const renderCodeField = renderWhenTrue(() => (
    <CodeField
      {...formik.getFieldProps(FIELD_NAMES.CUSTOM_JAVASCRIPT)}
      {...getFieldProps(FIELD_NAMES.CUSTOM_JAVASCRIPT)}
      error={formik.errors[FIELD_NAMES.CUSTOM_JAVASCRIPT]}
      functionArguments={['variables']}
      readOnly={readOnly}
    />
  ));

  const renderVariableSource = () => {
    const scrollToProps = getFieldProps(FIELD_NAMES.VARIABLE_SOURCE);
    return (
      <FormField label={t('stepDetails.localVariable.source', 'Value')}>
        <Select
          fullWidth
          {...formik.getFieldProps(FIELD_NAMES.VARIABLE_SOURCE)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...scrollToProps}
          disabled={readOnly}
        >
          {Object.values(VARIABLE_TYPE).map((type) => (
            <SelectOption key={type} value={type}>
              {SET_LOCAL_VARIABLE_SOURCE_LABEL[type]}
            </SelectOption>
          ))}
        </Select>
      </FormField>
    );
  };

  return (
    <>
      <FormField label={t('stepDetails.localVariable.name', 'Variable name')}>
        <Input
          {...formik.getFieldProps(FIELD_NAMES.VARIABLE_NAME)}
          {...getFieldProps(FIELD_NAMES.VARIABLE_NAME)}
          readOnly={readOnly}
        />
        <CopyButton value={formik.values[FIELD_NAMES.VARIABLE_NAME]} />
      </FormField>
      {renderVariableSource()}
      {renderElementField(formik.values[FIELD_NAMES.VARIABLE_SOURCE] === VARIABLE_TYPE.ELEMENT)}
      {renderStaticValue(formik.values[FIELD_NAMES.VARIABLE_SOURCE] === VARIABLE_TYPE.VALUE)}
      {renderCodeField(formik.values[FIELD_NAMES.VARIABLE_SOURCE] === VARIABLE_TYPE.EVALUATE)}
      {stepRun.computedValue && (
        <ComputedField
          label={
            context === 'testRun'
              ? t('stepDetails.localVariable.thisComputedValue', 'This test run value')
              : t('stepDetails.localVariable.lastComputedValue', 'Last test run value')
          }
        >
          <ComputedValue>{stepRun.computedValue}</ComputedValue>
          <CopyButton value={stepRun.computedValue} />
        </ComputedField>
      )}
    </>
  );
};

export default SetLocalVariableFields;
