import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import { compose, flatten, values } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';
import { getSelectedPartialsLabel } from '~/utils/misc';

import { Container, Button } from './SelectedRowsActions.styled';

const SelectedRowsActions = ({
  className,
  testId,
  rows,
  stepsActivation,
  onDeselect,
  onActivationChange,
  onDelete,
  onCopySteps,
}) => {
  const { t } = useTranslation();
  const modal = useModal();

  const allSelectedSteps = useMemo(
    () => compose(flatten, values)(rows.groupSteps),
    [rows.groupSteps],
  );

  const canActivateSteps = useMemo(
    () => !!allSelectedSteps.find((stepId) => !stepsActivation[stepId]),
    [allSelectedSteps, stepsActivation],
  );

  const handleStepsActiveChange = useCallback(() => {
    onActivationChange(allSelectedSteps, canActivateSteps);
  }, [allSelectedSteps, canActivateSteps, onActivationChange]);

  const handleCopySteps = useCallback(() => {
    onCopySteps(allSelectedSteps);
  }, [allSelectedSteps, onCopySteps]);

  const partialsLabel = useMemo(
    () => getSelectedPartialsLabel(rows.steps.length, rows.groups.length),
    [rows.groups, rows.steps],
  );

  const handleDelete = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_TEST_PARTIALS, {
        testId,
        steps: rows.steps,
        groups: rows.groups,
        onSuccess: onDelete,
      }),
    [modal, testId, rows.steps, rows.groups, onDelete],
  );

  return (
    <Container className={className} data-testid="TestDetails.SelectedRowsActions">
      <Dropdown
        rounded
        data-testid="TestDetails.BulkActions"
        variant={DROPDOWN_VARIANT.PRIMARY}
        label={t('testsList.header.bulkActionsButton.main', '{{partialsLabel}} selected', {
          partialsLabel,
        })}
      >
        <DropdownItem onClick={handleStepsActiveChange}>
          {canActivateSteps ? <Icon name="layers" /> : <Icon name="layersClear" />}
          {canActivateSteps
            ? t('testDetails.selectedRowsActions.activateSteps', 'Activate')
            : t('testDetails.selectedRowsActions.deactivateSteps', 'Deactivate')}
        </DropdownItem>
        <DropdownItem onClick={handleCopySteps}>
          <Icon name="copy" />
          {t('testDetails.selectedRowsActions.copySteps', 'Copy steps')}
        </DropdownItem>
        <DropdownItem onClick={handleDelete}>
          <Icon name="delete" />
          {t('testDetails.selectedRowsActions.deleteButton', 'Delete')}
        </DropdownItem>
      </Dropdown>
      <Button
        data-testid="TestDetails.DeselectButton"
        iconName="close"
        onClick={onDeselect}
        bordered
      >
        {t('testDetails.selectedRowsActions.deselectButton', 'Deselect all')}
      </Button>
    </Container>
  );
};

SelectedRowsActions.defaultProps = {
  className: null,
};

SelectedRowsActions.propTypes = {
  className: PropTypes.string,
  onDeselect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivationChange: PropTypes.func.isRequired,
  stepsActivation: PropTypes.shape({}).isRequired,
  testId: PropTypes.string.isRequired,
  rows: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.string).isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default SelectedRowsActions;
