import {
  DropdownItem,
  DROPDOWN_VARIANT,
  DROPDOWN_ANCHOR,
  DropdownLinkItem,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { MODAL_TYPE } from '~/components/modals';
import { VisibleOnRowHover } from '~/components/Table';
import useModal from '~/hooks/useModal';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import useRefreshTestRunsHistory from '~/views/RunsHistory/hooks/useRefreshTestRunsHistory';
import urls, { reverse } from '~/views/urls';

import { Container, Dropdown, IconButton } from './TestRunRowActions.styled';

const TestRunRowActions = ({ className, row }) => {
  const { t } = useTranslation();
  const { organizationId, projectId, projectSlug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const modal = useModal();
  const testRun = row.original;
  const containerRef = useRef();
  const isRunning = isRunningStatus(testRun.status);
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const onDelete = useRefreshTestRunsHistory();

  const handleStopRunning = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(TestRunActions.stopRequest([testRun.id]));
    },
    [dispatch, testRun.id],
  );

  const handleClearClick = useCallback(() => {
    modal.show(MODAL_TYPE.DELETE_TEST_RUNS, {
      onDelete,
      testRunsIds: [testRun.id],
    });
  }, [modal, onDelete, testRun.id]);

  const hiddenActions = useMemo(() => {
    const pathname = reverse(urls.test, {
      organizationId,
      projectId,
      projectSlug,
      testId: testRun.testSourceId,
    });
    const to = { pathname, state: { backUrl: `${location.pathname}${location.search}` } };

    return [
      {
        name: t('testRunRowActions.goToTest.label', 'Go to test'),
        Component: DropdownLinkItem,
        disabled: !testRun.testSourceId,
        to: testRun.testSourceId ? to : '',
        iconName: 'cornerDownRight',
      },
      {
        name: t('testRunRowActions.remove.label', 'Clear from history'),
        onClick: handleClearClick,
        iconName: 'delete',
        danger: true,
      },
    ];
  }, [
    t,
    organizationId,
    handleClearClick,
    projectId,
    projectSlug,
    testRun.testSourceId,
    location.pathname,
    location.search,
  ]);

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <Container ref={containerRef} className={className} data-testid="TestRunRowActions">
      {isRunning && (
        <VisibleOnRowHover force={isRunning || areActionsLocked}>
          <IconButton data-testid="TestRunRowActions.StopButton" onClick={handleStopRunning}>
            <Icon name="stop" />
          </IconButton>
        </VisibleOnRowHover>
      )}
      <VisibleOnRowHover force={areActionsLocked}>
        <Dropdown
          variant={DROPDOWN_VARIANT.ICON}
          anchor={DROPDOWN_ANCHOR.BOTTOM_END}
          iconName="more"
          onOpen={lockActionButtons}
          onClose={unlockActionButtons}
        >
          {hiddenActions.map(
            ({
              name,
              onClick,
              to,
              iconName,
              disabled,
              dataTestId,
              Component = DropdownItem,
              danger,
            }) => (
              <Component
                data-testid={dataTestId}
                key={name}
                disabled={disabled}
                to={to}
                onClick={onClick}
                danger={danger}
              >
                <Icon name={iconName} /> {name}
              </Component>
            ),
          )}
        </Dropdown>
      </VisibleOnRowHover>
    </Container>
  );
};

export default TestRunRowActions;
