import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

import Page from '~/components/Page';
import { TableBody } from '~/components/Table';

export const ListContainer = styled(Page)`
  ${TableBody} {
    overflow-y: hidden;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
`;

export const BulkActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
`;

export const CreateButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-left: 17px;
`;

export const DeselectButton = styled(Button)`
  margin-left: 9px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.common.white};

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const TableEmptyState = styled.div`
  background-color: ${({ theme }) => theme.colors.common.white};
  position: relative;
  height: 100%;

  ${LoaderContainer} {
    top: 25px;
  }
`;
