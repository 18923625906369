import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 8px;
  border-radius: 30px;
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.GRAY_9};
  box-shadow: 0 2px 0 0 rgb(198, 198, 198, 0.1);
  padding: 8px 16px 7px 14px;
  transition: border 400ms ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 47px;
  color: ${COLOR.DARK_GRAY};

  &:hover {
    border-color: ${COLOR.PRIMARY_LIGHT};
  }

  svg {
    margin-top: -1px;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.8;
    color: ${COLOR.GRAY_8};

    svg {
      fill: ${COLOR.GRAY_8};
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3px;
`;

export const Title = styled.span`
  display: block;
  font-size: 13px;
  line-height: 20px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`;

export const Description = styled.p`
  color: ${COLOR.GRAY_12};
  font-size: 13px;
  line-height: 16px;
  margin: 0;
`;
