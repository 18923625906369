import { path } from 'ramda';

import type { Column } from 'react-table';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';
import { sortByDate } from '~/utils/sorting';

import { SuiteRowActions } from './components/SuiteRowActions/SuiteRowActions';

export const SUITE_LIST_COLUMN_ID = {
  NAME: 'name',
  SUITE_NUMBER: 'tests_number',
  LAST_RUN: 'last_result',
  ACTIONS: 'actions',
};

export const SUITE_LIST_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('suitesList.table.columns.name', 'Name'),
    accessor: 'name',
    id: SUITE_LIST_COLUMN_ID.NAME,
    Cell: TablePartials.TextCell,
  },
  {
    Header: i18n.t('suitesList.table.columns.testsNumber', 'Tests'),
    accessor: path(['tests', 'length']),
    id: SUITE_LIST_COLUMN_ID.SUITE_NUMBER,
    Cell: TablePartials.TextCell,
  },
  {
    Header: i18n.t('suitesList.table.columns.lastRun', 'Last result'),
    // @ts-expect-error Missing typings
    accessor: (suite) => suite.previousResult || suite.lastResult,
    id: SUITE_LIST_COLUMN_ID.LAST_RUN,
    Cell: TablePartials.StatusCell,
    sortType: sortByDate,
    minWidth: 80,
  },
  {
    Header: i18n.t('suitesList.table.columns.actions', 'Actions'),
    Cell: SuiteRowActions,
    id: SUITE_LIST_COLUMN_ID.ACTIONS,
    disableSortBy: true,
    maxWidth: 85,
  },
] as Column<TablePartials.Data>[];
