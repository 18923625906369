import Icon from '@bugbug/core/components/Icon';
import React from 'react';

import { Container, MetaValue } from './ProfileCell.styled';

const ProfileCell = ({ className, value }) => (
  <Container className={className}>
    <MetaValue>
      <Icon name="variables" />
      {value}
    </MetaValue>
  </Container>
);

export default ProfileCell;
