import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isRunningStatus } from '@bugbug/core/types/base';
import throttle from 'lodash.throttle';
import { path } from 'ramda';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import { RUN_ENV } from '~/modules/constans';
import { selectHasMoreThanOneProfile } from '~/modules/profile/profile.selectors';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { TestActions } from '~/modules/test/test.redux';
import { selectTestIsRecording, selectHasUnconfirmedGroups } from '~/modules/test/test.selectors';
import { selectTestRun } from '~/modules/testRun/testRun.selectors';
import { selectUserId } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE, TRACK_EVENT_ARG_TYPE } from '~/services/analytics';
import localStorage from '~/services/localStorage';
import { isTriggeredByCurrentUser, isCloudRun } from '~/utils/runs';

const START_THROTTLE_TIME = 1000;

const useTestRunner = (test = {}, config = {}) => {
  const dispatch = useDispatch();
  const lastUnfinishedRunId = path(['lastUnfinishedUserRun', 'id'], test);
  const testRunId = config.testRunId || lastUnfinishedRunId;
  const testRunResult = useSelector(selectTestRun(testRunId, true));
  const status = testRunResult.status || path(['lastUnfinishedUserRun', 'status'], test);
  const extension = useExtensionState();
  const modal = useModal();
  const project = useSelector(selectSingleProject);
  const isRecording = useSelector(selectTestIsRecording);
  const hasMoreThanOneProfile = useSelector(selectHasMoreThanOneProfile);
  const hasUnconfirmedGroups = useSelector(selectHasUnconfirmedGroups);
  const currentUserId = useSelector(selectUserId);

  const isRunning =
    isRunningStatus(status) &&
    isTriggeredByCurrentUser(testRunResult, currentUserId, !!lastUnfinishedRunId);
  const isQueued = status === RUN_STATUS.QUEUED;

  const isDebugging = testRunResult.status === RUN_STATUS.DEBUGGING;
  const isIdle = !isRecording && !isRunning && !isDebugging;

  const openExtensionModal = useCallback(
    () => modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION),
    [modal],
  );

  const openUnconfirmedStepsModal = useCallback(
    () => modal.show(MODAL_TYPE.UNCONFIRMED_STEPS),
    [modal],
  );

  const openIncognitoTabsModal = useCallback(
    (onConfirm) => modal.show(MODAL_TYPE.INCOGNITO_TABS_CLOSE, { onConfirm }),
    [modal],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const start = useCallback(
    throttle(
      async ({ withProfileSelection, ...options } = {}) => {
        if (!options.withRecording && !options.runMode) {
          analytics.trackEvent(TRACK_EVENT_TYPE.START_TEST_RUN, {
            [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: RUN_ENV.SERVER,
          });
        }
        const continueRun = (profileId = options.runProfileId) =>
          dispatch(
            TestActions.startRunningRequest(test.id, {
              runMode: RUN_ENV.SERVER,
              ...options,
              runProfileId: profileId,
              redirect: options.withRedirect,
            }),
          );

        if (options.stopWhenUnconfirmedGroups && hasUnconfirmedGroups) {
          openUnconfirmedStepsModal();
          return;
        }

        const showIncognitoWindowsModalIfNeeded = async (profileId) => {
          if (options.runMode === RUN_ENV.LOCAL) {
            const hasActiveIncognitoWindows = await extension.hasActiveIncognitoWindows();
            if (hasActiveIncognitoWindows) {
              openIncognitoTabsModal(continueRun);
              return;
            }
          }
          continueRun(profileId);
        };

        if (withProfileSelection && hasMoreThanOneProfile) {
          modal.show(MODAL_TYPE.RUN_WITH_PROFILE, {
            initialProfileId: test.runProfileId,
            onSuccess: showIncognitoWindowsModalIfNeeded,
          });
          return;
        }

        await showIncognitoWindowsModalIfNeeded();
      },
      START_THROTTLE_TIME,
      { trailing: false },
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      test.id,
      test.runProfileId,
      dispatch,
      hasMoreThanOneProfile,
      hasUnconfirmedGroups,
      modal.show,
      openUnconfirmedStepsModal,
    ],
  );

  const startLocal = useCallback(
    (options) => {
      if (options.withRecording) {
        analytics.trackEvent(TRACK_EVENT_TYPE.CLICK_RECORD_FROM_HERE);
      } else {
        analytics.trackEvent(TRACK_EVENT_TYPE.START_TEST_RUN, {
          [TRACK_EVENT_ARG_TYPE.TEST_RUN_METHOD]: RUN_ENV.LOCAL,
        });
      }

      if (!extension.isActive) {
        openExtensionModal();
        return null;
      }
      return start({ ...options, runMode: RUN_ENV.LOCAL });
    },
    [start, openExtensionModal, extension.isActive],
  );

  const stop = useCallback(() => {
    if (isRecording) {
      dispatch(TestActions.stopRecordingRequest(test.id));
    } else {
      dispatch(TestActions.stopRunningRequest(test.id, { testRunId }));
    }
  }, [test.id, dispatch, testRunId, isRecording]);

  const runNextStep = useCallback(() => {
    dispatch(TestActions.debugRunNextStepRequest(test.id));
  }, [dispatch, test.id]);

  const pause = useCallback(() => {
    dispatch(TestActions.debugPauseTestRequest(test.id, testRunId));
  }, [dispatch, testRunId, test.id]);

  const resume = useCallback(() => {
    dispatch(TestActions.debugResumeTestRequest(test.id, testRunId));
  }, [dispatch, testRunId, test.id]);

  const record = useCallback(
    async (url = '') => {
      analytics.trackEvent(TRACK_EVENT_TYPE.RECORDING_STARTED);

      if (!extension.isActive) {
        localStorage.setUserItem(`${test.id}.initialUrl`, url);
        openExtensionModal();
        return;
      }

      const continueRecording = () => {
        localStorage.removeUserItem(`${test.id}.initialUrl`);
        dispatch(TestActions.startRecordingRequest(test.id, url));
      };

      const hasActiveIncognitoWindows = await extension.hasActiveIncognitoWindows();
      if (hasActiveIncognitoWindows) {
        openIncognitoTabsModal(continueRecording);
        return;
      }

      continueRecording();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      extension.isActive,
      dispatch,
      test.id,
      openExtensionModal,
      openIncognitoTabsModal,
      extension.hasActiveIncognitoWindows,
    ],
  );

  return {
    start,
    startLocal,
    stop,
    runNextStep,
    pause,
    resume,
    record,
    incognitoMode: project.settings.incognitoMode,
    isExtensionActive: extension.isActive,
    isExtensionConnected: extension.isConnected,
    isExtensionAllowedIncognitoAccess: extension.hasIncognitoAccess,
    isIncognitoModeRequired: extension.isIncognitoModeRequired,
    isIdle,
    isRunning,
    isRecording,
    isDebugging,
    isQueued,
    isCloudRun: isCloudRun(testRunResult),
  };
};

export default useTestRunner;
