import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import {
  selectSingleTest,
  selectRecentUnconfirmedGroup,
  selectIsSingleTestLoading,
} from '~/modules/test/test.selectors';

const useTestRecordingFinished = (onFinish) => {
  const initialScrollHasBeenSkipped = useRef(false);
  const test = useSelector(selectSingleTest);
  const isLoading = useSelector(selectIsSingleTestLoading);
  const unconfirmedGroup = useSelector(selectRecentUnconfirmedGroup);

  useUpdateEffect(() => {
    if (initialScrollHasBeenSkipped.current && !test.isRecording && !isLoading) {
      onFinish(unconfirmedGroup);
    } else {
      initialScrollHasBeenSkipped.current = true;
    }
  }, [test.isRecording, unconfirmedGroup, isLoading]);
};

export default useTestRecordingFinished;
