import IconButtonBase from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme/colors';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

const pendingStyles = css`
  border-radius: 0;
  padding: 3px;

  &:hover,
  &:active {
    background-color: ${COLOR.TRANSPARENT};
  }

  svg {
    position: absolute;
    min-height: 0;
    min-width: 0;
    height: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const IconButton = styled(IconButtonBase)`
  font-size: 24px;

  ${styleWhenTrue(prop('pending'), pendingStyles)}
`;
