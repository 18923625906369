import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import TutorialMessageBase from '~/components/InlineTutorialMessage';
import Page, { Content as PageContent, Header, HeaderWrapper } from '~/components/Page';
import TabsBase from '~/components/Tabs';

export const Container = styled(Page)`
  flex: 1;
  display: flex;
  overflow: hidden;

  ${HeaderWrapper} {
    display: flex;
    flex-wrap: wrap;
  }

  ${Header} {
    padding-right: 20px;
    flex: 50%;
  }

  ${PageContent} {
    padding-right: 0;
    padding-bottom: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  overflow: auto;
  padding-bottom: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TabContent = styled.div`
  padding-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const VariablesSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding-right: 20px;

  & + & {
    margin-top: 50px;
  }

  h2 {
    font-size: 18px;
    color: ${COLOR.PRIMARY};
  }

  > p {
    color: ${COLOR.GRAY_19};
    line-height: 16px;
  }
`;

export const VariablesList = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
`;

export const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const TableEmptyState = styled.div`
  background-color: ${COLOR.WHITE};
  position: relative;
  height: 100%;

  ${LoaderContainer} {
    top: 25px;
  }
`;

export const TutorialMessage = styled(TutorialMessageBase)`
  margin-right: 20px;
`;

export const Tabs = styled(TabsBase)`
  && {
    max-width: 100% !important;
    width: 100% !important;
  }
`;
