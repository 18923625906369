import getCaretCoordinates from 'textarea-caret';

import type { HTMLInputLikeElement } from './InputWithVariables.types';

import * as S from './InputWithVariables.styled';

export const variableStartToken = '{{';
export const variableEndToken = '}}';

export const getTriggerStartIndex = (element: HTMLInputLikeElement) => {
  let variableStartIndex = -1;

  const { value, selectionStart } = element;
  if (!selectionStart) return variableStartIndex;

  // Find variable start token, skip redundant tokens: 012{{{{ -> index 3
  for (let i = selectionStart; i >= 0; i -= 1) {
    const chars = value.slice(i - variableStartToken.length, i);

    if (chars === variableStartToken) {
      // Update index and continue searching for redundant tokens
      variableStartIndex = i - variableStartToken.length;

      // Stop if it's not a start token and we already found one
    } else if (variableStartIndex > 0) {
      return variableStartIndex;
    }

    // Stop if end token was found
    if (chars === variableEndToken && i < selectionStart) {
      return variableStartIndex;
    }
  }

  return variableStartIndex;
};

export const getTrigger = (element: HTMLInputLikeElement) => {
  const { value, selectionStart } = element;
  if (!selectionStart) return null;

  const lastChars = value.slice(selectionStart - variableStartToken.length, selectionStart);
  if (lastChars === variableStartToken) return lastChars;

  if (!isBeforeVariableEnd(element)) return null;

  const triggerStartIndex = getTriggerStartIndex(element);
  if (triggerStartIndex === -1) return null;

  const triggerStart = value.slice(
    triggerStartIndex,
    triggerStartIndex + variableStartToken.length,
  );

  if (triggerStart !== variableStartToken) return null;

  return triggerStart;
};

export const calculateAnchorRect = (element: HTMLInputLikeElement) => {
  const triggerStartIndex = getTriggerStartIndex(element);
  if (triggerStartIndex === -1) return null;

  const { left, top, height } = getCaretCoordinates(
    element,
    triggerStartIndex + variableStartToken.length,
  );

  const { x, y, width } = element.getBoundingClientRect();

  const inputYOffset = 8;
  const inputCaretYPos = y + inputYOffset;
  const textareaCaretYPos = top + y - element.scrollTop;

  const caretYPos = element instanceof HTMLTextAreaElement ? textareaCaretYPos : inputCaretYPos;

  const caretXOffset = left + x;
  const maxX = x + width - S.variablesDropdownWidthPx;
  const minX = x + 2; // 2px input outline
  const caretXPos = Math.max(Math.min(maxX, caretXOffset), minX);

  return {
    x: caretXPos,
    y: caretYPos,
    height,
  };
};

export const hasEnteredFirstCharOfEndToken = (element: HTMLInputLikeElement) => {
  const { value, selectionStart } = element;
  if (!selectionStart) return false;

  const lastChar = value.slice(selectionStart - 1, selectionStart);
  return lastChar === variableEndToken[0];
};

export const isVariableEnd = (element: HTMLInputLikeElement) => {
  const { value, selectionStart } = element;
  if (!selectionStart) return false;

  const lastChars = value.slice(selectionStart - variableEndToken.length, selectionStart);
  return lastChars === variableEndToken;
};

export const isBeforeVariableEnd = (element: HTMLInputLikeElement) => {
  const { value, selectionStart } = element;
  if (!selectionStart) return false;

  const nextChars = value.slice(selectionStart, selectionStart + variableEndToken.length);
  return nextChars === variableEndToken;
};

export const getSearchValue = (element: HTMLInputLikeElement) => {
  const triggerStartIndex = getTriggerStartIndex(element);
  if (triggerStartIndex === -1 || !element.selectionStart) return '';
  return element.value.slice(triggerStartIndex + variableStartToken.length, element.selectionStart);
};

export const isRevertCommand = (event: KeyboardEvent) =>
  (event.metaKey || event.ctrlKey) && event.key === 'z';

export const getVariablesNamesInValue = (value: string) =>
  Array.from(value.matchAll(/{{([^{]+?)}}/gm)).map((match) => match[1]);
