import Link from '@bugbug/core/components/Link';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Step } from '@bugbug/core/types/steps';
import analytics from '~/services/analytics';

import * as S from './StepRunScreenshot.styled';

interface StepRunScreenshotProps {
  step: Step;
}

const StepRunScreenshot = ({ step }: StepRunScreenshotProps) => {
  const { t } = useTranslation();

  const trackScreenshotClick = useCallback(() => {
    analytics.trackEvent('screenshot_fullpage_clicked');
  }, []);

  return step.screenshot ? (
    <Link to={step.screenshot} openNewTab onClick={trackScreenshotClick}>
      <S.Image src={step.screenshot} loading="lazy" />
    </Link>
  ) : (
    <S.EmptyStateLabel>{t('stepRunScreenshot.emptyState.label')}</S.EmptyStateLabel>
  );
};

export default StepRunScreenshot;
