import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { selectSingleTest } from '~/modules/test/test.selectors';

import StepsBaseList from '../StepsBaseList';

const StepsList = forwardRef(({ testRunId, onSelectionChange }, ref) => {
  const test = useSelector(selectSingleTest);
  const lastResult = propOr({}, 'testRun', test);

  return (
    <StepsBaseList
      ref={ref}
      test={test}
      testRunId={lastResult.id || testRunId}
      onSelectionChange={onSelectionChange}
      readOnly={test.isRecording}
    />
  );
});

StepsList.displayName = 'StepsList';

StepsList.defaultProps = {
  onSelectionChange: Function.prototype,
  testRunId: null,
};

StepsList.propTypes = {
  testRunId: PropTypes.string,
  onSelectionChange: PropTypes.func,
};

export default StepsList;
