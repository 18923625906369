import CopyButton from '@bugbug/core/components/CopyButton';
import Input from '@bugbug/core/components/Input';
import { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CodeField from '~/components/CodeField';
import FormField from '~/components/FormField';
import { FormikInputWithVariables } from '~/components/InputWithVariables/FormikInputWithVariables';
import {
  ASSERTION_OPERATOR_FIELD_LABEL,
  ASSERTION_PROPERTY_TYPE,
  ASSERTION_TYPE,
  ASSERTION_TYPES_BY_PROPERTY,
} from '~/constants/step';

// eslint-disable-next-line object-curly-newline
import ElementSelectorField, {
  FIELD_NAMES as ELEMENT_SELECTOR_FIELD_NAMES,
  // eslint-disable-next-line object-curly-newline
} from '../ElementSelectorField';
import { isDocumentAssertion, isTrueFalseAssertion } from '../Interaction/Interaction.helpers';
import { ComputedField, ComputedValue } from '../Interaction/Interaction.styled';

import { FIELD_NAMES } from './AssertActionFields.constants';
import * as S from './AssertActionFields.styled';

const AssertActionFields = ({ context, stepRun, readOnly = false }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const currentProperty = formik.values[FIELD_NAMES.ASSERTION_PROPERTY];
  const assertionTypes = ASSERTION_TYPES_BY_PROPERTY[currentProperty];

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { error } = formik.getFieldMeta(fieldName);
      return { name, value, error, 'aria-labelledby': name };
    },
    [formik],
  );

  return (
    <>
      {ASSERTION_PROPERTY_TYPE.VARIABLE_VALUE === currentProperty && (
        <FormField
          label={t('stepDetails.interaction.variableName', 'Variable name')}
          labelId={FIELD_NAMES.ASSERTION_VARIABLE_NAME}
        >
          <Input
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getFieldProps(FIELD_NAMES.ASSERTION_VARIABLE_NAME)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            readOnly={readOnly}
          />
        </FormField>
      )}

      {!isDocumentAssertion(currentProperty) && (
        <ElementSelectorField
          context={context}
          name={ELEMENT_SELECTOR_FIELD_NAMES.SELECTORS}
          computedValue={stepRun.computedSelector}
          disabled={readOnly}
        />
      )}
      {!isTrueFalseAssertion(currentProperty) && (
        <>
          <FormField
            label={ASSERTION_OPERATOR_FIELD_LABEL[currentProperty].DEFAULT}
            labelId={FIELD_NAMES.ASSERTION_TYPE}
          >
            {assertionTypes.length > 1 && (
              <S.Select
                fullWidth
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getFieldProps(FIELD_NAMES.ASSERTION_TYPE)}
                onChange={formik.handleChange}
                value={formik.values[FIELD_NAMES.ASSERTION_TYPE] || assertionTypes[0].value}
                disabled={!assertionTypes.length || readOnly}
              >
                {assertionTypes.map(({ label, value }) => (
                  <SelectOption key={value} value={value}>
                    {label}
                  </SelectOption>
                ))}
              </S.Select>
            )}

            {formik.values[FIELD_NAMES.ASSERTION_TYPE] !== ASSERTION_TYPE.ANY && (
              <FormikInputWithVariables
                as="textarea"
                name={FIELD_NAMES.ASSERTION_EXPECTED_VALUE}
                readOnly={readOnly}
                fullWidth
                maxInitialHeight={300}
                autoSize
              />
            )}
          </FormField>
          {stepRun.computedAssertionExpectedValue && (
            <ComputedField
              label={
                context === 'testRun'
                  ? t(
                      'stepDetails.interaction.thisComputedAssertionExpectedValue',
                      'This test run expected value',
                    )
                  : t(
                      'stepDetails.interaction.lastComputedAssertionExpectedValue',
                      'Last test run expected value',
                    )
              }
            >
              <ComputedValue>{stepRun.computedAssertionExpectedValue}</ComputedValue>
              <CopyButton value={stepRun.computedAssertionExpectedValue} />
            </ComputedField>
          )}
        </>
      )}

      {ASSERTION_PROPERTY_TYPE.CUSTOM_JAVASCRIPT === currentProperty && (
        <CodeField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.ASSERTION_JAVASCRIPT)}
          functionArguments={['element', 'selector', 'variables']}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

AssertActionFields.defaultProps = {
  context: 'test',
};

AssertActionFields.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  readOnly: PropTypes.bool,
};

export default AssertActionFields;
