import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import { ParamsCell } from '~/components/Table';
import { STEP_TYPE, STEP_TYPE_ICON_NAME } from '~/constants/step';

import { Container, IconWrapper, StepName, SleepIcon, Tooltip } from './StepNameWithParams.styled';

const StepNameWithParams = ({ value }) => {
  const { t } = useTranslation();
  const iconName =
    STEP_TYPE_ICON_NAME[STEP_TYPE.ASSERT === value.type ? value.assertionProperty : value.type];

  return (
    <Container>
      <IconWrapper>
        <Icon name={iconName} />
      </IconWrapper>
      <StepName type={value.type} />
      <ParamsCell id={value.frontId || value.id} type={value.type} />
      {value.sleep ? (
        <Tooltip
          content={t(
            'stepName.sleep.tooltip',
            'This step has Delay / Sleep set for {{seconds}} seconds',
            {
              seconds: value.sleep,
            },
          )}
        >
          <SleepIcon />
        </Tooltip>
      ) : null}
    </Container>
  );
};

export default StepNameWithParams;
