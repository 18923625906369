import { FONT_WEIGHT, lineClamp } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export const MetaValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  ${lineClamp({ lines: 1 })}
`;
