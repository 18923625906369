import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { complement, propEq } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${styleWhenTrue(
    complement(propEq('onClickCapture', null)),
    css`
      color: ${COLOR.PRIMARY_LIGHT};
      font-weight: ${FONT_WEIGHT.BOLD};

      &:hover {
        text-decoration: underline;
      }
    `,
  )}
`;
