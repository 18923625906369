import { useTranslation } from 'react-i18next';

import type { AlertStatus, AlertRunMode, Alert } from '@bugbug/core/types/alerts';
import {
  METHODS_OPTIONS,
  STATUS_OPTIONS,
  ALERT_EVENTS,
} from '~/components/modals/EditAlertModal/EditAlertModal.constants';
import { splitByCamelCase } from '~/utils/text';

import * as S from './AlertItemWhen.styled';

interface AlertItemWhenProps {
  data: Alert;
}

const AlertItemWhen = ({ data: alert }: AlertItemWhenProps) => {
  const { t } = useTranslation();

  const runWhen = ALERT_EVENTS.find(({ value }) => value === alert.event);
  const runMode = METHODS_OPTIONS.find(({ value }) =>
    alert.runMode.includes(value as AlertRunMode),
  );
  const status = alert.status.includes('all')
    ? t('alertsListItem.allStatus.label', 'All results')
    : STATUS_OPTIONS.filter(({ value }) => alert.status.includes(value as AlertStatus))
        .map((row) => row.label)
        .join(', ');

  const whatKey = splitByCamelCase(alert.event)[0];

  const what = {
    test: t('alertsListItem.event.test', {
      defaultValue_one: 'test',
      defaultValue_other: 'tests',
      count: alert.objects.length,
    }),
    suite: t('alertsListItem.event.suite', {
      defaultValue_one: 'suite',
      defaultValue_other: 'suites',
      count: alert.objects.length,
    }),
    schedule: t('alertsListItem.event.schedule', {
      defaultValue_one: 'schedule',
      defaultValue_other: 'schedules',
      count: alert.objects.length,
    }),
  }[whatKey];

  return (
    <S.Container>
      <S.Title>{runWhen?.label}</S.Title>
      <S.Content data-testid="AlertItemWhen">
        {!alert.objects.length ? t('alertsListItem.event.all', 'All') : alert.objects.length} {what}{' '}
        · {status} {!alert.runMode.includes('all') && `· ${runMode?.label || ''}`}
      </S.Content>
    </S.Container>
  );
};

export default AlertItemWhen;
