import Loader from '@bugbug/core/components/Loader';
import Tooltip from '@bugbug/core/components/Tooltip';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMount from 'react-use/lib/useMount';
import useUnmount from 'react-use/lib/useUnmount';

import { CONDITION_MESSAGE_DELAY } from './StepStatusCell.constants';
import { useUnresolvedCondition } from './StepStatusCell.hooks';

interface PendingStatusProps {
  stepRunId: string;
  sleep: number;
  progressTooltipDisabled: boolean;
}

export const PendingStatus = ({
  stepRunId,
  sleep,
  progressTooltipDisabled,
}: PendingStatusProps) => {
  const { t } = useTranslation();
  const sleepMs = sleep * 1000;
  const unresolvedCondition = useUnresolvedCondition(stepRunId, CONDITION_MESSAGE_DELAY);
  const sleepTimer = useRef<ReturnType<typeof setTimeout>>();
  const [showSleepMessage, setShowSleepMessage] = useState<boolean>(!!sleepMs);
  let label = '';

  if (unresolvedCondition.isVisible) {
    label = t('stepStatusCell.conditionLabel', 'Awaiting "{{ condition }}"', {
      // @ts-expect-error Fix this after migration of useUnresolvedCondition to TypeScript
      condition: unresolvedCondition.value.label,
    });
  }

  if (showSleepMessage) {
    label = t('stepStatusCell.sleepLabel', 'Sleep/Delay');
  }

  useMount(() => {
    sleepTimer.current = setTimeout(() => {
      setShowSleepMessage(false);
    }, sleepMs);
  });

  useUnmount(() => {
    clearTimeout(sleepTimer.current);
    sleepTimer.current = undefined;
  });

  return (
    <Tooltip
      anchor="bottom-center"
      content={progressTooltipDisabled ? '' : label}
      variant="light"
      forceOpen={!progressTooltipDisabled && (showSleepMessage || !!label)}
      overflowContainer={false}
    >
      <Loader size="small" />
    </Tooltip>
  );
};
