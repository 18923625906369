import { COLOR } from '@bugbug/core/theme/colors';
import { RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  max-height: 230px;
  cursor: pointer;
  object-fit: cover;
  object-position: 0% 0%;
  overflow: hidden;
  width: 100%;
  border: 2px solid ${COLOR.GRAY_3};
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 ${COLOR.GRAY_30};
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const EmptyStateLabel = styled(RegularParagraph)`
  color: ${COLOR.GRAY_23};
`;
