import i18n from '~/translations';

export const SELECTOR_NAME = {
  ELEMENT_ID: 'elementId',
  ELEMENT_NAME: 'elementName',
  ELEMENT_HREF: 'elementHref',
  ELEMENT_PLACEHOLDER: 'elementPlaceholder',
  ELEMENT_CLASS_NAME: 'elementClassName',
  ELEMENT_TEXT: 'elementText',
  ELEMENT_FULL_XPATH: 'elementFullXPath',
  ELEMENT_CUSTOM_ATTRIBUTES: 'elementCustomAttributes',
};

export const SELECTOR_PARAMS = {
  [SELECTOR_NAME.ELEMENT_ID]: {
    label: i18n.t('projectSettings.selectorsSettings.elementId.label', 'id'),
    extraValuePlaceholder: "id='example'",
  },
  [SELECTOR_NAME.ELEMENT_NAME]: {
    label: i18n.t('projectSettings.selectorsSettings.elementName.label', 'Input or form name'),
    extraValuePlaceholder: "name='example'",
  },
  [SELECTOR_NAME.ELEMENT_HREF]: {
    label: i18n.t('projectSettings.selectorsSettings.elementHref.label', 'href'),
    extraValuePlaceholder: "href='/example'",
  },
  [SELECTOR_NAME.ELEMENT_PLACEHOLDER]: {
    label: i18n.t(
      'projectSettings.selectorsSettings.elementPlaceholder.label',
      'Input placeholder',
    ),
    extraValuePlaceholder: "placeholder='example'",
  },
  [SELECTOR_NAME.ELEMENT_CLASS_NAME]: {
    label: i18n.t('projectSettings.selectorsSettings.elementClassName.label', 'class name'),
    extraValuePlaceholder: "class='example'",
  },
  [SELECTOR_NAME.ELEMENT_TEXT]: {
    label: i18n.t('projectSettings.selectorsSettings.elementText.label', 'Text content'),
    extraValuePlaceholder: 'HTML element.textContent',
  },
  [SELECTOR_NAME.ELEMENT_FULL_XPATH]: {
    label: i18n.t('projectSettings.selectorsSettings.elementFullXPath.label', 'XPath'),
    extraValuePlaceholder: 'element full XPath',
  },
  [SELECTOR_NAME.ELEMENT_CUSTOM_ATTRIBUTES]: {
    label: i18n.t(
      'projectSettings.selectorsSettings.elementCustomAttributes.label',
      'Custom attributes',
    ),
    tooltip: i18n.t(
      'projectSettings.selectorsSettings.elementCustomAttributes.tooltip',
      'List of extraValue and their values, separated by comma. For example data-test="value", data-bugbug="value"',
    ),
    isExtraValueEditable: true,
    extraValuePlaceholder: 'data-testid, data-test',
  },
};
