/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import UnorganizedList from '@bugbug/core/components/UnorganizedList';
import { propOr } from 'ramda';
import { Trans } from 'react-i18next';

import { STEP_SCROLL_EDGE_MAP, STEP_SCROLL_TO_TYPE } from '~/constants/step';
import { getWholeNumberOrDecimal } from '~/utils/misc';

import ErrorTips from '../ErrorTips';

export const getDescription = (stepRun, step) => {
  const scrollToMessage = {
    [STEP_SCROLL_TO_TYPE.COORDS]: () => {
      const expectedX = step.scrollX;
      const expectedY = step.scrollY;
      const currentX = getWholeNumberOrDecimal(stepRun.scrollX);
      const currentY = getWholeNumberOrDecimal(stepRun.scrollY);

      return (
        <Trans
          i18nKey="scrollFailedDetails.errorMessage.coords"
          values={{ expectedX, expectedY, currentX, currentY }}
        >
          Couldn't scroll to expected XY coordinates.
          <br />
          Attempted to scroll to (x: {{ expectedX }}, y: {{ expectedY }}).
          <br />
          Actually scrolled to (x: {{ currentX }}, y: {{ currentY }}).
          <br />
          <ErrorTips>
            Possible solutions:
            <UnorganizedList>
              <li>update the expected XY scroll position in the step details below</li>
              <li>change the "Scroll to" to a different type, ex. "Position &gt; Bottom center"</li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
    },
    [STEP_SCROLL_TO_TYPE.EDGE]: () => {
      const expectedEdge = STEP_SCROLL_EDGE_MAP[step.scrollEdge].label.toLowerCase();
      return (
        <Trans i18nKey="scrollFailedDetails.errorMessage.edge">
          Couldn't scroll to {{ expectedEdge }}.
          <br />
          <ErrorTips>
            Possible solutions:
            <UnorganizedList>
              <li>update the expected position in the step details below</li>
              <li>
                change the "Scroll to" to a different type, ex. "Until element from next step is
                visible"
              </li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      );
    },
  };

  return propOr(() => {}, step.scrollTo, scrollToMessage)();
};
