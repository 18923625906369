import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { SelectOption } from '@bugbug/core/components/Select';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { remove, update, prop, isEmpty } from 'ramda';
import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FormField from '~/components/FormField';
import { CONDITION_PARAMS, WAITING_CONDITIONS_LIST } from '~/modules/constans';
import { selectSingleProjectConditionByType } from '~/modules/project/project.selectors';
import { PROJECT_SETTINGS_TAB } from '~/views/ProjectSettings/ProjectSettings.constants';
import urls, { reverse } from '~/views/urls';

import { WAITING_CONDITION_STATE } from '../StepDetails/StepDetails.constants';

import { FIELD_NAMES } from './WaitingConditions.constants';
import {
  Container,
  Url,
  Dropdown,
  Select,
  Input,
  ConditionState,
  CustomSelectOption,
  ActionButton,
} from './WaitingConditions.styled';

const WaitingConditions = ({ className, readOnly }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { projectSlug, projectId, organizationId } = useParams();
  const conditionsByType = useSelector(selectSingleProjectConditionByType);

  const conditions = useMemo(
    () =>
      formik.values[FIELD_NAMES.WAITING_CONDITIONS].reduce(
        (groupedConditions, condition, index) => {
          const conditionWithIndex = { ...condition, index };
          if (condition.isGlobal) {
            groupedConditions.global.push(conditionWithIndex);
          } else {
            groupedConditions.additional.push(conditionWithIndex);
          }
          return groupedConditions;
        },
        { global: [], additional: [] },
      ),
    [formik.values],
  );

  const availableConditionsList = useMemo(() => {
    const notAvailableTypes = conditions.global.map(prop('type'));
    return WAITING_CONDITIONS_LIST.filter(({ type }) => !notAvailableTypes.includes(type));
  }, [conditions.global]);

  const handleDelete = (index) => () => {
    formik.setFieldValue(
      FIELD_NAMES.WAITING_CONDITIONS,
      remove(index, 1, formik.values[FIELD_NAMES.WAITING_CONDITIONS]),
    );
  };

  const updateCondition = (index, updatedFields) => {
    const updatedCondition = {
      ...formik.values[FIELD_NAMES.WAITING_CONDITIONS][index],
      ...updatedFields,
    };
    formik.setFieldValue(
      FIELD_NAMES.WAITING_CONDITIONS,
      update(index, updatedCondition, formik.values[FIELD_NAMES.WAITING_CONDITIONS]),
    );
  };

  const handleStateChange = (index) => (event) => {
    const updatedFields = {
      isActive: event.target.value !== WAITING_CONDITION_STATE.DISABLED,
    };
    if (formik.values[FIELD_NAMES.WAITING_CONDITIONS][index].isGlobal) {
      updatedFields.isOverridden = event.target.value !== WAITING_CONDITION_STATE.DEFAULT;
    }
    updateCondition(index, updatedFields);
  };

  const handleValueChange = (index) => (event) => {
    updateCondition(index, { expected: isEmpty(event.target.value) ? null : event.target.value });
  };

  const handleAdd = (type) => () => {
    const waitingConditions = [
      ...formik.values[FIELD_NAMES.WAITING_CONDITIONS],
      { type, isActive: true, isOverridden: false },
    ];
    formik.setFieldValue(FIELD_NAMES.WAITING_CONDITIONS, waitingConditions);
  };

  // eslint-disable-next-line react/prop-types
  const renderCondition = ({ type, expected: value, isActive, isGlobal, isOverridden, index }) => {
    const { label, hasValue, valueOptions } = CONDITION_PARAMS[type];
    const projectCondition = conditionsByType[type] || {};
    const labelId = `waiting-condition-label-${index}`;

    const defaultActivityState = isActive
      ? WAITING_CONDITION_STATE.ENABLED
      : WAITING_CONDITION_STATE.DISABLED;
    const state =
      isGlobal && !isOverridden ? WAITING_CONDITION_STATE.DEFAULT : defaultActivityState;

    let placeholder = hasValue ? valueOptions.placeholder : '';

    if (!placeholder && projectCondition && projectCondition.expected) {
      placeholder = t('waitingConditions.expected.default', 'Default: {{ value }}', {
        value: projectCondition.expected,
      });
    }

    return [
      <FormField key={labelId} label={label} labelId={labelId}>
        <Select
          value={state}
          onChange={handleStateChange(index)}
          disabled={readOnly}
          aria-labelledby={labelId}
        >
          {isGlobal && (
            <SelectOption value={WAITING_CONDITION_STATE.DEFAULT}>
              <CustomSelectOption>
                <ConditionState enabled={projectCondition.isActive} />
                {t('waitingConditions.state.default.label', 'Use project settings')}
                <span>
                  (
                  {projectCondition.isActive
                    ? t('waitingConditions.state.enabled')
                    : t('waitingConditions.state.disabled')}
                  )
                </span>
              </CustomSelectOption>
            </SelectOption>
          )}
          <SelectOption value={WAITING_CONDITION_STATE.ENABLED}>
            <CustomSelectOption>
              <ConditionState enabled /> {t('waitingConditions.state.enabled', 'Enabled')}
            </CustomSelectOption>
          </SelectOption>
          <SelectOption value={WAITING_CONDITION_STATE.DISABLED}>
            <CustomSelectOption>
              <ConditionState /> {t('waitingConditions.state.disabled', 'Disabled')}
            </CustomSelectOption>
          </SelectOption>
          {!isGlobal && !readOnly && (
            <ActionButton aria-label="Delete" onClick={handleDelete(index)}>
              <Icon name="delete" /> {t('waitingConditions.delete', 'Delete')}
            </ActionButton>
          )}
        </Select>
      </FormField>,
      hasValue &&
        WAITING_CONDITION_STATE.ENABLED === state &&
        (!isGlobal || (isGlobal && isOverridden)) && (
          <FormField label={valueOptions.label} helperText={valueOptions.tooltip}>
            <Input
              type={valueOptions.type}
              min={valueOptions.min}
              fullWidth
              value={value}
              onChange={handleValueChange(index)}
              placeholder={placeholder}
              endAdornment={valueOptions.endAdornment}
            />
          </FormField>
        ),
    ];
  };

  return (
    <Container className={className} data-testid="WaitingConditions">
      <p>
        <Trans key="waitingConditions.section.global.description">
          By default inherited from{' '}
          <Url
            to={reverse(urls.projectSettings, {
              projectSlug,
              projectId,
              organizationId,
              settingsTabSlug: PROJECT_SETTINGS_TAB.WAITING_CONDITIONS,
            })}
          >
            global project settings.
          </Url>{' '}
          You can override the global settings for each step.
        </Trans>
      </p>
      {conditions.global.map(renderCondition)}
      {conditions.additional.map(renderCondition)}
      {!readOnly && (
        <Dropdown
          label={t('waitingConditions.section.additional.button', 'Add condition')}
          iconName="addCircle"
        >
          {availableConditionsList.map(({ label, type }) => (
            <DropdownItem small key={type} onClick={handleAdd(type)}>
              {label}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

WaitingConditions.defaultProps = {
  className: null,
  readOnly: false,
};

WaitingConditions.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default WaitingConditions;
