import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { ErrorMessage as ErrorMessageBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import FilePreviewBase from '~/components/FilePreview';

export const Container = styled.div`
  height: 38px;

  && .dzu-dropzone {
    height: 38px;
    min-height: auto;
    overflow: auto;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all 0.15s linear;
    border: 2px dashed ${COLOR.GRAY_9};
    border-radius: 4px;
    color: #333333;
    margin-bottom: 0;
  }

  && .dzu-inputLabel {
    color: inherit;
    font-size: 13px;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
    font-weight: 400;
    padding-left: 10px;
    font-family: 'NeueFrutiger', sans-serif;
    padding-bottom: 2px;

    span {
      color: ${COLOR.PRIMARY_LIGHT};
      font-weight: ${FONT_WEIGHT.MEDIUM};
    }
  }

  && .dzu-dropzoneActive {
    background-color: rgba(82, 30, 201, 0.08);
    border-color: ${COLOR.PRIMARY_LIGHT};
    color: ${COLOR.PRIMARY_LIGHT};
  }

  .dzu-previewImage {
    max-height: 38px;
    border: 2px solid ${COLOR.GRAY_9};
  }

  && .dzu-previewContainer {
    padding: 0;
    height: 38px;
    min-height: auto;
    border-bottom: none;
  }

  progress {
    display: none;
  }
`;

export const FilePreview = styled(FilePreviewBase)`
  height: 100%;
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  margin-top: 0;
`;
