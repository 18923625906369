import PropTypes from 'prop-types';
import { memo } from 'react';

import TestRunMetadata from '~/components/TestRunMetadata';
import useFeatureFlags from '~/hooks/useFeatureFlags';

import DownloadLogsButton from '../DownloadLogsButton';
import TestRunActions from '../TestRunActions';

import { Actions, Container, NameContainer, NameInput, Title } from './TestRunHeader.styled';

const TestRunHeader = memo(({ test, testRun, onErrorClick }) => {
  const featureFlags = useFeatureFlags();

  return (
    <Container data-testid="TestRunHeader">
      <Title>
        <NameContainer data-testid="TestRunHeader.Name">
          <NameInput value={test?.name} readOnly />
        </NameContainer>
        <Actions>
          {featureFlags.runLogs && <DownloadLogsButton url={testRun.logsArtifactUrl} />}
          <TestRunActions testRun={testRun} />
        </Actions>
      </Title>
      <TestRunMetadata testRunId={testRun.id} onErrorClick={onErrorClick} />
    </Container>
  );
});

TestRunHeader.displayName = 'TestRunHeader';

TestRunHeader.defaultProps = {
  onErrorClick: Function.prototype,
};

TestRunHeader.propTypes = {
  onErrorClick: PropTypes.func,
  test: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  testRun: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default TestRunHeader;
