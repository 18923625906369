import Checkbox from '@bugbug/core/components/Checkbox';
import Icon from '@bugbug/core/components/Icon';
import IconButton from '@bugbug/core/components/IconButton';
import {
  memo,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import ExpanderButton from '~/components/ExpanderButton';
import useActionState from '~/hooks/useActionState';
import { TestActions } from '~/modules/test/test.redux';

import GroupMetaData from '../GroupMetaData';
import {
  GroupHeader,
  GroupNameInput,
  GroupActions,
  ExpanderContainer,
  SelectionContainer,
  DragHandler,
  GroupNameContainer,
  GroupNameOverlay,
  GroupMetaWrapper,
  IntersectionDetection,
} from '../StepsGroup/StepsGroup.styled';
import ToggleComponent from '../ToggleComponent';

const StepsGroupHeader = memo(
  forwardRef((props, ref) => {
    const {
      testId,
      group,
      readOnly,
      dragHandleProps,
      expanded,
      onSelectionChange,
      onExpandClick,
      runResultEnabled,
      onEnter,
      onLeave,
    } = props;

    const { t } = useTranslation();
    const animationFrameRequestId = useRef();
    const dispatch = useDispatch();
    const headerRef = useRef();
    const detectorRef = useRef();
    const fieldRef = useRef();
    const checkboxRef = useRef();
    const pinObserver = useRef();
    const expandedRef = useRef();
    const { isComponent, isNameFocused } = group;
    const [isPinned, setIsPinned] = useState(false);
    const [isNameEditable, setIsNameEditable] = useState(false);
    const [selectionState, setSelectionState] = useState({
      all: false,
      partial: false,
    });

    const { errors } = useActionState(TestActions.renameGroupRequest, {
      reqId: group.id,
    });

    expandedRef.current = expanded;
    const groupName = group.name || t('default.group.name');

    const focusNameField = useCallback((event) => {
      event?.stopPropagation();
      setIsNameEditable(true);

      animationFrameRequestId.current = requestAnimationFrame(() => {
        fieldRef.current?.input.focus();
        fieldRef.current?.input.select();
      });
    }, []);

    const handleFieldBlur = useCallback(() => {
      setIsNameEditable(false);
    }, []);

    useMount(() => {
      if (!isComponent && isNameFocused) {
        focusNameField();
      }

      pinObserver.current = new IntersectionObserver(
        ([entry]) => {
          const isBottomVisible =
            entry.boundingClientRect.bottom < window.innerHeight && entry.boundingClientRect.bottom;

          const newPinnedState =
            entry.intersectionRatio < 1 && expandedRef.current && isBottomVisible;
          headerRef.current?.classList?.toggle('is-pinned', newPinnedState);
          setIsPinned(newPinnedState);
        },
        { threshold: [1] },
      );

      pinObserver.current.observe(detectorRef.current);
    });

    useUnmount(() => {
      cancelAnimationFrame(animationFrameRequestId.current);
      pinObserver.current.disconnect();
    });

    useUpdateEffect(() => {
      setIsNameEditable(false);
    }, [groupName]);

    useEffect(() => {
      if (!expanded) {
        setIsPinned(false);
        pinObserver.current?.classList?.remove('is-pinned');
      }
    }, [expanded]);

    useEffect(() => {
      if (errors?.name) {
        fieldRef.current.setError(errors.name);
        focusNameField();
      }
    }, [errors, focusNameField]);

    const handleNameChange = useCallback(
      (newName) => {
        dispatch(TestActions.renameGroupRequest(group.id, newName, { reqId: group.id }));
      },
      [dispatch, group.id],
    );

    const handleSelectionChange = useCallback(
      (event) => {
        onSelectionChange(event.target.checked);
        setSelectionState({ all: event.target.checked, partial: false });
      },
      [onSelectionChange],
    );

    const onGroupDelete = useCallback(() => {
      onSelectionChange(false);
      setSelectionState({ all: false, partial: false });
    }, [onSelectionChange]);

    useImperativeHandle(
      ref,
      () => ({
        setSelectionState,
        isSelected: selectionState.all,
        element: headerRef.current,
      }),
      [selectionState],
    );

    return (
      <>
        <IntersectionDetection ref={detectorRef} />
        <GroupHeader
          ref={headerRef}
          data-testid="GroupHeader"
          onMouseEnter={readOnly || isPinned ? null : onEnter}
          onMouseLeave={readOnly || isPinned ? null : onLeave}
          onClick={onExpandClick}
        >
          {!runResultEnabled && (
            <>
              <DragHandler dragHandleProps={dragHandleProps} />
              <SelectionContainer>
                <Checkbox
                  ref={checkboxRef}
                  data-testid="GroupSelectionCell"
                  onChange={handleSelectionChange}
                  disabled={readOnly}
                  indeterminate={selectionState.partial}
                  checked={selectionState.all}
                  small
                />
              </SelectionContainer>
            </>
          )}
          <ExpanderContainer>
            <ExpanderButton active={expanded} onClick={onExpandClick} />
          </ExpanderContainer>
          <GroupNameContainer>
            <GroupNameOverlay hidden={isNameEditable}>
              <div>{groupName}</div>
              {!runResultEnabled && (
                <IconButton onClick={focusNameField}>
                  <Icon name="edit" />
                </IconButton>
              )}
            </GroupNameOverlay>
            <GroupNameInput
              ref={fieldRef}
              type="text"
              name="value"
              value={groupName}
              onChange={handleNameChange}
              readOnly={readOnly}
              hidden={!isNameEditable}
              onBlur={handleFieldBlur}
            />
          </GroupNameContainer>
          <GroupMetaWrapper>
            <GroupMetaData group={group} compact={expanded} runResultEnabled={runResultEnabled} />
            <ToggleComponent testId={testId} group={group} readOnly={readOnly} />
          </GroupMetaWrapper>
          <GroupActions
            group={group}
            readOnly={readOnly}
            onDelete={onGroupDelete}
            onRename={focusNameField}
          />
        </GroupHeader>
      </>
    );
  }),
);

StepsGroupHeader.displayName = 'StepsGroupHeader';

export default StepsGroupHeader;
