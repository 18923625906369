import { PAGE_LOADING_ERROR_CODE } from '@bugbug/core/constants/errors';

import i18n from '~/translations';

export const DEFAULT_ERROR_DESCRIPTION = i18n.t(
  'pageLoadingFailedDetails.errorDescription.default',
  'Browser is having problems loading the page.',
);

const sslErrorMessage = i18n.t(
  'pageLoadingFailedDetails.errorDescription.badSslClientAuthCert',
  "Signing in to the website (for example, a bank or work website) isn't working due to an errored client certificate.",
);

export const ERROR_DESCRIPTION = {
  [PAGE_LOADING_ERROR_CODE.ERR_NAME_NOT_RESOLVED]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.nameNotResolved',
    'It is not possible to access the website at this hostname (web address).',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_INTERNET_DISCONNECTED]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.internetDisconnected',
    "An internet connection isn't available.",
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_CONNECTION_TIMED_OUT]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.connectionTimedOut',
    'The page took too long to connect. Your internet connection might be too slow, or the page might be too busy.',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_CONNECTION_RESET]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.connectionReset',
    'Something happened that interrupted your connection to the page.',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_NETWORK_CHANGED]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.networkChanged',
    'During the page loading, your device disconnected from the network or connected to a new one.',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_CONNECTION_REFUSED]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.connectionRefused',
    'The browser was unable to connect to the page.',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_CACHE_MISS]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.cacheMiss',
    'The page needs information you entered earlier to be submitted again.',
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_EMPTY_RESPONSE]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.emptyResponse',
    "The website didn't send any data, and might be down.",
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_SSL_PROTOCOL_ERROR]: i18n.t(
    'pageLoadingFailedDetails.errorDescription.sslProtocolError',
    "The page sent data that browser doesn't understand.",
  ),
  [PAGE_LOADING_ERROR_CODE.ERR_BAD_SSL_CLIENT_AUTH_CERT]: sslErrorMessage,
  [PAGE_LOADING_ERROR_CODE.ERR_CERT_DATE_INVALID]: sslErrorMessage,
};
