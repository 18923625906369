import { DROPDOWN_ANCHOR } from '@bugbug/core/components/Dropdown/Dropdown.constants';
import Input from '@bugbug/core/components/Input';
import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import { PROJECT_SETTINGS_RUN_LOGS } from '@bugbug/core/constants/project';
import { TIMEZONE_OPTIONS, TIMEZONES_VALUES } from '@bugbug/core/hooks/useTimezone';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { SELECT_STATE } from '~/modules/constans';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { VALIDATION_MESSAGE } from '~/utils/validators';
import * as validators from '~/utils/validators';

import {
  Row,
  Column,
  HelpIcon,
  Label,
  Description,
  SubmitButton,
  Select,
  Form,
  ButtonsWrapper,
  ErrorInfo,
} from '../ProjectSettings.styled';

import * as S from './GeneralSettings.styled';

const GeneralSettingsSchema = Yup.object().shape({
  name: validators.nameValidator,
  runTimeoutLocal: validators.runTimeoutValidator.required(VALIDATION_MESSAGE.REQUIRED),
  runTimeoutServer: validators.runTimeoutValidator.required(VALIDATION_MESSAGE.REQUIRED),
  sleep: validators.sleepValidator.required(VALIDATION_MESSAGE.REQUIRED),
  timezone: Yup.string().oneOf(TIMEZONES_VALUES),
});

const GeneralSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(selectSingleProject);

  const handleOnSubmit = ({ name, ...values }) => {
    dispatch(ProjectActions.updateGeneralSettingsRequest(name, values));
  };
  const flags = useFeatureFlags();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setErrors,
    submitForm,
  } = useFormik({
    initialValues: {
      name: project.name,
      runTimeoutLocal: project.settings.runTimeoutLocal,
      runTimeoutServer: project.settings.runTimeoutServer,
      timezone: project.settings.timezone,
      sleep: project.settings.sleep,
      closeWindowsOnSuccessfulTestRun: project.settings.closeWindowsOnSuccessfulTestRun,
      runLogs: project.settings.runLogs,
    },
    validationSchema: GeneralSettingsSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess, hasInternalServerError } = useActionState(
    ProjectActions.updateGeneralSettingsRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  const defaultTimeoutTooltipContent = t(
    'projectSettings.general.form.defaultTimeout.tooltipContent',
    'How many seconds to wait before marking a test as failed, when a test step has errors and it is not possible to continue running the test',
  );

  const renderTimeZone = useCallback(
    (timezone) => (
      <SelectOption key={timezone.value} value={timezone.value}>
        {timezone.label}
      </SelectOption>
    ),
    [],
  );

  const renderSelectedOption = (option) => (option.label ? <div>{option.label[0]}</div> : '');

  return (
    <Form noValidate data-testid="GeneralSettings" onSubmit={handleSubmit}>
      <Row>
        <Column>
          <Label>{t('projectSettings.general.form.name.label', 'Project name')}</Label>
        </Column>
        <Column>
          <Input
            type="text"
            name="name"
            placeholder={t(
              'projectSettings.general.form.name.placeholder',
              'ex. ACME Inc. Website',
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={touched.name && errors.name}
            fullWidth
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Label>{t('projectSettings.general.form.timezone.label', 'Timezone')}</Label>
        </Column>
        <Column>
          <Select
            name="timezone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.timezone}
            fullWidth
            showSearch
          >
            {TIMEZONE_OPTIONS.map(renderTimeZone)}
          </Select>
        </Column>
      </Row>

      <Row>
        <Column>
          <Tooltip content={defaultTimeoutTooltipContent}>
            <Label>
              {t(
                'projectSettings.general.form.defaultLocalTimeout.label',
                'Default local run timeout',
              )}
              <HelpIcon data-testid="HelpIcon" />
            </Label>
          </Tooltip>
          <Description>
            {t('projectSettings.general.form.defaultLocalTimeout.description', 'Seconds')}
          </Description>
        </Column>
        <Column>
          <Input
            type="number"
            name="runTimeoutLocal"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.runTimeoutLocal}
            error={touched.runTimeoutLocal && errors.runTimeoutLocal}
            endAdornment={t('default.units.timeout')}
            fullWidth
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Tooltip content={defaultTimeoutTooltipContent}>
            <Label>
              {t(
                'projectSettings.general.form.defaultCloudTimeout.label',
                'Default cloud run timeout',
              )}
              <HelpIcon data-testid="HelpIcon" />
            </Label>
          </Tooltip>
          <Description>
            {t('projectSettings.general.form.defaultCloudTimeout.description', 'Seconds')}
          </Description>
        </Column>
        <Column>
          <Input
            type="number"
            name="runTimeoutServer"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.runTimeoutServer}
            error={touched.runTimeoutServer && errors.runTimeoutServer}
            endAdornment={t('default.units.timeout')}
            fullWidth
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Label>{t('projectSettings.general.form.defaultSleep.label', 'Default sleep')}</Label>
          <Description>
            {t('projectSettings.general.form.defaultSleep.description', 'Seconds')}
          </Description>
        </Column>
        <Column>
          <Input
            type="number"
            name="sleep"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sleep}
            error={touched.sleep && errors.sleep}
            endAdornment={t('default.units.sleep')}
            fullWidth
          />
        </Column>
      </Row>

      <Row>
        <Column>
          <Label>
            {t(
              'projectSettings.general.form.closeWindowsOnSuccessfulTestRun.label',
              'Close windows on successful test run',
            )}
          </Label>
        </Column>
        <Column>
          <Select
            name="closeWindowsOnSuccessfulTestRun"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.closeWindowsOnSuccessfulTestRun.toString()}
            fullWidth
          >
            <SelectOption value={SELECT_STATE.ENABLED}>{t('default.select.enabled')}</SelectOption>
            <SelectOption value={SELECT_STATE.DISABLED}>
              {t('default.select.disabled')}
            </SelectOption>
          </Select>
        </Column>
      </Row>

      {flags.runLogs && (
        <Row>
          <Column>
            <Label>{t('projectSettings.general.form.runLogs.label', 'Collecting logs')}</Label>
          </Column>
          <Column>
            <Select
              name="runLogs"
              renderSelectedOption={renderSelectedOption}
              onChange={handleChange}
              onBlur={handleBlur}
              anchor={DROPDOWN_ANCHOR.BOTTOM_START}
              value={values.runLogs.toString()}
              fullWidth
            >
              <SelectOption value={PROJECT_SETTINGS_RUN_LOGS.DISABLED}>
                <div>{t('default.select.disabled')}</div>
                <S.SelectOptionHelpText>
                  {t(
                    'projectSettings.general.form.runLogs.options.disabledHelText',
                    'Don’t collect logs',
                  )}
                </S.SelectOptionHelpText>
                <span />
              </SelectOption>
              <SelectOption value={PROJECT_SETTINGS_RUN_LOGS.CONSOLE_LOGS}>
                {t('projectSettings.general.form.runLogs.options.consoleLogs', 'Console logs')}
                <S.SelectOptionHelpText>
                  {t(
                    'projectSettings.general.form.runLogs.options.consoleLogsHelpText',
                    'Collect browser console logs for each test run and view them in runs history. This can slow down your tests if your logs are large.',
                  )}
                </S.SelectOptionHelpText>
              </SelectOption>
              <SelectOption value={PROJECT_SETTINGS_RUN_LOGS.FULL_BUGBUG_DEBUGGING}>
                {t(
                  'projectSettings.general.form.runLogs.options.fullBugBugDebugging',
                  'Full BugBug debugging',
                )}
                <S.SelectOptionHelpText>
                  {t(
                    'projectSettings.general.form.runLogs.options.fullBugBugDebuggingHelptext',
                    'Enable this option if you encountered a BugBug problem and want to help the support team debug it.',
                  )}
                </S.SelectOptionHelpText>
              </SelectOption>
            </Select>
          </Column>
        </Row>
      )}

      <ButtonsWrapper>
        <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
          {t('default.button.save')}
        </SubmitButton>
        <ErrorInfo isVisible={hasInternalServerError} onRetry={submitForm} />
      </ButtonsWrapper>
    </Form>
  );
};

export default GeneralSettings;
