import { RUN_STATUS } from '@bugbug/core/constants/status';

import { getFormattedDuration } from '~/utils/dates';

export const getDuration = ({ status, duration }) => {
  if ([RUN_STATUS.PASSED, RUN_STATUS.FAILED].includes(status)) {
    return getFormattedDuration(duration);
  }

  return null;
};
