import { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import urls, { reverse } from '~/views/urls';

const useBackUrl = (defaultUrl, defaultUrlParams = {}) => {
  const location = useLocation();
  const locationStateBackUrl = location.state?.backUrl;
  const { organizationId, projectId, projectSlug } = useParams();
  const extraParams = useRef(defaultUrlParams);

  return useMemo(() => {
    if (locationStateBackUrl) {
      return locationStateBackUrl;
    }

    if (defaultUrl) {
      return reverse(defaultUrl, {
        organizationId,
        projectId,
        projectSlug,
        ...extraParams.current,
      });
    }

    return reverse(urls.testsList, {
      organizationId,
      projectId,
      projectSlug,
    });
  }, [locationStateBackUrl, projectId, projectSlug, organizationId, defaultUrl]);
};

export default useBackUrl;
