import Dropdown, { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Test } from '@bugbug/core/types/tests';
import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';

import { Button, Container } from './HeaderTestRunsActions.styled';

interface HeaderTestRunsActionsProps {
  selectedTests: Test['id'][];
  onDeselect?: () => void;
}

const HeaderTestRunsActions = ({ selectedTests, onDeselect }: HeaderTestRunsActionsProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleDelete = useCallback(() => {
    modal.show(MODAL_TYPE.DELETE_TEST_RUNS, {
      testRunsIds: selectedTests,
    });
  }, [modal, selectedTests]);

  return (
    <Container data-testid="HeaderTestRunsActions">
      <Dropdown
        data-testid="HeaderTestRunsActions.Menu"
        label={t('suiteRunDetails.header.bulkActionsButton', {
          defaultValue_one: '{{count}} test run selected',
          defaultValue_other: '{{count}} test runs selected',
          count: selectedTests.length,
        })}
        rounded
      >
        <DropdownItem onClick={handleDelete}>
          <Icon name="delete" />
          {t('suiteRunDetails.header.clearFromHistory', 'Clear from history')}
        </DropdownItem>
      </Dropdown>
      <Button
        bordered
        data-testid="HeaderTestRunsActions.DeselectButton"
        iconName="close"
        onClick={onDeselect}
      >
        {t('suiteRunDetails.header.deselectAll', 'Deselect all')}
      </Button>
    </Container>
  );
};

export default HeaderTestRunsActions;
