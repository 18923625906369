import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Page from '~/components/Page';

export const Container = styled(Page)`
  display: block;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  overflow: auto;
  padding-bottom: 30px;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;

export const AlertsSection = styled.section``;
