import Icon from '@bugbug/core/components/Icon';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import {
  Accordion as MuiAccordion,
  AccordionActions as MuiAccordionActions,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import styled from 'styled-components';

import ExpanderButton from '~/components/ExpanderButton';

export const Container = styled(MuiAccordion).attrs(() => ({
  classes: {
    expanded: 'expanded',
  },
}))`
  && {
    box-shadow: none;
    border-radius: 0;

    &:before {
      display: none;
    }
  }

  &&.expanded {
    margin: 0;
  }
`;

export const Header = styled(MuiAccordionSummary).attrs(() => ({
  classes: {
    expanded: 'expanded',
  },
}))`
  && {
    font-size: 14px;
    line-height: 23px;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: 0.03em;
    height: 48px;
    padding-left: 0;
  }

  &&.expanded {
    min-height: auto;
  }
`;

export const Content = styled(MuiAccordionActions)`
  && {
    padding: 8px 22px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > :not(:first-child) {
      margin-left: 0;
    }
  }
`;

export const Expander = styled(ExpanderButton)`
  margin-left: -5px;
  transform: translateY(-2px);
`;

export const HelperIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  margin-left: 8px;
  color: #858585;
`;
