import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';

const StatusCell = ({ value }) => {
  const { status, started, extended, errorCode, isAutoRetried } = value ?? {};
  return (
    <StatusBadge
      status={status}
      date={started}
      extended={extended}
      autoRetried={isAutoRetried}
      hiddenStatusLabel={!isAutoRetried}
      errorCode={errorCode}
    />
  );
};

export default StatusCell;
