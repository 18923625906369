import { LinkButton } from '@bugbug/core/components/Button';
import { ButtonsRow } from '@bugbug/core/components/Button/Button.styled';
import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Suite, SuiteRun } from '@bugbug/core/types/suites';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import analytics from '~/services/analytics';

import * as S from './HeaderActions.styled';

interface HeaderActionsProps {
  suiteId: Suite['id'];
  suiteRun: SuiteRun;
}

const HeaderActions = ({ suiteId, suiteRun }: HeaderActionsProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const isRunning = isRunningStatus(suiteRun.status);
  const suiteRunId = suiteRun.id;
  const { getRouteUrl, push, params } = useAppRoutes('suite');

  const urlToSuite = getRouteUrl('suite', { ...params, suiteId });

  const handleClearFromHistory = useCallback(() => {
    modal.show('delete_suite_runs', {
      suiteRunsIds: [suiteRunId],
      onDelete: () => push('suiteRunsList'),
    });
  }, [modal, suiteRunId, push]);

  const handleDownloadPDF = useCallback(() => {
    const onDownload = () => {
      analytics.trackEvent('report_downloaded', {
        suite_status: suiteRun.status,
        suite_id: suiteRun.id,
      });
    };

    modal.show('download_pdf_file', {
      name: suiteRun.name,
      onDownload,
    });
  }, [modal, suiteRun]);

  return (
    <S.Container data-testid="SuiteRunActions">
      <ButtonsRow>
        <LinkButton
          to={urlToSuite}
          disabled={!suiteId}
          button={{
            'aria-label': t('suiteRunDetails.header.editSuite'),
            iconName: 'edit',
            bordered: true,
            variant: 'primary',
          }}
        >
          {t('suiteRunDetails.header.editSuite', 'Edit suite')}
        </LinkButton>
        <PaidFeature>
          {(isDisabled) => (
            <S.Button
              aria-label={t('suiteRunDetails.header.goToDownloadPdfReportoSuite')}
              disabled={isRunning || !suiteRun.totalCount || isDisabled}
              iconName="report"
              onClick={handleDownloadPDF}
            >
              {t('suiteRunDetails.header.goToDownloadPdfReportoSuite', 'Download PDF report')}
            </S.Button>
          )}
        </PaidFeature>
      </ButtonsRow>
      <S.Menu condensed data-testid="SuiteRunActions.Menu" hideExpander iconName="more" rounded>
        <DropdownItem onClick={handleClearFromHistory}>
          <Icon name="delete" />{' '}
          {t('suiteRunDetails.header.clearFromHistory', 'Clear from history')}
        </DropdownItem>
      </S.Menu>
    </S.Container>
  );
};

export default HeaderActions;
