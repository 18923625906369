import { KEY_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDispatch } from 'react-redux';

import type { ChangeEventHandler } from 'react';

import type { Test } from '@bugbug/core/types/tests';
import { TestActions } from '~/modules/test/test.redux';

import * as S from './TestSidebarNotesForm.styled';

interface TestSidebarNotesFormProps {
  testId: Test['id'];
  notes?: Test['notes'];
}

const TestSidebarNotesForm = ({ testId, notes = '' }: TestSidebarNotesFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitValueChange = useCallback(
    async (form, formik) => {
      const { value: isValueInvalid } = await formik.validateForm();
      if (!isValueInvalid && notes !== form.notes) {
        dispatch(
          TestActions.updateRequest(testId, { notes: form.notes.trim() }, { reqId: testId }),
        );
      }
    },
    [notes, dispatch, testId],
  );

  const formik = useFormik({
    initialValues: { notes },
    onSubmit: submitValueChange,
  });

  const handleBlur = useCallback<ChangeEventHandler>(
    (event) => {
      formik.handleBlur(event);
      formik.submitForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleKeyEvent = useCallback(() => {
    formik.resetForm({ values: { notes } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  return (
    <KeyboardEventHandler handleKeys={[KEY_BINDINGS.ESC]} onKeyEvent={handleKeyEvent}>
      <S.TextArea
        fullWidth
        name="notes"
        autoSelect={false}
        placeholder={t('testSidebar.notes.placeholder', 'Add text...')}
        value={formik.values.notes}
        onChange={formik.handleChange}
        onBlur={handleBlur}
        autoSize
        maxInitialHeight={290}
      />
    </KeyboardEventHandler>
  );
};

export default TestSidebarNotesForm;
