import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: ${COLOR.WHITE};
  width: 100%;
  height: 100%;
  z-index: 1;

  > * {
    position: relative;
    bottom: calc(50vh - 32px);
  }
`;
