import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: ${COLOR.WHITE};
`;

export const TableEmptyState = styled.div`
  background-color: ${COLOR.WHITE};
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;

  ${LoaderContainer} {
    top: 25px;
  }
`;
