import LinkBase from '@bugbug/core/components/Link';
import { COLOR } from '@bugbug/core/theme';
import { Tag as TagBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import DropdownPickerBase, { ToggleButton } from '~/components/DropdownPicker';

export const DropdownPicker = styled(DropdownPickerBase)`
  ${ToggleButton} {
    border: none;
    color: ${COLOR.DARK_GRAY};
    margin-right: 8px;
    max-width: 120px;
  }
`;

export const Tag = styled(TagBase)`
  margin-left: 4px;
`;

export const IconWrapper = styled.div`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  width: 14px;
  top: -2px;
  position: relative;
`;

export const Name = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.secondaryAllCaps};
  text-transform: uppercase;
`;

export const Label = styled.div`
  font-size: 12px;
  display: flex;
  padding: 8px 10px 8px;
  align-items: center;
`;

export const LearnMore = styled(LinkBase)`
  margin-left: auto;
`;
