import type { Component } from '@bugbug/core/types/components';
import type { Group } from '@bugbug/core/types/groups';
import type { Test } from '@bugbug/core/types/tests';

import MakeComponent from './MakeComponent';
import UnlinkComponent from './UnlinkComponent';

interface ToggleComponentProps {
  testId: Test['id'];
  group: Group | Component;
  readOnly?: boolean;
}

const ToggleComponent = ({ testId, group, readOnly }: ToggleComponentProps) => {
  const { isComponent, id } = group;

  if (readOnly) {
    return null;
  }

  if (isComponent) {
    return <UnlinkComponent testId={testId} componentId={group.id} />;
  }

  return <MakeComponent groupId={id} />;
};

export default ToggleComponent;
