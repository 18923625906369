import {
  DropdownItem,
  DROPDOWN_VARIANT,
  DROPDOWN_ANCHOR,
  DropdownLinkItem,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MODAL_TYPE } from '~/components/modals';
import { VisibleOnRowHover } from '~/components/Table';
import useModal from '~/hooks/useModal';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import urls, { reverse } from '~/views/urls';

import { Container, Dropdown, IconButton } from './TestRunsActions.styled';

const TestRunsActions = ({ className, row }) => {
  const { t } = useTranslation();
  const { organizationId, suiteRunId } = useParams();
  const dispatch = useDispatch();
  const modal = useModal();
  const testRun = row.original;
  const containerRef = useRef();
  const isRunning = isRunningStatus(testRun.status);
  const [areActionsLocked, setAreActionsLocked] = useState(false);

  const onDelete = useCallback(() => {
    dispatch(SuiteRunActions.removeTestRuns(suiteRunId, testRun.id));
  }, [dispatch, suiteRunId, testRun.id]);

  const handleStopRunning = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      dispatch(TestRunActions.stopRequest([testRun.id]));
    },
    [dispatch, testRun.id],
  );

  const handleClearClick = useCallback(() => {
    modal.show(MODAL_TYPE.DELETE_TEST_RUNS, {
      onDelete,
      testRunsIds: [testRun.id],
    });
  }, [modal, onDelete, testRun.id]);

  const hiddenActions = useMemo(
    () => [
      {
        name: t('testRunRowActions.goToTest.label', 'Go to test'),
        Component: DropdownLinkItem,
        disabled: !testRun.test,
        to: testRun.test
          ? reverse(urls.test, {
              testId: testRun.testSourceId || testRun.test.id,
              projectSlug: testRun.projectSlug,
              projectId: testRun.projectId,
              organizationId,
            })
          : '',
        iconName: 'cornerDownRight',
      },
      {
        name: t('testRunRowActions.remove.label', 'Clear from history'),
        onClick: handleClearClick,
        iconName: 'delete',
      },
    ],
    [t, testRun, organizationId, handleClearClick],
  );

  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <Container
      className={className}
      data-testid="SuiteRunDetailsTestRunsActions"
      ref={containerRef}
    >
      {isRunning && (
        <VisibleOnRowHover force={isRunning || areActionsLocked}>
          <IconButton
            data-testid="SuiteRunDetailsTestRunsActions.StopButton"
            onClick={handleStopRunning}
          >
            <Icon name="stop" />
          </IconButton>
        </VisibleOnRowHover>
      )}
      <VisibleOnRowHover force={areActionsLocked}>
        <Dropdown
          variant={DROPDOWN_VARIANT.ICON}
          anchor={DROPDOWN_ANCHOR.BOTTOM_END}
          iconName="more"
          onOpen={lockActionButtons}
          onClose={unlockActionButtons}
          condensed
        >
          {hiddenActions.map(
            ({ name, onClick, to, iconName, disabled, dataTestId, Component = DropdownItem }) => (
              <Component
                data-testid={dataTestId}
                key={name}
                disabled={disabled}
                to={to}
                onClick={onClick}
              >
                <Icon name={iconName} /> {name}
              </Component>
            ),
          )}
        </Dropdown>
      </VisibleOnRowHover>
    </Container>
  );
};

export default TestRunsActions;
