import Dropdown, { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Row } from 'react-table';

import type { DropdownAction } from '@bugbug/core/components/Dropdown';
import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import { VisibleOnRowHover } from '~/components/Table';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';

import * as S from './TestRunStepMoreActions.styled';

interface TestRunStepMoreActionsProps {
  row: Row<Step>;
}

const TestStepMoreActions = ({ row }: TestRunStepMoreActionsProps) => {
  const { t } = useTranslation();
  const { getAbsoluteRouteUrl } = useAppRoutes('testRun');

  const [areActionsLocked, setAreActionsLocked] = useState(false);

  const step = row.original;

  const lockActionButtons = useCallback<(shouldLock: boolean) => SideEffect>(
    (shouldLock) => () => setAreActionsLocked(shouldLock),
    [],
  );

  const handleCopyLinkToStep = useCopyHandler(
    getAbsoluteRouteUrl('testRun', {}, { groupId: step.groupId, stepId: step.id }),
    {
      success: t(
        'testRunStepActions.copyLinkToStep.copyLinkToStepMsg.success',
        'Link to step copied',
      ),
      failure: t(
        'testRunStepActions.copyLinkToStep.copyLinkToStepMsg.failure',
        'Error when copying link to step',
      ),
    },
  );

  const actions = useMemo<DropdownAction[]>(
    () => [
      {
        name: t('testRunStepActions.copyLinkToStep.label', 'Copy link to step'),
        onClick: handleCopyLinkToStep,
        iconName: 'link2',
        dataTestId: 'TestRunStepRowActions.CopyLink',
      },
    ],
    [t, handleCopyLinkToStep],
  );

  return (
    <S.Content>
      <VisibleOnRowHover force={areActionsLocked}>
        <Dropdown
          iconName="more"
          anchor="bottom-end"
          onOpen={lockActionButtons(true)}
          onClose={lockActionButtons(false)}
          variant="icon"
        >
          {actions.map(({ name, onClick, iconName, dataTestId }) => (
            <DropdownItem data-testid={dataTestId} key={name} onClick={onClick}>
              <Icon name={iconName} /> {name}
            </DropdownItem>
          ))}
        </Dropdown>
      </VisibleOnRowHover>
    </S.Content>
  );
};

export default TestStepMoreActions;
