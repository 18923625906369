import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import FormField from '~/components/FormField';
import {
  STEP_SCROLL_TARGET,
  STEP_SCROLL_DIRECTION,
  STEP_SCROLL_EDGE,
  STEP_SCROLL_TO,
  STEP_SCROLL_TARGET_TYPE,
  STEP_SCROLL_TO_TYPE,
} from '~/constants/step';

import ElementSelectorField from '../ElementSelectorField';
import { getInitialValues } from '../StepDetails/StepDetails.helpers';

import { FIELD_NAMES } from './ScrollActionFields.constants';
import { CoordsValues, EdgeType } from './ScrollActionFields.styled';

const ScrollActionFields = ({ context, step, readOnly }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const getFieldProps = useCallback(
    (fieldName) => {
      const { value, name } = formik.getFieldProps(fieldName);
      const { touched, error } = formik.getFieldMeta(fieldName);
      return { name, value, error: touched && error };
    },
    [formik],
  );

  const handleUpdateScrollToType = (event) => {
    const { value: scrollTo } = event.target;
    formik.setValues(
      getInitialValues({
        ...step,
        scrollTo,
      }),
    );
  };

  const renderElementField = renderWhenTrue(() => (
    <ElementSelectorField context={context} disabled={readOnly} />
  ));
  const renderCoords = renderWhenTrue(() => (
    <CoordsValues>
      <Input
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.SCROLL_X)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={formik.handleFocus}
        readOnly={readOnly}
        startAdornment={t('stepDetails.interaction.scrollX.label', 'X')}
        endAdornment={t('stepDetails.interaction.unit', 'px')}
        aria-label={t('stepDetails.interaction.scrollX.ariaLabel', 'Position X')}
      />
      <Input
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.SCROLL_Y)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onFocus={formik.handleFocus}
        readOnly={readOnly}
        startAdornment={t('stepDetails.interaction.scrollY.label', 'Y')}
        endAdornment={t('stepDetails.interaction.unit')}
        aria-label={t('stepDetails.interaction.scrollY.ariaLabel', 'Position Y')}
      />
    </CoordsValues>
  ));

  const renderDirection = renderWhenTrue(() => (
    <FormField label={t('stepDetails.interaction.direction', 'Direction')}>
      <Select
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.SCROLL_DIRECTION)}
        onChange={formik.handleChange}
        disabled={readOnly}
      >
        {Object.values(STEP_SCROLL_DIRECTION).map(({ value, label }) => (
          <SelectOption key={value} value={value}>
            {label}
          </SelectOption>
        ))}
      </Select>
    </FormField>
  ));

  const renderEdge = renderWhenTrue(() => (
    <EdgeType>
      <Select
        fullWidth
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getFieldProps(FIELD_NAMES.SCROLL_EDGE)}
        onChange={formik.handleChange}
        disabled={readOnly}
      >
        {Object.values(STEP_SCROLL_EDGE).map(({ value, label }) => (
          <SelectOption key={value} value={value}>
            {label}
          </SelectOption>
        ))}
      </Select>
    </EdgeType>
  ));

  const renderScrollTo = () => {
    const scrollToProps = getFieldProps(FIELD_NAMES.SCROLL_TO);
    return (
      <>
        <FormField label={t('stepDetails.interaction.position', 'Scroll to')}>
          <Select
            fullWidth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...scrollToProps}
            onChange={handleUpdateScrollToType}
            disabled={readOnly}
          >
            {Object.values(STEP_SCROLL_TO).map(({ value, label }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </FormField>
        {renderCoords(scrollToProps.value === STEP_SCROLL_TO_TYPE.COORDS)}
        {renderEdge(scrollToProps.value === STEP_SCROLL_TO_TYPE.EDGE)}
      </>
    );
  };

  return (
    <>
      <FormField label={t('stepDetails.interaction.scrollInside', 'Scroll inside')}>
        <Select
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getFieldProps(FIELD_NAMES.SCROLL_INSIDE)}
          onChange={formik.handleChange}
          disabled={readOnly}
        >
          {Object.values(STEP_SCROLL_TARGET).map(({ value, label }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </FormField>
      {renderElementField(
        formik.values[FIELD_NAMES.SCROLL_INSIDE] === STEP_SCROLL_TARGET_TYPE.ELEMENT,
      )}
      {renderScrollTo()}
      {renderDirection(
        formik.values[FIELD_NAMES.SCROLL_TO] ===
          STEP_SCROLL_TO_TYPE.UNTIL_NEXT_STEP_ELEMENT_IS_VISIBLE,
      )}
    </>
  );
};

ScrollActionFields.defaultProps = {
  readOnly: false,
};

ScrollActionFields.propTypes = {
  context: PropTypes.oneOf(['component', 'test', 'testRun']),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  step: PropTypes.object.isRequired,
};

export default ScrollActionFields;
