import { useMemo, useState } from 'react';
import SplitPane from 'react-split-pane';

import localStorage from '~/services/localStorage';

import * as S from './SplitView.styled';

const DEFAULT_MIN_LEFT_PANE_SIZE = window.innerWidth / 2;

interface SplitViewProps {
  className?: string;
  sizeStoreName?: string;
  rightPaneAsMain?: boolean;
  minSize?: number;
  maxSize?: number;
  LeftPane?: React.ReactNode;
  RightPane?: React.ReactNode;
  rightPaneStyle?: React.CSSProperties;
  resizerStyle?: React.CSSProperties;
}

const SplitView = ({
  className,
  LeftPane = null,
  RightPane = null,
  rightPaneStyle,
  resizerStyle,
  rightPaneAsMain,
  sizeStoreName = 'splitView',
  minSize = DEFAULT_MIN_LEFT_PANE_SIZE,
  maxSize,
}: SplitViewProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const defaultSize = useMemo(() => {
    const storedSize = localStorage.getUserItem(`${sizeStoreName}.size`) || 0;
    return Math.max(storedSize, minSize);
  }, [minSize, sizeStoreName]);

  const handleSizeChange = (size) => {
    localStorage.setUserItem(`${sizeStoreName}.size`, size);
  };

  return (
    <S.Container className={className} data-testid="SplitView">
      <SplitPane
        split="vertical"
        minSize={minSize}
        maxSize={maxSize}
        defaultSize={defaultSize}
        onChange={handleSizeChange}
        primary={rightPaneAsMain ? 'first' : 'second'}
        pane2Style={{
          ...rightPaneStyle,
          transition: isDragging ? 'none' : rightPaneStyle?.transition,
        }}
        resizerStyle={resizerStyle}
        onDragStarted={() => setIsDragging(true)}
        onDragFinished={() => setIsDragging(false)}
      >
        {LeftPane}
        {RightPane}
      </SplitPane>
    </S.Container>
  );
};

export default SplitView;
