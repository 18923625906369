import { getExtendedRunStatus } from '@bugbug/core/utils/status';
import { useTranslation } from 'react-i18next';

import type { ScrollStep } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';

import { Title, Description } from '../ErrorDetails.styled';

import { getDescription } from './ScrollFailedDetails.helpers';

interface ScrollFailedDetailsProps {
  step: ScrollStep;
  stepRun: StepRun;
}

export const ScrollFailedDetails = ({ step, stepRun }: ScrollFailedDetailsProps) => {
  const { t } = useTranslation();
  const extendedStatus = getExtendedRunStatus(stepRun);

  const title =
    extendedStatus === 'passed-with-issues'
      ? t('scrollFailedDetails.title.warning', 'Passed but with some issues')
      : t('scrollFailedDetails.title.failed', 'Scroll failed!');

  return (
    <>
      <Title>{title}</Title>
      <Description>{getDescription(stepRun, step)}</Description>
    </>
  );
};

export default ScrollFailedDetails;
