import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import type { Route } from '@bugbug/core/types/routes';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { useGetScheduleQuery } from '~/modules/schedules';
import analytics from '~/services/analytics';
import { isNotFoundError } from '~/services/api/utils';
import toasts from '~/services/toasts';

const ScheduleDetails = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const appRoutes = useAppRoutes('schedule');
  const { scheduleId } = appRoutes.params;
  const isNew = scheduleId === 'new';
  const { data, isSuccess, error } = useGetScheduleQuery(isNew ? skipToken : scheduleId);

  const handleHide = useCallback(
    (route: Route = 'schedulesList') => {
      appRoutes.push(route);
    },
    [appRoutes],
  );

  useMount(() => {
    if (!isNew) {
      analytics.trackEvent('schedule_opened');
    }
  });

  useEffect(() => {
    if (isSuccess || isNew) {
      modal.showFullscreen('schedule', { data }, { onHide: handleHide });
    }
    if (error) {
      if (isNotFoundError(error)) {
        appRoutes.replace('notFound');
        return;
      }
      toasts.showError({
        content: t('scheduleDetails.fetchError', 'Loading schedule details failed'),
      });
    }
  }, [isSuccess, isNew, data, handleHide, modal, error, t, appRoutes]);

  return null;
};

export default ScheduleDetails;
