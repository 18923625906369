import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  border: 2px solid ${COLOR.GRAY_1};
  padding: 21px 18px 28px 32px;
  border-radius: 5px;
  margin-bottom: 24px;
  cursor: pointer;
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: minmax(auto, 35%) 167px minmax(auto, 40%) auto;

  :hover {
    border-color: ${COLOR.PRIMARY_LIGHT};
  }
`;

export const When = styled.div`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 13px;
  color: ${COLOR.BLACK};
  margin-bottom: 2px;
`;

export const AlertArrow = styled.div`
  color: ${COLOR.PRIMARY_LIGHT};
  width: 167px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
