import styled from 'styled-components';

import Page from '~/components/Page';
import { PrintableContent as PrintableContentBase } from '~/components/PrintableDocument';
import * as TableS from '~/components/Table/Table.styled';

export const PrintableContent = styled(PrintableContentBase)`
  page-break-before: always;

  ${TableS.TableBase} {
    display: block;
    width: 100%;
    height: max-content;
  }

  ${TableS.TableRow} {
    width: 100%;
    page-break-before: auto;
  }

  ${TableS.HeaderCell}:first-child, ${TableS.RowCell}:first-child {
    display: none !important;
  }
`;

export const Container = styled(Page)``;
