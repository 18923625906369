import Icon from '@bugbug/core/components/Icon';
import Tooltip from '@bugbug/core/components/Tooltip';
import { useTranslation } from 'react-i18next';

import type { ComponentPropsWithoutRef } from 'react';

import * as S from './VariablesAdornment.styled';

export interface VariablesAdornmentProps extends ComponentPropsWithoutRef<'button'> {
  hideTooltip?: boolean;
  container?: 'input' | 'textarea';
}

export const VariablesAdornment = ({ hideTooltip, ...props }: VariablesAdornmentProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'variablesCombobox' });

  return (
    <S.AdornmentContainer type="button" {...props}>
      <Tooltip
        content={!hideTooltip && t('variableTooltip', 'You can use variables in this field')}
        anchor="top-end"
      >
        <Icon name="variablesExtension" />
      </Tooltip>
    </S.AdornmentContainer>
  );
};
