import { useTranslation } from 'react-i18next';

import type { Profile } from '@bugbug/core/types/profiles';
import GridItem from '~/components/GridItem';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import useModal from '~/hooks/useModal';

interface ProfileItemProps {
  profile: Profile;
}

const ProfileItem = ({ profile }: ProfileItemProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const handleCopy = useCopyHandler(profile.name);
  const { push } = useAppRoutes('profiles');

  const numberOfOverrides = profile.variables?.filter(({ value }) => !!value).length;

  const handleClick = () => {
    push('profile', { profileId: profile.id });
  };

  const handleDeleteClick = () => {
    modal.show('delete_profile', { id: profile.id });
  };

  const actions = [
    {
      label: t('profileItem.actions.copyName', 'Copy name'),
      onClick: handleCopy,
      iconName: 'copy' as const,
    },
    {
      label: t('profileItem.actions.rename', 'Rename'),
      onClick: handleClick,
      iconName: 'edit' as const,
    },
    {
      label: t('profileItem.actions.delete', 'Delete'),
      onClick: handleDeleteClick,
      iconName: 'delete' as const,
      disabled: profile.isDefault,
      danger: !profile.isDefault,
    },
  ];

  const description = numberOfOverrides
    ? t('profileItem.description.variables.defined', {
        defaultValue_one: '1 variable',
        defaultValue_other: '{{ count }} variables',
        count: numberOfOverrides,
      })
    : t('profileItem.description.variables.undefined', 'No variable overrides');

  return (
    <GridItem
      key={profile.id}
      title={profile.name}
      description={description}
      onClick={handleClick}
      actions={actions}
      tag={profile.isDefault ? t('profileItem.tagDefault', 'Default') : ''}
    />
  );
};

export default ProfileItem;
