import * as diff from 'diff';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import i18n from '~/translations';

import { Container, Part } from './TextDiff.styled';

const getPartAriaLabel = (added, removed) => {
  if (added) {
    return i18n.t('textDiff.part.added.ariaLabel', 'Added part');
  }
  if (removed) {
    return i18n.t('textDiff.part.removed.ariaLabel', 'Removed part');
  }
  return i18n.t('textDiff.part.equal.ariaLabel', 'The same part');
};

const TextDiff = memo(({ className, oldValue, value }) => {
  const diffParts = useMemo(() => diff.diffChars(oldValue, value), [oldValue, value]);

  return (
    <Container className={className} data-testid="TextDiff">
      {diffParts.map((part, index) => (
        <Part
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          added={part.added}
          removed={part.removed}
          size={part.count}
          aria-label={getPartAriaLabel(part.added, part.removed)}
          dangerouslySetInnerHTML={{ __html: part.value }}
          whitespaceOnly={!/\S/.test(part.value)}
          lineBreaksOnly={/^\n+$/.test(part.value)}
        />
      ))}
    </Container>
  );
});

TextDiff.displayName = 'TextDiff';

TextDiff.defaultProps = {
  className: null,
  oldValue: '',
};

TextDiff.propTypes = {
  className: PropTypes.string,
  oldValue: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default TextDiff;
