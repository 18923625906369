import { useCallback, useRef } from 'react';
import { useMousePositionAsTrigger, useLayer } from 'react-laag';
import { useUnmount } from 'react-use';

import type { MouseEvent, MouseEventHandler } from 'react';
import type { IBounds } from 'react-laag';

import type { Step } from '@bugbug/core/types/steps';
import type { SideEffect } from '@bugbug/core/types/utils';
import { TextCell } from '~/components/Table';
import { selectCurrentStep } from '~/modules/components';
import { useAppSelector } from '~/modules/store';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { getScreenshotUrl } from '~/utils/misc';

import * as S from './ImageCell.styled';

const HIDE_PREVIEW_DELAY_MS = 1000;

interface ImageCellProps {
  value: Step;
}

const ImageCell = ({ value }: ImageCellProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mouseTimeout = useRef<NodeJS.Timeout>();

  const {
    hasMousePosition: isOpen,
    handleMouseEvent,
    resetMousePosition,
  } = useMousePositionAsTrigger();

  const { layerProps, layerSide, renderLayer, triggerBounds } = useLayer({
    isOpen,
    placement: 'top-center',
    onOutsideClick: resetMousePosition,
    overflowContainer: true,
    auto: true,
    onDisappear: resetMousePosition,
    trigger: {
      getBounds() {
        return containerRef.current?.getBoundingClientRect() as IBounds;
      },
      getParent() {
        return containerRef.current?.parentElement as HTMLElement;
      },
    },
  });

  const currentStep = useAppSelector(selectCurrentStep);
  const isRelatedStepActive = currentStep?.id === value.id;
  const imageUrl = getScreenshotUrl(value.screenshot);

  useUnmount(() => {
    clearTimeout(mouseTimeout.current);
  });

  const handleClick = useCallback<
    SideEffect<MouseEvent<HTMLDivElement>, MouseEventHandler<HTMLDivElement>>
  >(
    (event, callback) => {
      if (isRelatedStepActive) {
        event.stopPropagation();
      }
      callback(event);
    },
    [isRelatedStepActive],
  );

  const handlePreviewMouseLeave = useCallback<SideEffect<MouseEvent<HTMLDivElement>>>(
    (event) => {
      event.persist();
      const timeoutId = setTimeout(() => {
        resetMousePosition();
      }, HIDE_PREVIEW_DELAY_MS);

      mouseTimeout.current = timeoutId;
    },
    [resetMousePosition],
  );

  const handlePreviewMouseEnter = useCallback<SideEffect>(() => {
    clearTimeout(mouseTimeout.current);
  }, [mouseTimeout]);

  const trackScreenshotClick = useCallback<SideEffect>(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.SCREENSHOT_ELEMENT_CLICKED);
  }, []);

  const handleImageClick = useCallback<SideEffect<MouseEvent<HTMLDivElement>>>(
    (event) => {
      trackScreenshotClick();
      handleClick(event, handleMouseEvent);
    },
    [handleClick, handleMouseEvent, trackScreenshotClick],
  );

  const renderPreview = () =>
    renderLayer(
      <S.PreviewImage
        loading="lazy"
        onMouseEnter={handlePreviewMouseEnter}
        onMouseLeave={handlePreviewMouseLeave}
        onMouseDown={resetMousePosition}
        data-testid="ImageCell.Preview"
        {...layerProps}
        src={imageUrl}
        side={layerSide}
        initialHeight={triggerBounds?.height}
        initialWidth={triggerBounds?.width}
      />,
    );

  return imageUrl ? (
    <S.Container ref={containerRef} onClick={handleImageClick} data-testid="ImageCell">
      <S.Image loading="lazy" src={imageUrl} alt={value.type} />
      {isOpen && renderPreview()}
    </S.Container>
  ) : (
    <S.TextContainer>
      <TextCell />
    </S.TextContainer>
  );
};

export default ImageCell;
