import PropTypes from 'prop-types';

import LogsInfo from '../LogsInfo';
import SupportRequest from '../SupportRequest';

import * as S from './HelpBox.styled';

const HelpBox = ({ className }) => (
  <S.Container className={className}>
    <LogsInfo />
    <SupportRequest />
  </S.Container>
);

HelpBox.defaultProps = {
  className: null,
};

HelpBox.propTypes = {
  className: PropTypes.string,
};
export default HelpBox;
