import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { arc, select } from 'd3';

export interface ChartConfig {
  svgElement: SVGSVGElement | null;
  strokeWidth?: number;
  value?: number;
  backgroundColor?: string;
  color?: string;
}

export const renderChart = ({
  svgElement,
  strokeWidth = 16,
  value = 0,
  color,
  backgroundColor,
}: ChartConfig) => {
  if (!svgElement) {
    return;
  }

  const svg = select(svgElement);
  const container = select(svgElement.parentElement);
  const currentWidth = parseInt(container.style('width'), 10);
  const currentHeight = parseInt(container.style('height'), 10);
  const radius = (currentWidth - strokeWidth) / 2;
  const arcEdgeFactor = 1.25;
  const middlePoint = radius + strokeWidth / 2;
  const transform = `translate(${middlePoint}, ${middlePoint})`;

  const refreshArc = (element: SVGPathElement | null, percentage: number, strokeColor?: string) => {
    const generateArc = arc<void, void>()
      .outerRadius(radius)
      .innerRadius(radius - 1)
      .startAngle((-Math.PI / 2) * arcEdgeFactor)
      .endAngle((Math.PI * (percentage / 100) - Math.PI / 2) * arcEdgeFactor);

    return select(element)
      .attr('stroke', strokeColor as string)
      .attr('stroke-width', strokeWidth)
      .attr('transform', transform)
      .attr('d', generateArc());
  };

  refreshArc(svgElement.querySelector('path:nth-child(1)'), 100, backgroundColor);
  refreshArc(svgElement.querySelector('path:nth-child(2)'), value, color);
  svg.attr('width', currentWidth).attr('height', currentHeight);

  svg
    .select('text')
    .attr('transform', transform)
    .style('text-anchor', 'middle')
    .style('font-weight', FONT_WEIGHT.BOLD)
    .style('font-size', 24)
    .text(() => `${value}%`);
};
