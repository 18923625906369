import EmptyState from '@bugbug/core/components/EmptyState';
import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { H1 } from '@bugbug/core/theme/typography';
import memoize from 'lodash.memoize';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { useMount } from 'react-use';

import type { SuitesActionsRef } from './components/SuitesActions/SuitesActions';
import type { SimpleTableRef } from '~/components/Table';

import type { Suite } from '@bugbug/core/types/suites';
import type { Maybe } from '@bugbug/core/types/utils';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import { SimpleTable, useRecentSort } from '~/components/Table';
import useActionState from '~/hooks/useActionState';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { SuiteActions } from '~/modules/suite/suite.redux';
import { selectSuiteList } from '~/modules/suite/suite.selectors';
import urls from '~/views/urls';

import SuiteDetails from '../SuiteDetails';

import SelectedSuitesActionsHeader from './components/SelectedSuitesActions';
import { SuitesActions } from './components/SuitesActions/SuitesActions';
import { SUITE_LIST_COLUMNS } from './SuitesList.constants';
import {
  Actions,
  Container,
  ListContainer,
  LoaderContainer,
  TableEmptyState,
  TitleContainer,
} from './SuitesList.styled';

const defaultSort = {
  sortBy: 'created',
  desc: true,
};

export const SuitesList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryString = useQueryString();
  const tableRef = useRef<Maybe<SimpleTableRef>>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const suitesActionsRef = useRef<Maybe<SuitesActionsRef>>(null);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const suites = useAppSelector(selectSuiteList) as Suite[];
  const suitesListState = useActionState(SuiteActions.getListRequest, { reset: false });
  const appRoute = useAppRoutes('suitesList');

  const { updateRecentSort, ...recentSort } = useRecentSort({
    cacheName: 'suitesList',
    defaultConfig: defaultSort,
    columns: SUITE_LIST_COLUMNS,
  });

  const handleDataUpdate = useCallback(
    (tableData = tableRef.current?.state) => {
      dispatch(
        SuiteActions.getListRequest(
          queryString.query,
          tableData.sortConfig.sortBy,
          tableData.sortConfig.desc,
        ),
      );
      updateRecentSort({ ...tableData.sortConfig, ...queryString });
    },
    [dispatch, updateRecentSort, queryString],
  );

  const handleSortChange = useCallback(
    (sortConfig) => {
      handleDataUpdate({ sortConfig });
    },
    [handleDataUpdate],
  );

  const handleSelectionChange = useCallback(setSelectedRowIds, [setSelectedRowIds]);

  const handleDeselectAll = useCallback(() => {
    tableRef.current?.toggleAllRowsSelected(false);
  }, []);

  const fetchInitialData = useCallback(() => {
    handleDataUpdate({ sortConfig: recentSort, ...queryString });
  }, [handleDataUpdate, recentSort, queryString]);

  useMount(() => {
    fetchInitialData();
  });

  useActionState(SuiteActions.cloneRequest, { onSuccess: fetchInitialData });

  const renderEmptyState = () => {
    if (suitesListState.hasInternalServerError || suitesListState.hasBadRequestError) {
      return (
        <ServerErrorInfo
          isVisible
          onRetry={handleDataUpdate}
          hideRetryButton={suitesListState.hasBadRequestError}
        />
      );
    }

    if (suitesListState.isLoading) {
      return (
        <TableEmptyState>
          <LoaderContainer>
            <Loader size="large" />
          </LoaderContainer>
        </TableEmptyState>
      );
    }

    if (!suites.length) {
      return (
        <EmptyState
          isVisible
          text={
            !queryString.query
              ? t('suitesList.emptyState.label', "You haven't created any suite yet")
              : t(
                  'suitesList.noSearchResults.label',
                  'No suites were found that match your search criteria.',
                )
          }
          action={{
            text: t('suitesList.emptyState.action', 'Add new suite'),
            onClick: () => suitesActionsRef.current?.showCreateModal(),
          }}
        />
      );
    }

    return null;
  };

  const Title = useMemo(
    () => (
      <TitleContainer data-testid="SuitesList.Header">
        <H1>{t('suitesList.title', 'Suites')}</H1>
        <Actions>
          {selectedRowIds.length ? (
            <SelectedSuitesActionsHeader
              suitesIds={selectedRowIds}
              onDeselect={handleDeselectAll}
            />
          ) : (
            <SuitesActions ref={suitesActionsRef} onDelete={handleDeselectAll} />
          )}
        </Actions>
      </TitleContainer>
    ),
    [t, selectedRowIds, handleDeselectAll],
  );

  const getExtraRowProp = useMemo(
    () =>
      memoize(
        (row: Suite) => {
          const nextPathname =
            row.lastResult || row.lastUnfinishedUserRun
              ? appRoute.getRouteUrl('suiteRun', {
                  suiteRunId: row.lastUnfinishedUserRun?.id || row.lastResult?.id,
                })
              : appRoute.getRouteUrl('suite', { suiteId: row.id }, { ...queryString });
          return { to: { pathname: nextPathname, state: { backUrl: appRoute.pathname } } };
        },
        (row: Suite) => row.id,
      ),
    [appRoute, queryString],
  );

  return (
    <Container ref={containerRef}>
      <Helmet title={t('suitesList.pageTitle', 'Suites')} />

      <ListContainer title={Title} data-testid="SuitesList" wideContent>
        <InlineTutorialMessage
          title={t('inlineTutorial.title')}
          message={
            <Trans key="suitesList.inlineTutorial.message">
              Suites are simply groups of multiple tests. They can be scheduled to run in the cloud
              via schedule and/or CI/CD integration.{' '}
              <Link to={t('default.docs.suites')}>Learn more</Link>
            </Trans>
          }
          storageName="suitesGettingStarted"
        />

        <SimpleTable
          ref={tableRef}
          columns={SUITE_LIST_COLUMNS}
          // @ts-expect-error SimpleTable needs refactor
          data={suites}
          // @ts-expect-error SimpleTable needs refactor
          getRowProps={getExtraRowProp}
          onSortChange={handleSortChange}
          onSelectionChange={handleSelectionChange}
          defaultSortBy={defaultSort.sortBy}
          defaultDescOrder={defaultSort.desc}
          initialSortBy={recentSort.sortBy}
          initialDescOrder={recentSort.desc}
          renderEmptyState={renderEmptyState}
        />
      </ListContainer>
      {!suitesListState.isLoading && (
        <Switch>
          <Route path={urls.suite} component={SuiteDetails} exact />
        </Switch>
      )}
    </Container>
  );
};
