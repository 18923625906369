import EmptyState from '@bugbug/core/components/EmptyState';
import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { H1 } from '@bugbug/core/theme/typography';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';

import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import SchedulesActions from '~/components/SchedulesActions';
import ScrollShadow from '~/components/ScrollShadow';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useGetSchedulesQuery } from '~/modules/schedules';
import { isBadRequestError, isInternalServerError } from '~/services/api/utils';

import ScheduleDetails from '../ScheduleDetails';
import urls from '../urls';

import ScheduleItem from './components/ScheduleItem';
import * as S from './SchedulesList.styled';

const SchedulesList = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
  } = useAppRoutes('schedule');
  const queryString = useQueryString();

  const {
    data: schedules,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetSchedulesQuery({
    projectId,
    ordering: 'name',
  });

  const filteredSchedules = schedules?.filter((schedule) => {
    if (!queryString.query) {
      return true;
    }

    return schedule.name.toLowerCase().includes(queryString.query.toString().toLowerCase());
  });

  const Title = useMemo(
    () => (
      <S.TitleContainer>
        <H1>{t('schedulesList.title', 'Schedules')}</H1>
        <S.Actions>
          <SchedulesActions />
        </S.Actions>
      </S.TitleContainer>
    ),
    [t],
  );

  return (
    <S.Container title={Title} paidFeatureBanner="schedules">
      <Helmet title={t('schedulesList.pageTitle', 'Schedules')} />
      <InlineTutorialMessage
        title={t('inlineTutorial.title')}
        message={
          <Trans key="schedules.inlineTutorial.message">
            Schedules allow you to run your tests on a regular basis, as often as you want. You will
            be notified about failed tests with email notifications by default.{' '}
            <Link to={t('default.docs.schedules')}>Learn more</Link>
          </Trans>
        }
        storageName="schedulesGettingStarted"
      />
      <S.ListWrapper>
        <ScrollShadow />
        {/* Loading state */}
        {isLoading && (
          <S.LoaderContainer>
            <Loader size="large" />
          </S.LoaderContainer>
        )}

        {/* Error state */}
        {isError && (
          <ServerErrorInfo
            isVisible={isInternalServerError(error) || isBadRequestError(error)}
            onRetry={refetch}
          />
        )}

        {/* Empty state */}
        {!isLoading && !isError && !filteredSchedules?.length && (
          <EmptyState
            isVisible
            inline
            text={
              queryString.query
                ? t('schedulesList.emptySearchList.text', 'No schedules match your search')
                : t('schedulesList.emptyList.text', "You haven't created any schedules yet")
            }
          />
        )}

        {/* Schedules list */}
        {!isLoading && !!filteredSchedules?.length && (
          <S.ListContainer>
            {filteredSchedules.map((schedule) => (
              <ScheduleItem key={schedule.id} data={schedule} />
            ))}
          </S.ListContainer>
        )}
      </S.ListWrapper>

      <Switch>
        <Route path={urls.schedule} component={ScheduleDetails} exact />
      </Switch>
    </S.Container>
  );
};

export default SchedulesList;
