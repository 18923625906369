import type { MenuItem } from './Menu.types';

import i18n from '~/translations';

export const MENU_ITEMS: MenuItem[] = [
  {
    label: i18n.t('menu.navigation.testsList', 'Tests'),
    route: 'testsList',
    icon: 'tests',
    trackEvent: 'tests_menu_opened',
  },
  {
    label: i18n.t('menu.navigation.suitesList', 'Suites'),
    route: 'suitesList',
    icon: 'suites',
    trackEvent: 'suites_menu_opened',
  },
  {
    label: i18n.t('menu.navigation.runsHistory', 'Runs history'),
    route: 'runsHistory',
    icon: 'history',
  },
  {
    label: i18n.t('menu.navigation.schedulesList', 'Schedules'),
    route: 'schedulesList',
    icon: 'schedule',
    trackEvent: 'schedules_menu_opened',
  },
  {
    label: i18n.t('menu.navigation.alerts', 'Alerts'),
    route: 'alerts',
    icon: 'alert',
    trackEvent: 'alerts_menu_opened',
  },
  {
    label: i18n.t('menu.navigation.variables', 'Variables'),
    route: 'variables',
    icon: 'variables',
    trackEvent: 'open_variables_menu',
  },
  {
    featureFlag: 'componentList',
    label: i18n.t('menu.navigation.components', 'Components'),
    route: 'componentsList',
    icon: 'component',
    trackEvent: 'open_components_menu',
  },
  {
    label: i18n.t('menu.navigation.integrations', 'Integrations'),
    route: 'integrations',
    icon: 'integrations',
    trackEvent: 'open_integrations_menu',
  },
  {
    label: i18n.t('menu.navigation.settings', 'Project settings'),
    route: 'projectSettingsIndex',
    icon: 'settings',
  },
];
