import * as T from '@bugbug/core/utils/toolbox';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import type { Suite } from '@bugbug/core/types/suites';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { selectSuite } from '~/modules/suite/suite.selectors';
import { useLazyGetSuitesQuery } from '~/modules/suites';
import analytics from '~/services/analytics';

const SuiteDetails = () => {
  const modal = useModal();
  const appRoutes = useAppRoutes('suite');
  const { suiteId, projectId } = appRoutes.params;
  const isNew = suiteId === 'new';
  const suite = useSelector(selectSuite(suiteId)) as Suite;

  // TODO: Remove this after full migration suites.sagas to RTK Query
  const [getSuites] = useLazyGetSuitesQuery();

  const handleHide = useCallback<SideEffect>(() => {
    appRoutes.push('suitesList');
  }, [appRoutes]);

  useMount(async () => {
    if (!isNew) {
      if (T.isEmpty(suite)) {
        await getSuites({ projectId, pagination: 'off' });
      }
    } else {
      modal.showFullscreen('suite_details', { data: {} }, { onHide: handleHide });
    }
  });

  useEffect(() => {
    if (!isNew && !T.isEmpty(suite)) {
      analytics.trackEvent('suite_opened');
      modal.showFullscreen('suite_details', { data: suite }, { onHide: handleHide });
    }
  }, [handleHide, isNew, modal, suite]);

  return null;
};

export default SuiteDetails;
