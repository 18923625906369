import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';
import Tooltip from '@bugbug/core/components/Tooltip';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isRunningStatus } from '@bugbug/core/types/base';
import { equals, props } from 'ramda';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { StepRun } from '@bugbug/core/types/tests';
import { useAppSelector } from '~/modules/store';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import { PendingStatus } from './PendingStatus';
import * as S from './StepStatusCell.styled';

const areEqualProps = (prevProps, nextProps) => {
  const compareProps = props(['value', 'progressTooltipDisabled']);
  return equals(compareProps(prevProps), compareProps(nextProps));
};

interface StepStatusCellProps {
  className?: string;
  value: {
    id: string;
    sleep: number;
  };
  progressTooltipDisabled: boolean;
}

const StepStatusCell = memo<StepStatusCellProps>(
  ({ className, value, progressTooltipDisabled }) => {
    const { id, sleep } = value;
    const stepRun = useAppSelector(selectStepRun(id)) as unknown as StepRun;
    const { t } = useTranslation();
    const isRunning = isRunningStatus(stepRun.status);

    const PendingState = useMemo(
      () => (
        <PendingStatus
          stepRunId={id}
          sleep={sleep}
          progressTooltipDisabled={progressTooltipDisabled}
        />
      ),
      [id, sleep, progressTooltipDisabled],
    );

    const IdleState = useMemo(() => {
      let label = '';
      if (stepRun.warning) {
        label = t('stepStatusCell.passedWithWarningLabel', 'Passed but with some issues');
      }

      if (stepRun.status !== RUN_STATUS.PASSED && stepRun.ignoredFailed) {
        label = t('stepStatusCell.continueOnFailureLabel', 'Step failed but the issue was ignored');
      }

      return (
        <Tooltip content={label}>
          <span>
            <StatusBadge
              status={stepRun.status}
              warning={stepRun.warning}
              ignoredFailed={stepRun.ignoredFailed}
            />
          </span>
        </Tooltip>
      );
    }, [t, stepRun.warning, stepRun.status, stepRun.ignoredFailed]);

    return (
      <S.Container className={className} data-testid="StepStatusCell">
        {isRunning && PendingState}
        {!isRunning && IdleState}
      </S.Container>
    );
  },
  areEqualProps,
);

StepStatusCell.displayName = 'StepStatusCell';

export default StepStatusCell;
