import styled from 'styled-components';

import { PrintableContent as PrintableContentBase } from '~/components/PrintableDocument';
import * as RunDetailsSummaryS from '~/components/RunDetailsSummary';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 4px;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

export const TitleContainer = styled.div`
  flex: 1;
  max-width: 50%;
  padding-bottom: 4px;
  margin-left: 25px;
`;

export const PrintableContent = styled(PrintableContentBase)`
  > * {
    width: 80%;
  }

  ${RunDetailsSummaryS.Container} {
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    padding: 0 0 50px;

    ${RunDetailsSummaryS.ContentSlice}:nth-child(2) {
      flex: 1;
    }

    ${RunDetailsSummaryS.Stats}, ${RunDetailsSummaryS.DetailsMetricContainer}:last-child {
      display: none;
    }
  }
`;
