import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { RUN_STATUS } from '@bugbug/core/constants/status';
import { isRunningStatus } from '@bugbug/core/types/base';
import { useSpring, animated } from '@react-spring/web';
import PropTypes from 'prop-types';
import { last } from 'ramda';
import React, { useState, useCallback, useRef, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import { STEP_TYPE_PICKER_TAB } from '~/components/StepTypePicker';
import useCopyPasteSteps from '~/hooks/useCopyPasteSteps';
import useModal from '~/hooks/useModal';
import useTestRunner from '~/hooks/useTestRunner';
import { useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectSingleTest, selectTestGroups } from '~/modules/test/test.selectors';

import {
  PlaceKeeper,
  Container,
  Line,
  Menu,
  Dot,
  LineWrapper,
  Paste,
} from './RowActiveBorder.styled';

const RowActiveBorder = memo((props) => {
  const {
    className,
    row,
    index,
    disabledGroupOptions,
    disabledSplitting,
    disabledStepOptions,
    disabledRunAndStop,
    testsCount,
    isComponent,
    onClick: handleClick,
    onClose,
    opened,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const menuRef = useRef();
  const { pasteSteps, hasCopiedSteps } = useCopyPasteSteps();
  const [shouldBeVisible, setShouldBeVisible] = useState(false);
  const [menuStyles, apiMenuStyles] = useSpring(() => ({ opacity: 0 }));
  const [lineStyles, apiLineStyles] = useSpring(() => ({ opacity: 0 }));
  const placeKeeperRef = useRef();
  const test = useSelector(selectSingleTest);
  const nextGroupIndex = Math.min(index + 1, test?.groups?.length || 0);
  const testGroups = useAppSelector(selectTestGroups);

  const groupId = row.groupId || test?.groups?.[index];
  const isStepRow = !!row.groupId;
  const atIndex = isStepRow ? index : row.steps.length;
  const testRunner = useTestRunner(test);
  const status = test?.testRun?.status;
  const isRunning = isRunningStatus(status) && status !== RUN_STATUS.QUEUED;

  const handleDropdownToggle = useCallback(() => {
    setShouldBeVisible((visible) => !visible);
  }, []);

  useEffect(() => {
    if (!shouldBeVisible) {
      onClose();
    }
  }, [onClose, shouldBeVisible]);

  const handleInsertGroup = useCallback(() => {
    dispatch(TestActions.createStepsGroupRequest(test.id, null, [], nextGroupIndex));
  }, [dispatch, test.id, nextGroupIndex]);

  const handleInsertComponent = useCallback(
    () =>
      modal.show(MODAL_TYPE.INSERT_COMPONENT, {
        testId: test.id,
        atIndex: nextGroupIndex,
      }),
    [modal, test.id, nextGroupIndex],
  );

  const handlePasteSteps = useCallback(
    () =>
      pasteSteps(
        test.id,
        disabledStepOptions ? null : groupId,
        disabledStepOptions ? nextGroupIndex : atIndex,
      ),
    [test.id, groupId, atIndex, pasteSteps, nextGroupIndex, disabledStepOptions],
  );

  const addStep = useCallback(
    (initialTab) =>
      modal.showWide(MODAL_TYPE.CREATE_STEP, {
        testId: test.id,
        groupId,
        atIndex,
        initialTab,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [test.id, groupId, atIndex],
  );

  const handleSplitGroup = useCallback(() => {
    if (isComponent && testsCount > 1) {
      modal.show(MODAL_TYPE.GROUP_SPLIT_CONFIRMATION, {
        testId: test.id,
        groupId,
        index,
        testsCount,
      });
      return;
    }

    dispatch(TestActions.splitGroupRequest(test.id, groupId, index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsCount, dispatch, test.id, groupId, index, modal.show, isComponent]);

  const handleRecordFromHere = useCallback(() => {
    testRunner.startLocal({
      unconfirmedRelatedGroupId: groupId,
      unconfirmedIndex: atIndex,
      withRedirect: true,
      withRecording: true,
      stopWhenUnconfirmedGroups: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atIndex, groupId, testRunner.startLocal]);

  const handleRunToThisPlace = useCallback(() => {
    testRunner.startLocal({
      stopAtStepId: isStepRow ? testGroups[groupId].steps[index - 1] : last(row.steps),
      withRedirect: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.id, testRunner.startLocal]);

  const handleInsertStep = useCallback(() => addStep(STEP_TYPE_PICKER_TAB.ACTIONS), [addStep]);

  const handleInsertAssertion = useCallback(
    () => addStep(STEP_TYPE_PICKER_TAB.ASSERTIONS),
    [addStep],
  );

  const handleMouseLeave = useCallback(() => {
    apiLineStyles.start({ opacity: 0 });
    apiMenuStyles.start({ opacity: 0 });
  }, [apiLineStyles, apiMenuStyles]);

  const handleLineMouseEnter = useCallback(() => {
    apiLineStyles.start({ opacity: 1, immediate: false });
    apiMenuStyles.start({ opacity: 1, immediate: true });
  }, [apiLineStyles, apiMenuStyles]);

  const handleMenuWrapperMouseEnter = useCallback(() => {
    apiMenuStyles.start({ opacity: 1, immediate: true });
    apiLineStyles.start({ opacity: 1, immediate: true });
  }, [apiMenuStyles, apiLineStyles]);

  useEffect(() => {
    if (opened) {
      menuRef.current.open();
    }
  }, [opened]);

  const handleNewTestFromHere = useCallback(
    () =>
      modal.show(MODAL_TYPE.EDIT_TEST, {
        group: { id: groupId, testId: test.id },
        screenSizeType: test.screenSizeType,
        name: null,
      }),
    [modal, groupId, test.id, test.screenSizeType],
  );

  useEffect(() => {
    apiLineStyles.start({ opacity: Number(shouldBeVisible), immediate: true });
    apiMenuStyles.start({ opacity: Number(shouldBeVisible), immediate: true });
  }, [apiMenuStyles, apiLineStyles, shouldBeVisible]);

  return (
    <PlaceKeeper
      ref={placeKeeperRef}
      className={className}
      data-testid="RowActiveBorder"
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Container data-testid="RowActiveBorder.Container">
        <LineWrapper onMouseEnter={handleLineMouseEnter}>
          <Line style={lineStyles} data-testid="RowActiveBorder.Line" />
        </LineWrapper>
        <animated.div style={menuStyles} onMouseEnter={handleMenuWrapperMouseEnter}>
          <Menu
            ref={menuRef}
            iconName="add"
            hideExpander
            onOpen={handleDropdownToggle}
            onClose={handleDropdownToggle}
            data-testid="RowActiveBorder.Menu"
          >
            <DropdownItem
              data-testid="RowActiveBorder.RecordFromHere"
              onClick={handleRecordFromHere}
              disabled={disabledStepOptions || isRunning}
            >
              <Dot />
              {t('rowActiveBorder.option.recordFromHere', 'Record from here')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.RunAndStopHere"
              onClick={handleRunToThisPlace}
              disabled={disabledStepOptions || disabledRunAndStop || isRunning}
            >
              <Icon name="play" />
              {t('rowActiveBorder.option.runAndStopHere', 'Run and stop here')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.NewTestFromHere"
              onClick={handleNewTestFromHere}
              disabled={disabledGroupOptions}
            >
              <Icon name="clone" />
              {t('rowActiveBorder.option.newTestFromHere', 'New test from here')}
            </DropdownItem>
            <DropdownItem
              onClick={handleSplitGroup}
              data-testid="RowActiveBorder.SplitHere"
              disabled={disabledSplitting}
            >
              <Icon name="split" />
              {isComponent
                ? t('rowActiveBorder.option.splitComponent', 'Split component')
                : t('rowActiveBorder.option.splitHere', 'Split group here')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.PasteSteps"
              onClick={handlePasteSteps}
              disabled={!hasCopiedSteps}
            >
              <Paste />
              {t('rowActiveBorder.option.pasteSteps', 'Paste step(s)')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.NewStep"
              onClick={handleInsertStep}
              disabled={disabledStepOptions}
            >
              <Icon name="add" />
              {t('rowActiveBorder.option.newStep', 'New step')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.NewAssertion"
              onClick={handleInsertAssertion}
              disabled={disabledStepOptions}
            >
              <Icon name="add" />
              {t('rowActiveBorder.option.newAssertion', 'New assertion')}
            </DropdownItem>
            <DropdownItem
              data-testid="RowActiveBorder.NewGroup"
              onClick={handleInsertGroup}
              disabled={disabledGroupOptions}
            >
              <Icon name="add" />
              {t('rowActiveBorder.option.newGroup', 'New group')}
            </DropdownItem>
            <DropdownItem
              onClick={handleInsertComponent}
              data-testid="RowActiveBorder.InsertExistingComponent"
              disabled={disabledGroupOptions}
            >
              <Icon name="add" />
              {t('rowActiveBorder.option.insertExistingComponent', 'Insert existing component')}
            </DropdownItem>
          </Menu>
        </animated.div>
      </Container>
    </PlaceKeeper>
  );
});

RowActiveBorder.displayName = 'RowActiveBorder';

RowActiveBorder.defaultProps = {
  className: null,
  row: {
    steps: [],
  },
  index: 0,
  disabledGroupOptions: false,
  disabledStepOptions: false,
  disabledSplitting: false,
  testsCount: 0,
};

RowActiveBorder.propTypes = {
  className: PropTypes.string,
  disabledGroupOptions: PropTypes.bool,
  disabledStepOptions: PropTypes.bool,
  disabledSplitting: PropTypes.bool,
  testsCount: PropTypes.number,
  row: PropTypes.shape({
    id: PropTypes.string,
    groupId: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.string),
  }),
  index: PropTypes.number,
};

export default RowActiveBorder;
