import styled from 'styled-components';

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 10px;
  color: ${({ theme }) => theme.colors.text.normal};
  cursor: pointer;

  &[aria-disabled] {
    opacity: 0.4;
  }

  &[data-active-item] {
    background-color: ${({ theme }) => theme.colors.neutrals[96]};
  }
`;

export const VariableName = styled.p`
  color: ${({ theme }) => theme.colors.text.normal};
  margin: 0;
  font-weight: 600;
`;

export const VariableDescription = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0;
  white-space: pre-wrap;
`;
