import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteRunActions } from '~/modules/suiteRun/suiteRun.redux';
import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { TAB } from '~/views/RunsHistory/RunsHistory.constants';

import { Container, Button } from './SelectedRunsActions.styled';

const SelectedRunsActions = ({ className, type, runs, onDeselect, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const isTestsTab = type === TAB.TESTS;

  useActionState(SuiteRunActions.stopRequest, { onSuccess: onDeselect });
  useActionState(TestRunActions.stopRequest, { onSuccess: onDeselect });

  const handleStop = useCallback(() => {
    if (isTestsTab) {
      dispatch(TestRunActions.stopRequest(runs));
    } else {
      dispatch(SuiteRunActions.stopRequest(runs));
    }
  }, [dispatch, isTestsTab, runs]);

  const handleDelete = useCallback(() => {
    const modalType = isTestsTab ? MODAL_TYPE.DELETE_TEST_RUNS : MODAL_TYPE.DELETE_SUITE_RUNS;
    const props = isTestsTab ? { testRunsIds: runs, onDelete } : { suiteRunsIds: runs, onDelete };
    modal.show(modalType, props);
  }, [modal, isTestsTab, runs, onDelete]);

  const bulkActionsLabel = isTestsTab
    ? t('runsHistory.selectedRowsActions.testRuns.bulkActionsButton', {
        defaultValue_one: '{{count}} test run selected',
        defaultValue_other: '{{count}} test runs selected',
        count: runs.length,
      })
    : t('runsHistory.selectedRowsActions.suiteRuns.bulkActionsButton', {
        defaultValue_one: '{{count}} suite run selected',
        defaultValue_other: '{{count}} suite runs selected',
        count: runs.length,
      });

  return (
    <Container className={className} data-testid="SelectedRunsActions">
      <Dropdown
        rounded
        data-testid="SelectedRunsActions.BulkActions"
        variant={DROPDOWN_VARIANT.PRIMARY}
        label={bulkActionsLabel}
      >
        <DropdownItem onClick={handleStop}>
          <Icon name="stop" /> {t('runsHistory.selectedRowsActions.stop', 'Stop')}
        </DropdownItem>
        <DropdownItem onClick={handleDelete}>
          <Icon name="delete" />
          {t('runsHistory.selectedRowsActions.deleteButton', 'Clear from history')}
        </DropdownItem>
      </Dropdown>
      <Button
        data-testid="TestDetails.DeselectButton"
        iconName="close"
        onClick={onDeselect}
        bordered
      >
        {t('testDetails.selectedRowsActions.deselectButton', 'Deselect all')}
      </Button>
    </Container>
  );
};

SelectedRunsActions.defaultProps = {
  className: null,
};

SelectedRunsActions.propTypes = {
  className: PropTypes.string,
  onDeselect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(TAB)).isRequired,
  runs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SelectedRunsActions;
