import * as T from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import analytics from '~/services/analytics';
import toasts from '~/services/toasts';

const IntegrationDetails = () => {
  const modal = useModal();
  const appRoutes = useAppRoutes('integration');
  const { type } = appRoutes.params;
  const { t } = useTranslation();
  const handleHide = useCallback(() => {
    appRoutes.push('integrations');
  }, [appRoutes]);

  useMount(() => {
    analytics.trackEvent('integration_opened');

    modal.show(
      'integration',
      { data: { type, projectId: appRoutes.params.projectId } },
      { onHide: handleHide },
    );

    if (appRoutes.state?.connected) {
      toasts.showSuccess({
        content: t('integrationDetails', '{{name}} connected', {
          name: T.capitalize(type),
        }),
      });
    }
  });

  return null;
};

export default IntegrationDetails;
