import Link from '@bugbug/core/components/Link';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MODAL_TYPE } from '~/components/modals';
import useModal from '~/hooks/useModal';

import * as S from './SupportRequest.styled';

const SupportRequest = ({ className }) => {
  const modal = useModal();
  const { t } = useTranslation();

  const handleSupportRequest = useCallback(() => {
    modal.show(MODAL_TYPE.SUPPORT_REQUEST_CONFIRMATION);
  }, [modal]);

  return (
    <S.Container className={className}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={handleSupportRequest}>
        <S.LinkIcon /> {t('supportRequest.description', 'Request free support')}
      </Link>
    </S.Container>
  );
};

export default SupportRequest;
