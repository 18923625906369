import { DROPDOWN_ANCHOR, DROPDOWN_VARIANT, DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Alert } from '@bugbug/core/types/alerts';
import { MODAL_TYPE } from '~/components/modals';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';

import * as S from './AlertItemActions.styled';

interface AlertItemActionsProps {
  data: Alert;
}
const AlertItemActions = ({ data: alert }: AlertItemActionsProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const appRoute = useAppRoutes('alerts');

  const handleEditClick = useCallback(() => {
    appRoute.push('alert', { alertId: alert.id });
  }, [alert, appRoute]);

  const handleCloneClick = useCallback(() => {
    modal.show(MODAL_TYPE.CLONE_ALERT, { alert });
  }, [alert, modal]);

  const handleRemoveClick = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_ALERT, {
        alert,
      }),
    [alert, modal],
  );

  const actions = [
    {
      name: t('alertItemActions.edit.label', 'Edit'),
      onClick: handleEditClick,
      iconName: 'edit',
      danger: false,
    },
    {
      name: t('alertItemActions.duplicate.label', 'Duplicate'),
      onClick: handleCloneClick,
      iconName: 'clone',
      danger: false,
    },

    {
      name: t('alertItemActions.delete.label', 'Delete'),
      onClick: handleRemoveClick,
      iconName: 'delete',
      danger: true,
    },
  ] as const;

  return (
    <S.Container>
      <S.Dropdown
        variant={DROPDOWN_VARIANT.ICON}
        anchor={DROPDOWN_ANCHOR.BOTTOM_END}
        iconName="more"
      >
        {actions.map(({ name, onClick, iconName, danger }) => (
          <DropdownItem key={name} onClick={onClick} danger={danger}>
            <Icon name={iconName} /> {name}
          </DropdownItem>
        ))}
      </S.Dropdown>
    </S.Container>
  );
};

export default AlertItemActions;
