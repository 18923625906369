import IconComponent from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
`;

export const Text = styled.p`
  width: 100%;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${COLOR.GRAY_12};
`;

export const IconWrapper = styled.div`
  margin-right: 14px;
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'help',
}))`
  position: relative;
  top: 1px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
`;
