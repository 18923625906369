import React from 'react';

import { Container, Text, Icon, IconWrapper } from './Tip.styled';

const Tip = ({ className, text = '' }) => (
  <Container className={className} data-testid="Tip">
    <IconWrapper>
      <Icon />
    </IconWrapper>
    <Text data-testid="Tip.Text">{text}</Text>
  </Container>
);

export default Tip;
