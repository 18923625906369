export const getElement = (selector, styles = {}, forceRecreate = false) => {
  const currentElement = document.querySelector(selector);

  if (currentElement && !forceRecreate) {
    return currentElement;
  }

  const element = currentElement || document.createElement('div');
  document.body.appendChild(element);

  const selectors = selector.split('.').filter(String);
  for (let index = 0; index < selectors.length; index += 1) {
    const partialSelector = selectors[index];

    if (partialSelector.startsWith('#')) {
      element.setAttribute('id', partialSelector.replace(/^#/, ''));
    } else {
      element.setAttribute('class', partialSelector);
    }
  }

  const styleProps = Object.keys(styles);
  for (let index = 0; index < styleProps.length; index += 1) {
    const prop = styleProps[index];
    element.style[prop] = styles[prop];
  }
  return element;
};

export const preloadImages = (images = []) => {
  for (let i = 0; i < images.length; i += 1) {
    const image = new Image();
    image.src = images[i];
  }
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getVisibility = (element, container) => {
  const { bottom, height, top } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const isAbove = !(containerRect.top - top <= height);
  const isBelow = !(bottom - containerRect.bottom <= height);

  const isVisible = top <= containerRect.top ? !isAbove : !isBelow;

  return {
    isVisible,
    isAbove,
    isBelow,
  };
};
