import CopyButtonBase from '@bugbug/core/components/CopyButton';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Command = styled.div`
  display: flex;
  align-items: center;
  background: ${COLOR.GRAY_21};
  border-radius: 5px;
  width: 100%;
  margin-right: 12px;
  max-width: 580px;
  padding: 12px 16px;

  span {
    font-size: 16px;
    font-family: Inconsolata, monospace;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding: 1px 0;
  height: 32px;
`;

export const CopyButton = styled(CopyButtonBase)`
  && {
    width: 148px;
    height: 32px;
    padding: 6px;
    border-radius: 5px;
    color: ${COLOR.DARK_GRAY};
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background: ${COLOR.DARK_GREY_8};
    }

    svg {
      font-size: 20px;
    }
  }
`;
