import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useMount } from 'react-use';

import type { Profile } from '@bugbug/core/types/profiles';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { ProfileActions } from '~/modules/profile/profile.redux';
import { selectProfilesList } from '~/modules/profile/profile.selectors';
import { useAppSelector } from '~/modules/store';
import urls from '~/views/urls';

import ProfileItem from '../ProfileItem';
import { ProfilesModalRoute } from '../ProfilesModalRouteHandler';

import { LoaderContainer, ProfilesList } from './Profiles.styled';

const Profiles = () => {
  const { t } = useTranslation();
  const queryString = useQueryString();
  const dispatch = useDispatch();
  const profiles = useAppSelector(selectProfilesList) as unknown as Profile[];
  const [isLoading, setIsLoading] = useState(true);

  const { hasInternalServerError } = useActionState(ProfileActions.getListRequest, {
    onSuccess: () => setIsLoading(false),
  });

  const filteredProfiles = useMemo(
    () =>
      profiles.filter(
        ({ name }) =>
          !queryString.query || name.toLowerCase().includes(queryString.query.toLowerCase()),
      ),
    [profiles, queryString.query],
  );

  const fetchData = useCallback(() => {
    dispatch(ProfileActions.getListRequest());
  }, [dispatch]);

  useMount(() => {
    fetchData();
  });

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader size="large" />
    </LoaderContainer>
  ));

  const renderEmptyState = renderWhenTrue(() => {
    if (hasInternalServerError) {
      return <ServerErrorInfo isVisible={hasInternalServerError} onRetry={fetchData} />;
    }
    return renderLoader(true);
  });

  return (
    <>
      <Helmet title={t('profiles.pageTitle', 'Profiles')} />
      {!isLoading && (
        <>
          <InlineTutorialMessage
            title={t('inlineTutorial.title')}
            message={
              <Trans key="profiles.inlineTutorial.message">
                Profiles enable you to efficiently work with variables among multiple testing
                environments. Once you add more than one profile, you will be able to select them
                before running tests or suites.{' '}
                <Link to={t('default.docs.profiles')}>Learn more</Link>
              </Trans>
            }
            storageName="profilesGettingStarted"
          />
          <ProfilesList role="grid">
            {filteredProfiles.map((profile) => (
              <ProfileItem key={profile.id} profile={profile} />
            ))}
          </ProfilesList>
        </>
      )}
      {renderEmptyState(hasInternalServerError || isLoading)}
      <Switch>
        <Route path={urls.profile} component={ProfilesModalRoute} />
      </Switch>
    </>
  );
};

export default Profiles;
