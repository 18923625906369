import Button, { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import { TableRow } from '~/components/Table';

export const Container = styled.section`
  border: 2px dashed ${COLOR.PRIMARY_BORDER};
  border-radius: 5px;
  margin-top: 1px;

  & + & {
    margin-top: 16px;
  }
`;

export const Header = styled.header`
  display: flex;
  padding: 11px 13px 9px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLOR.GRAY_18};
`;

export const EmptyState = styled.div`
  padding: 15px 13px 13px;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.p`
  color: ${COLOR.PRIMARY_BORDER};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT.BOLD};
  margin: 0;
  padding-bottom: 3px;
`;

export const Actions = styled.div`
  display: flex;
`;

export const DeleteButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))``;

export const ConfirmButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-left: 8px;
`;

export const Steps = styled.div`
  pointer-events: none;

  ${TableRow} {
    padding-left: 25px;
  }
`;
