import styled from 'styled-components';

import type { VariablesAdornmentProps } from './VariablesAdornment';

export const AdornmentContainer = styled.button<VariablesAdornmentProps>`
  all: unset;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 150ms;
  cursor: pointer;
  margin-right: ${({ container }) => (container === 'textarea' ? '-5px' : '-8px')};
  margin-top: ${({ container }) => (container === 'textarea' ? '-3px' : '2px')};

  :hover,
  :focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.neutrals[92]};
    color: ${({ theme }) => theme.colors.text.normal};
  }
`;
