import { isRunningStatus } from '@bugbug/core/types/base';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import type { Position } from './ScrollToStep.types';

import type { StepRun, TestRun } from '@bugbug/core/types/tests';
import type { Maybe } from '@bugbug/core/types/utils';
import { useAppSelector } from '~/modules/store';
import { selectCurrentStepRun, selectSingleTestRun } from '~/modules/testRun/testRun.selectors';
import { getVisibility } from '~/utils/dom';

import { WHEEL_DEBOUNCE_TIME } from './ScrollToStep.constants';
import { Container, Arrow } from './ScrollToStep.styled';

const eventParams: AddEventListenerOptions = { passive: true };

interface ScrollToStepProps {
  className?: string;
  groupsRefs: Record<
    StepRun['groupId'],
    Maybe<{
      goToStep: (
        stepId: string,
        smooth: boolean,
        position: ScrollLogicalPosition,
        center: boolean,
      ) => void;
      getStepElement: (stepId: string) => HTMLElement | null;
    }>
  >;
}

export const ScrollToStep = ({ className, groupsRefs }: ScrollToStepProps) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const scrollContainerRef = useRef<ParentNode | null>(null);
  const isAutoScrollActive = useRef<boolean>(true);
  const runningStepRun = useAppSelector(selectCurrentStepRun) as unknown as StepRun;
  const currentTestRun = useAppSelector(selectSingleTestRun) as unknown as TestRun;

  const [visibility, setVisibility] = useState<Position>(null);

  const origScrollToStep = useCallback((stepRun) => {
    if (stepRun) {
      groupsRefs[stepRun.groupId]?.goToStep(stepRun.stepId, false, 'center', false);
      setVisibility(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToStep = useMemo(
    () => debounce(origScrollToStep, WHEEL_DEBOUNCE_TIME),
    [origScrollToStep],
  );

  const handleClick = useCallback(() => {
    origScrollToStep(runningStepRun);
    isAutoScrollActive.current = true;
  }, [origScrollToStep, runningStepRun]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleButtonVisibility = useMemo(
    () =>
      debounce((stepRun) => {
        if (stepRun && !isAutoScrollActive.current) {
          const stepElement = groupsRefs[stepRun.groupId]?.getStepElement(stepRun.stepId);
          if (stepElement) {
            const { isVisible, isAbove } = getVisibility(stepElement, scrollContainerRef.current);
            const shouldShow = !isVisible;
            const newPosition: Position = shouldShow && isAbove ? 'top' : 'bottom';
            setVisibility(shouldShow ? newPosition : null);
          }
        }
      }, WHEEL_DEBOUNCE_TIME),
    [groupsRefs],
  );

  const handleUserInterrupt = useCallback(() => {
    if (runningStepRun) {
      isAutoScrollActive.current = false;
    }
  }, [runningStepRun]);

  const handleScroll = useCallback(
    () => {
      handleButtonVisibility(runningStepRun);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runningStepRun],
  );

  useMount(() => {
    if (buttonRef.current) {
      scrollContainerRef.current = buttonRef.current.parentNode;
    }
  });

  useEffect(() => {
    if (isAutoScrollActive.current) {
      scrollToStep(runningStepRun);
    } else {
      handleButtonVisibility(runningStepRun);
    }
  }, [runningStepRun, scrollToStep, handleButtonVisibility]);

  useUpdateEffect(() => {
    isAutoScrollActive.current = true;
    setVisibility(null);
  }, [currentTestRun.id]);

  useUpdateEffect(() => {
    if (!isRunningStatus(currentTestRun.status)) {
      setVisibility(null);
    }
  }, [currentTestRun.status]);

  useEffect(() => {
    scrollContainerRef.current?.addEventListener('scroll', handleScroll, eventParams);
    scrollContainerRef.current?.addEventListener('wheel', handleUserInterrupt, eventParams);
    scrollContainerRef.current?.addEventListener('mousedown', handleUserInterrupt, eventParams);

    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', handleScroll, eventParams);
      scrollContainerRef.current?.removeEventListener('wheel', handleUserInterrupt, eventParams);
      scrollContainerRef.current?.removeEventListener(
        'mousedown',
        handleUserInterrupt,
        eventParams,
      );
    };
  }, [handleScroll, handleUserInterrupt]);

  useUnmount(() => {
    handleButtonVisibility.cancel();
    scrollToStep.cancel();
  });

  return (
    <Container
      ref={buttonRef}
      className={className}
      Icon={Arrow}
      onClick={handleClick}
      visibility={visibility}
    >
      {t('scrollToStep.label', 'Jump to the running step')}
    </Container>
  );
};
