import type { Column } from 'react-table';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';
import { sortByDate } from '~/utils/sorting';

import TestRowActions from './components/TestRowActions';

export const TEST_LIST_COLUMN_ID = {
  NAME: 'name',
  LAST_RUN: 'last_result',
  ACTIONS: 'actions',
};

export const TEST_LIST_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('testList.table.columns.name', 'Name'),
    accessor: 'name',
    id: TEST_LIST_COLUMN_ID.NAME,
    Cell: TablePartials.TextCell,
  },
  TablePartials.PREDEFINED_COLUMN.SCREEN,
  {
    Header: i18n.t('testList.table.columns.lastRun', 'Last result'),
    accessor: (test: TablePartials.Data & { previousResult?: unknown; lastResult?: unknown }) =>
      test.previousResult || test.lastResult || {},
    id: TEST_LIST_COLUMN_ID.LAST_RUN,
    Cell: TablePartials.StatusCell,
    sortType: sortByDate,
    minWidth: 80,
    maxWidth: 160,
  },
  {
    Header: i18n.t('testList.table.columns.actions', 'Actions'),
    Cell: TestRowActions,
    id: TEST_LIST_COLUMN_ID.ACTIONS,
    disableSortBy: true,
    maxWidth: 85,
  },
] as Column<TablePartials.Data>[];

export const TEST_LIST_COLUMNS_WITHOUT_SCREEN = TEST_LIST_COLUMNS.filter(
  (column) => column !== TablePartials.PREDEFINED_COLUMN.SCREEN,
);
