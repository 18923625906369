import Switch from '@bugbug/core/components/Switch';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Alert } from '@bugbug/core/types/alerts';
import { PaidFeature } from '~/components/PaidFeatureGuard';
import { usePartialUpdateAlertMutation } from '~/modules/alerts';
import toasts from '~/services/toasts';

interface AlertItemActivateProps {
  data: Alert;
}
const AlertItemActivate = ({ data: alert }: AlertItemActivateProps) => {
  const { t } = useTranslation();
  const [handlePartialUpdate, { isSuccess }] = usePartialUpdateAlertMutation();

  useEffect(() => {
    if (isSuccess) {
      toasts.showSuccess({
        content: t('editAlertModal.edit.success', 'Alert has been changed successfully.'),
        id: 'handleToggleActive',
      });
    }
  }, [isSuccess, t]);

  const handleToggleActive = useCallback(() => {
    handlePartialUpdate({ data: { isActive: !alert.isActive }, id: alert.id });
  }, [alert, handlePartialUpdate]);

  return (
    <PaidFeature>
      {(isDisabled) => (
        <Switch disabled={isDisabled} onChange={handleToggleActive} value={alert.isActive} />
      )}
    </PaidFeature>
  );
};

export default AlertItemActivate;
