import LoaderComponent from '@bugbug/core/components/Loader';
import React from 'react';

import { Container } from './Loader.styled';

const Loader = () => (
  <Container data-testid="SuiteRunDetailsLoader">
    <LoaderComponent size="large" />
  </Container>
);

export default Loader;
