import Dropdown, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import { PointerEventsCatcher } from '@bugbug/core/components/Tooltip';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  ${PointerEventsCatcher} + ${PointerEventsCatcher} {
    margin-left: 9px;
  }
`;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
}))`
  margin-left: 9px;
`;
