import UnorganizedList from '@bugbug/core/components/UnorganizedList';
import PropTypes from 'prop-types';
import { complement, either, prop, propOr } from 'ramda';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { List, Title } from '../ErrorDetails.styled';
import ErrorTips from '../ErrorTips';

import { getDescription } from './WaitingConditionsFailedDetails.helpers';
import * as S from './WaitingConditionsFailedDetails.styled';

const WaitingConditionsFailedDetails = ({ stepRun }) => {
  const { t } = useTranslation();

  const waitingConditionsList = useMemo(() => {
    const failedConditions = Object.values(propOr({}, 'waitingConditions', stepRun)).filter(
      complement(either(prop('isSuccess'), prop('isIgnored'))),
    );
    // TODO: Hide the rest if document ready failed; Hide is covered if element is not visible
    return failedConditions.map((condition) => (
      <li key={condition.type}>{getDescription(condition, stepRun)}</li>
    ));
  }, [stepRun]);

  const title = stepRun.warning
    ? t('waitingConditionsFailedDetails.title.warning', 'Passed but with some issues')
    : t('waitingConditionsFailedDetails.title.failed', 'Step failed!');

  return (
    <>
      <Title>{title}</Title>
      <S.Description>
        {t('waitingConditionsFailedDetails.errorMessage.title', 'Some waiting conditions failed:')}
        <List>
          <UnorganizedList>{waitingConditionsList}</UnorganizedList>
        </List>
        <Trans i18nKey="waitingConditionsFailedDetails.errorMessage.solutions">
          <ErrorTips>
            Possible solutions:
            <UnorganizedList>
              <li>Run the test again and see if this problem happens every time</li>
              <li>Analyse which conditions failed and what is the cause</li>
              <li>In the worst case disable some waiting conditions for this step</li>
            </UnorganizedList>
          </ErrorTips>
        </Trans>
      </S.Description>
    </>
  );
};

WaitingConditionsFailedDetails.propTypes = {
  stepRun: PropTypes.shape({
    waitingConditions: PropTypes.arrayOf(
      PropTypes.shape({
        isSuccess: PropTypes.bool,
      }),
    ),
    coveringElementScreenshot: PropTypes.string,
    warning: PropTypes.bool.isRequired,
  }).isRequired,
};

WaitingConditionsFailedDetails.defaultProps = {};

export default WaitingConditionsFailedDetails;
