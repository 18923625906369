import { useEffect } from 'react';

import i18n from '~/translations';

const useLeavePagePrompt = (showMessage, promptText = i18n.t('leavePagePrompt', 'Leave page?')) => {
  useEffect(() => {
    if (showMessage) {
      const handler = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        e.returnValue = promptText;
        return promptText;
      };

      window.addEventListener('beforeunload', handler);
      return () => window.removeEventListener('beforeunload', handler);
    }

    return Function.prototype;
  }, [showMessage, promptText]);
};

export default useLeavePagePrompt;
