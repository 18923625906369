import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 17px;
`;

export const CreateButton = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  iconName: 'addCircle',
}))``;
