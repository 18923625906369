import Dropdown, { DropdownItem, DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPE } from '~/components/modals';
import useExtensionState from '~/hooks/useExtensionState';
import useModal from '~/hooks/useModal';
import { RUN_ENV } from '~/modules/constans';
import { selectHasMoreThanOneProfile } from '~/modules/profile/profile.selectors';
import { SuiteActions } from '~/modules/suite/suite.redux';

import { Container, Button } from './SelectedSuitesActions.styled';

const SelectedSuitesActions = ({ className, suitesIds, onDeselect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useModal();
  const extensionState = useExtensionState();
  const hasMoreThanOneProfile = useSelector(selectHasMoreThanOneProfile);

  const handleStop = useCallback(() => {
    dispatch(SuiteActions.stopRequest(suitesIds, true));
    onDeselect();
  }, [dispatch, suitesIds, onDeselect]);

  const handleDelete = useCallback(
    () =>
      modal.show(MODAL_TYPE.DELETE_SUITES, {
        suitesIds,
        onDelete: onDeselect,
      }),
    [modal, suitesIds, onDeselect],
  );

  const openExtensionModal = useCallback(
    () => modal.showTutorial(MODAL_TYPE.INSTALL_EXTENSION),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.showTutorial],
  );

  const run = useCallback(
    (options) => {
      const continueRun = (profileId) => {
        dispatch(
          SuiteActions.runSelectedRequest(suitesIds, {
            runMode: RUN_ENV.SERVER,
            ...options,
            runProfileId: profileId,
          }),
        );
        onDeselect();
      };

      if (hasMoreThanOneProfile) {
        modal.show(MODAL_TYPE.RUN_WITH_PROFILE, {
          onSuccess: continueRun,
        });
        return;
      }

      continueRun();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, suitesIds, onDeselect, hasMoreThanOneProfile, modal.show],
  );

  const handleRun = useCallback(() => run({ runMode: RUN_ENV.SERVER }), [run]);

  const handleRunLocally = useCallback(() => {
    if (extensionState.isDisabled) {
      openExtensionModal();
      return;
    }
    run({ runMode: RUN_ENV.LOCAL });
  }, [extensionState.isDisabled, openExtensionModal, run]);

  return (
    <Container className={className} data-testid="SelectedSuitesActions">
      <Dropdown
        rounded
        data-testid="SelectedSuitesActions.BulkActions"
        variant={DROPDOWN_VARIANT.PRIMARY}
        label={t('suitesList.header.bulkActionsButton', {
          defaultValue_one: '{{count}} suite selected',
          defaultValue_other: '{{count}} suites selected',
          count: suitesIds.length,
        })}
      >
        <DropdownItem onClick={handleRunLocally}>
          <Icon name="play" />
          {t('suitesList.selectedSuitesActions.runButton', 'Run')}
        </DropdownItem>
        <DropdownItem onClick={handleRun}>
          <Icon name="cloudRun" />
          {t('suitesList.selectedSuitesActions.runInCloudButton', 'Run in cloud')}
        </DropdownItem>
        <DropdownItem onClick={handleStop}>
          <Icon name="stop" />
          {t('suitesList.selectedSuitesActions.stopButton', 'Stop')}
        </DropdownItem>
        <DropdownItem onClick={handleDelete}>
          <Icon name="delete" />
          {t('suitesList.selectedSuitesActions.deleteButton', 'Delete')}
        </DropdownItem>
      </Dropdown>
      <Button
        data-testid="SelectedSuitesActions.DeselectButton"
        iconName="close"
        onClick={onDeselect}
        bordered
      >
        {t('suitesList.selectedSuitesActions.deselectButton', 'Deselect all')}
      </Button>
    </Container>
  );
};

SelectedSuitesActions.defaultProps = {
  className: null,
};

SelectedSuitesActions.propTypes = {
  className: PropTypes.string,
  onDeselect: PropTypes.func.isRequired,
  suitesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SelectedSuitesActions;
