import { SelectOption } from '@bugbug/core/components/Select';
import Tooltip from '@bugbug/core/components/Tooltip';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { propOr, path } from 'ramda';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { CONDITION_PARAMS, SELECT_STATE } from '~/modules/constans';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectProjectWaitingConditions } from '~/modules/project/project.selectors';
import * as validators from '~/utils/validators';
import {
  SettingsDescriptionWrapper,
  SettingsDescription,
  HelpIcon,
  SubmitButton,
  ButtonsWrapper,
  ErrorInfo,
} from '~/views/ProjectSettings/ProjectSettings.styled';

import {
  Container,
  Input,
  Select,
  Label,
  Table,
  TableRow,
  TimeoutWrapper,
} from './WaitingConditionsSettings.styled';

const WaitingConditionsSettingsSchema = Yup.object().shape({
  conditions: Yup.array().of(
    Yup.object().shape({
      timeout: validators.positiveIntegerValidator.default(null),
      expected: Yup.string().nullable(),
    }),
  ),
});

const WaitingConditionsSettings = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectWaitingConditions = useSelector(selectProjectWaitingConditions);

  const timeoutTooltipContent = t(
    'projectSettings.waitingConditionsSettings.form.timeout.tooltipContent',
    'How many seconds to wait for this condition before attempting to continue',
  );

  const handleOnSubmit = ({ conditions }) => {
    const waitingConditions = conditions.map((condition) => {
      if (condition.timeout === '') {
        return {
          ...condition,
          timeout: null,
        };
      }

      return condition;
    });

    dispatch(
      ProjectActions.updateWaitingConditionsSettingsRequest({
        waitingConditions,
      }),
    );
  };

  const {
    submitForm,
    errors,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    touched,
    isSubmitting,
    setSubmitting,
    setErrors,
  } = useFormik({
    initialValues: {
      conditions: projectWaitingConditions,
    },
    validationSchema: WaitingConditionsSettingsSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isLoading, isSuccess, hasInternalServerError } = useActionState(
    ProjectActions.updateWaitingConditionsSettingsRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  const renderLabel = (label, tooltipContent, labelId) => {
    if (!label) {
      return null;
    }
    return (
      <Tooltip content={tooltipContent}>
        <Label id={labelId}>
          {label} <HelpIcon data-testid="HelpIcon" />
        </Label>
      </Tooltip>
    );
  };

  // eslint-disable-next-line react/prop-types
  const renderRow = ({ isActive: state, type, timeout, expected }, index) => {
    const { label, hasValue, valueOptions } = CONDITION_PARAMS[type];

    const getTimeout = path(['conditions', index, 'timeout']);
    const getExpected = path(['conditions', index, 'expected']);
    const labelId = `waiting-condition-state-${index}`;
    const expectedValueId = `waiting-condition-value-${index}`;

    return (
      <TableRow key={type}>
        <Label id={labelId}>{label}</Label>
        <Select
          name={`conditions[${index}].isActive`}
          onChange={handleChange}
          onBlur={handleBlur}
          value={state.toString()}
          aria-labelledby={labelId}
        >
          <SelectOption value={SELECT_STATE.ENABLED}>{t('default.select.enabled')}</SelectOption>
          <SelectOption value={SELECT_STATE.DISABLED}>{t('default.select.disabled')}</SelectOption>
        </Select>
        {hasValue && state.toString() === SELECT_STATE.ENABLED && (
          <>
            {renderLabel(valueOptions.label, valueOptions.tooltip, expectedValueId)}
            <Input
              type={valueOptions.type}
              name={`conditions[${index}].expected`}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getExpected(touched) && getExpected(errors)}
              value={expected}
              placeholder={valueOptions.placeholder}
              min={valueOptions.min}
              aria-labelledby={expectedValueId}
            />
          </>
        )}
        <TimeoutWrapper>
          {renderLabel(
            t('projectSettings.waitingConditionsSettings.timeoutLabel', 'Timeout'),
            timeoutTooltipContent,
          )}
          <Input
            type="number"
            name={`conditions[${index}].timeout`}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getTimeout(touched) && getTimeout(errors)}
            value={timeout}
          />
        </TimeoutWrapper>
      </TableRow>
    );
  };

  return (
    <Container className={className} data-testid="WaitingConditionsSettings">
      <SettingsDescriptionWrapper>
        <SettingsDescription data-testid="SettingsDescription">
          {t(
            'projectSettings.waitingConditionsSettings.description',
            'Global default waiting conditions for all tests. If you need different set up for specific test you can overwrite the defaults while editing a test.',
          )}
        </SettingsDescription>
      </SettingsDescriptionWrapper>
      <form noValidate data-testid="WaitingConditionsSettings.form" onSubmit={handleSubmit}>
        <Table>{propOr([], 'conditions', values).map(renderRow)}</Table>
        <ButtonsWrapper>
          <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
            {t('default.button.save')}
          </SubmitButton>
          <ErrorInfo isVisible={hasInternalServerError} inline onRetry={submitForm} />
        </ButtonsWrapper>
      </form>
    </Container>
  );
};

WaitingConditionsSettings.defaultProps = {
  className: null,
};

WaitingConditionsSettings.propTypes = {
  className: PropTypes.string,
};

export default WaitingConditionsSettings;
