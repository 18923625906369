import DropdownBase from '@bugbug/core/components/Dropdown';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 5px;
`;

export const VisibilityWrapper = styled.div`
  padding: 6px;
  flex: 35 0 auto;
  max-width: 65px;

  ${styleWhenTrue(
    prop('force'),
    css`
      & > * {
        display: flex !important;
      }
    `,
  )}
`;

export const Dropdown = styled(DropdownBase)``;
