import { COLOR, hexToRgba } from '@bugbug/core/theme/colors';
import { TextWithExtraSpaces } from '@bugbug/core/theme/typography';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Part = styled(TextWithExtraSpaces)`
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  display: inline;

  ${styleWhenTrue(
    prop('added'),
    css`
      background: ${hexToRgba(COLOR.SUCCESS, 0.49)};
    `,
  )}

  ${styleWhenTrue(
    prop('whitespaceOnly'),
    css`
      min-width: calc(${prop('size')} * 2px);
      min-height: 18px;
      display: inline-block;
    `,
  )}

  ${styleWhenTrue(
    prop('lineBreaksOnly'),
    css`
      display: inherit;
      width: 100%;
    `,
  )}

  ${styleWhenTrue(
    prop('removed'),
    css`
      background: ${hexToRgba(COLOR.ERROR, 0.4)};
    `,
  )}
`;
