import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: inherit;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
  offset: 5,
}))``;
