import { COLOR, FONT_WEIGHT, THEME_MODE } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { propEq } from 'ramda';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import { BADGE_VARIANT } from './Badge.constants';

const badgeVariant = theme.variants('mode', 'variant', {
  [BADGE_VARIANT.DEFAULT]: {
    [THEME_MODE.LIGHT]: css`
      right: 14%;
      bottom: 14%;
      padding: 2px 6px 4px 6px;
      height: auto;
      background-color: ${COLOR.PRIMARY_LIGHT};
      border-radius: 10px;
      color: ${COLOR.WHITE};
      font-size: 13px;
      font-weight: ${FONT_WEIGHT.SEMIBOLD};
      line-height: 1;
      pointer-events: none;
      display: grid;
      place-items: center;
      position: absolute;
      z-index: 1;
      transform: scale(1) translate(50%, 50%);
      transform-origin: 100% 100%;
    `,
  },
  [BADGE_VARIANT.COUNTER]: {
    [THEME_MODE.LIGHT]: css`
      position: relative;
      top: 2px;
      min-height: 20px;
      margin-left: 6px;
      padding: 0 4px 2px 4px;
      border-radius: 3px;
      background-color: ${COLOR.GRAY_35};
      color: ${COLOR.GRAY_12};
      font-size: 14px;
      line-height: 20px;
      font-weight: ${FONT_WEIGHT.NORMAL};
      text-align: center;
    `,
  },
});

export const BadgeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BadgeItem = styled.span`
  min-width: 20px;
  transition: transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${badgeVariant}
  ${styleWhenTrue(
    propEq('value', 0),
    css`
      transform: scale(0) translate(50%, 50%);
    `,
  )}
`;
