import type { Step } from '@bugbug/core/types/steps';
import FilePreview from '~/components/FilePreview';
import { TextCell } from '~/components/Table';
import AnswerPromptParams from '~/components/Table/components/ParamsCell/AnswerPromptParams';
import AssertParams from '~/components/Table/components/ParamsCell/AssertParams';
import SelectParams from '~/components/Table/components/ParamsCell/SelectParams';
import SwitchContextParams from '~/components/Table/components/ParamsCell/SwitchContextParams';
import { getStepParams } from '~/utils/misc';

import * as S from './ParamsCell.styled';

interface ParamsCell {
  step: Step;
}

// TODO remove this component as part of the Table refactoring
const ParamsCell = ({ step }) => {
  const paramValue = getStepParams(step);

  switch (step.type) {
    case 'answerPrompt':
      // @ts-expect-error this needs to be migrated to TS
      return <AnswerPromptParams value={paramValue} />;

    case 'assert':
      return <AssertParams {...paramValue} />;

    case 'select':
      return <SelectParams {...paramValue} />;

    case 'switchContext':
      return <SwitchContextParams {...paramValue} />;

    case 'uploadFile':
      return (
        <S.TextBlock>
          <FilePreview name={paramValue} hideDownload />
        </S.TextBlock>
      );

    case 'change':
    case 'setLocalVariable':
    case 'type':
      return <S.TextBlock>{paramValue}</S.TextBlock>;

    default:
      // @ts-expect-error this needs to be migrated to TS
      return <TextCell value={paramValue} parsedUrls />;
  }
};

export default ParamsCell;
