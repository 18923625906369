import type { ISODate, URLString, UUID } from './aliases';
import type { ExtendedRunStatus, RunResult, RunStatus } from './base';
import type { ErrorCode, PageLoadingErrorCode } from './errors';
import type { Grouping } from './groups';
import type { Profile } from './profiles';
import type { Project } from './projects';
import type { AssertionProperty, AssertionType, Step, StepType } from './steps';
import type { SuiteRun } from './suites';
import type { User } from './users';
import type { Maybe } from './utils';
import type { WaitingCondition } from './waitingConditions';

export type Test = {
  created: ISODate;
  createdBy: string;
  groups: Grouping[];
  hasSteps: boolean;
  id: UUID;
  isActive: boolean;
  isRecording: boolean;
  isVerified: boolean;
  lastResult: Maybe<RunResult>;
  lastUnfinishedUserRun: Maybe<Omit<RunResult, 'user'>>;
  name: string;
  notes: string;
  projectId: UUID;
  projectSlug: Project['slug'];
  runProfile: TestRunProfile;
  runProfileId: TestRunProfile['id'];
  screenSizeType: TestRunScreenSize;
  status: RunStatus;
  testRun: Maybe<TestRun>;
  unconfirmedGroups: Grouping[];
};

interface TestRunProfile {
  id: UUID;
  name: string;
  isDefault: boolean;
}

export type TestRunScreenSize = 'custom' | 'desktop' | 'mobile';

type TestRunTrigger = 'user' | 'api' | 'scheduler';

type TestRunMode = 'local' | 'server';

export interface TestRun {
  autoRetryAttempt: number;
  browserHeight: number;
  browserName: string;
  browserVersion: string;
  browserWidth: number;
  created: ISODate;
  duration: string;
  ended: ISODate;
  errorCode: Maybe<ErrorCode>;
  extensionVersion: string;
  id: UUID;
  isAutoRetried: boolean;
  logsArtifactUrl: null;
  osName: string;
  profileName: Profile['name'];
  projectId: UUID;
  projectSlug: Project['slug'];
  runMode: TestRunMode;
  screenSizeType: TestRunScreenSize;
  sequence: number;
  started: ISODate;
  status: RunStatus;
  stepsRuns: StepRun[];
  suiteRun: Maybe<SuiteRun>;
  test: Test | TestArchive;
  testArchive: Test | TestArchive;
  triggeredBy: TestRunTrigger;
  testSourceId?: Test['id'];
  user: Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>;
}

type TestArchive = Pick<
  Test,
  'id' | 'isActive' | 'isRecording' | 'isVerified' | 'name' | 'runProfileId' | 'screenSizeType'
>;

export interface StepRunSummary extends Omit<StepRun, 'status'> {
  status: ExtendedRunStatus;
}

export interface StepRun extends RunStatusFlags, RunErrorFlags {
  assertionCurrentValue: Maybe<string>;
  assertionExpectedValue: Maybe<string>;
  assertionProperty: AssertionProperty;
  assertionType: AssertionType;
  computedAssertionExpectedValue: Maybe<string>;
  computedFrameLocation: Maybe<string>;
  computedPassword: Maybe<string>;
  computedSelector: Maybe<string>;
  computedUrl: Maybe<string>;
  computedUsername: Maybe<string>;
  computedValue: Maybe<string>;
  coveringElementScreenshot: Maybe<URLString>;
  created: ISODate;
  deleted: Maybe<ISODate>;
  duration: Maybe<string>;
  elementExists: Maybe<boolean>;
  ended: Maybe<ISODate>;
  executed: Maybe<boolean>;
  executionUrl: Maybe<URLString>;
  groupId: Grouping['id'];
  id: UUID;
  interactionPositionX: Maybe<number>;
  interactionPositionY: Maybe<number>;
  modified: ISODate;
  queued: Maybe<ISODate>;
  resumed: Maybe<ISODate>;
  runMode: TestRunMode;
  runTimeout: Maybe<number>;
  screenshot: Maybe<URLString>;
  scrollX: Maybe<number>;
  scrollY: Maybe<number>;
  selector: Maybe<string>;
  sleep: Maybe<number>;
  sourceGroupId: Grouping['id'];
  sourceStepId: Step['id'];
  started: Maybe<ISODate>;
  stepId: Step['id'];
  tabNo: number;
  testId: Test['id'];
  testRunId: TestRun['id'];
  timeout: Maybe<boolean>;
  triggeredBy: TestRunTrigger;
  type: StepType;
  windowInnerHeight: Maybe<number>;
  windowInnerWidth: Maybe<number>;
  waitingConditions: WaitingCondition[];
}

export interface RunStatusFlags {
  status: RunStatus;
  warning: boolean;
  forceFailed: boolean;
  ignoredFailed: boolean;
}

export interface RunErrorFlags {
  error: Maybe<PageLoadingErrorCode | string>;
  errorCode: Maybe<ErrorCode>;
  errorId: Maybe<string>;
}

export const RUNS_HISTORY_TAB = ['testRunsList', 'suiteRunsList'] as const;
export type RunsHistoryTab = (typeof RUNS_HISTORY_TAB)[number];
