import { DropdownHeader, DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import type { Test } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import useModal from '~/hooks/useModal';
import config from '~/modules/config';
import { selectTestGroupsIds } from '~/modules/test/test.selectors';
import { UIStateActions } from '~/modules/uiState';
import { selectCollapsedGroups } from '~/modules/uiState/uiState.selectors';

import * as S from './TestActions.styled';

interface MoreActionsProps {
  testId: Test['id'];
  onClearTest: SideEffect;
  onRename: React.MouseEventHandler<HTMLButtonElement>;
  hasSteps: boolean;
}

const MoreActions = ({ testId, onClearTest, onRename, hasSteps }: MoreActionsProps) => {
  const { t } = useTranslation();
  const modal = useModal();
  const location = useLocation();
  const { push } = useAppRoutes('test');

  const dispatch = useDispatch();
  const groupsIds = useSelector(selectTestGroupsIds);
  const collapsedGroups = useSelector(selectCollapsedGroups(testId));

  const handleCopyLinkToTest = useCopyHandler(`${config.WEBAPP_URL}${location.pathname}`, {
    success: t('testDetails.testActions.copyLinkToTestMsg.success', 'Link to test copied'),
    failure: t(
      'testDetails.testActions.copyLinkToTestMsg.failure',
      'Error when copying link to test',
    ),
  });

  const handleShowHistory = () => {
    push('testRunsList', undefined, { query: testId });
  };

  const handleClone = () =>
    modal.show('clone_test', {
      id: testId,
      shouldNavigate: true,
    });

  const handleCliCommands = () => {
    modal.show('test_cli_commands', { data: { testId } });
  };

  const handleTestSettings = () => {
    modal.show('test_settings', { testId });
  };

  const handleDeleteSuccess = () => {
    push('testsList');
  };

  const handleDelete = () =>
    modal.show('delete_tests', {
      testsIds: [testId],
      onDelete: handleDeleteSuccess,
    });

  const handleDeleteAllGroups = useCallback<SideEffect>(
    () =>
      modal.show('delete_all_groups', {
        testId,
        onSubmit: onClearTest,
      }),
    [modal, testId, onClearTest],
  );

  const onCollapseExpandAll = useCallback<SideEffect<boolean>>(
    (shouldCollapse) => {
      dispatch(
        UIStateActions.setCollapsedGroups({
          collapsedGroupsIds: shouldCollapse ? groupsIds : [],
          testId,
        }),
      );
    },
    [dispatch, groupsIds, testId],
  );

  return (
    <S.Menu
      data-testid="TestDetails.TestHeaderActions.Menu"
      iconName="more"
      hideExpander
      rounded
      condensed
    >
      <DropdownHeader>{t('testDetails.testActions.header.test', 'Test')}</DropdownHeader>

      <DropdownItem onClick={handleTestSettings}>
        <Icon name="settings" /> {t('testDetails.testActions.settings', 'Test settings')}
      </DropdownItem>

      <DropdownItem onClick={onRename}>
        <Icon name="edit" /> {t('testDetails.testActions.rename', 'Rename')}
      </DropdownItem>

      <DropdownItem onClick={handleClone}>
        <Icon name="clone" /> {t('testDetails.testActions.clone', 'Duplicate')}
      </DropdownItem>

      <DropdownItem onClick={handleShowHistory}>
        <Icon name="history" /> {t('testDetails.testActions.runsHistory', 'Runs history')}
      </DropdownItem>

      <DropdownItem onClick={handleCopyLinkToTest}>
        <Icon name="link2" /> {t('testDetails.testActions.copyLinkToTest', 'Copy link to test')}
      </DropdownItem>

      <DropdownItem onClick={handleCliCommands}>
        <Icon name="terminal" /> {t('testDetails.testActions.cliCommands', 'Run via CLI')}
      </DropdownItem>

      <DropdownItem onClick={handleDelete} danger noDivider>
        <Icon name="trashRemove" fill="currentColor" width={18} height={18} />
        {t('testDetails.testActions.delete', 'Delete')}
      </DropdownItem>

      <DropdownHeader>{t('testDetails.testActions.header.groups', 'Groups')}</DropdownHeader>

      <DropdownItem
        onClick={() => onCollapseExpandAll(false)}
        disabled={collapsedGroups.length === 0}
      >
        <Icon name="chevronsDown" />{' '}
        {t('testDetails.testActions.expandAllGroups', 'Expand all groups')}
      </DropdownItem>

      <DropdownItem
        onClick={() => onCollapseExpandAll(true)}
        disabled={collapsedGroups.length === groupsIds.length}
      >
        <Icon name="chevronsUp" />{' '}
        {t('testDetails.testActions.collapseAllGroups', 'Collapse all groups')}
      </DropdownItem>

      <DropdownItem onClick={handleDeleteAllGroups} danger noDivider disabled={!hasSteps}>
        <Icon name="clearAll" /> {t('testDetails.testActions.deleteAllGroups', 'Delete all groups')}
      </DropdownItem>
    </S.Menu>
  );
};

export default MoreActions;
