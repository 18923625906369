import { noop } from '@bugbug/core/utils/toolbox';
import { useRef, memo, useCallback } from 'react';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import type { SideEffect } from '@bugbug/core/types/utils';
import useTestRunner from '~/hooks/useTestRunner';
import { useAppSelector } from '~/modules/store';
import { selectHasSteps } from '~/modules/test/test.selectors';

import DebugButtons from './DebugButtons';
import IdleButtons from './IdleButtons';
import MoreActions from './MoreActions';
import RecordingButtons from './RecordingButtons';
import RunningButtons from './RunningButtons';
import * as S from './TestActions.styled';

interface TestHeaderActionsProps {
  className?: string;
  testId: Test['id'];
  testRunId?: TestRun['id'];
  onClearTest?: SideEffect;
}

const TestHeaderActions = memo(
  ({ className, onClearTest = noop, testId, testRunId }: TestHeaderActionsProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const hasSteps = useAppSelector(selectHasSteps);
    const testRunner = useTestRunner({ id: testId }, { testRunId });

    const handleRename = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
      if (containerRef.current) {
        const input = containerRef.current.closest('header')?.querySelector('input');
        input?.focus();
        input?.select();
      }
    }, []);

    return (
      <S.Container
        ref={containerRef}
        className={className}
        data-testid="TestDetails.TestHeaderActions"
        id="testHeader"
      >
        {hasSteps && testRunner.isIdle && <IdleButtons testId={testId} />}
        {!testRunner.isRecording && testRunner.isRunning && (
          <RunningButtons testId={testId} testRunId={testRunId} />
        )}
        {!testRunner.isRecording && testRunner.isDebugging && (
          <DebugButtons testId={testId} testRunId={testRunId} />
        )}
        {testRunner.isRecording && <RecordingButtons testId={testId} testRunId={testRunId} />}
        {!testRunner.isRunning && !testRunner.isRecording && (
          <MoreActions
            testId={testId}
            onClearTest={onClearTest}
            onRename={handleRename}
            hasSteps={hasSteps}
          />
        )}
      </S.Container>
    );
  },
);

TestHeaderActions.displayName = 'TestHeaderActions';

export default TestHeaderActions;
