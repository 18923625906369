import Icon from '@bugbug/core/components/Icon';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { ProjectActions } from '~/modules/project/project.redux';
import { selectSingleProject } from '~/modules/project/project.selectors';
import * as validators from '~/utils/validators';
import {
  SettingsDescriptionWrapper,
  SettingsDescription,
  Column,
  Label,
  SubmitButton,
  Form,
  ButtonsWrapper,
  ErrorInfo,
} from '~/views/ProjectSettings/ProjectSettings.styled';

import { Container, Row, ResolutionRow, ResolutionInput } from './ScreenSizes.styled';

const ScreenSizesSchema = Yup.object().shape({
  browserWidth: validators.windowSizeValidator,
  browserHeight: validators.windowSizeValidator,
  mobileBrowserHeight: validators.windowSizeValidator,
  mobileBrowserWidth: validators.windowSizeValidator,
});

const ScreenSizes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(selectSingleProject);

  const handleOnSubmit = (values) => {
    dispatch(ProjectActions.updateWindowAndBrowserSettingsRequest(values));
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    submitForm,
    setSubmitting,
    setErrors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      browserWidth: project.settings.browserWidth,
      browserHeight: project.settings.browserHeight,
      mobileBrowserWidth: project.settings.mobileBrowserWidth,
      mobileBrowserHeight: project.settings.mobileBrowserHeight,
    },
    validationSchema: ScreenSizesSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const { isSuccess, isLoading, hasInternalServerError } = useActionState(
    ProjectActions.updateWindowAndBrowserSettingsRequest,
    {
      reset: false,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    },
  );

  return (
    <Container data-testid="ScreenSizes">
      <SettingsDescriptionWrapper>
        <SettingsDescription data-testid="SettingsDescription">
          {t('projectSettings.screenSizes.description', 'Default window size for tests')}
        </SettingsDescription>
      </SettingsDescriptionWrapper>
      <Form noValidate data-testid="ScreenSizes.Form" onSubmit={handleSubmit}>
        <Row>
          <Column>
            <Label>
              <Icon name="desktop" />
              {t('projectSettings.screenSizes.form.desktopResolution.label', 'Desktop')}
            </Label>
          </Column>
          <Column>
            <ResolutionRow>
              <ResolutionInput
                type="number"
                name="browserWidth"
                min="500"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.browserWidth}
                error={touched.browserWidth && errors.browserWidth}
                endAdornment={t('default.units.screen')}
                fullWidth
              />
              <span>x</span>
              <ResolutionInput
                type="number"
                min="500"
                name="browserHeight"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.browserHeight}
                error={touched.browserHeight && errors.browserHeight}
                endAdornment={t('default.units.screen')}
                fullWidth
              />
            </ResolutionRow>
          </Column>
        </Row>

        <Row>
          <Column>
            <Label>
              <Icon name="mobile" />
              {t('projectSettings.screenSizes.form.mobileResolution.label', 'Mobile')}
            </Label>
          </Column>
          <Column>
            <ResolutionRow>
              <ResolutionInput
                type="number"
                min="500"
                name="mobileBrowserWidth"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobileBrowserWidth}
                error={touched.mobileBrowserWidth && errors.mobileBrowserWidth}
                endAdornment={t('default.units.screen')}
                fullWidth
              />
              <span>x</span>
              <ResolutionInput
                type="number"
                min="500"
                name="mobileBrowserHeight"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobileBrowserHeight}
                error={touched.mobileBrowserHeight && errors.mobileBrowserHeight}
                endAdornment={t('default.units.screen')}
                fullWidth
              />
            </ResolutionRow>
          </Column>
        </Row>

        <ButtonsWrapper>
          <SubmitButton succeeded={isSuccess} disabled={isSubmitting} pending={isLoading}>
            {t('default.button.save')}
          </SubmitButton>
          <ErrorInfo isVisible={hasInternalServerError} onRetry={submitForm} />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
};

export default ScreenSizes;
