import type { RunsHistoryTab } from '@bugbug/core/types/tests';
import i18n from '~/translations';

export const TAB = {
  TESTS: 'testRunsList',
  SUITES: 'suiteRunsList',
} as const;

export const TAB_LABEL: Record<RunsHistoryTab, string> = {
  testRunsList: i18n.t('runsHistory.tabs.tests.label', 'Tests'),
  suiteRunsList: i18n.t('runsHistory.tabs.suites.label', 'Suites'),
};
