import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TestRunActions } from '~/modules/testRun/testRun.redux';
import { selectTestRunList, selectCurrentPageParams } from '~/modules/testRun/testRun.selectors';

const useRefreshTestRunsHistory = () => {
  const dispatch = useDispatch();
  const testRunList = useSelector(selectTestRunList);
  const params = useSelector(selectCurrentPageParams);

  const page = !testRunList.length
    ? Math.max(params.pagination.currentPage - 1, 1)
    : params.pagination.currentPage;

  return useCallback(() => {
    dispatch(TestRunActions.getListRequest(page, params.query, params.sortBy, params.descOrder));
  }, [dispatch, page, params]);
};

export default useRefreshTestRunsHistory;
