import { always, cond, equals } from 'ramda';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ActionBlock from '~/components/ActionBlock';

import { ACTIONS, ACTION } from './EndTestActions.constants';
import { Container, ActionWrapper, Title } from './EndTestActions.styled';

const EndTestActions = (props) => {
  const {
    className,
    onRecordClick,
    onNewGroupClick,
    onInsertComponentClick,
    onPasteStepsClick,
    disabledRecording = false,
    disabledPasting = false,
    pendingPasting = false,
  } = props;
  const { t } = useTranslation();

  const renderAction = useCallback(
    (action) => {
      const { key, title, Icon } = action;
      const actionProps = cond([
        [
          equals(ACTION.RECORD),
          always({
            onClick: onRecordClick,
            disabled: disabledRecording,
          }),
        ],
        [equals(ACTION.NEW_GROUP), always({ onClick: onNewGroupClick })],
        [equals(ACTION.EXISTING_COMPONENT), always({ onClick: onInsertComponentClick })],
        [
          equals(ACTION.PASTE_STEPS),
          always({
            onClick: onPasteStepsClick,
            disabled: disabledPasting,
            pending: pendingPasting,
          }),
        ],
      ])(key);

      return (
        <ActionWrapper key={key}>
          <ActionBlock title={title} Icon={Icon} {...actionProps} />
        </ActionWrapper>
      );
    },
    [
      onInsertComponentClick,
      onNewGroupClick,
      onRecordClick,
      disabledRecording,
      disabledPasting,
      onPasteStepsClick,
      pendingPasting,
    ],
  );

  return (
    <Container className={className} data-testid="EndTestActions">
      <Title>{t('endTestActions.title', 'End of test')}</Title>
      {ACTIONS.map(renderAction)}
    </Container>
  );
};

export default EndTestActions;
