import IconBase from '@bugbug/core/components/Icon';
import { FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  gap: 20px;
`;

export const Title = styled.div`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: 2px;
`;

export const Content = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.secondary};
  word-break: keep-all;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled(IconBase)`
  color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  width: 19px;
  height: 19px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
