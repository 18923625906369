import { useTranslation } from 'react-i18next';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useTestRunner from '~/hooks/useTestRunner';

import * as S from './TestActions.styled';

interface RecordingButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

const RecordingButtons = ({ testId, testRunId }: RecordingButtonsProps) => {
  const { t } = useTranslation();
  const testRunner = useTestRunner({ id: testId }, { testRunId });

  return (
    <S.RecordButton onClick={testRunner.stop} iconName="stop">
      {t('testDetails.testActions.stopRecordingButton', 'Stop recording')}
    </S.RecordButton>
  );
};

export default RecordingButtons;
