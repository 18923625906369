import Tooltip, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';

import type { Profile } from '@bugbug/core/types/profiles';
import type { Test, TestRun } from '@bugbug/core/types/tests';
import ProfilePicker from '~/components/ProfilePicker';
import useTestRunner from '~/hooks/useTestRunner';
import { selectProfile } from '~/modules/profile';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { TestActions } from '~/modules/test/test.redux';
import { selectTestProfileId } from '~/modules/test/test.selectors';
import { selectHasNotStartedTest } from '~/modules/user/user.selectors';

import * as S from './TestActions.styled';

interface IdleButtonsProps {
  testId: Test['id'];
  testRunId?: TestRun['id'];
}

const IdleButtons = ({ testId, testRunId }: IdleButtonsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profileId = useAppSelector(selectTestProfileId);
  const currentProfile = useAppSelector(selectProfile(profileId)) as unknown as Profile;
  const hasNotStartedTest = useAppSelector(selectHasNotStartedTest);
  const testRunner = useTestRunner({ id: testId }, { testRunId });

  const [showRunTooltip, setShowRunTooltip] = useState(hasNotStartedTest);

  useUpdateEffect(() => {
    if (!hasNotStartedTest) {
      setShowRunTooltip(false);
    }
  }, [hasNotStartedTest]);

  const handleProfileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(TestActions.updateRequest(testId, { runProfileId: event.target.value }));
  };

  const handleRun: React.MouseEventHandler<HTMLButtonElement> = () => {
    testRunner.start({ withDebug: true, withRedirect: true, stopWhenUnconfirmedGroups: true });
  };

  const handleRunLocal: React.MouseEventHandler<HTMLButtonElement> = () => {
    testRunner.startLocal({ withDebug: true, withRedirect: true, stopWhenUnconfirmedGroups: true });
  };

  return (
    <>
      <ProfilePicker value={currentProfile} onChange={handleProfileChange} container="testHeader" />
      <Tooltip
        anchor={TOOLTIP_ANCHOR.BOTTOM_CENTER}
        forceOpen={showRunTooltip}
        offset={5}
        onOutsideClick={showRunTooltip ? () => setShowRunTooltip(false) : undefined}
        content={
          showRunTooltip
            ? t(
                'testDetails.testActions.runButtonTooltip',
                'Click here to run the test and see how BugBug repeats your recorded steps.',
              )
            : ''
        }
      >
        <S.RunButton onClick={handleRunLocal} iconName="play">
          {t('testDetails.testActions.runButton', 'Run')}
        </S.RunButton>
      </Tooltip>
      <S.Button onClick={handleRun} iconName="cloudRun">
        {t('testDetails.testActions.runInCloudButton', 'Run in cloud')}
      </S.Button>
    </>
  );
};

export default IdleButtons;
