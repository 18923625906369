import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { BADGE_VARIANT } from './Badge.constants';
import { BadgeContainer, BadgeItem } from './Badge.styled';

const Badge = ({ className, value, children, variant, limit }) => {
  const badgeValue = useCallback(() => {
    if (limit && value > limit) {
      return `${limit}+`;
    }

    return value;
  }, [value, limit]);

  return (
    <BadgeContainer className={className} data-testid="Badge">
      {children}

      <BadgeItem variant={variant} value={value}>
        {badgeValue()}
      </BadgeItem>
    </BadgeContainer>
  );
};

Badge.defaultProps = {
  className: null,
  variant: BADGE_VARIANT.DEFAULT,
  limit: null,
};

Badge.propTypes = {
  className: PropTypes.string,
  value: PropTypes.PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(Object.values(BADGE_VARIANT)),
  limit: PropTypes.number,
};

export default Badge;
