import { always, complement, cond, prop, T } from 'ramda';

import EmptyStateNoRecording from '../EmptyStateNoRecording';
import EmptyStateRecording from '../EmptyStateRecording';

export const STATES = {
  RECORDING: 'recording',
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const STATE_COMPONENT_MAP = {
  [STATES.RECORDING]: EmptyStateRecording,
  [STATES.VISIBLE]: EmptyStateNoRecording,
  [STATES.HIDDEN]: always(null),
};

export const getActiveState = cond([
  [complement(prop('isVisible')), always(STATES.HIDDEN)],
  [prop('isRecording'), always(STATES.RECORDING)],
  [T, always(STATES.VISIBLE)],
]);
