import { COLOR, FONT_WEIGHT, lineClamp } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  line-height: 13px;
  align-items: center;
`;

export const Index = styled.span`
  margin-right: 4px;
  border-radius: 5px;
  color: ${COLOR.PRIMARY_LIGHT};
  font-weight: ${FONT_WEIGHT.BOLD};
  flex: none;
`;

export const Name = styled.span`
  width: 100%;
  ${lineClamp({ lines: 3 })}
  padding-right: 15px;
  line-height: 17px;
`;
