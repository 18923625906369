import Tooltip, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { useTranslation } from 'react-i18next';

import * as S from './DownloadLogsButton.styled';

const DownloadLogsButton = ({ url }) => {
  const { t } = useTranslation();

  const handleDownloadLogs = () => window.open(url, '_blank');

  return (
    <Tooltip
      content={
        !url &&
        t('downloadLogsButton.logsEmpty', 'The logs have expired or have not yet been collected')
      }
      anchor={TOOLTIP_ANCHOR.BOTTOM_CENTER}
    >
      <S.Button
        onClick={handleDownloadLogs}
        iconName="externalLink"
        disabled={!url}
        wrappedByTooltip={!url}
      >
        {t('downloadLogsButton.logsLabel', 'Logs')}
      </S.Button>
    </Tooltip>
  );
};

export default DownloadLogsButton;
