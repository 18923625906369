import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';
import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const Status = styled(StatusBadge)`
  display: flex;
`;

export const SmallButton = styled.button.attrs(() => ({
  type: 'button',
}))`
  color: ${COLOR.PRIMARY_LIGHT};
  background: none;
  border: none;
  padding: 0 5px;
  outline: none !important;
  font-weight: ${FONT_WEIGHT.BOLD};
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: ${COLOR.PRIMARY_LIGHT_ACTIVE};
  }

  &:disabled {
    color: ${COLOR.PRIMARY_LIGHT_INACTIVE};
    cursor: default;
  }
`;

export const MetaValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  opacity: 0.5;
  cursor: default;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
  offset: 5,
}))``;

export const ShortValue = styled.span`
  white-space: nowrap;
  max-width: 13ch;
  overflow: hidden;
  text-overflow: ellipsis;

  & div {
    margin-top: 2px;
    height: 16px;
  }
`;
