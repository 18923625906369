import { StatusBadge } from '@bugbug/core/components/StatusBadge/StatusBadge';
import { COLOR } from '@bugbug/core/theme';
import { useTranslation } from 'react-i18next';

import type { StatsType } from './RunDetailsSummary.types';

import DurationTime from '../DurationTime';
import SemiCircleProgressChart from '../SemiCircleProgressChart';

import { DetailsMetric } from './DetailsMetric';
import * as S from './RunDetailsSummary.styled';

interface RunDetailsSummaryProps {
  className?: string;
  stats: Record<StatsType, number>;
  duration: string;
  passedLabel?: string;
  durationLabel?: string;
}

const RunDetailsSummary = ({
  className,
  stats,
  duration,
  passedLabel,
  durationLabel,
}: RunDetailsSummaryProps) => {
  const { t } = useTranslation();
  const passedPercent = Math.round((stats.passed * 100) / stats.total || 0);

  return (
    <S.Container className={className}>
      <S.ContentSlice>
        <S.ChartContainer>
          <SemiCircleProgressChart
            value={passedPercent}
            color={COLOR.SUCCESS}
            backgroundColor={COLOR.ERROR}
          />
        </S.ChartContainer>
        <S.Stats>
          <S.StatsItem>
            <StatusBadge status="passed" />
            <span>
              {t('runDetailsSummary.stats.passedLabel', 'Passed - {{percent}}%', {
                percent: passedPercent,
              })}
            </span>
          </S.StatsItem>
          <S.StatsItem>
            <StatusBadge status="failed" />
            <span>
              {t('runDetailsSummary.stats.failedLabel', 'Failed - {{percent}}%', {
                percent: Math.round((stats.failed * 100) / stats.total),
              })}
            </span>
          </S.StatsItem>
          <S.StatsItem>
            <StatusBadge status="error" />
            <span>
              {t('runDetailsSummary.stats.errorLabel', 'Error - {{percent}}%', {
                percent: Math.round((stats.error * 100) / stats.total),
              })}
            </span>
          </S.StatsItem>
        </S.Stats>
      </S.ContentSlice>
      <S.ContentSlice>
        <DetailsMetric
          value={t('runDetailsSummary.stats.passedValue', '{{passed}} / {{ total }}', stats)}
          description={passedLabel ?? t('runDetailsSummary.passedLabel', 'Passed')}
        />
        <DetailsMetric
          value={<DurationTime value={duration} />}
          description={durationLabel ?? t('runDetailsSummary.durationLabel', 'Run duration')}
        />
      </S.ContentSlice>
    </S.Container>
  );
};

export default RunDetailsSummary;
