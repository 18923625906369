import { useFormikContext } from 'formik';
import {
  keys,
  values,
  intersection,
  map,
  ifElse,
  addIndex,
  compose,
  always,
  filter,
  complement,
  isNil,
  lt,
  length,
  pipe,
} from 'ramda';
import { useMemo } from 'react';

const useFormikContextWithTabsErrors = ({ fieldGroups }) => {
  const groups = useMemo(() => keys(fieldGroups), [fieldGroups]);
  const fields = useMemo(() => values(fieldGroups), [fieldGroups]);
  const formik = useFormikContext();

  const groupsWithErrors = useMemo(() => {
    const errorsFieldsKeys = keys(formik.errors);
    const groupsErrors = map(intersection(errorsFieldsKeys))(fields);

    return pipe(
      addIndex(map)((errors, index) =>
        ifElse(compose(lt(0), length), always(groups[index]), always(null))(errors),
      ),
      filter(complement(isNil)),
    )(groupsErrors);
  }, [fields, formik.errors, groups]);

  return {
    ...formik,
    groupsWithErrors,
  };
};

export default useFormikContextWithTabsErrors;
