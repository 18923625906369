import { AddIcon as AddIconBase, COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

import DragHandlerBase from '~/components/DragHandler';
import EditableTextBase from '~/components/EditableText';
import { Expander } from '~/components/ExpanderButton';
import { TableRow, SimpleTable, TableBody, TableEventCatcher } from '~/components/Table';

import GroupActionsBase from '../GroupActions';
import RowActiveBorderBase from '../RowActiveBorder';

export const Table = styled(SimpleTable)`
  overflow: visible;

  ${TableBody} {
    border-bottom: none;
  }

  ${TableBody}, ${TableEventCatcher} {
    overflow: visible;
  }
`;

export const RowActiveBorder = styled(RowActiveBorderBase)`
  right: 2px;
  left: 2px;
  margin-top: -7px;

  ${styleWhenTrue(
    prop('fixed'),
    css`
      &:first-child {
        margin-top: -3px;
      }
    `,
  )}
`;

export const AddIconWrapper = styled.div`
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding: 0;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  border: 2px solid ${COLOR.DARK_GRAY};
  position: fixed;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  ${styleWhenTrue(
    prop('bottom'),
    css`
      position: absolute;
      bottom: -11px;
      right: 0;
    `,
  )}
`;

export const GroupContainer = styled.section`
  margin-bottom: 18px;
  border: 2px solid ${COLOR.GRAY_9};
  border-radius: 7px;
  position: relative;
  cursor: pointer;

  &:after {
    height: 20px;
    width: 2px;
    background: ${COLOR.GRAY_9};
    content: '';
    position: absolute;
    left: 50%;

    ${styleWhenTrue(
      prop('dragged'),
      css`
        display: none;
      `,
    )}
  }

  &:last-of-type {
    margin-bottom: 20px;

    &:after {
      display: none;
    }
  }
`;

export const DragHandler = styled(DragHandlerBase)`
  opacity: 0;
  flex: none;
`;

export const GroupNameOverlay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;

  > div {
    padding-bottom: 2px;
    flex: none;
    width: auto;
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    opacity: 0;
    margin-left: 4px;
  }

  button svg {
    transform: scale(0.8);
  }
`;

export const GroupNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;

  flex: 230 0 auto;
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;

  > form {
    padding-right: 30px;
  }
`;

export const GroupHeader = styled.header`
  display: flex;
  flex: 1 0 auto;
  background: ${COLOR.GRAY_21};
  font-size: 13px;
  line-height: 21px;
  font-weight: ${FONT_WEIGHT.BOLD};
  letter-spacing: 0.002em;
  overflow: visible;
  border: none !important;
  border-radius: 5px 5px 0 0;
  align-items: center;
  position: relative;
  top: -1px;

  &.is-pinned {
    z-index: 9;
    position: sticky;
    border-radius: 0;
    border-top: 3px solid ${COLOR.GRAY_9} !important;
    box-shadow: 0px 15px 12px -17px #2d2d2d45;
  }

  &:hover {
    ${AddIconWrapper} {
      opacity: 1;

      ${styleWhenTrue(
        prop('readOnly'),
        css`
          opacity: 0;
        `,
      )}
    }

    ${DragHandler}, ${GroupNameContainer} button {
      opacity: 1;
    }
  }

  && {
    height: 44px;
  }
`;

export const GroupActions = styled(GroupActionsBase)`
  width: 40px;
  & > * {
    display: none;
  }

  ${GroupHeader}:hover & > * {
    display: flex;
  }
`;

export const GroupNameInput = styled(EditableTextBase)`
  padding: 0;
  margin-left: 0px;
  background: none;
  border-color: transparent;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  height: 30px;
`;

export const ExpanderContainer = styled.div`
  height: 100%;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale3d(0.8, 0.8, 1);
  width: 21px;
  margin-left: 5px;

  ${Expander} {
    color: ${COLOR.GRAY_15};
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  overflow: visible;
`;

export const SelectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 32px;
  margin-left: -2px;
  flex: none;

  &:hover {
    background-color: rgba(29, 12, 36, 0.04);
  }
`;

export const Container = styled(TableRow)`
  position: relative;
  padding: 0;
  border: none;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${COLOR.GRAY_9} !important;

  &:first-of-type() {
    margin-top: 0;
  }

  && {
    min-height: 44px;
    height: auto;
  }
`;

export const AddIcon = styled(AddIconBase)`
  font-size: 18px !important;
  fill: ${COLOR.DARK_GRAY} !important;
`;

export const GroupMetaWrapper = styled.div`
  display: flex;
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  color: ${COLOR.GRAY_13};
  padding-bottom: 2px;
`;

export const IntersectionDetection = styled.div``;
