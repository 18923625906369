import PropTypes from 'prop-types';
import React, { useRef, useState, useCallback } from 'react';

import TestStepMoreActions from '../TestStepMoreActions';

import { Container, VisibleOnRowHover } from './TestStepActions.styled';

const TestStepActions = ({ className, row, readOnly = false, onDelete }) => {
  const step = row.original;
  const containerRef = useRef();
  const [areActionsLocked, setAreActionsLocked] = useState(false);
  const lockActionButtons = useCallback(() => setAreActionsLocked(true), []);
  const unlockActionButtons = useCallback(() => setAreActionsLocked(false), []);

  return (
    <VisibleOnRowHover force={areActionsLocked}>
      <Container ref={containerRef} className={className} data-testid="TestStepActions">
        {!readOnly && (
          <TestStepMoreActions
            step={step}
            onOpen={lockActionButtons}
            onClose={unlockActionButtons}
            onDelete={onDelete}
          />
        )}
      </Container>
    </VisibleOnRowHover>
  );
};

TestStepActions.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onDelete: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      frontId: PropTypes.string,
      isActive: PropTypes.bool,
      isBreakpoint: PropTypes.bool,
      isTemporary: PropTypes.bool,
    }),
  }).isRequired,
};

export default TestStepActions;
