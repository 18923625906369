import { COLOR } from '@bugbug/core/theme';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

import pixelArrowUrl from '~/images/pixel-arrow-down.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 250px;
`;

export const Icon = styled.img.attrs(() => ({ src: pixelArrowUrl, loading: 'lazy' }))`
  margin-left: 35px;
  width: 90px;
  transform: rotate(135deg);
`;

export const Label = styled.span`
  max-width: 180px;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 8px;
  color: ${COLOR.GRAY_23};
  line-height: 18px;
  font-weight: ${FONT_WEIGHT.NORMAL};
  text-align: center;
`;
