import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import type { InputWithVariableProps } from './InputWithVariables';

import { InputWithVariables } from './InputWithVariables';

type FormikInputWithVariableProps = Omit<
  InputWithVariableProps,
  'value' | 'onChange' | 'onBlur'
> & {
  name: string;
};

export const FormikInputWithVariables = ({
  as = 'input',
  ...props
}: FormikInputWithVariableProps) => {
  const { getFieldMeta, getFieldProps, handleChange, handleBlur } = useFormikContext();

  const { value, ...field } = useMemo(() => {
    const { value: fieldValue, name } = getFieldProps(props.name);
    const { touched, error } = getFieldMeta(props.name);
    return { name, value: fieldValue, error: touched && error, 'aria-labelledby': name };
  }, [getFieldMeta, getFieldProps, props.name]);

  return (
    <InputWithVariables
      {...props}
      {...field}
      as={as}
      value={value ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};
