import React from 'react';
import { useTranslation } from 'react-i18next';

import { RUN_TRIGGER } from '~/modules/constans';

import { Container, MetaValue } from './RunByCell.styled';

const RunByCell = ({ className, value }) => {
  const { t } = useTranslation();
  const labels = {
    [RUN_TRIGGER.API]: t('runByCell.api', 'API'),
    [RUN_TRIGGER.SCHEDULER]: t('runByCell.scheduler', 'Scheduler'),
    [RUN_TRIGGER.USER]: t('runByCell.user', 'User'),
    [RUN_TRIGGER.GITHUB]: t('runByCell.github', 'Github'),
    [RUN_TRIGGER.CLI]: t('runByCell.cli', 'CLI'),
  };
  return (
    <Container className={className}>
      <MetaValue>{labels[value?.triggeredBy]}</MetaValue>
      {value?.triggeredBy === RUN_TRIGGER.USER ? <MetaValue>{value?.user?.email}</MetaValue> : null}
    </Container>
  );
};

export default RunByCell;
