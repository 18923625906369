import { STEP_RUN_ERROR_CODE } from '@bugbug/core/constants/errors';
import { isFailedStatus } from '@bugbug/core/types/base';
import { getExtendedRunStatus } from '@bugbug/core/utils/status';
import { useCallback, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ScrollStep, Step } from '@bugbug/core/types/steps';
import type { StepRun } from '@bugbug/core/types/tests';
import useTestFailedStateDetector from '~/hooks/useTestFailedStateDetector';
import { useAppSelector } from '~/modules/store';
import { selectStepRun } from '~/modules/testRun/testRun.selectors';

import AssertFailedDetails from './AssertFailedDetails';
import * as S from './ErrorDetails.styled';
import { GenericErrorDescription } from './GenericErrorDescription';
import HelpBox from './HelpBox';
import { PageLoadingFailedDetails } from './PageLoadingFailedDetails/PageLoadingFailedDetails';
import { ScrollFailedDetails } from './ScrollFailedDetails/ScrollFailedDetails';
import WaitingConditionsFailedDetails from './WaitingConditionsFailedDetails';

interface ErrorDetailsProps {
  className?: string;
  step: Step;
}
const ErrorDetails = ({ className, step }: ErrorDetailsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const stepRun = useAppSelector(selectStepRun(step.id)) as unknown as StepRun;
  const { t } = useTranslation(undefined, { keyPrefix: 'errorDetails' });
  const hasStepFailed = isFailedStatus(stepRun.status);
  const extendedStatus = getExtendedRunStatus(stepRun);
  const hasStepPassedWithIssues = extendedStatus === 'passed-with-issues';
  const hasStepFailedButIgnored = extendedStatus === 'failed-ignored';
  const shouldShowMessage = hasStepFailed || hasStepPassedWithIssues;

  const handleTestFailure = useCallback(
    ({ stepId }) => {
      if (step.id === stepId && containerRef.current) {
        containerRef.current.parentElement?.scrollTo?.({ top: 0, behavior: 'smooth' });
      }
    },
    [step.id],
  );

  useTestFailedStateDetector(handleTestFailure, true);

  if (!shouldShowMessage) {
    return null;
  }

  const renderContent = () => {
    const hasFailedConditions = stepRun.errorCode === 'FAILED_WAITING_CONDITIONS';
    const isElementMissing = stepRun.errorCode === 'ELEMENT_DOES_NOT_EXIST';
    const meetsInitialConditions = !isElementMissing && !hasFailedConditions;

    switch (true) {
      case stepRun.errorCode === 'ASSERT_FAILED' && meetsInitialConditions:
        return <AssertFailedDetails stepRun={stepRun} />;
      case stepRun.type === 'scroll' && stepRun.errorCode === 'SCROLL_FAILED':
        return <ScrollFailedDetails step={step as ScrollStep} stepRun={stepRun} />;
      case stepRun.errorCode === STEP_RUN_ERROR_CODE.PAGE_LOADING_ERROR && meetsInitialConditions:
        return <PageLoadingFailedDetails error={stepRun.error} />;
      case hasFailedConditions || hasStepPassedWithIssues:
        return <WaitingConditionsFailedDetails stepRun={stepRun} />;
      default:
        return <GenericErrorDescription stepRun={stepRun} step={step} />;
    }
  };

  const renderFooter = () => {
    const errorLabel =
      stepRun.errorCode === 'PAGE_LOADING_ERROR' ? stepRun.error : stepRun.errorCode;
    const { runTimeout } = stepRun;

    if (stepRun.timeout && stepRun.status !== 'error') {
      return (
        <S.Footer>
          <Trans i18nKey="errorDetails.footer.timeoutLabel">
            Timeout after {{ runTimeout }}s<br />
            {{ errorLabel }}
          </Trans>
        </S.Footer>
      );
    }

    return (
      <S.Footer>
        {t('footer.error.code', '{{ code }}', { code: errorLabel })}
        {stepRun.errorId && (
          <S.ErrorId>
            {t('footer.error.id', '(id: {{errorId}})', { errorId: stepRun.errorId })}
          </S.ErrorId>
        )}
      </S.Footer>
    );
  };

  return (
    <S.Container
      className={className}
      data-testid="ErrorDetails"
      warning={hasStepPassedWithIssues || hasStepFailedButIgnored}
      ref={containerRef}
    >
      {renderContent()}
      <HelpBox />
      {renderFooter()}
    </S.Container>
  );
};

export default ErrorDetails;
