import Tooltip, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { ExtendedMenuItem } from './Menu.types';

import type { Project } from '@bugbug/core/types/projects';
import type { Route } from '@bugbug/core/types/routes';
import useAppRoutes from '~/hooks/useAppRoutes';
import useBreakpoint from '~/hooks/useBreakpoint';
import useFeatureFlags from '~/hooks/useFeatureFlags';
import { selectSingleProject } from '~/modules/project/project.selectors';
import { useAppSelector } from '~/modules/store';
import { selectRecentlySelectedTabs } from '~/modules/uiState';
import analytics from '~/services/analytics';
import localStorage from '~/services/localStorage';

import { MENU_ITEMS } from './Menu.constants';
import * as S from './Menu.styled';

const PROJECT_NAME_MAX_LENGTH_WITHOUT_TOOLTIP = 13;

const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { getRouteUrl } = useAppRoutes('project');
  const flags = useFeatureFlags();
  const { name: projectName } = useAppSelector(selectSingleProject) as Project;
  const cachedMenuCollapsed = localStorage.getUserItem('menu.expanded');
  const defaultMenuCollapsed = cachedMenuCollapsed === null ? false : cachedMenuCollapsed;
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(defaultMenuCollapsed);
  const isMinWideDesktop = useBreakpoint('desktopWide');
  const recentlySelectedTabs = useAppSelector(selectRecentlySelectedTabs);

  const handleToggleMenu = () => {
    localStorage.setUserItem('menu.expanded', !isMenuCollapsed);
    setIsMenuCollapsed((prev) => !prev);
  };

  useEffect(() => {
    setIsMenuCollapsed(isMinWideDesktop ? defaultMenuCollapsed : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinWideDesktop]);

  const getParsedUrl = useCallback(
    (route: Route) => {
      switch (route) {
        case 'projectSettingsIndex':
          return getRouteUrl('projectSettings', {
            settingsTabSlug: recentlySelectedTabs.projectSettings,
          });
        case 'runsHistory':
          return getRouteUrl(recentlySelectedTabs.runsHistory);
        case 'variables':
          return getRouteUrl(recentlySelectedTabs.variables);
        default:
          return getRouteUrl(route);
      }
    },
    [getRouteUrl, recentlySelectedTabs],
  );

  const menuItems = useMemo<ExtendedMenuItem[]>(
    () =>
      MENU_ITEMS.map((menuItem) => {
        const onClick: React.MouseEventHandler<HTMLLIElement> = () => {
          if (!menuItem.trackEvent) {
            return;
          }
          analytics.trackEvent(menuItem.trackEvent);
        };

        return {
          ...menuItem,
          url: getParsedUrl(menuItem.route),
          matchUrl: getRouteUrl(menuItem.route),
          onClick,
        };
      }),
    [getParsedUrl, getRouteUrl],
  );

  const shouldShowTooltip = projectName.length >= PROJECT_NAME_MAX_LENGTH_WITHOUT_TOOLTIP;

  return (
    <S.Container data-testid="Menu" collapsed={isMenuCollapsed}>
      <S.Sizer>
        <S.Context>
          <Tooltip
            content={shouldShowTooltip ? projectName : undefined}
            anchor={TOOLTIP_ANCHOR.RIGHT_CENTER}
          >
            <S.ProjectName>{projectName}</S.ProjectName>
          </Tooltip>
        </S.Context>

        <S.Navigation data-testid="Menu.Navigation" collapsed={isMenuCollapsed}>
          {menuItems.map(({ featureFlag, url, matchUrl, icon, label, onClick }) => {
            const active = location.pathname.startsWith(matchUrl);
            const shouldRender = !featureFlag || flags[featureFlag];

            return (
              shouldRender && (
                <S.NavItem key={url} onClick={onClick}>
                  <S.NavItemTooltip
                    content={isMenuCollapsed ? label : undefined}
                    anchor="right-center"
                    wrapperClassName={S.tooltipWrapperClassName}
                  >
                    <S.NavLink to={url} aria-current={active ? 'page' : undefined}>
                      <S.NavItemIcon name={icon} />
                      <S.NavItemLabel>{label}</S.NavItemLabel>
                    </S.NavLink>
                  </S.NavItemTooltip>
                </S.NavItem>
              )
            );
          })}
        </S.Navigation>
        <S.Footer collapsed={isMenuCollapsed}>
          <S.NavItem>
            <S.NavItemTooltip
              content={isMenuCollapsed ? t('menu.toggleButton.expand', 'Expand') : undefined}
              anchor="right-center"
              wrapperClassName={S.tooltipWrapperClassName}
            >
              <S.NavLink onClick={handleToggleMenu}>
                <S.NavItemIcon name="chevronsUp" />
                <S.NavItemLabel>{t('menu.toggleButton.collapse', 'Collapse')}</S.NavItemLabel>
              </S.NavLink>
            </S.NavItemTooltip>
          </S.NavItem>
        </S.Footer>
      </S.Sizer>
    </S.Container>
  );
};

export default Menu;
