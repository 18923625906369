import { identity } from 'ramda';
import { useCallback, useState, useRef, useLayoutEffect, useMemo } from 'react';
import { useEvent, useMount } from 'react-use';

import localStorage from '~/services/localStorage';

export const STORAGE_DATA_TYPE = {};

const getProperValue = (name, defaultValue, transform = JSON.parse) => {
  const value = localStorage.getUserItem(name, identity);
  if (!value) {
    return defaultValue;
  }

  try {
    return transform(value);
  } catch (error) {
    return value;
  }
};

const useLocalStorageValue = (name, defaultValue = '', transform) => {
  const localStorageValue = useRef({
    previousValue: defaultValue,
    value: getProperValue(name, defaultValue, transform),
  });
  const nameInUserSpace = useMemo(() => localStorage.getKeyInUserNameSpace(name), [name]);

  const [, setCachedValue] = useState(localStorageValue);

  const setValue = useCallback(
    (value) => {
      localStorage.setUserItem(name, value);
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: nameInUserSpace,
          newValue: JSON.stringify(value),
        }),
      );
    },
    [name, nameInUserSpace],
  );

  const handleStorageChangeSync = useCallback(
    (event) => {
      if (event.key === nameInUserSpace) {
        localStorageValue.current.previousValue = localStorageValue.current.value;
        localStorageValue.current.value = localStorage.getUserItem(name);
        setCachedValue(localStorageValue.current.value);
      }
    },
    [name, nameInUserSpace, setCachedValue],
  );

  useEvent('storage', handleStorageChangeSync);

  useMount(() => {
    localStorageValue.current.set = setValue;
  });

  useLayoutEffect(() => {
    const newInitialValue = getProperValue(name, defaultValue, transform);
    localStorageValue.current.previousValue = defaultValue;
    localStorageValue.current.value = newInitialValue;
    localStorageValue.current.set = setValue;
    setCachedValue(newInitialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue, transform]);

  return localStorageValue.current;
};

export default useLocalStorageValue;
