import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const EdgeType = styled.div`
  display: grid;
  grid-column-gap: 16px;
  align-items: center;
  margin-top: -12px;
  grid-template-columns: 1fr;
`;

export const CoordsValues = styled.div`
  grid-template-columns: 142px 142px;
  display: grid;
  grid-column-gap: 16px;
  align-items: flex-start;
  margin-top: -12px;
`;
