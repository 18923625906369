import 'react-dropzone-uploader/dist/styles.css';

import { is } from 'ramda';
import { useState, useCallback, useMemo } from 'react';
import Dropzone from 'react-dropzone-uploader/dist/react-dropzone-uploader';
import { Trans } from 'react-i18next';
import { useMount } from 'react-use';

import type { CustomEventHandler } from '@bugbug/core/types/forms';
import api from '~/services/api';

import { Container, FilePreview, ErrorMessage } from './FileUpload.styled';

const setPreviewUrl = (files: File[]) => {
  files.forEach((file) => {
    Object.defineProperty(file, 'previewUrl', { value: URL.createObjectURL(file) });
  });
};

interface FileUploadProps {
  className?: string;
  accept?: string;
  name: string;
  error?: string | boolean;
  multiple?: boolean;
  maxFiles?: number;
  onChange?: (params: CustomEventHandler) => void;
  value?: string | File | File[];
}

const FileUpload = ({ className, value, name, onChange, error, ...props }: FileUploadProps) => {
  const [initialFiles, setInitialFiles] = useState<File[]>([]);

  useMount(() => {
    const fetchFiles = async () => {
      const files: File[] = [];
      if (!value) {
        setInitialFiles([]);
        return;
      }

      if (typeof value === 'string') {
        const file = await api.artifacts.get(value);
        files.push(file);
      } else if (Array.isArray(value)) {
        files.push(...value);
      } else if (typeof value === 'object') {
        files.push(value);
      }
      setPreviewUrl(files);
      setInitialFiles(files);
    };

    fetchFiles();
  });

  const handleChange = useCallback(
    ({ file }, status) => {
      const event = { target: { value: file, name } };
      if (['done', 'removed'].includes(status)) {
        if (status === 'removed') {
          event.target.value = null;
        }
        if (status === 'removed' || !initialFiles.includes(file)) {
          onChange?.(event);
        }
      }
    },
    [name, onChange, initialFiles],
  );

  // eslint-disable-next-line react/prop-types
  const Layout = useCallback(
    ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => (
      <>
        {previews}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {files.length < maxFiles && <div {...dropzoneProps}>{input}</div>}
      </>
    ),
    [],
  );

  // eslint-disable-next-line react/prop-types
  const Preview = useCallback(
    ({ fileWithMeta, meta }) => (
      <FilePreview
        name={meta.name}
        url={meta.previewUrl || fileWithMeta.file.previewUrl}
        onRemove={fileWithMeta.remove}
        onCancel={fileWithMeta.cancel}
      />
    ),
    [],
  );

  const InputContent = useMemo(
    () => (
      <div key="label">
        <Trans i18nKey="fileUpload.label">
          <span>Select file </span>
          or drag and drop
        </Trans>
      </div>
    ),
    [],
  );

  return (
    <Container className={className}>
      <Dropzone
        // eslint-disable-next-line react/jsx-props-no-spreading
        maxFiles={1}
        accept="*"
        multiple={false}
        {...props}
        onChangeStatus={handleChange}
        LayoutComponent={Layout}
        PreviewComponent={Preview}
        inputContent={InputContent}
        initialFiles={initialFiles}
      />
      {is(String, error) ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Container>
  );
};

export default FileUpload;
