import Button from '@bugbug/core/components/Button';
import LoaderBase from '@bugbug/core/components/Loader';
import TooltipBase from '@bugbug/core/components/Tooltip';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  align-self: center;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  flex: none;
  margin-left: 10px;
  width: 126px;
`;

export const Tooltip = styled(TooltipBase)``;

export const Loader = styled(LoaderBase)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  margin-top: 1px;
`;

export const Container = styled(Button)<{ component?: boolean }>`
  height: 22px;
  font-size: 12px;
  line-height: 13px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  letter-spacing: -0.13px;
  padding: 4px 8px 2px 6px;
  height: 23px;
  transition-property: background;

  &&:hover {
    background: ${COLOR.GRAY_22};
  }

  svg {
    width: 17px;
  }

  ${styleWhenTrue(
    prop('component'),
    css`
      border: 2px solid ${COLOR.YELLOW_2};
      background: ${COLOR.YELLOW_2} !important;
      color: ${COLOR.YELLOW_3};
      text-transform: uppercase;

      span {
        width: 11px;
        margin-right: 4px;
      }
    `,
  )}

  &:disabled {
    background: ${COLOR.GRAY_22};
    color: ${({ theme }) => theme.colors.text.lighter};
  }
`;
