import * as T from '@bugbug/core/utils/toolbox';
import { useCallback } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';

import type { SideEffect } from '@bugbug/core/types/utils';
import EmptySidebar from '~/components/EmptySidebar';
import { ComponentsActions, selectCurrentStep } from '~/modules/components';
import { useAppDispatch, useAppSelector } from '~/modules/store';

import StepDetails from '../StepDetails';

import * as S from './ComponentSidebar.styled';

interface ComponentSidebarProps {
  collapsed: boolean;
  onToggle?: SideEffect<boolean | undefined>;
}

const ComponentSidebar = ({ collapsed = false, onToggle = T.noop }: ComponentSidebarProps) => {
  const step = useAppSelector(selectCurrentStep);
  const dispatch = useAppDispatch();

  const handleStepDetailsClosed = useCallback<SideEffect>(() => {
    dispatch(ComponentsActions.resetCurrentStep());
  }, [dispatch]);

  const handleToggle = () => onToggle(undefined);

  useUpdateEffect(() => {
    if (step) {
      onToggle(false);
    }
  }, [step]);

  useUnmount(() => {
    handleStepDetailsClosed();
  });

  return step ? (
    <S.Container>
      <StepDetails step={step} onClose={handleStepDetailsClosed} />
    </S.Container>
  ) : (
    <S.CollapsibleContainer collapsed={collapsed}>
      <S.ExpandButton onClick={handleToggle} />
      <S.Container>
        <S.CollapseButton onClick={handleToggle} />
        <EmptySidebar />
      </S.Container>
    </S.CollapsibleContainer>
  );
};

export default ComponentSidebar;
