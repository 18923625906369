import InputComponent from '@bugbug/core/components/Input';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Label as LabelComponent } from '~/components/FormField/FormField.styled';
import { Select as SelectComponent, Row } from '~/views/ProjectSettings/ProjectSettings.styled';
import { Table as TableComponent } from '~/views/ProjectSettings/SelectorsSettings/SelectorsSettings.styled';

export const Container = styled.div``;

export const Label = styled(LabelComponent)`
  width: 170px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px 0 0;

  svg {
    flex: none;
  }

  &:nth-of-type(2) {
    width: 120px;
  }
`;

export const Select = styled(SelectComponent)`
  width: 220px;
  margin-right: 16px;
`;

export const Table = styled(TableComponent)`
  width: fit-content;
  min-width: 708px;
`;

export const Input = styled(InputComponent)`
  width: 140px;

  & + div {
    width: 140px;
  }
`;

// TODO remove when timeout is ready to be displayed
export const TimeoutWrapper = styled.div`
  display: none;
`;

export const ExpectedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex: none;
  }
`;

export const TableRow = styled(Row)`
  width: 100%;
  border-bottom: 1px solid ${COLOR.GRAY_18};
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
`;
