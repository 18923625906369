import { isEmpty } from 'ramda';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectFailedStepRunWithStep } from '~/modules/testRun/testRun.selectors';

const useTestFailedStateDetector = (onChange, initialSkipped = false) => {
  const initialErrorSkipped = useRef(initialSkipped);
  const { failedStepRun = {} } = useSelector(selectFailedStepRunWithStep);

  useEffect(() => {
    if (initialErrorSkipped.current && !isEmpty(failedStepRun)) {
      onChange(failedStepRun);
    } else {
      initialErrorSkipped.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedStepRun, onChange]);
};

export default useTestFailedStateDetector;
