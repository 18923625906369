import Icon from '@bugbug/core/components/Icon';
import IconButtonBase from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background-color: ${COLOR.MUTED};
  margin-bottom: 24px;
  flex: none;

  img {
    width: 60px;
    margin: 10px 14px 7px 8px;
    flex: none;
  }
`;

export const Title = styled.h2`
  text-transform: uppercase;
`;

export const Content = styled.div`
  padding: 15px 0 14px 0;
  flex: 1;
  color: ${COLOR.GRAY_36};
  line-height: 19px;

  h2 {
    margin: 0 0 6px 0;
    color: ${COLOR.GRAY_36};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 0;
  }
`;

export const GettingStarted = styled(Icon).attrs(() => ({
  name: 'gettingStarted',
}))`
  margin: 4px 8px 0 16px;
`;

export const CloseButton = styled(IconButtonBase)`
  color: ${COLOR.GRAY_38};
  border-radius: 0;
  width: 40px;
  height: 40px;

  svg {
    transform: scale(0.85);
  }
`;
