import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import urls, { reverse } from '~/views/urls';

import * as S from './ComponentActions.styled';

interface ComponentHeaderActionsProps {
  component: Component;
}

const ComponentHeaderActions = ({ component }: ComponentHeaderActionsProps) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const modal = useModal();

  const history = useHistory();
  const {
    params: { projectSlug, projectId, organizationId },
  } = useAppRoutes('component');

  const handleRename = useCallback(() => {
    const input = containerRef.current?.closest('header')?.querySelector('input');

    input?.focus();
    input?.select();
  }, []);

  const handleCloneSuccess = useCallback<SideEffect<Component['id']>>(
    (componentId) => {
      history.push(
        reverse(urls.component, {
          projectId,
          projectSlug,
          organizationId,
          componentId,
        }),
      );
    },
    [history, projectId, projectSlug, organizationId],
  );

  const handleDeleteSuccess = useCallback(() => {
    history.push(reverse(urls.componentsList, { projectSlug, projectId, organizationId }));
  }, [history, projectSlug, projectId, organizationId]);

  const handleClone = useCallback(
    () =>
      modal.show('clone_component', {
        component,
        onCloneSuccess: handleCloneSuccess,
      }),
    [component, handleCloneSuccess, modal],
  );

  const handleDelete = useCallback(
    () =>
      modal.show('delete_component', {
        data: component,
        onDeleteSuccess: handleDeleteSuccess,
      }),
    [component, handleDeleteSuccess, modal],
  );

  const Actions = useCallback(
    () => (
      <S.Menu
        data-testid="componentDetails.componentHeaderActions.Menu"
        iconName="more"
        hideExpander
        rounded
        condensed
      >
        <DropdownItem onClick={handleRename}>
          <Icon name="edit" /> {t('componentDetails.componentActions.rename', 'Rename')}
        </DropdownItem>

        <DropdownItem onClick={handleClone}>
          <Icon name="clone" /> {t('componentDetails.componentActions.duplicate', 'Duplicate')}
        </DropdownItem>

        <DropdownItem onClick={handleDelete} danger>
          <Icon name="delete" /> {t('componentDetails.componentActions.delete', 'Delete')}
        </DropdownItem>
      </S.Menu>
    ),
    [handleRename, handleClone, handleDelete, t],
  );

  return (
    <S.Container
      ref={containerRef}
      data-testid="ComponentDetails.TestHeaderActions"
      id="testHeader"
    >
      <Actions />
    </S.Container>
  );
};

export default ComponentHeaderActions;
