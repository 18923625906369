import ButtonBase, { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';

import { Title as TitleBase } from '../EmptyState/EmptyState.styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: ${COLOR.WHITE};
  width: 340px;
  pointer-events: all;
`;

export const ActionWrapper = styled(animated.div)``;

export const DotIcon = styled(Icon).attrs(() => ({
  name: 'dot',
}))`
  && {
    width: 14px;
    height: 14px;
  }
`;

export const PasteIcon = styled(Icon).attrs(() => ({
  name: 'paste',
}))`
  && {
    max-width: 17px;
  }
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  && {
    fill: ${COLOR.PRIMARY_LIGHT};
  }
`;

export const AddComponentIcon = styled(Icon).attrs(() => ({
  name: 'addComponent',
}))`
  width: 24px;
  height: 24px;
  left: 4px;

  rect {
    fill: transparent;
  }

  path {
    fill: ${COLOR.PRIMARY_LIGHT};
  }
`;

export const TipWrapper = styled(animated.div)`
  margin-top: 10px;
  margin-bottom: 42px;
`;

export const Description = styled(animated.p)`
  font-size: 13px;
  line-height: 20px;
  color: ${COLOR.DRAK_GRAY};
  margin-bottom: 28px;
`;

const commonButtonStyles = css`
  width: 100%;
  margin-bottom: 16px;
  justify-content: center;

  div {
    flex: none;
  }
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  ${commonButtonStyles}
`;

export const RecordButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  type: 'submit',
}))`
  ${commonButtonStyles}
`;

export const Title = styled(TitleBase)`
  margin-bottom: 45px;
  color: ${COLOR.GRAY_25};
`;

export const Separator = styled.span`
  width: 100%;
  text-align: center;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 37px;
  color: ${COLOR.GRAY_23};
  display: flex;
  align-items: center;
  justify-content: center;

  &:after,
  &:before {
    content: '';
    height: 1px;
    flex: 1;
    background-color: ${COLOR.GRAY_35};
    margin-bottom: -4px;
  }

  &:after {
    margin-left: 10px;
  }

  &:before {
    margin-right: 10px;
  }
`;
