import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { FONT_WEIGHT } from '@bugbug/core/theme';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const MetaValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  color: ${COLOR.GRAY_12};

  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
  offset: 5,
}))``;
