import { LinkButton } from '@bugbug/core/components/Button';
import { DropdownHeader, DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import type { Test, TestRun } from '@bugbug/core/types/tests';
import useAppRoutes from '~/hooks/useAppRoutes';
import useCopyHandler from '~/hooks/useCopyHandler';
import useModal from '~/hooks/useModal';
import config from '~/modules/config';
import {
  selectSingleTestRunTestArchive,
  selectTestRunGroupsIds,
} from '~/modules/testRun/testRun.selectors';
import { UIStateActions } from '~/modules/uiState/uiState.redux';
import { selectCollapsedGroups } from '~/modules/uiState/uiState.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import * as S from './TestRunActions.styled';

interface TestRunActionsProps {
  testRun: {
    id: TestRun['id'];
    test: Test['id'];
  };
  className?: string;
}

const TestRunActions = ({ testRun, className }: TestRunActionsProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { push, getAbsoluteRouteUrl, getRouteUrl } = useAppRoutes('testRun');
  const modal = useModal();

  const dispatch = useDispatch();
  const test = useSelector(selectSingleTestRunTestArchive);
  const groupsIds = useSelector(selectTestRunGroupsIds);
  const collapsedGroups = useSelector(selectCollapsedGroups(test.id));

  const handleGoToTest = () => {
    analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_TEST);
    push('test', { testId: testRun.test });
  };

  const handleGoToTestRunList = () => {
    push('testRunsList', undefined, { query: testRun.test });
  };

  const handleDelete = () => {
    modal.show('delete_test_runs', {
      testRunsIds: [testRun.id],
      onDelete: handleGoToTestRunList,
    });
  };

  const onCollapseExpandAll = (shouldCollapse: boolean) => {
    dispatch(
      UIStateActions.setCollapsedGroups({
        collapsedGroupsIds: shouldCollapse ? groupsIds : [],
        testId: test.id,
      }),
    );
  };

  const handleCopyLinkToTestRun = useCopyHandler(`${config.WEBAPP_URL}${location.pathname}`, {
    success: t(
      'testRunDetails.testRunActions.copyLinkToTesRuntMsg.success',
      'Link to test run copied',
    ),
    failure: t(
      'testRunDetails.testRunActions.copyLinkToTestRunMsg.failure',
      'Error when copying link to test run',
    ),
  });

  const handleCopyLinkToTest = useCopyHandler(
    getAbsoluteRouteUrl('test', { testId: testRun.test }),
    {
      success: t('testRunDetails.testRunActions.copyLinkToTestMsg.success', 'Link to test copied'),
      failure: t(
        'testRunDetails.testRunActions.copyLinkToTestMsg.failure',
        'Error when copying link to test',
      ),
    },
  );

  const renderMenu = () => (
    <S.Menu data-testid="TestRunActions.Menu" iconName="more" hideExpander rounded condensed>
      <DropdownHeader>
        {t('testRunDetails.testRunActions.header.testRun', 'Test run')}
      </DropdownHeader>

      <DropdownItem onClick={handleCopyLinkToTestRun}>
        <Icon name="link2" />{' '}
        {t('testRunDetails.testRunActions.copyLinkToTestRun', 'Copy link to test run')}
      </DropdownItem>

      <DropdownItem onClick={handleDelete} danger noDivider>
        <Icon name="delete" /> {t('testRunDetails.testRunActions.delete', 'Delete from history')}
      </DropdownItem>

      <DropdownHeader>{t('testRunDetails.testRunActions.header.test', 'Test')}</DropdownHeader>

      <DropdownItem onClick={handleGoToTest}>
        <Icon name="cornerDownRight" /> {t('testRunDetails.testRunActions.goToTest', 'Go to test')}
      </DropdownItem>

      <DropdownItem onClick={handleGoToTestRunList}>
        <Icon name="history" /> {t('testRunDetails.testRunActions.runsHistory', 'Runs history')}
      </DropdownItem>

      <DropdownItem onClick={handleCopyLinkToTest}>
        <Icon name="link2" />{' '}
        {t('testRunDetails.testRunActions.copyLinkToTest', 'Copy link to test')}
      </DropdownItem>

      <DropdownHeader>{t('testRunDetails.testRunActions.header.groups', 'Groups')}</DropdownHeader>

      <DropdownItem
        onClick={() => onCollapseExpandAll(true)}
        disabled={collapsedGroups.length === groupsIds.length}
      >
        <Icon name="chevronsUp" />{' '}
        {t('testRunDetails.testRunActions.collapseAllGroups', 'Collapse all groups')}
      </DropdownItem>

      <DropdownItem
        onClick={() => onCollapseExpandAll(false)}
        disabled={collapsedGroups.length === 0}
      >
        <Icon name="chevronsDown" />{' '}
        {t('testRunDetails.testRunActions.expandAllGroups', 'Expand all groups')}
      </DropdownItem>
    </S.Menu>
  );

  return (
    <S.Container className={className} data-testid="TestRunActions">
      <LinkButton
        to={getRouteUrl('test', { testId: testRun.test })}
        disabled={!testRun.test}
        button={{
          iconName: 'cornerDownRight',
          variant: 'primary',
          bordered: true,
        }}
      >
        {t('testRunDetails.testRunActions.goToTest', 'Go to test')}
      </LinkButton>
      {renderMenu()}
    </S.Container>
  );
};

export default TestRunActions;
