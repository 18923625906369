import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 13px;
  color: ${COLOR.BLACK};
  margin-bottom: 2px;
`;

export const Content = styled.div`
  font-size: 13px;
  color: ${COLOR.GRAY_36};
`;
