import { Container, Tooltip } from './MetaValue.styled';

export const MetaValue = ({ className, value, Icon = null, tooltip }) => (
  <Tooltip content={tooltip}>
    <Container className={className}>
      <Icon />
      {value}
    </Container>
  </Tooltip>
);
