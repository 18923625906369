import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MoreSection from '~/components/MoreSection';

export const STORAGE_ARE_TIPS_EXPANDED = 'areTipsExpanded';

const ErrorTips = ({ className, children }) => {
  const { t } = useTranslation();
  return (
    <MoreSection
      className={className}
      storageKey={STORAGE_ARE_TIPS_EXPANDED}
      showButtonText={t('errorTips.toggleButton.show', 'Show tips')}
      hideButtonText={t('errorTips.toggleButton.hide', 'Hide tips')}
    >
      {children}
    </MoreSection>
  );
};

ErrorTips.defaultProps = {
  className: null,
};

ErrorTips.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default ErrorTips;
