import Link from '@bugbug/core/components/Link';
import LoaderComponent from '@bugbug/core/components/Loader';
import SearchInput from '@bugbug/core/components/SearchInput';
import { H1 } from '@bugbug/core/theme/typography';
import * as T from '@bugbug/core/utils/toolbox';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import type { Sorting, SortingFn } from './ComponentsList.types';

import type { InputChangeHandler } from '@bugbug/core/components/Input/Input.types';
import type { Component } from '@bugbug/core/types/components';
import Badge, { BADGE_VARIANT } from '~/components/Badge';
import InlineTutorialMessage from '~/components/InlineTutorialMessage';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useGetComponentsQuery } from '~/modules/components';
import urls, { reverse } from '~/views/urls';

import ComponentsTable from './components/ComponentsTable';
import SelectedComponentActions from './components/SelectedComponentsActions';
import * as S from './ComponentsList.styled';

const defaultSorting: Sorting<Component> = {
  desc: true,
  sortBy: 'testsCount',
};

const sortData: SortingFn = (data, sorting) => {
  const sortedData = T.sortBy(sorting.sortBy, data);

  return sorting.desc ? T.reverse(sortedData) : sortedData;
};

const ComponentsList = () => {
  const { t } = useTranslation();
  const {
    params: { projectSlug, projectId, organizationId },
  } = useAppRoutes('componentsList');

  const { data: components, isLoading, isError, refetch } = useGetComponentsQuery(projectId);

  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState(defaultSorting);
  const [selectedIds, setSelectedIds] = useState<Array<Component['id']>>([]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const filteredData = useMemo(
    () =>
      sortData(
        components?.filter((component) =>
          searchQuery !== '' ? component.name.toLowerCase().includes(searchQuery) : component,
        ) || [],
        sorting,
      ),
    [components, searchQuery, sorting],
  );

  const handleChangeSearchInput = useCallback<InputChangeHandler>((event) => {
    setSearchQuery(event.target.value.toLowerCase());
  }, []);

  // TODO
  // After SimpleTable refactoring it's worth using the whole
  // RowSelectionInstance<TData> interface here instead of only one of its functions
  const tableRef = useRef<{ toggleAllRowsSelected: (checked?: boolean) => void }>();

  const handleDeselectAll = useCallback(() => {
    tableRef.current?.toggleAllRowsSelected(false);
  }, []);

  const handleSortChange = useCallback((sortConfig: Sorting<Component>): void => {
    setSorting(sortConfig);
  }, []);

  const getExtraRowProps = T.memoize(
    (row) => ({
      to: reverse(urls.component, {
        projectId,
        projectSlug,
        organizationId,
        componentId: row.id,
      }),
    }),
    T.get('id'),
  );

  const Title: JSX.Element = useMemo(() => {
    const selectedComponents = filteredData.filter(({ id }) => selectedIds.includes(id));

    return (
      <S.TitleContainer data-testid="ComponentsList.Header">
        <Badge value={components?.length || 0} variant={BADGE_VARIANT.COUNTER}>
          <H1>{t('componentsList.title', 'Components')}</H1>
        </Badge>

        <S.Actions>
          {selectedIds.length === 0 ? (
            <SearchInput onChange={handleChangeSearchInput} />
          ) : (
            <SelectedComponentActions
              components={selectedComponents}
              onDeselect={handleDeselectAll}
            />
          )}
        </S.Actions>
      </S.TitleContainer>
    );
  }, [t, components, filteredData, handleChangeSearchInput, handleDeselectAll, selectedIds]);

  return (
    <S.Container>
      <Helmet title={t('componentsList.pageTitle', 'Components')} />
      {isError && <ServerErrorInfo isVisible={isError} onRetry={refetch} />}
      {!isError && isLoading && (
        <S.LoaderContainer>
          <LoaderComponent />
        </S.LoaderContainer>
      )}
      {!isError && !isLoading && (
        <S.ListContainer title={Title} data-testid="ComponentsList" wideContent>
          <InlineTutorialMessage
            title={t('inlineTutorial.title')}
            message={
              <Trans key="components.inlineTutorial.message">
                Components are groups of steps that are shared across multiple tests. This is useful
                if you don't want to repeat the most frequently used steps.{' '}
                <Link to={t('default.docs.components')}>Learn more</Link>
              </Trans>
            }
            storageName="componentsGettingStarted"
          />
          <ComponentsTable
            data={filteredData}
            getExtraRowProps={getExtraRowProps}
            handleSortChange={handleSortChange}
            isSearchEmpty={filteredData.length === 0 && searchQuery !== ''}
            onSelectionChange={setSelectedIds}
            ref={tableRef}
            sorting={defaultSorting}
          />
        </S.ListContainer>
      )}
    </S.Container>
  );
};

export default ComponentsList;
