import ButtonBase, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 15px;
`;

export const Button = styled(ButtonBase).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))`
  margin-right: 9px;
  svg {
    width: 16px;
  }
`;
