import styled from 'styled-components';

import TextAreaBase from '~/components/TextArea';

export const TextArea = styled(TextAreaBase)`
  textarea {
    width: 100%;
  }

  &:not(:focus-within) {
    border-color: transparent !important;

    textarea {
      color: ${({ theme }) => theme.colors.text.secondary};
      resize: none;
    }

    textarea::placeholder {
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      color: inherit;
      opacity: 1;
    }
  }
`;
