import { COLOR, ExternalLinkIcon, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 13px 4px;
  display: flex;
  align-items: center;
  opacity: 0.7;
  a {
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 11px;
    color: ${COLOR.PRIMARY_LIGHT};
    &:hover {
      text-decoration: none;
    }
  }
`;

export const LinkIcon = styled(ExternalLinkIcon)`
  width: 15px;
  margin-right: 3px;
`;
