import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import { selectCurrentOrganizationId } from '~/modules/organization';
import { TestActions } from '~/modules/test/test.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import toasts from '~/services/toasts';

import useActionState from './useActionState';
import useLocalStorageValue from './useLocalStorageValue';

const useCopyPasteSteps = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const clipboardKey = `${currentOrganizationId}.stepsClipboard`;
  const { value, set: setClipboard } = useLocalStorageValue(clipboardKey);

  const { reset, isLoading } = useActionState(TestActions.pasteStepsRequest);

  const handleCopy = useCallback(
    (stepIds = []) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.COPY_STEP);
      setClipboard(stepIds);
      toasts.showSuccess({
        content: t('copyPasteSteps.copied', {
          defaultValue_one: 'Step copied',
          defaultValue_other: 'Steps copied',
          count: stepIds.length,
        }),
      });
    },
    [t, setClipboard],
  );

  const handlePaste = useCallback(
    (testId, groupId, atIndex = 0) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.PASTE_STEP);
      dispatch(TestActions.pasteStepsRequest(testId, groupId, atIndex, value));
    },
    [dispatch, value],
  );

  useUnmount(() => {
    reset();
  });

  return {
    copySteps: handleCopy,
    pasteSteps: handlePaste,
    hasCopiedSteps: !!value?.length,
    isPending: isLoading,
  };
};

export default useCopyPasteSteps;
