import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  border: 2px solid ${COLOR.GRAY_9};
  border-radius: 5px;
  display: flex;
  transition: all 150ms ease-in-out;
  height: 38px;
  padding: 0 29px 2px 10px;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${COLOR.GRAY_10};
  }

  &:active,
  &:focus-within {
    border-color: ${COLOR.PRIMARY_LIGHT};
  }

  &[disabled] {
    pointer-events: none;
    resize: none;
  }

  &[readonly] {
    border-color: ${COLOR.GRAY_9};
    background-color: ${COLOR.GRAY_28};
    opacity: 0.8;
    resize: none;
  }
`;

export const ExpandIcon = styled(Icon).attrs(() => ({
  name: 'arrowDown',
}))`
  position: absolute;
  top: 5px;
  right: 10px;
  color: ${COLOR.GRAY_27};
  flex: none;
  margin-left: 5px;
  margin-right: -5px;

  && {
    font-size: 19px;
  }
`;
