import * as Ariakit from '@ariakit/react';

import * as S from './VariableListItem.styled';

export const VariableListItem = ({
  value,
  disabled,
  description,
  onClick,
}: {
  value: string;
  disabled?: boolean;
  description: string | null;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => (
  <Ariakit.ComboboxItem
    value={value}
    focusOnHover
    onClick={onClick}
    disabled={disabled}
    render={<S.VariableContainer />}
  >
    <S.VariableName>{value}</S.VariableName>
    {description && <S.VariableDescription>{description}</S.VariableDescription>}
  </Ariakit.ComboboxItem>
);
