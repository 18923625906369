import type { AlertAction, AlertEmailAction, AlertSlackAction } from '@bugbug/core/types/alerts';
import type { Project } from '@bugbug/core/types/projects';
import i18n from '~/translations';

const getEmailSummary = (action: AlertEmailAction, project: Project): string => {
  const totalRecipients = action.config.extraEmails.length + action.config.users.length;

  return action.config.recipientType === 'all'
    ? i18n.t(
        'alertsListItem.actionSummary.emailRecipientAll',
        'All people in "{{ projectName }}"',
        {
          interpolation: { escapeValue: false },
          projectName: project.name,
        },
      )
    : i18n.t('alertsListItem.actionSummary.totalRecipients', {
        defaultValue_one: '{{totalRecipients}} recipient',
        defaultValue_other: '{{totalRecipients}} recipients',
        count: totalRecipients,
        totalRecipients,
      });
};

const getSlackSummary = (action: AlertSlackAction): string => {
  if (action.config.method === 'directMessage') {
    return i18n.t('alertsListItem.actionSummary.slack.direct', 'To user: {{name}}', {
      interpolation: { escapeValue: false },
      name: action.config.name,
    });
  }

  return i18n.t('alertsListItem.actionSummary.slack.channel', 'To channel: #{{name}}', {
    interpolation: { escapeValue: false },
    name: action.config.name,
  });
};

export const getActionSummary = (action: AlertAction, project: Project): string => {
  switch (action.type) {
    case 'sendEmail': {
      return getEmailSummary(action, project);
    }

    case 'sendSlackMessage':
      return getSlackSummary(action);

    case 'sendTeamsMessage':
      return i18n.t(
        'alertsListItem.actionSummary.teams.channel',
        'To channel "{{channelName}}" in team "{{teamName}}"',
        {
          interpolation: { escapeValue: false },
          channelName: action.config.name,
          teamName: action.config.teamName,
        },
      );

    case 'sendWebhook':
      return i18n.t(
        'alertsListItem.actionSummary.webhook',
        '{{ method }} request on url: {{ url }}',
        {
          interpolation: { escapeValue: false },
          method: action.config.method,
          url: action.config.url,
        },
      );

    default:
      return '';
  }
};
