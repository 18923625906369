import * as S from './RunDetailsSummary.styled';

interface DetailsMetricProps {
  value: string | JSX.Element;
  description: string;
}

export const DetailsMetric = ({ value, description }: DetailsMetricProps) => (
  <S.DetailsMetricContainer>
    <span>{value}</span>
    {description && <span>{description}</span>}
  </S.DetailsMetricContainer>
);
