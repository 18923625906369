import Dropdown, { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Menu = styled(Dropdown).attrs(() => ({
  variant: DROPDOWN_VARIANT.PRIMARY,
}))`
  margin-left: 9px;
`;
