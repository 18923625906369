import Icon from '@bugbug/core/components/Icon';
import UnorganizedList from '@bugbug/core/components/UnorganizedList';
import { ThumbDownIcon, FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import * as T from '@bugbug/core/utils/toolbox';
import { ifElse, always } from 'ramda';
import styled from 'styled-components';

import OrganizedList from '~/components/OrganizedList';

const ifWarningElse = (warningStyle, style) =>
  ifElse(T.prop('warning'), always(warningStyle), always(style));

export const Container = styled.div<{ warning: boolean }>`
  padding: 3px 12px 12px;
  color: ${ifWarningElse(COLOR.DARK_GRAY, COLOR.ERROR)};
  border-radius: 4px;
  background-color: ${ifWarningElse(COLOR.GRAY_21, COLOR.RED_1)};
  font-weight: ${FONT_WEIGHT.BOLD};
  position: relative;
  margin: 0 32px 26px;
  width: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  word-break: break-word;
`;

export const Title = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ErrorMessage = styled.div`
  margin: 10px 0 12px 0;
  font-weight: ${FONT_WEIGHT.NORMAL};
`;

export const ErrorId = styled.div``;

export const Footer = styled.div`
  opacity: ${ifWarningElse(0.2, 0.6)};
  margin-top: 12px;
  font-size: 11px;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-align: left;

  ${ErrorId} {
    opacity: 0.8;
    font-size: 10px;
  }
`;

export const Timeout = styled.div``;

export const Selector = styled.div``;

export const Description = styled.section`
  font-weight: ${FONT_WEIGHT.NORMAL};
  line-height: 17px;
  margin: 5px 0 18px 0;
`;

export const StepFailedIcon = styled(ThumbDownIcon)`
  margin: 8px 8px 0;
  flex: none;
`;

export const RuntimeErrorIcon = styled(Icon).attrs(() => ({
  name: 'error',
}))`
  margin: 2px 5px 0;
  width: 20px;
  flex: none;
`;

export const WarningIcon = styled(Icon).attrs(() => ({
  name: 'info',
}))`
  margin: 2px 5px 0;
  width: 20px;
  flex: none;
`;

export const Details = styled.div`
  margin-bottom: 18px;
`;

export const Tables = styled.div`
  display: flex;
`;

export const Result = styled.span<{ succeeded: boolean }>`
  color: ${({ succeeded, theme }) =>
    succeeded ? theme.colors.status.success : theme.colors.status.error};
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const List = styled.div`
  ${UnorganizedList}, ${OrganizedList} {
    padding-left: 20px;
    margin: 5px 0 0;

    li {
      margin-bottom: 6px;
      line-height: inherit;
    }
  }

  ${UnorganizedList} {
    li:before {
      width: 5px;
      height: 5px;
      transform: translate(6px, 7px);
      background-color: currentColor;
    }
  }
`;

export const TimeoutReason = styled.div`
  margin-top: 12px;
`;
