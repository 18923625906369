import { isEmpty } from 'ramda';
import { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import type { SimpleTableProps, SimpleTableRef } from '~/components/Table';

import { SimpleTable } from '~/components/Table';
import useAppRoutes from '~/hooks/useAppRoutes';
import { selectSingleSuiteRunTestList } from '~/modules/suiteRun/suiteRun.selectors';
import urls, { reverse } from '~/views/urls';

import { TESTS_COLUMNS } from './TestRuns.constants';
import { Container } from './TestRuns.styled';

export type TestRunRef = SimpleTableRef;

interface TestRunsProps {
  className?: string;
  onSelectionChange?: SimpleTableProps['onSelectionChange'];
  disabledWindowing?: boolean;
}

const TestRuns = forwardRef<TestRunRef, TestRunsProps>(
  ({ className, onSelectionChange, disabledWindowing }, ref) => {
    const {
      params: { projectSlug, projectId, organizationId },
    } = useAppRoutes('testRunsList');
    const location = useLocation();
    const testsRuns = useSelector(selectSingleSuiteRunTestList);

    const getRowProps = useCallback(
      (row) => {
        const pathname = reverse(urls.testRun, {
          organizationId,
          projectId,
          projectSlug,
          testRunId: row.id,
        });

        return {
          to: { pathname, state: { backUrl: location.pathname } },
        };
      },
      [organizationId, projectId, projectSlug, location.pathname],
    );

    if (isEmpty(testsRuns)) {
      return null;
    }

    return (
      <Container data-testid="SuiteRunDetailsTestRuns" className={className}>
        <SimpleTable
          columns={TESTS_COLUMNS}
          data={testsRuns}
          getRowProps={getRowProps}
          onSelectionChange={onSelectionChange}
          ref={ref}
          disabledWindowing={disabledWindowing}
        />
      </Container>
    );
  },
);

TestRuns.displayName = 'TestRuns';

export default TestRuns;
