import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import toasts from '~/services/toasts';

const useCopyHandler = (value, { success, failure } = {}) => {
  const { t } = useTranslation();

  const msgSuccess = success || t('copyHandler.success', 'Copied');
  const msgFailure =
    failure || t('copyHandler.error', 'Error when trying to copy to the clipboard');

  const handleCopyClick = useCallback(() => {
    try {
      copy(value);
      toasts.showSuccess({ content: msgSuccess });
    } catch (error) {
      toasts.showError({ content: msgFailure });
    }
  }, [msgFailure, msgSuccess, value]);

  return handleCopyClick;
};

export default useCopyHandler;
