import CheckboxBase from '@bugbug/core/components/Checkbox';
import InputContainer from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

import {
  FieldRow as FieldRowBase,
  FieldLabel as FieldLabelBase,
  FieldValue as FieldValueBase,
} from '../StepDetails/StepDetails.styled';

export const SmallContainer = styled.div`
  width: fit-content;
  margin-top: 15px;
`;

export const Url = styled(Link)`
  color: ${COLOR.PRIMARY_LIGHT} !important;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  text-decoration: none !important;
`;

export const FieldRow = styled(FieldRowBase)`
  border-bottom: 1px solid ${COLOR.GRAY_18};
  border-top: 1px solid ${COLOR.GRAY_18};
  padding: 7px 10px;
  margin-left: -10px;
  margin-top: -1px;
  max-width: 860px;
  grid-template-columns: 120px 172px;

  ${({ theme }) => theme.media.desktopWide`
    grid-template-columns: 170px 172px;
  `};
`;

export const FieldValue = styled(FieldValueBase)`
  && {
    display: block;
  }
`;

export const FieldLabel = styled(FieldLabelBase)``;

export const Input = styled(InputContainer)``;

export const Checkbox = styled(CheckboxBase)`
  width: 100%;
  margin-bottom: -5px;
`;
