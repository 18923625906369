import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUnconfirmedGroups } from '~/modules/test/test.selectors';

import UnconfirmedGroup from '../UnconfirmedGroup';

const UnconfirmedGroups = memo(({ relatedGroupId, index, includeOutOfRange }) => {
  const selectGroups = useMemo(
    () => selectUnconfirmedGroups(relatedGroupId, index, includeOutOfRange),
    [relatedGroupId, index, includeOutOfRange],
  );
  const groups = useSelector(selectGroups);

  return groups.map((group) => (
    <UnconfirmedGroup
      key={group.id}
      groupId={group.id}
      relatedGroup={group.unconfirmedRelatedGroup}
      relatedIndex={group.unconfirmedIndex}
    />
  ));
});

UnconfirmedGroups.displayName = 'UnconfirmedGroups';

UnconfirmedGroups.defaultProps = {
  includeOutOfRange: false,
};

UnconfirmedGroups.propTypes = {
  relatedGroupId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  includeOutOfRange: PropTypes.bool,
};

export default UnconfirmedGroups;
