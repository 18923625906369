import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 17px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: 50%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
`;

export const CreateButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
  rounded: true,
}))``;
