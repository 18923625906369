import Icon from '@bugbug/core/components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Handler } from './DragHandler.styled';

const DragHandler = ({ dragHandleProps, className }) => {
  const handleClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, react/jsx-props-no-spreading, react/destructuring-assignment, react/prop-types, jsx-a11y/click-events-have-key-events
    <Handler
      className={className}
      {...dragHandleProps}
      onClick={handleClick}
      data-testid="DragHandler"
    >
      <Icon name="drag" />
    </Handler>
  );
};

DragHandler.defaultProps = {
  className: null,
  disabled: false,
  dragHandleProps: null,
};

DragHandler.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dragHandleProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DragHandler;
