import { pick, pipe, mergeDeepRight } from 'ramda';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';

import { getDuration } from '../../RunsHistory.helpers';
import IndexedNameCell from '../IndexedNameCell';
import MethodCell from '../MethodCell';
import ProfileCell from '../ProfileCell';
import RunByCell from '../RunByCell';
import SuiteRunCell from '../SuiteRunCell';
import TestRunRowActions from '../TestRunRowActions';

export const TESTS_HISTORY_COLUMN_ID = {
  NAME: 'name',
  SUITE_RUN: 'suite_run',
  DURATION: 'duration',
  METHOD: 'runMode',
  STATUS: 'status',
  ACTIONS: 'actions',
  PROFILE: 'profile',
  RUN_BY: 'runBy',
  SCREEN_SIZE: 'screenSizeType',
};

export const TESTS_HISTORY_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('testsHistory.table.columns.name', 'Name'),
    accessor: 'name',
    id: TESTS_HISTORY_COLUMN_ID.NAME,
    Cell: IndexedNameCell,
    width: '100%',
    minWidth: 260,
  },
  {
    Header: i18n.t('testsHistory.table.columns.suiteRun', 'Suite run'),
    accessor: 'suiteRun',
    id: TESTS_HISTORY_COLUMN_ID.SUITE_RUN,
    Cell: SuiteRunCell,
    maxWidth: 100,
  },
  {
    Header: i18n.t('testsHistory.table.columns.duration', 'Duration'),
    accessor: getDuration,
    id: TESTS_HISTORY_COLUMN_ID.DURATION,
    Cell: TablePartials.TextCell,
    maxWidth: 100,
  },
  {
    Header: i18n.t('testsHistory.table.columns.profile', 'Profile'),
    accessor: 'profileName',
    id: TESTS_HISTORY_COLUMN_ID.PROFILE,
    Cell: ProfileCell,
    maxWidth: 90,
  },
  {
    Header: i18n.t('testsHistory.table.columns.method', 'Method'),
    accessor: 'runMode',
    id: TESTS_HISTORY_COLUMN_ID.METHOD,
    Cell: MethodCell,
    maxWidth: 90,
  },
  TablePartials.PREDEFINED_COLUMN.SCREEN,
  {
    Header: i18n.t('testsHistory.table.columns.runBy', 'Run by'),
    accessor: pick(['user', 'triggeredBy']),
    id: TESTS_HISTORY_COLUMN_ID.RUN_BY,
    Cell: RunByCell,
    width: 240,
    maxWidth: 400,
  },
  {
    Header: i18n.t('testsHistory.table.columns.status', 'Status'),
    accessor: pipe(
      pick(['started', 'status', 'errorCode', 'isAutoRetried']),
      mergeDeepRight({ extended: true }),
    ),
    Cell: TablePartials.StatusCell,
    id: TESTS_HISTORY_COLUMN_ID.STATUS,
    width: 120,
  },
  {
    Header: i18n.t('testsHistory.table.columns.actions', ''),
    Cell: TestRunRowActions,
    id: TESTS_HISTORY_COLUMN_ID.ACTIONS,
    disableSortBy: true,
    width: 75,
  },
];
