import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 24px 0 19px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 7px;

  > span {
    margin: 0 34px 0 24px;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    flex: 1;
  }
`;

export const Title = styled.div`
  color: ${COLOR.DARK_GRAY};
  margin: 0 34px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;
