import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Content = styled.div`
  color: ${COLOR.DARK_GRAY};
  font-weight: 400;
  opacity: 0.6;
  cursor: pointer;
  margin-top: 10px;
  font-size: 13px;
  &:hover {
    opacity: 1;
  }
  a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
`;
export const Text = styled.div`
  ${mixins.textEllipsis};
  margin-left: 5px;
`;

export const InfoIcon = styled(Icon).attrs(() => ({
  name: 'info',
}))`
  && {
    color: ${COLOR.DARK_GRAY};
    height: 18px;
  }
`;
