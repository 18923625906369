import ButtonBase from '@bugbug/core/components/Button';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  color: ${COLOR.GRAY_13};
  padding-bottom: 2px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: -1px;
`;

export const Button = styled(ButtonBase)`
  && {
    padding: 4px;
    display: inline;
    height: auto;
    margin: 0;
    font-weight: ${FONT_WEIGHT.MEDIUM};

    &:hover {
      background: ${COLOR.TRANSPARENT};
      text-decoration: underline;
    }

    > div {
      margin: 0;
    }
  }
`;
