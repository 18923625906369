import Input from '@bugbug/core/components/Input';
import { useTrail } from '@react-spring/web';
import { useFormik } from 'formik';
import { always, cond, equals } from 'ramda';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import FormField from '~/components/FormField';
import { selectExtensionSettings } from '~/modules/extension/extension.selectors';
import { hasAtMostVersion } from '~/utils/misc';
import { handleYToTranslateInterpolation } from '~/utils/spring';
import { addProtocolWhenNotExists } from '~/utils/url';
import { urlValidator } from '~/utils/validators';

import {
  ACTIONS,
  ACTION,
  ANIMATED_ELEMENTS_LENGTH,
  ANIMATED_ELEMENTS_FOR_FIRST_RECORDING_LENGTH,
} from './EmptyStateNoRecording.constants';
import {
  Container,
  ActionWrapper,
  Button,
  Description,
  Title,
  Separator,
  RecordButton,
} from './EmptyStateNoRecording.styled';

const schema = Yup.object().shape({
  url: urlValidator,
});

const EmptyStateNoRecording = ({
  className,
  isVisible,
  isFirstRecording,
  onRecordClick,
  onNewStepClick,
  onPasteStepsClick,
  onInsertComponentClick,
  initialRecordingUrl,
  disabledPasting,
}) => {
  const extensionSettings = useSelector(selectExtensionSettings);

  const { t } = useTranslation();
  const otherActions = isFirstRecording ? [] : ACTIONS;
  const animatedElementsLength = isFirstRecording
    ? ANIMATED_ELEMENTS_FOR_FIRST_RECORDING_LENGTH
    : ANIMATED_ELEMENTS_LENGTH;

  const trail = useTrail(animatedElementsLength, {
    y: isVisible ? 0 : 50,
    opacity: Number(isVisible),
  });

  const handleSubmit = useCallback(
    (values, actions) => {
      onRecordClick({}, addProtocolWhenNotExists(values.url || null));
      if (!extensionSettings.isActive) {
        actions.setSubmitting(false);
      }
    },
    [onRecordClick, extensionSettings.isActive],
  );

  const formik = useFormik({
    initialValues: { url: initialRecordingUrl || '' },
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const getTrailStyle = useCallback(
    (index) => ({
      ...trail[index],
      transform: trail[index].y.to(handleYToTranslateInterpolation),
    }),
    [trail],
  );

  const renderAction = useCallback(
    (action, index) => {
      const { key, label, Icon } = action;
      const extraProps = cond([
        [equals(ACTION.NEW_STEP), always({ onClick: onNewStepClick })],
        [equals(ACTION.EXISTING_COMPONENT), always({ onClick: onInsertComponentClick })],
        [
          equals(ACTION.PASTE_STEPS),
          always({ onClick: onPasteStepsClick, disabled: disabledPasting }),
        ],
      ])(key);

      return (
        <ActionWrapper key={key}>
          <Button style={getTrailStyle(index + 2)} Icon={Icon} {...extraProps} bordered>
            {label}
          </Button>
        </ActionWrapper>
      );
    },
    [getTrailStyle, onInsertComponentClick, onNewStepClick, onPasteStepsClick, disabledPasting],
  );

  const description = t(
    'testDetailsEmptyState.firstRecording.description',
    'Create your first automated test by "recording" how you click on your website.',
  );

  const hasRequiredExtensionVersion = !hasAtMostVersion(extensionSettings.version, '3.37.0');

  return (
    <Container className={className} data-testid="TestDetailsEmptyStateNoRecording">
      <Title style={getTrailStyle(0)}>
        {isFirstRecording
          ? t('testDetailsEmptyState.firstRecording.title', 'Record your first test')
          : t('testDetailsEmptyState.noRecording.title', 'This test is empty')}
      </Title>

      {isFirstRecording && <Description style={getTrailStyle(1)}>{description}</Description>}

      {hasRequiredExtensionVersion ? (
        <form onSubmit={formik.handleSubmit} style={getTrailStyle(isFirstRecording ? 2 : 1)}>
          <FormField
            label={t('testDetailsEmptyState.url', 'Enter a URL of a website you want to record')}
            helperText={!isFirstRecording ? description : null}
          >
            <Input
              {...formik.getFieldProps('url')}
              autoFocus
              data-hj-allow
              error={formik.touched.url && formik.errors.url}
            />
          </FormField>

          <RecordButton type="submit" pending={formik.isSubmitting}>
            {t('testDetailsEmptyState.firstRecording.button', 'Start recording')}
          </RecordButton>
        </form>
      ) : (
        <RecordButton type="submit" onClick={onRecordClick}>
          {t('testDetailsEmptyState.firstRecording.button', 'Start recording')}
        </RecordButton>
      )}

      {!isFirstRecording && (
        <>
          <Separator>{t('testDetailsEmptyState.noRecording.otherActions', 'or')}</Separator>
          {otherActions.map(renderAction)}
        </>
      )}
    </Container>
  );
};

export default EmptyStateNoRecording;
