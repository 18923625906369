import { ItemsContainer } from '@bugbug/core/components/Dropdown/Dropdown.styled';
import styled from 'styled-components';

export const variablesDropdownWidthPx = 240;
export const VariablesDropdownContainer = styled(ItemsContainer)`
  display: flex;
  flex-direction: column;
  max-height: 350px;
  width: ${variablesDropdownWidthPx}px;
  overflow: auto;
`;

export const InsertVariableHeader = styled.p`
  padding: 10px;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.normal};
  font-weight: 600;
  outline: 1px solid ${({ theme }) => theme.colors.border.light};
`;

export const EmptyState = styled.p`
  margin: 0;
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.text.emptyState};
`;

export const VariableError = styled.div`
  background-color: ${({ theme }) => theme.colors.background.failure};
  color: ${({ theme }) => theme.colors.status.error};
  padding: 8px 12px;
  border-radius: 5px;
  border-radius: 4px;
  margin-top: 8px;
  overflow-wrap: anywhere;

  p {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.status.error};
    text-decoration: underline;
  }
`;

export const VariableErrorTitle = styled.div`
  font-weight: 600;
`;
