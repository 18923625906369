import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RunButton from '~/components/RunButton';
import useTestRunner from '~/hooks/useTestRunner';

const RunTestButton = (props) => {
  const { t } = useTranslation();
  const {
    className,
    running,
    queued,
    disabled,
    testRunId,
    test,
    withRecording,
    withDebug,
    withRedirect,
  } = props;
  const testRunner = useTestRunner(test, { testRunId });

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (running) {
        testRunner.stop();
        return;
      }

      testRunner.startLocal({
        withRecording,
        withDebug,
        withRedirect,
        withProfileSelection: true,
      });
    },
    [testRunner, running, withRecording, withDebug, withRedirect],
  );

  const label = useMemo(() => {
    if (queued) {
      return t('runTestButton.queued.label', 'Test is queued');
    }
    if (running) {
      return t('runTestButton.running.label', 'Test is running');
    }

    return t('runTestButton.idle.local.label', 'Run test locally');
  }, [t, queued, running]);

  return (
    <RunButton
      className={className}
      onClick={handleClick}
      disabled={disabled}
      running={running}
      queued={queued}
      label={label}
    />
  );
};

RunTestButton.defaultProps = {
  className: null,
  running: false,
  disabled: false,
  queued: false,
  withRedirect: false,
  withDebug: false,
  withRecording: false,
  testRunId: '',
};

RunTestButton.propTypes = {
  className: PropTypes.string,
  running: PropTypes.bool,
  disabled: PropTypes.bool,
  queued: PropTypes.bool,
  withRedirect: PropTypes.bool,
  withDebug: PropTypes.bool,
  withRecording: PropTypes.bool,
  testRunId: PropTypes.string,
  test: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RunTestButton;
