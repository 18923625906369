import Button from '@bugbug/core/components/Button';
import { lineClamp } from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

import FormField from '~/components/FormField';
import { TextAreaBase } from '~/components/TextArea';

export const Container = styled.div`
  display: flex;

  ${TextAreaBase} {
    height: 150px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BasicAuthButton = styled(Button)`
  width: 112px;
  margin-top: -8px;
  height: auto;
  margin-left: -5px;
  padding: 5px 9px;
`;

export const ComputedValue = styled.div`
  ${lineClamp({ lines: 3 })}
  margin-right: 12px;
  padding: 0 2px 2px;
  width: 100%;
  word-wrap: break-word;
`;

export const ComputedField = styled(FormField)`
  padding: 8px 10px;
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 20px;
`;
