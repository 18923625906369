import { useTranslation } from 'react-i18next';

import * as S from './EmptySidebar.styled';

const ComponentSidebar = () => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Icon />
      <S.Label>
        {t('componentSidebar.empyState.label', 'Select a row on the left side to see its details')}
      </S.Label>
    </S.Container>
  );
};

export default ComponentSidebar;
