import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { useUpdateEffect } from 'react-use';
import * as Yup from 'yup';

import { TRANSITION_TIME } from '~/components/CollapsableSection';
import { MODAL_TYPE } from '~/components/modals';
import ScrollShadow from '~/components/ScrollShadow';
import { STEP_TYPE } from '~/constants/step';
import useActionState from '~/hooks/useActionState';
import useFormikContextWithTabsErrors from '~/hooks/useFormikContextWithTabsErrors';
import useLeavePagePrompt from '~/hooks/useLeavePagePrompt';
import useModal from '~/hooks/useModal';
import { selectIsFreePlan } from '~/modules/organization';
import { TestActions } from '~/modules/test/test.redux';
import { selectStep } from '~/modules/test/test.selectors';
import { addProtocolWhenNotExists } from '~/utils/url';

import ErrorDetails from '../ErrorDetails';
import Execution, { FIELD_NAMES_VALUES as EXECUTION_SECTION_FIELDS } from '../Execution';
import Interaction, { FIELD_NAMES_VALUES as INTERACTION_SECTION_FIELDS } from '../Interaction';
import StepRunExecutionUrl from '../StepRunExecutionUrl';
import StepRunMetadata from '../StepRunMetadata';
import StepRunScreenshot from '../StepRunScreenshot';
import WaitingConditions, {
  FIELD_NAMES_VALUES as WAITING_CONDITIONS_SECTION_FIELDS,
  // eslint-disable-next-line object-curly-newline
} from '../WaitingConditions';

import { SECTION, SECTION_LABEL, STEP_SCHEMA, STEP_UNSAVED_CHANGES } from './StepDetails.constants';
import { getInitialValues } from './StepDetails.helpers';
import * as S from './StepDetails.styled';

const SECTION_FIELDS = {
  [SECTION.INTERACTION]: INTERACTION_SECTION_FIELDS,
  [SECTION.EXECUTION]: EXECUTION_SECTION_FIELDS,
  [SECTION.WAITING_CONDITIONS]: WAITING_CONDITIONS_SECTION_FIELDS,
};

const StepDetailsContent = ({ context, className, step, onClose, readOnly, temporary }) => {
  const { t } = useTranslation();
  const containerRef = useRef();
  const contentRef = useRef();
  const interactionRef = useRef();
  const executionRef = useRef();
  const conditionsRef = useRef();
  const modal = useModal();

  const sectionsRefs = {
    [SECTION.INTERACTION]: interactionRef,
    [SECTION.EXECUTION]: executionRef,
    [SECTION.WAITING_CONDITIONS]: conditionsRef,
  };

  const {
    setSubmitting,
    setErrors,
    values,
    handleSubmit,
    isSubmitting,
    groupsWithErrors: sectionsWithErrors,
    dirty,
  } = useFormikContextWithTabsErrors({ fieldGroups: SECTION_FIELDS });

  useLeavePagePrompt(dirty, STEP_UNSAVED_CHANGES);

  const handleFailure = useCallback(
    (errors) => {
      setSubmitting(false);
      if (errors) {
        setErrors(errors);
      }
    },
    [setSubmitting, setErrors],
  );

  const handleSaveSuccess = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const handleCreateSuccess = useCallback(() => {
    setSubmitting(false);
    onClose();
  }, [setSubmitting, onClose]);

  const saveStepRequestState = useActionState(TestActions.saveStepSettingsRequest, {
    onFailure: handleFailure,
    onSuccess: handleSaveSuccess,
    reqId: step.id,
  });

  const createStepRequestState = useActionState(TestActions.saveTemporaryStepRequest, {
    onFailure: handleFailure,
    onSuccess: handleCreateSuccess,
    reqId: step.id,
  });

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        (dirty || temporary) &&
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        // is not a modal
        !document.querySelector('.backdrop')?.contains(event.target) &&
        // is not a dropdown
        !document.querySelector('#layers')?.contains(event.target) &&
        // test step side panel resizer
        !document.querySelector('.Resizer')?.contains(event.target)
      ) {
        event.preventDefault();
        event.stopPropagation();
        modal.show(MODAL_TYPE.UNSAVED_CHANGES, {
          onSave: handleSubmit,
          onDiscard: onClose,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, onClose, dirty, temporary],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, true);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [handleOutsideClick]);

  useLayoutEffect(() => {
    if (sectionsWithErrors.length) {
      sectionsWithErrors.forEach((type) => {
        sectionsRefs[type].current.open();
      });
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        contentRef.current
          ?.querySelector('[aria-invalid="true"]')
          ?.scrollIntoView({ behavior: 'smooth' });
      }, TRANSITION_TIME * 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsWithErrors]);

  useUpdateEffect(() => {
    contentRef.current.scrollTop = 0;
    setSubmitting(false);
  }, [step.id]);

  const hasWaitingConditions = ![STEP_TYPE.GOTO, STEP_TYPE.NEW_TAB, STEP_TYPE.CLOSE_TAB].includes(
    values.type,
  );

  const handleClick = useCallback(() => handleSubmit(), [handleSubmit]);

  const isPrimaryActionPending =
    isSubmitting || saveStepRequestState.isLoading || createStepRequestState.isLoading;

  return (
    <S.Container className={className} data-testid="StepDetails" ref={containerRef}>
      <Prompt message={STEP_UNSAVED_CHANGES} when={!!step.isTemporary || dirty} />
      <S.Header>
        <S.TitleContainer>
          <S.Title as="h2">{t('stepDetails.header.title', 'Step details')}</S.Title>
          <S.ActionButton onClick={onClose} disabled={isSubmitting} bordered>
            {t('default.button.cancel')}
          </S.ActionButton>
          {!readOnly && (
            <>
              <S.ActionButton
                key={step.id}
                onClick={handleClick}
                pending={isPrimaryActionPending}
                disabled={isPrimaryActionPending}
                succeeded={saveStepRequestState.isSuccess || createStepRequestState.isSuccess}
              >
                {step.isTemporary ? t('default.button.create') : t('default.button.save')}
              </S.ActionButton>
              <S.MoreActions step={step} bordered />
            </>
          )}
        </S.TitleContainer>
        <StepRunMetadata stepId={step.id} />
      </S.Header>

      <S.PaidFeatureBanner messageFor={step.type} style={{ margin: '0 24px' }} />

      <S.Content ref={contentRef}>
        <ScrollShadow />
        <ErrorDetails step={step} />
        <S.Section
          defaultExpanded
          label={SECTION_LABEL[SECTION.SCREENSHOTS]}
          expandedCacheName={SECTION.SCREENSHOTS}
        >
          <StepRunScreenshot stepId={step.id || step.frontId} />
          <StepRunExecutionUrl stepId={step.id || step.frontId} />
        </S.Section>
        <S.Section
          ref={interactionRef}
          defaultExpanded
          label={SECTION_LABEL[SECTION.INTERACTION]}
          expandedCacheName={SECTION.INTERACTION}
        >
          <Interaction context={context} step={step} readOnly={readOnly} />
        </S.Section>
        <S.Section
          ref={executionRef}
          defaultExpanded
          label={SECTION_LABEL[SECTION.EXECUTION]}
          expandedCacheName={SECTION.EXECUTION}
        >
          <Execution step={step} readOnly={readOnly} />
        </S.Section>
        {hasWaitingConditions && (
          <S.Section
            ref={conditionsRef}
            defaultExpanded
            label={SECTION_LABEL[SECTION.WAITING_CONDITIONS]}
            expandedCacheName={SECTION.WAITING_CONDITIONS}
          >
            <WaitingConditions step={step} readOnly={readOnly} />
          </S.Section>
        )}
      </S.Content>
    </S.Container>
  );
};

const StepDetails = memo(({ context = 'test', onClose, stepId, readOnly }) => {
  const dispatch = useDispatch();
  const step = useSelector(selectStep(stepId, readOnly));
  const { groupId, isTemporary, atIndex } = step || {};

  const isFreePlan = useSelector(selectIsFreePlan);
  const isNotEditable =
    isFreePlan && [STEP_TYPE.EXECUTE, STEP_TYPE.SET_LOCAL_VARIABLE].includes(step.type);
  const isReadOnly = readOnly || isNotEditable;

  const getValidationSchema = useCallback(
    () => Yup.lazy((values) => STEP_SCHEMA[values.type] || STEP_SCHEMA.DEFAULT),
    [],
  );

  const handleClose = useCallback(() => {
    if (isTemporary) {
      dispatch(TestActions.cancelTemporaryStep(stepId, groupId));
    }

    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onClose, groupId, stepId]);

  const handleSubmit = useCallback(
    (values, { resetForm }) => {
      const schema = STEP_SCHEMA[values.type] || STEP_SCHEMA.DEFAULT;
      const newValues = schema.cast({ ...values, url: addProtocolWhenNotExists(values.url) });
      if (isTemporary) {
        dispatch(
          TestActions.saveTemporaryStepRequest(stepId, groupId, newValues, atIndex, {
            reqId: stepId,
          }),
        );
        return;
      }

      dispatch(
        TestActions.saveStepSettingsRequest(stepId, groupId, newValues, {
          reqId: stepId,
        }),
      );

      resetForm({ values: newValues });
    },
    [groupId, isTemporary, atIndex, stepId, dispatch],
  );

  const initialValues = useMemo(() => (step ? getInitialValues(step) : {}), [step]);

  if (!step) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema}
      onSubmit={handleSubmit}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StepDetailsContent
        context={context}
        step={step}
        onClose={handleClose}
        temporary={isTemporary}
        readOnly={isReadOnly}
      />
    </Formik>
  );
});

StepDetailsContent.defaultProps = {
  className: null,
  readOnly: false,
};
StepDetailsContent.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  step: PropTypes.shape({
    id: PropTypes.string,
    isTemporary: PropTypes.bool,
    frontId: PropTypes.string,
    groupId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    atIndex: PropTypes.number,
  }).isRequired,
};
StepDetailsContent.displayName = 'StepDetailsContent';

StepDetails.displayName = 'StepDetails';

export default StepDetails;
