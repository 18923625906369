import Icon from '@bugbug/core/components/Icon';
import RelativeTime from '@bugbug/core/components/RelativeTime';
import { useTimezone } from '@bugbug/core/hooks/useTimezone';
import { useTranslation } from 'react-i18next';

import type { Component } from '@bugbug/core/types/components';
import type { SideEffect } from '@bugbug/core/types/utils';

import * as S from './ComponentMetadata.styled';

interface ComponentMetadataProps {
  component: Component;
  onShowRelatedTests: SideEffect;
}

const ComponentMetadata = ({ component, onShowRelatedTests }: ComponentMetadataProps) => {
  const { t } = useTranslation();
  const { toUserTZ } = useTimezone();

  return (
    <S.Container data-testid="ComponentMetadata" aria-label="Component details">
      <S.MetaValue>
        <Icon name="calendar" />
        <S.ShortValue>
          <RelativeTime
            date={component.modified}
            tooltipTemplate={(datetime: string) =>
              t('componentMetadata.lastModified', 'Modified on {{datetime}}', {
                datetime: toUserTZ(datetime).userFriendly,
              })
            }
          />
        </S.ShortValue>
      </S.MetaValue>

      <S.MetaValue>
        <Icon name="info" />
        <S.ShortValue>
          {t('componentMetadata.numberOfSteps', {
            count: component.steps.length,
            defaultValue_one: '1 step',
            defaultValue_other: '{{count}} steps',
          })}
        </S.ShortValue>
      </S.MetaValue>

      <S.SmallButton onClick={onShowRelatedTests} disabled={component.testsCount === 0}>
        {t('componentMetadata.more.openSmallButton', {
          count: component.testsCount,
          defaultValue_one: 'Show 1 related test',
          defaultValue_other: 'Show {{count}} related tests',
        })}
      </S.SmallButton>
    </S.Container>
  );
};

export default ComponentMetadata;
