import Link from '@bugbug/core/components/Link';
import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useMemo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useMount } from 'react-use';

import type { Variable } from '@bugbug/core/types/variables';
import ServerErrorInfo from '~/components/ServerErrorInfo';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { useAppSelector } from '~/modules/store';
import { VariableActions } from '~/modules/variable/variable.redux';
import { selectBuiltInVariablesList } from '~/modules/variable/variable.selectors';
import urls from '~/views/urls';

import {
  LoaderContainer,
  VariablesList,
  VariablesSection,
  TutorialMessage,
} from '../../Variables.styled';
import VariableItem from '../VariableItem';
import { BuiltInVariablesModalRoute } from '../VariablesModalRoute';

const BuiltInVariablesList = () => {
  const { t } = useTranslation();
  const queryString = useQueryString();
  const dispatch = useDispatch();
  const variables = useAppSelector(selectBuiltInVariablesList) as Variable[];
  const [isLoading, setIsLoading] = useState(true);

  const { hasInternalServerError } = useActionState(VariableActions.getListRequest, {
    onSuccess: () => setIsLoading(false),
  });

  const filteredVariables = useMemo(
    () =>
      variables.filter(
        ({ key }) =>
          !queryString.query || key.toLowerCase().includes(queryString.query.toLowerCase()),
      ),
    [variables, queryString.query],
  );

  const fetchData = useCallback(() => {
    dispatch(VariableActions.getListRequest());
  }, [dispatch]);

  useMount(() => {
    fetchData();
  });

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader size="large" />
    </LoaderContainer>
  ));

  const renderEmptyState = renderWhenTrue(() => {
    if (hasInternalServerError) {
      return <ServerErrorInfo isVisible={hasInternalServerError} onRetry={fetchData} />;
    }
    return renderLoader(true);
  });

  return (
    <>
      <Helmet title={t('builtInVariablesList.pageTitle', 'Custom variables')} />
      <TutorialMessage
        title={t('builtInVariablesList.inlineTutorial.title', 'Getting started')}
        message={
          <Trans key="builtInVariablesList.inlineTutorial.description">
            Built-in variables provides you a selection of auto generated values that you can use in
            your tests. <Link to={t('default.docs.variables')}>Learn more</Link>
          </Trans>
        }
        storageName="BuiltInVariablesListGettingStarted"
      />
      {!isLoading && (
        <VariablesSection>
          <VariablesList role="grid">
            {filteredVariables.map((variable) => (
              <VariableItem key={variable.id} variable={variable} />
            ))}
          </VariablesList>
        </VariablesSection>
      )}

      {renderEmptyState(hasInternalServerError || isLoading)}
      <Switch>
        <Route path={urls.builtInVariable} component={BuiltInVariablesModalRoute} />
      </Switch>
    </>
  );
};

export default BuiltInVariablesList;
