import { identity, pick } from 'ramda';

import * as TablePartials from '~/components/Table';
import i18n from '~/translations';

import TestRunStepMoreActions from '../../../TestRunDetails/components/TestRunStepMoreActions';
import StepNameWithParams from '../StepNameWithParams';
import StepStatusCell from '../StepStatusCell';
import TestStepActions from '../TestStepActions';

export const STEPS_COLUMN_ID = {
  ACTION: 'action',
  PARAMS: 'params',
  SCREENSHOT: 'screenshot',
  STATUS: 'status',
  ACTIONS: 'actions',
};

const getBaseStepData = (step) => ({
  id: step.frontId || step.id,
  alt: step.type,
  type: step.type,
});

export const STEPS_COLUMNS = [
  TablePartials.PREDEFINED_COLUMN.SELECTION,
  {
    Header: i18n.t('test.table.columns.action', 'Action'),
    accessor: identity,
    id: STEPS_COLUMN_ID.ACTION,
    Cell: StepNameWithParams,
    minWidth: 240,
    width: '43%',
    disableSortBy: true,
  },
  {
    Header: i18n.t('test.table.columns.screenshot', 'Screenshot'),
    accessor: getBaseStepData,
    Cell: TablePartials.ImageCell,
    id: STEPS_COLUMN_ID.SCREENSHOT,
    disableSortBy: true,
    width: 200,
  },
  {
    Header: i18n.t('test.table.columns.status', 'Status'),
    accessor: pick(['id', 'sleep']),
    Cell: StepStatusCell,
    id: STEPS_COLUMN_ID.STATUS,
    width: 20,
    maxWidth: 30,
    disableSortBy: true,
  },
  {
    Header: i18n.t('test.table.columns.actions', ''),
    Cell: TestStepActions,
    id: STEPS_COLUMN_ID.ACTIONS,
    disableSortBy: true,
    width: 55,
    maxWidth: 55,
  },
];

export const STEPS_RUNS_COLUMNS = [
  ...STEPS_COLUMNS.slice(1, STEPS_COLUMNS.length - 1),
  {
    ...STEPS_COLUMNS[STEPS_COLUMNS.length - 1],
    Cell: TestRunStepMoreActions,
  },
];

export const GROUP_HEADER_HEIGHT = 46;
